import { Types } from '../../actions/admin/skill-relationships/Types'

const initialState = {
  skillRelations: {
    data: [],
    meta: {}
  }
}

export default function adminSkillRelationsReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_SKILL_RELATIONSHIP:
      return {
        ...state,
        skillRelations: {
          ...state.skillRelations,
          data: state.skillRelations.data.concat(action.payload.data.newSkillRelationship)
        }
      }
    case Types.ALL_SKILL_RELATIONSHIPS:
      return {
        ...state,
        skillRelations: {
          ...state.skillRelations,
          data: action.payload.data.skillRelationships,
          meta: {
            current_page: Number(action.payload.data.page),
            total: action.payload.data.count,
            page: action.payload.data.page,
            pageSize: action.payload.data.pageSize
          }
        }
      }

    case Types.UPDATE_SKILL_RELATIONSHIP:
      return {
        ...state,
        skillRelations: {
          ...state.skillRelations,
          data: state.skillRelations.data.map((form) => {
            return form.id === action.payload.data.skill.id ? action.payload.data.skill : form
          })
        }
      }

    case Types.DELETE_SKILL_RELATIONSHIP:
      return {
        ...state,
        skillRelations: {
          ...state.skillRelations,
          data: state.skillRelations.data.filter((skill) => skill.id !== action.id)
        }
      }

    default:
      return state
  }
}
