import React, { useState } from 'react'
import { Button, Form, Input, InputNumber, Upload } from 'antd'
import { connect, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import CloseModal from '../../components/close-modal'
import TlaIcon from '../../components/tla-icon'
import { handleAddOrganization, handleUpdateOrganization } from '../../actions/admin/organization/Action'
import { TlaModal } from '../../components/pop-ups/tla-modal'
import { TlaError, TlaSuccess } from '../../utils/messages'
import SearchItems from '../../components/search'
import { handleSearchUser } from '../../actions/admin/users/Action'
import { addOrReplace, getErrors } from '../../utils'
import { MdClose } from 'react-icons/md'

function OrgForm ({ addContentPost, updateContentPost, searchUser }) {
  const { state } = useLocation()
  const [loading, setLoading] = useState(false)
  const [orgUsers, setOrgUsers] = useState(state?.data?.orgUsers?.map(item => ({ ...item.user })) ?? [])
  const userId = useSelector(state => state.loginReducer.authUser.id)
  // eslint-disable-next-line no-unused-vars
  const [selectedFile, setSelectedFile] = useState(null)
  const [form] = Form.useForm()
  const resourceId = state?.data?.id
  const formValues = {
    ...state?.data
  }

  const props = {
    // accept: allowedResumeFileTypes,
    beforeUpload: (info) => {
      setSelectedFile(info)
    },
    name: 'logo',
    multiple: false,
    maxCount: 1,
    method: 'get',
    onDrop (e) {
      // console.log('Dropped files', e.dataTransfer.files)
    }
  }

  const navigate = useNavigate()
  const onFinish = (values) => {
    setLoading(true)
    const formData = new FormData()
    if (selectedFile) {
      formData.append('logo', selectedFile)
    }

    formData.append('userId', userId)
    for (const key in values) {
      if (Object.prototype.hasOwnProperty.call(values, key)) {
        formData.append(key, values[key])
      }
    }

    if (orgUsers.length > 0) {
      formData.append('admins', JSON.stringify(orgUsers))
    }
    (resourceId ? updateContentPost(resourceId, formData) : addContentPost(formData))
      .then((res) => {
        setLoading(false)
        navigate(-1)
        TlaSuccess('Success')
      }).catch((error) => {
        setLoading(false)
        TlaError(error.response.data.message ?? getErrors(error.response.data?.errors))
      })
  }

  return (
        <TlaModal loading={loading} title={`${resourceId ? 'Update' : 'Create'} Organization`}>
            <Form requiredMark={false} form={form}
                  onFinish={onFinish} layout="vertical" name="formName" initialValues={formValues}>
                <Form.Item
                    name={'name'}
                    rules={[
                      {
                        required: true,
                        message: 'Required'
                      }
                    ]}
                    label={'Organization Name'}>
                    <Input rows={2} size={'large'} placeholder={'Eg:'}/>
                </Form.Item>
                <Form.Item
                    name={'validation_code'}
                    rules={[
                      {
                        required: true,
                        message: 'Required'
                      }
                    ]}
                    label={'Organization Validation Code'}>
                    <Input rows={2} size={'large'} placeholder={'ORG-ABCDSED1234'}/>
                </Form.Item>
                <Form.Item
                    name={'website'}
                    rules={[
                      {
                        required: true,
                        message: 'Required'
                      },
                      {
                        type: 'url',
                        message: 'Invalid URL',
                        warningOnly: true
                      }
                    ]}
                    label={'Organization Website'}>
                    <Input rows={2} size={'large'} placeholder={'www.abcdef.com'}/>
                </Form.Item>
                <Form.Item
                    name={'allowed_users'}
                    rules={[
                      {
                        required: true,
                        message: 'Required'
                      }
                    ]}
                    label={'Allowed Users*'}>
                    <InputNumber className={'w-full'} rows={2} size={'large'} placeholder={'300'}/>
                </Form.Item>
                <Form.Item
                    name={'paid_access'}
                    rules={[
                      {
                        required: true,
                        message: 'Required'
                      }
                    ]}
                    label={'Paid Access'}>
                    <InputNumber className={'w-full'} rows={2} size={'large'} placeholder={'300'}/>
                </Form.Item>
                <Form.Item label={'Search Admins*'}>
                    {/* <p>Search Admins*</p> */}
                    <SearchItems search={searchUser}
                                 onChangeCallback={(value) => {
                                   setOrgUsers(addOrReplace(orgUsers, value))
                                 }} placeholder={'Search admin by email'} displayField={'email'}/>
                </Form.Item>
                <div className={'flex gap-2 text-sm font-medium mt-2.5 mb-5'}>
                    {orgUsers.map(user => (
                        <span className={'bg-blue-50 text-blue-700 rounded-full px-1 flex items-center'} key={user.id}>
                            <MdClose
                                onClick={() => {
                                  setOrgUsers(orgUsers.filter(u => u.id !== user.id))
                                }}
                                className={'text-primary-400 font-medium cursor-pointer'}
                                size={16}/>
                            {user.userInformation.name}
                        </span>))}
                </div>

                <Form.Item>
                    <Upload.Dragger style={{ background: 'white', border: 'solid 1px #EAECF0' }} {...props}>
                        <div className={'w-fit mx-auto'}>
                            <TlaIcon name={'upload'}/>
                        </div>
                        <p className="text-sm text-primary-700 font-medium">Click to upload Logo</p>
                    </Upload.Dragger>
                </Form.Item>
                <div className={'flex gap-2'}>
                    <div className={'w-full'}>
                        <CloseModal/>
                    </div>
                    <Form.Item className={'w-full'}>
                        <Button className={'btn-primary w-full'} size={'large'} htmlType="submit">
                            {`${resourceId ? 'Edit' : 'Create'}`} Organization
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </TlaModal>
  )
}

OrgForm.propTypes = {
  searchUser: PropTypes.func.isRequired,
  addContentPost: PropTypes.func.isRequired,
  updateContentPost: PropTypes.func.isRequired
}

/**
 * @param state
 * @returns {{skills: *}}
 */
const mapStateToProps = (state) => ({
  skills: state.adminSkillsReducer.skills?.data,
  userId: state.loginReducer.authUser.id
})

/**
 *
 * @param dispatch
 * @returns {{addContentPost: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  addContentPost: (data) => dispatch(handleAddOrganization(data)),
  searchUser: (email) => dispatch(handleSearchUser(email)),
  updateContentPost: (id, data) => dispatch(handleUpdateOrganization(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(OrgForm)
