import { Route } from 'react-router-dom'
import React from 'react'
import PublicHeader from '../public-components/public-header'
import PublicCourses from '../../pages/public-pages/public-courses'
import PublicSingleCourse from '../../pages/public-pages/public-single-course'
import PublicQuizzes from '../../pages/public-pages/quizzes/public-quizzes'
import PublicQuizQuestion from '../../pages/public-pages/quizzes/public-quiz-question'
import SelfAssessment from '../../pages/public-pages/self-assessment'
import PublicPlayedGame from '../../pages/public-pages/public-played-game'
import RandomNousGame from '../../pages/public-pages/public-game/random-nous-game'
import PublicGameCategories from '../../pages/public-pages/public-game/public-game-categories'

export default [
    <Route key={ 'public-courses' } path={ 'public' } element={ <PublicHeader/> }>
        <Route index element={ <PublicCourses/> } path='courses'/>
        <Route element={ <PublicSingleCourse/> } path='courses/:courseId'/>
        <Route element={ <PublicQuizzes isSingle={true}/> } path="nous-quizzes/:quizId"/>
        <Route element={ <PublicQuizzes/> } path="nous-quizzes"/>
        <Route element={ <PublicQuizQuestion/> } path="nous-quizzes/:id/questions"/>
        <Route element={ <SelfAssessment/> } path="self-assessment"/>
        <Route element={ <PublicPlayedGame/> } path="played-game/:uuid"/>
        <Route element={ <PublicGameCategories/> } path="nous-games"/>
        <Route element={ <RandomNousGame/> } path="random-nous-game/:id"/>
        <Route element={ <RandomNousGame/> } path="played-game/private/:id"/>
    </Route>
]
