import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Divider, Radio, Spin } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'
import { sentenceCaseString } from '../../../utils'

function DisplayCourses ({ getCourses, courses, options, publicPage }) {
  const page = useSelector(state => state.commonReducer.courses.meta.page)
  const [tab, setTab] = useState('courses')

  const [loading, setLoading] = useState(false)
  const loadMoreData = (reset = null) => {
    setLoading(true)
    getCourses(page, reset).then(() => {
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }
  const coursesType = (value) => {
    setLoading(true)
    setTab(value)
    if (value !== 'courses') {
      getCourses(1, true, value).then(res => {
        setLoading(false)
      })
    } else {
      getCourses(1, true).then(res => {
        setLoading(false)
      })
    }
  }
  // const skeletonLoader = [1, 2, 3]
  useEffect(() => {
    loadMoreData('refresh')
  }, [])

  return (
        <React.Fragment>
            <Radio.Group className='my-5 courses-radio-checked-btn flex md:gap-2 font-medium md:text-lg course-tabs'
                         buttonStyle='outline' options={ options } onChange={ (e) => coursesType(e.target.value) }
                         value={ tab } optionType="button"/>
            <InfiniteScroll
                className='py-4'
                dataLength={ courses.data.length }
                next={ loadMoreData }
                hasMore={ courses.data.length < courses.meta.total }
                endMessage={ courses.length && <Divider plain>Nothing There 🤐</Divider> }
                scrollableTarget="scrollableDiv">
                <Spin spinning={ loading }>
                    <div className={ 'flex flex-wrap gap-x-8 gap-y-8 justify-center md:justify-start' }>
                        { courses.data.map(course => (
                            <div key={ course.id }
                                 className="rounded-lg py-5 px-4 border w-full md:w-[40%] lg:w-[31%] pb-5">
                                <Link
                                    to={ `${publicPage ? '/public' : ''}/courses/${course?.id}` }
                                    className='hover:text-inherit'>
                                    <img className='w-full aspect-video object-cover rounded-md'
                                         src={ course.featureImageUrl ? course.featureImageUrl : 'https://www.shutterstock.com/image-vector/elearning-banner-online-education-home-260nw-1694176021.jpg' }
                                         alt=""/>
                                    <h3 className='text-sm mt-2 mb-1 text-primary-600'>{ course?.category?.title }</h3>
                                    <h2 className='font-medium text-sm text-gray-800 mb-3'>{ course.title }</h2>
                                    <div className="flex justify-end items-start">
                                        {
                                            course.progress &&
                                            <span
                                                className={ 'bg-purple-50 text-purple-700 py-1 px-2 rounded-2xl text-xs mr-1 whitespace-nowrap' }>
                                  { sentenceCaseString(course.progress?.replaceAll('_', ' ')) }
                              </span>
                                        }
                                    </div>
                                </Link>
                            </div>
                        )) }
                    </div>

                </Spin>
            </InfiniteScroll>
        </React.Fragment>
  )
}

DisplayCourses.defaultProps = {
  publicPage: false
}

DisplayCourses.propTypes = {
  publicPage: PropTypes.bool,
  courses: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  getCourses: PropTypes.func.isRequired
}

export default DisplayCourses
