import React, { useEffect, useState } from 'react'
import TlaTableWrapper from '../../components/table/tla-table-wrapper'
import { Select, Spin, Table } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { handleGetAllContacts } from '../../actions/user/contacts/Action'
import { formatDate } from '../../utils'
import RequestButton from './request-button'
import TlaImage from '../../components/tla-image'
import { handleGetProfile } from '../../actions/authenticate/Actions'

function Contacts ({ contacts, getContacts, userId, getProfile }) {
  const { data, meta } = contacts
  const [loading, setLoading] = useState(true)
  const contactTypes = [
    {
      value: 'family/friend',
      label: 'Family/Friend'
    },
    {
      value: 'school',
      label: 'School'
    },
    {
      value: 'work',
      label: 'Work'
    }
  ]
  useEffect(() => {
    getContacts(userId).then(() => {
      setLoading(false)
    })
    getProfile()
  }, [])
  return (
    <div className={'bg-white-base rounded-lg p-[13px] pb-6'}>
      {/* <h1 className={'font-medium text-3xl leading-[38px] text-gray-900 mb-2'}>Contacts</h1> */}
      <div className={'rounded-lg shadow-2.5xl pb-1 border overflow-x-scroll'}>
        <div className={'border-b p-5'}>
          <h3 className={'text-gray-900 text-base font-medium'}>Request Feedback</h3>
          <p className={'font-normal text-sm leading-6 text-gray-500'}>
            When you request feedback, the other person can assess up to 20 skills in one go.
            There is a one month limit, but we recommend to request feedback at least after 3 months.
          </p>
        </div>
        <Spin spinning={loading}>
          <TlaTableWrapper numberColumnTitle={'Sr.No.'}
            showHeader={false}
            callbackFunction={getContacts} fetchId={userId} data={data} meta={meta}>
            <Table.Column title={'Name'} width={250} className='break-all' render={(text, record) => (
              <div className={'flex gap-2 items-center'}>
                <TlaImage photo={record.contactUser?.userInformation?.photo} />
                <p>{record.contactUser?.userInformation?.name}</p>
              </div>
            )} />
            <Table.Column responsive={['md']} title={'Type'} className='border-b-8' render={(text, record) => {
              return <>
              <Select
                defaultValue="family/friend"
                className='no-border-dropdown'
                onChange={(value) => {
                  console.log(value)
                }}
                options={[...contactTypes]}
              />
              </>
            }
            } />
            <Table.Column responsive={['md']} title={'Requested on'} render={(text, record) => <>{formatDate(record.requestDate)}</>} />
            <Table.Column responsive={['md']} title={'Assessed on'} render={(text, record) => <>{formatDate(record.lastAssessDate ?? null)}</>} />
            <Table.Column title={'Actions'} render={(text, record) => (
              <RequestButton record={record} />
            )} />
          </TlaTableWrapper>
        </Spin>
      </div>
    </div>
  )
}

Contacts.propTypes = {
  contacts: PropTypes.object.isRequired,
  getContacts: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired
}

const mapStateToProps = (state) => ({
  contacts: state.contactsReducer.contacts,
  userId: state.loginReducer.authUser.id
})

const mapDispatchToProps = (dispatch) => ({
  getProfile: () => dispatch(handleGetProfile()),
  getContacts: (userId, page) => dispatch(handleGetAllContacts(userId, page))
})

export default connect(mapStateToProps, mapDispatchToProps)(Contacts)
