import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Radio } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import TlaModalFormWrapper from '../../../components/tla-modal-form-wrapper'
import CloseModal from '../../../components/close-modal'
import { handleAddPersonality, handleUpdatePersonality } from '../../../actions/admin/personalities/Action'
import TlaSelect from '../../../components/TlaSelect'
import { handleGetAllSkillsNoPagination } from '../../../actions/admin/skills/Action'
import { RTEconfig, contactTypes } from '../../../utils'
import { Editor } from '@tinymce/tinymce-react'

function PersonalitiesForm ({ addPersonality, updatePersonality, skills, getSkills }) {
  const { state } = useLocation()
  const [personalityType, setPersonalityType] = useState(state?.data?.personalityType ?? 'PROFESSIONAL')
  const [content, setContent] = useState(state?.data?.personalityDescription ?? '')
  const [dos, setDos] = useState(state?.data?.dos ?? '')
  const [donts, setDonts] = useState(state?.data?.donts ?? '')
  const resourceId = state?.data?.id
  useEffect(() => {
    getSkills(1, 500)
  }, [])
  const formValues = {
    ...state?.data,
    skills: state?.data?.skills.map(skill => skill.id)
  }
  const formatBeforeSubmit = (values) => {
    return {
      ...values,
      personalityDescription: content,
      dos,
      donts
    }
    // return values
  }
  return (
        <TlaModalFormWrapper beforeSubmit={formatBeforeSubmit}
                             width={'50%'}
                             initialValues={formValues}
                             formTitle={`${resourceId ? 'Update' : 'Create'} Personality`}
                             resourceId={state?.data?.id ?? null}
                             onSubmit={state?.data?.id ? updatePersonality : addPersonality}>

            <Form.Item label="" name="personalityType"
                       rules={[
                         {
                           required: true,
                           message: 'Required'
                         }
                       ]}>
                <div className={'flex flex-wrap gap-x-3'}>
                    <p>Type</p>
                    <Radio.Group value={personalityType} onChange={(e) => setPersonalityType(e.target.value)}>
                        <Radio value="PROFESSIONAL">Professional</Radio>
                        <Radio value="PERSONAL">Personal</Radio>
                    </Radio.Group>
                </div>
            </Form.Item>
            <Form.Item
                name={'personalityTitle'}
                rules={[
                  {
                    required: true,
                    message: 'Required'
                  }
                ]}
                label={'Personality Title *'}>
                <Input size={'large'} rows={2} placeholder={'Enter a Title...'}/>
            </Form.Item>
            <Form.Item
                name={'personalityDescription'}
                rules={[
                  {
                    required: true,
                    message: 'Required'
                  }
                ]}
                label={'Personality Description *'}>
                <Editor
                  {...RTEconfig}
                  onEditorChange={(cnt) => setContent(cnt)}
                />
            </Form.Item>
            <TlaSelect required name={'professionalStatus'} customClass='my-3' label={'Select Professional Status *'} options={contactTypes} optionKey={'label'}/>
            {/* <Form.Item
                name={'skills'}
                rules={[
                  {
                    required: true,
                    message: 'Required'
                  }
                ]}
                label={'Personality Skills *'}>
                <Select name={'skills'} defaultValue={state?.data?.skills} mode={'multiple'} options={skills} optionKey={'title'} />
            </Form.Item> */}
            <TlaSelect name={'skills'} defaultValue={state?.data?.skills} mode={'multiple'} customClass='my-3' label={'Select Skill *'} options={skills} optionKey={'title'}/>
            <Form.Item
                name={'dos'}
                rules={[
                  {
                    required: true,
                    message: 'Required'
                  }
                ]}
                label={'Dos *'}>
                <Editor
                    {...RTEconfig}
                    onEditorChange={(cnt) => setDos(cnt)}
                />
            </Form.Item>
            <Form.Item
                name={'donts'}
                rules={[
                  {
                    required: true,
                    message: 'Required'
                  }
                ]}
                label={'Donts *'}><Editor
                {...RTEconfig}
                onEditorChange={(cnt) => setDonts(cnt)}
            />
            </Form.Item>
            <div className={'flex gap-2'}>
                <div className={'w-full'}>
                    <CloseModal/>
                </div>
                <Form.Item className={'w-full'}>
                    <Button className={'btn-primary w-full'} size={'large'} htmlType="submit">
                        {`${resourceId ? 'Update' : 'Create'}`} Personality
                    </Button>
                </Form.Item>
            </div>
        </TlaModalFormWrapper>
  )
}

PersonalitiesForm.propTypes = {
  addPersonality: PropTypes.func.isRequired,
  skills: PropTypes.array.isRequired,
  updatePersonality: PropTypes.func.isRequired,
  getSkills: PropTypes.func.isRequired
}

/**
 * @param state
 * @returns {{skills: ([]|*)}}
 */
const mapStateToProps = (state) => ({
  skills: state.adminSkillsReducer.skills.data
})

/**
 *
 * @param dispatch
 * @returns {{addPersonality: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  addPersonality: (data) => dispatch(handleAddPersonality(data)),
  updatePersonality: (personalityId, data) => dispatch(handleUpdatePersonality(personalityId, data)),
  getSkills: () => dispatch(handleGetAllSkillsNoPagination())
})

export default connect(mapStateToProps, mapDispatchToProps)(PersonalitiesForm)
