import api from '../../../utils/api'
import { addModuleLesson, allModuleLessons, deleteModuleLesson, updateModuleLesson } from './ActionCreator'

/**
 * Store a newly created resource in storage.
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAddModuleLesson = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/modules/content', data).then((res) => {
      dispatch(addModuleLesson(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAllModuleLessons = (pageNumber = 1, pageSize = 15, filters = null) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/modules/content?page=${pageNumber}&pageSize=15${filters && filters.module ? '&moduleId=' + filters.module : ''}`).then((res) => {
      dispatch(allModuleLessons(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Update the specified resource in storage.
 * @param postId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleUpdateModuleLesson = (postId, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().patch(`/modules/content/${postId}`, data).then((res) => {
      dispatch(updateModuleLesson(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Remove the specified resource from storage.
 * @returns {function(*): Promise<unknown>}
 * @param postId
 */
export const handleDeleteModuleLesson = (postId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().delete(`/modules/content/${postId}`).then((res) => {
      dispatch(deleteModuleLesson(postId))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
