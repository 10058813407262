import React from 'react'
import PropTypes from 'prop-types'
import TlaIcon from './tla-icon'

function TlaImage ({ photo }) {
  return (
    photo !== 'default.png'
      ? <img className={'h-10 w-10 rounded-full'} src={photo} alt={'Photo'}/>
      : <TlaIcon name={'avatar'}/>
  )
}

TlaImage.defaultProps = {

}

TlaImage.propTypes = {
  photo: PropTypes.string
}

export default (TlaImage)
