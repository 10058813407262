import { Types } from '../../actions/admin/org-users/Types'

const initialState = {
  orgUsers: {
    data: [],
    meta: {}
  }
}

/**
 *
 * @param state
 * @param action
 *
 * @returns {{authenticated: boolean, authToken: null, authUser: {}}|{authenticated: boolean, authToken: null, authUser: {}}|{authenticated: boolean, authToken, authUser: *}}
 */
export default function orgUsersReducer (state = initialState, action) {
  switch (action.type) {
    case Types.ALL_ORG_USERS: {
      return {
        ...state,
        orgUsers: {
          data: action.payload.data.orgUsers,
          meta: {
            total: action.payload.data.count,
            from: action.payload.data.page,
            current_page: parseInt(action.payload.data.page),
            to: action.payload.data.pageSize
          }
        }
      }
    }

    default:
      return state
  }
}
