import React from 'react'
import Header from './header'
import PropTypes from 'prop-types'
import { Link, Outlet, useLocation } from 'react-router-dom'
import ProfileSidebar from './sidebar/profile-sidebar'
import { HeaderMenus, MobileMenus, ProfileMenus } from '../../../utils/menu-items'
import MobileMenu from './mobile-menu'
import { FiBriefcase, FiSettings, FiUsers } from 'react-icons/fi'
import { RiUser6Line } from 'react-icons/ri'

function ProfileLayout () {
  // eslint-disable-next-line no-unused-vars
  const icons = {
    organizations: <FiBriefcase className={'text-gray-900'} size={20}/>,
    settings: <FiSettings className={'text-gray-900'} size={20}/>,
    'public-profile': <FiUsers className={'text-gray-900'} size={20}/>,
    profile: <RiUser6Line className={'text-gray-900'} size={20}/>
  }

  const { pathname } = useLocation()

  return (
    <div>
      <div className="bg-white-base">
        <div className={'max-w-screen-2xl mx-auto border-b md:border-none'}>
          <Header menuItems={HeaderMenus} />
        </div>
      </div>
      <div className="bg-white-50 py-4">
        <div className={'max-w-screen-2xl mx-auto'}>
          <div className={'flex gap-x-2.5 mx-0 md:mx-12'}>
            <ProfileSidebar />
            <div className={'w-full mb-5'}>
              <div className={'block md:hidden'}>
                <div className={'grid grid-cols-2 gap-2 justify-center mb-2 px-5'}>
                  {
                    ProfileMenus.map((menu, index) => (
                        <Link key={index}
                              className={`font-medium ${pathname === menu.link ? 'text-primary-700' : ''} bg-white-base px-2 py-1 rounded-md text-gray-900 flex items-center gap-2`}
                              to={menu.link}>
                          {icons[menu.icon]}
                          {menu.title}
                        </Link>
                    ))
                  }
                </div>
              </div>
              <Outlet/>
            </div>
          </div>
          <div className={'block md:hidden relative mt-20 z-[1000]'}>
            <MobileMenu menuItems={MobileMenus}/>
          </div>
        </div>
      </div>
    </div>

  )
}

ProfileLayout.propTypes = {
  children: PropTypes.any
}

export default ProfileLayout
