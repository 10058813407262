import api from '../../../utils/api'
import { allFeedbacks, reportQuestion, submitFeedback } from './ActionCreator'
import { removeFeed } from '../common/ActionCreator'
import { incrementGivenAssessment, updateSelfAssessed } from '../../authenticate/ActionCreators'

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAllFeedbacks = (userId, pageNumber = 1) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/contact/feedbackRequests?userId=${userId}&page=${pageNumber}&pageSize=15`)
      .then((res) => {
        dispatch(allFeedbacks(res.data))
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetSubmitFeedback = (data, feedId = null) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/question/feedRequest', data)
      .then((res) => {
        dispatch(submitFeedback(res.data))
        if (data.giver.id === data.receiver.id) {
          dispatch(updateSelfAssessed(false))
        }
        if (feedId) {
          dispatch(removeFeed(feedId))
          dispatch(incrementGivenAssessment())
        }
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleReportQuestion = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/question/reportquestion', data)
      .then((res) => {
        dispatch(reportQuestion(res.data))
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}
