/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import ArrowLeft from '../../../../assets/images/icons/arrow-left.svg'
import ArrowRight from '../../../../assets/images/icons/arrow-right.svg'
const IconCarousel = ({ iconsForSlider, changeSlide }) => {
  const [rotation, setRotation] = useState(0)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [selectedIcon, setSelectedIcon] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const containerRef = useRef(null)
  const timeoutRef = useRef(null)

  useEffect(() => {
    setSelectedIndex(0)
    setStartX(0)
    setRotation(0)
  }, [iconsForSlider])

  const icons = iconsForSlider
  const handleMouseDown = (e) => {
    setIsDragging(true)
    setStartX(e.clientX)
  }
  const handleMouseMove = (e) => {
    if (!isDragging) return
    const delta = e.clientX - startX
    setRotation(prev => (prev + delta * 0.5) % 360)
    setStartX(e.clientX)
  }
  const handleMouseUp = () => {
    setIsDragging(false)
    snapToClosestIcon()
  }
  useEffect(() => {
    const container = containerRef.current
    container.addEventListener('mousedown', handleMouseDown)
    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseUp)
    return () => {
      container.removeEventListener('mousedown', handleMouseDown)
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isDragging, startX])
  useEffect(() => {
    const container = containerRef.current
    const handleTouchStart = (e) => {
      setIsDragging(true)
      setStartX(e.touches[0].clientX)
    }

    const handleTouchMove = (e) => {
      if (!isDragging) return
      const delta = e.touches[0].clientX - startX
      setRotation(prev => (prev + delta * 0.5) % 360)
      setStartX(e.touches[0].clientX)
    }

    const handleTouchEnd = () => {
      setIsDragging(false)
      snapToClosestIcon()
    }

    container.addEventListener('touchstart', handleTouchStart)
    container.addEventListener('touchmove', handleTouchMove)
    container.addEventListener('touchend', handleTouchEnd)

    return () => {
      container.removeEventListener('touchstart', handleTouchStart)
      container.removeEventListener('touchmove', handleTouchMove)
      container.removeEventListener('touchend', handleTouchEnd)
    }
  }, [isDragging, startX])

  const calculateOpacity = (angle) => {
    const normalizedAngle = ((angle + 180) % 360) - 180
    const absAngle = Math.abs(normalizedAngle)

    if (absAngle >= 90) {
      return 0
    }

    if (absAngle <= 45) {
      return 1 - (absAngle / 45) * 0.3
    }

    return 0.7 - (absAngle - 45) / 45 * 0.7
  }

  const findClosestIcon = () => {
    let closestAngle = Infinity
    let closestIcon = null
    let closestIndex = 0

    icons.forEach(({ name }, index) => {
      const angle = (index / icons.length) * 360
      const currentAngle = (angle + rotation + 360) % 360
      const normalizedAngle = ((currentAngle + 180) % 360) - 180
      const absAngle = Math.abs(normalizedAngle)

      if (absAngle < Math.abs(closestAngle)) {
        closestAngle = normalizedAngle
        closestIcon = name
        closestIndex = index
      }
    })

    return { name: closestIcon, index: closestIndex, angle: closestAngle }
  }

  const snapToClosestIcon = () => {
    const { index, angle } = findClosestIcon()
    const targetRotation = -(index / icons.length) * 360
    setRotation(targetRotation)
    changeSlide(index)
  }

  useEffect(() => {
    if (isDragging) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    } else {
      timeoutRef.current = setTimeout(() => {
        snapToClosestIcon()
      }, 200)
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [rotation, isDragging])

  useEffect(() => {
    const closestIcon = findClosestIcon()
    setSelectedIcon(closestIcon.name)
    setSelectedIndex(closestIcon.index)
  }, [rotation])

  const handleArrowClick = (direction) => {
    const step = (360 / icons.length) * (direction === 'left' ? -1 : 1)
    setRotation(prev => (prev + step + 360) % 360)
    const index = direction === 'left' ? (selectedIndex - 1 + iconsForSlider.length) % iconsForSlider.length : ((selectedIndex + 1) % iconsForSlider.length)
    handleIconClick(index)
    // changeSlide(index)
  }

  const handleIconClick = (index) => {
    const targetRotation = -(index / icons.length) * 360
    setRotation(targetRotation)
    changeSlide(index)
  }

  return (
    <div className="w-full relative flex items-center justify-center z-10" ref={containerRef}>
      <button
        className="absolute left-0 bg-white bg-opacity-50 p-2 rounded-full"
        onClick={() => handleArrowClick('left')}
      >
        <img src={ArrowLeft} className='w-6 h-6' />
      </button>
      <div
        className="relative pb-10"
        style={{
          transformStyle: 'preserve-3d',
          transition: isDragging ? 'none' : 'transform 0.3s ease-out'
        }}
      >
        {icons.map(({ Icon, name }, index) => {
          const angle = (index / icons.length) * 360
          const currentAngle = (angle + rotation + 360) % 360
          const radian = currentAngle * (Math.PI / 180)
          const radius = 100
          const x = Math.sin(radian) * radius
          const z = Math.cos(radian) * radius
          const opacity = calculateOpacity(currentAngle)
          return (
            <div
              key={index}
              className="absolute left-1/2 top-1/2 cursor-pointer"
              style={{
                transform: `translate(-50%, -50%) translateX(${x}px) translateZ(${z}px)`,
                opacity: opacity,
                pointerEvents: opacity > 0.1 ? 'auto' : 'none',
                transition: isDragging ? 'none' : 'all 0.3s ease-out'
              }}
              onClick={() => handleIconClick(index)}
            >
              <div className={'relative w-12 h-12 flex items-center justify-center p-3'}>
                <div
                  className={`absolute inset-0 rounded-full bg-white-base ${selectedIcon === name ? 'border-4 border-primary-500' : ''}`}
                  style={{
                    transform: 'scale(0.85)',
                    opacity: 1
                  }}
                ></div>
                <Icon
                  size={34}
                  isActive={selectedIcon === name ? '#2E90FA' : '#98A2B3'}
                  className='relative transition-all duration-300'
                  style={{
                    filter: `brightness(${0.8 + opacity * 0.2})`
                  }}
                />
              </div>
            </div>
          )
        })}
      </div>
      <button
        className="absolute right-0 bg-white bg-opacity-50 p-2 rounded-full"
        onClick={() => handleArrowClick('right')}
      >
        <img src={ArrowRight} className='w-6 h-6' />
      </button>
    </div>
  )
}

IconCarousel.propTypes = {
  iconsForSlider: PropTypes.array.isRequired,
  changeSlide: PropTypes.func.isRequired
}

export default IconCarousel
