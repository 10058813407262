import React from 'react'
import Mail from '../../../../assets/images/icons/check-success.svg'
import Title from '../verification/title'
import { Link } from 'react-router-dom'

export default function Verified () {
  return (
        <div className={'h-screen flex items-center justify-start pt-20 flex-col bg-white-base'}>
            <img src={Mail} alt={'mail'}/>
            <div className={'mb-8'}>
                <Title title={'Verification Complete'}
                       focusText={''}
                       subText={'Pin verification is completed. Click below to log in.'}/>
            </div>
            <Link to={'/login'} className={'mb-8'}>
                <button className={'bg-primary-400 border-primary-400 text-white-base text-base h-11 w-[360px]' +
                    ' rounded-lg'}>
                    Click to Log in
                </button>
            </Link>
        </div>
  )
}
