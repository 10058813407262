import api from '../../../utils/api'
import { addChat, allChatList, deleteChat, getChat, updateChatTitle } from './ActionCreator'

/**
 * Store a newly created resource in storage.
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAddChat = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('openai/userchat', data).then((res) => {
      if (res.data.data.error) {
        reject(res.data.data?.error)
      } else {
        dispatch(addChat(res.data))
        resolve(res)
      }
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAllChats = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get('openai/userchatlist').then((res) => {
      dispatch(allChatList(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const handleDeleteChat = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().delete(`openai/chat/${id}`).then((res) => {
      dispatch(deleteChat(res.data, id))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const handleUpdateChatTitle = (chatTitle, id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().put(`openai/update-title/${id}`, { chatTitle }).then((res) => {
      dispatch(updateChatTitle(chatTitle, id))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetChat = (chatId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`openai/userchat/${chatId}`).then((res) => {
      dispatch(getChat(res.data, chatId))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
