import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router'
import TlaTableWrapper from '../../../components/table/tla-table-wrapper'
import { Spin, Table } from 'antd'
import PropTypes from 'prop-types'
import { handleDeleteContentPost, handleGetAllContentPosts } from '../../../actions/admin/content-posts/Action'
import { connect } from 'react-redux'
import TlaConfirm from '../../../components/tla-confirm'
import { TlaSuccess } from '../../../utils/messages'
import TlaEdit from '../../../components/tla-edit'
import { FiEdit2 } from 'react-icons/fi'
import TlaSelect from '../../../components/TlaSelect'
import { handleGetAllSkillsNoPagination } from '../../../actions/admin/skills/Action'

const ContentPosts = ({ getContentPosts, contentPosts, deleteContentPost, skills, getSkills }) => {
  const { setPageInfo } = useOutletContext()
  const { data, meta } = contentPosts
  const [skill, setSkill] = useState(0)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setPageInfo({
      title: 'Content Posts',
      buttonText: 'Add Content Post',
      buttonLink: 'Add Content Post',
      importBtnText: 'Import Content Posts',
      showImportBtn: true,
      subTitle: '',
      helpingText: '',
      showSearch: false
    })
    getContentPosts().then(() => setLoading(false))
    getSkills().then(() => {})
  }, [])

  return (
        <div className={'pb-4'}>
            <Spin spinning={loading}>
              <div className="w-1/4 ml-auto pr-6 mb-5">
                <TlaSelect
                  defaultValue='Select Skill'
                  onChange={(value) => {
                    setLoading(true)
                    getContentPosts(1, { skill: value }).then(() => {
                      setLoading(false)
                      setSkill(value)
                    })
                  }}
                  required name={'skillId'} options={[...skills]} optionKey={'title'}/>
              </div>
                <TlaTableWrapper
                   meta={{
                     ...meta
                   }}
                   filters={{ skill }}
                   numberColumn={true} data={data} showHeader={false}
                   callbackFunction={getContentPosts}>
                    <Table.Column title={'Content Post Title'} render={((record) => (
                        <span className={'cursor-pointer hover:underline'} onClick={() => alert('reaching....')}>
                            {record?.title ? record?.title : 'No Title' }
                        </span>
                    ))}/>
                    <Table.Column title={'Actions'} render={((record) => (
                        <div className={'flex items-center'}>
                            <TlaConfirm title={'Delete Content Post'}
                                        fullText={'Do you really want to delete this query?'}
                                        callBack={() => {
                                          deleteContentPost(record.id).then(() => TlaSuccess('Deleted'))
                                        }}/>
                            <TlaEdit data={record} meta={{ ...meta, skill }} icon={<FiEdit2 className={'icon'}/>} link={'form'}/>
                        </div>
                    ))}/>
                </TlaTableWrapper>
            </Spin>
        </div>
  )
}

ContentPosts.propTypes = {
  getContentPosts: PropTypes.func.isRequired,
  deleteContentPost: PropTypes.func.isRequired,
  getSkills: PropTypes.func.isRequired,
  skills: PropTypes.array.isRequired,
  contentPosts: PropTypes.object.isRequired
}

/**
 * @param state
 * @returns {{contentPosts: *}}
 */
const mapStateToProps = (state) => ({
  contentPosts: state.adminContentPostsReducer.contentPosts,
  skills: state.adminSkillsReducer.skills.data
})

/**
 *
 * @param dispatch
 * @returns {{getContentPosts: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  getContentPosts: (pageNumber, skillId) => dispatch(handleGetAllContentPosts(pageNumber, skillId)),
  deleteContentPost: (contentpostId) => dispatch(handleDeleteContentPost(contentpostId)),
  getSkills: () => dispatch(handleGetAllSkillsNoPagination())
})

export default connect(mapStateToProps, mapDispatchToProps)(ContentPosts)
