import React from 'react'
import { ProtectedRoutes } from './protected-routes'
import PropTypes from 'prop-types'
import { AdminProtectedRoutes } from './admin-protected-routes'

export const AppRoutes = ({ userType }) => (
  userType === 'admin' ? <AdminProtectedRoutes/> : <ProtectedRoutes/>
)

AppRoutes.defaultProps = {
  userType: 'user'
}

AppRoutes.propTypes = {
  userType: PropTypes.string
}
