import React, { useEffect } from 'react'
import AuthLayout from './auth-layout'
import AuthBottomLink from './auth-bottom-link'
import { useDispatch } from 'react-redux'

function VerificationMessage () {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({
      type: 'SET_INVITATION_CODE',
      payload: null
    })
  }, [])

  return (
        <AuthLayout pageTitle={'Verify your email'}>
            <div>
                <p className={'text-sm text-center'}>A verification link has been sent to your email</p>
                <AuthBottomLink size={'sm'} text={''} link={'/'} linkText={'Login Here'}/>
            </div>
        </AuthLayout>
  )
}

export default VerificationMessage
