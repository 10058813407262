import React from 'react'
import PropTypes from 'prop-types'

const HandIcon = ({ className, isActive = '#98A2B3' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" className={className} viewBox="0 0 22 22" fill="none">
      <g clipPath="url(#clip0_10150_1172)">
        <path d="M12.0961 16.3794L10.5285 16.4577C9.54276 16.5071 8.89901 17.4726 9.15102 18.3461L10.0641 21.5016C10.1495 21.7967 10.4196 21.9997 10.7268 21.9997H12.6813C13.0402 21.9997 13.3392 21.7245 13.3688 21.3669C13.6977 17.3974 13.6452 18.0478 13.6504 17.9673C13.7138 17.0806 12.9865 16.3346 12.0961 16.3794ZM12.0463 20.6201H11.2453L10.4818 17.9813C10.4685 17.9103 10.5206 17.8396 10.5975 17.8358C12.3055 17.7504 12.1979 17.7407 12.2448 17.7886C12.2946 17.8396 12.289 17.691 12.0463 20.6201Z" fill={isActive}/>
        <path d="M15.012 16.7122C14.633 16.6758 14.2954 16.9527 14.2585 17.332L13.8778 21.2434C13.8384 21.6481 14.156 22.0002 14.5652 22.0002C14.9161 22.0002 15.2163 21.7335 15.251 21.3771L15.6317 17.4656C15.6686 17.0864 15.3912 16.7491 15.012 16.7122Z" fill={isActive}/>
        <path d="M15.12 13.1536C13.5825 12.4211 11.8472 11.9729 9.96222 11.8216C9.00173 11.7433 8.20527 12.5649 8.31775 13.5259L8.45615 14.7088C8.53104 15.3486 9.07309 15.8453 9.71698 15.8642L14.5015 16.005C15.1232 16.0232 15.6882 15.6437 15.9002 15.0498C16.1655 14.3071 15.83 13.492 15.12 13.1536ZM14.6009 14.5857C14.579 14.6473 14.8399 14.6346 9.81933 14.487C9.67433 13.248 9.66287 13.3122 9.72948 13.2431C9.75237 13.2194 9.79238 13.1927 9.8518 13.1968C11.5685 13.3346 13.1413 13.7391 14.5265 14.3992C14.5941 14.4314 14.6268 14.5133 14.6009 14.5857Z" fill={isActive}/>
        <path d="M6.22616 10.3847C6.0024 10.0764 5.57098 10.0079 5.26262 10.2317C4.95435 10.4556 4.88584 10.887 5.10969 11.1953L6.63309 13.2935C6.76804 13.4795 6.97844 13.5781 7.19186 13.5781C7.74801 13.5781 8.0828 12.942 7.74952 12.483L6.22616 10.3847Z" fill={isActive}/>
        <path d="M5.86944 9.15166V7.80069C5.86944 7.41968 5.56056 7.11084 5.17959 7.11084C4.79862 7.11084 4.48975 7.41968 4.48975 7.80069V9.15166C4.48975 9.53267 4.79862 9.84151 5.17959 9.84151C5.56056 9.84151 5.86944 9.53267 5.86944 9.15166Z" fill={isActive}/>
        <path d="M9.39281 7.37042C9.01249 7.39289 8.72241 7.71944 8.74487 8.09976L8.8928 10.6019C8.91552 10.9866 9.24682 11.2726 9.62219 11.2498C10.0025 11.2274 10.2926 10.9008 10.2701 10.5205L10.1222 8.01832C10.0997 7.638 9.77365 7.34736 9.39281 7.37042Z" fill={isActive}/>
        <path d="M11.7132 11.6624C12.0942 11.7051 12.4336 11.4304 12.476 11.0542L12.8341 7.87804C12.8768 7.49945 12.6045 7.15793 12.226 7.11525C11.8469 7.07256 11.5058 7.34488 11.4632 7.72343L11.105 10.8996C11.0623 11.2782 11.3346 11.6197 11.7132 11.6624Z" fill={isActive}/>
        <path d="M13.7159 12.3035C14.0914 12.3672 14.4476 12.1142 14.5112 11.7385L15.1149 8.17431C15.1785 7.79865 14.9255 7.44256 14.5499 7.37896C14.1743 7.31524 13.8181 7.56832 13.7545 7.94395L13.1509 11.5082C13.0873 11.8838 13.3403 12.2399 13.7159 12.3035Z" fill={isActive}/>
        <path d="M16.5018 9.71863C16.1324 9.6249 15.7572 9.84836 15.6635 10.2176L15.1748 12.1434C15.0644 12.5786 15.3934 13.0031 15.844 13.0031C16.1521 13.0031 16.4329 12.7951 16.5122 12.4828L17.0008 10.557C17.0945 10.1877 16.8711 9.81236 16.5018 9.71863Z" fill={isActive}/>
        <path d="M9.38731 7.05197C9.76789 7.07741 10.0963 6.7897 10.1218 6.40985L10.2465 4.55292C10.272 4.17281 9.9845 3.84397 9.60439 3.81845C9.2242 3.79314 8.8954 4.08037 8.86988 4.46056L8.74523 6.3175C8.71967 6.6976 9.00716 7.02644 9.38731 7.05197Z" fill={isActive}/>
        <path d="M9.67651 3.48756C10.0584 3.51187 10.385 3.22266 10.4095 2.84376L10.5101 1.29161C10.5348 0.911414 10.2465 0.583177 9.86631 0.558558C9.4862 0.534284 9.15792 0.822165 9.1333 1.20236L9.03272 2.75451C9.0081 3.1347 9.29632 3.46289 9.67651 3.48756Z" fill={isActive}/>
        <path d="M12.3048 6.87926C12.6854 6.9047 13.0138 6.61699 13.0393 6.23719L13.1686 4.31135C13.1942 3.93125 12.9067 3.60237 12.5266 3.57684C12.1459 3.55071 11.8176 3.83877 11.7921 4.21892L11.6627 6.14475C11.6371 6.52485 11.9246 6.85374 12.3048 6.87926Z" fill={isActive}/>
        <path d="M12.48 3.28789C12.8651 3.28789 13.1754 2.97306 13.17 2.58834L13.1433 0.680145C13.138 0.30254 12.8302 0 12.4537 0C12.0686 0 11.7584 0.314828 11.7637 0.699547L11.7905 2.60775C11.7957 2.98535 12.1035 3.28789 12.48 3.28789Z" fill={isActive}/>
        <path d="M14.8223 3.89287C14.4436 3.87002 14.1148 4.1588 14.0914 4.53904L13.9764 6.40736C13.952 6.80398 14.2669 7.13959 14.6656 7.13959C15.0272 7.13959 15.331 6.85791 15.3535 6.49204L15.4685 4.62372C15.4919 4.24353 15.2026 3.91628 14.8223 3.89287Z" fill={isActive}/>
        <path d="M14.0931 2.93549C14.1284 3.31188 14.4616 3.5933 14.8444 3.55799C15.2237 3.52242 15.5024 3.18612 15.4669 2.80674L15.3519 1.57959C15.3163 1.20031 14.9805 0.921955 14.6006 0.957094C14.2213 0.992664 13.9426 1.32896 13.9781 1.70834L14.0931 2.93549Z" fill={isActive}/>
        <path d="M16.8763 6.91203C16.4959 6.88073 16.1638 7.16417 16.133 7.54384L16.0467 8.60733C16.0142 9.00795 16.3302 9.35296 16.7351 9.35296C17.0908 9.35296 17.3927 9.07957 17.4219 8.71882L17.5081 7.65534C17.5389 7.27558 17.256 6.94277 16.8763 6.91203Z" fill={isActive}/>
        <path d="M16.0887 5.97163C16.1104 6.35303 16.4385 6.64371 16.8167 6.62108C17.1971 6.59939 17.4878 6.27344 17.4661 5.89308L17.423 5.13705C17.4013 4.75668 17.0753 4.46458 16.695 4.4876C16.3147 4.50929 16.0239 4.83524 16.0456 5.2156L16.0887 5.97163Z" fill={isActive}/>
      </g>
      <defs>
        <clipPath id="clip0_10150_1172">
          <rect width="22" height="22" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

HandIcon.propTypes = {
  isActive: PropTypes.string,
  className: PropTypes.string
}
export default HandIcon
