import React from 'react'
import NousgptButtonSet from '../../components/public-components/nousgpt/nousgpt-button-set'
// import { NewChatButton } from './new-chat-button'

function NoChat () {
  return (
        <div className={'flex items-center justify-center w-full content'}>
            <div className={'flex flex-col justify-center w-fit mx-auto items-center gap-5 h-[70vh] text-center'}>
                <h2 className={'text-5xl font-medium w-11/12 md:w-2/3'}>Chat with our AI to get ahead in game</h2>
                <p className='text-xl font-medium text-gray-900'>Select an Expert to begin chat</p>
                {/* <NewChatButton/> */}
                <NousgptButtonSet />
            </div>
        </div>
  )
}

export default NoChat
