/* eslint-disable quote-props */
/* eslint-disable no-unused-vars */

import { ReactComponent as Eye } from '../../../../assets/images/icons/body-anatomy/eyes.svg'
import { ReactComponent as Brain } from '../../../../assets/images/icons/body-anatomy/brain.svg'
import { ReactComponent as Heart } from '../../../../assets/images/icons/body-anatomy/heart.svg'
import { ReactComponent as Ear } from '../../../../assets/images/icons/body-anatomy/ear.svg'
import { ReactComponent as Mouth } from '../../../../assets/images/icons/body-anatomy/mouth.svg'
import { ReactComponent as Hand } from '../../../../assets/images/icons/body-anatomy/hand.svg'
import { ReactComponent as Spine } from '../../../../assets/images/icons/body-anatomy/spine.svg'
import { ReactComponent as Foot } from '../../../../assets/images/icons/body-anatomy/foot.svg'
import { ReactComponent as Shoulder } from '../../../../assets/images/icons/body-anatomy/shoulder.svg'
import { ReactComponent as Lungs } from '../../../../assets/images/icons/body-anatomy/lungs.svg'
import { ReactComponent as FullBody } from '../../../../assets/images/icons/body-anatomy/full.svg'
import { ReactComponent as Gut } from '../../../../assets/images/icons/body-anatomy/gut.svg'
import { ReactComponent as CriticalThinking } from '../../../../assets/images/icons/body-skills/critical thinking.svg'
import { ReactComponent as ProblemAnalysis } from '../../../../assets/images/icons/body-skills/problem-analysis 1.svg'
import { ReactComponent as Creativity } from '../../../../assets/images/icons/body-skills/creativity 1.svg'
import { ReactComponent as SelfManagement } from '../../../../assets/images/icons/body-skills/self-disciplined 1.svg'
import { ReactComponent as LearningAbility } from '../../../../assets/images/icons/body-skills/learning-ability.svg'
import { ReactComponent as Prioritization } from '../../../../assets/images/icons/body-skills/priority 1.svg'
import { ReactComponent as ContinuousLearning } from '../../../../assets/images/icons/body-skills/CLearning 1.svg'
import { ReactComponent as TimeManagement } from '../../../../assets/images/icons/body-skills/Time Management.svg'
import { ReactComponent as SelfAwareness } from '../../../../assets/images/icons/body-skills/self-love 1.svg'
import { ReactComponent as Objectivity } from '../../../../assets/images/icons/body-skills/Objectivity.svg'
import { ReactComponent as Curiosity } from '../../../../assets/images/icons/body-skills/Curiosity.svg'
import { ReactComponent as SelfConscious } from '../../../../assets/images/icons/body-skills/Self-Conscious.svg'
import { ReactComponent as DivesityAndInclusion } from '../../../../assets/images/icons/body-skills/diversity 1.svg'
import { ReactComponent as Insight } from '../../../../assets/images/icons/body-skills/Insight.svg'
import { ReactComponent as Perceptiveness } from '../../../../assets/images/icons/body-skills/Perceptiveness.svg'
import { ReactComponent as SocialAwareness } from '../../../../assets/images/icons/body-skills/Socialawareness.svg'
import { ReactComponent as Vision } from '../../../../assets/images/icons/body-skills/Vision.svg'
import { ReactComponent as Focus } from '../../../../assets/images/icons/body-skills/Focus.svg'
import { ReactComponent as Listening } from '../../../../assets/images/icons/body-skills/Listening.svg'
import { ReactComponent as Empathy } from '../../../../assets/images/icons/body-skills/Empathy.svg'
import { ReactComponent as Diplomacy } from '../../../../assets/images/icons/body-skills/Diplomacy.svg'
import { ReactComponent as Tactfullness } from '../../../../assets/images/icons/body-skills/dependency 1.svg'
import { ReactComponent as Dependability } from '../../../../assets/images/icons/body-skills/dependability.svg'
import { ReactComponent as Compassion } from '../../../../assets/images/icons/body-skills/Compassion.svg'
import { ReactComponent as Integrity } from '../../../../assets/images/icons/body-skills/integrity.svg'
import { ReactComponent as Inspiration } from '../../../../assets/images/icons/body-skills/art 1.svg'
import { ReactComponent as Trustworthiness } from '../../../../assets/images/icons/body-skills/trusted 1.svg'
import { ReactComponent as Humility } from '../../../../assets/images/icons/body-skills/humility.svg'
import { ReactComponent as Respect } from '../../../../assets/images/icons/body-skills/respect.svg'
import { ReactComponent as SelfDevelopment } from '../../../../assets/images/icons/body-skills/success 1.svg'
import { ReactComponent as Teamwork } from '../../../../assets/images/icons/body-skills/Teamwork.svg'
import { ReactComponent as Coordination } from '../../../../assets/images/icons/body-skills/coordination.svg'
import { ReactComponent as WrittenExpression } from '../../../../assets/images/icons/body-skills/writtenExpression.svg'
import { ReactComponent as Networking } from '../../../../assets/images/icons/body-skills/network.svg'
import { ReactComponent as Delegation } from '../../../../assets/images/icons/body-skills/entrust 1.svg'
import { ReactComponent as Cooperation } from '../../../../assets/images/icons/body-skills/cooperation.svg'
import { ReactComponent as Assertiveness } from '../../../../assets/images/icons/body-skills/Assertiveness.svg'
import { ReactComponent as Resilience } from '../../../../assets/images/icons/body-skills/resilience 1.svg'
import { ReactComponent as Perseverance } from '../../../../assets/images/icons/body-skills/perseverance 1.svg'
import { ReactComponent as StressManagement } from '../../../../assets/images/icons/body-skills/stressmnmt.svg'
import { ReactComponent as SelfControl } from '../../../../assets/images/icons/body-skills/Self-Control.svg'
import { ReactComponent as Discipline } from '../../../../assets/images/icons/body-skills/Discipline.svg'
import { ReactComponent as Influence } from '../../../../assets/images/icons/body-skills/influence.svg'
import { ReactComponent as Sociability } from '../../../../assets/images/icons/body-skills/social 1.svg'
import { ReactComponent as VerbalExpression } from '../../../../assets/images/icons/body-skills/speak 1.svg'
import { ReactComponent as Communication } from '../../../../assets/images/icons/body-skills/chat 1.svg'
import { ReactComponent as Negotiation } from '../../../../assets/images/icons/body-skills/chat (1) 1.svg'
import { ReactComponent as Presentation } from '../../../../assets/images/icons/body-skills/presentation 1.svg'
import { ReactComponent as persuasion } from '../../../../assets/images/icons/body-skills/persuasive 1.svg'
import { ReactComponent as Humor } from '../../../../assets/images/icons/body-skills/laugh 1.svg'
import { ReactComponent as Coaching } from '../../../../assets/images/icons/body-skills/whistle 1.svg'
import { ReactComponent as Decisiveness } from '../../../../assets/images/icons/body-skills/choice 1.svg'
import { ReactComponent as Intuition } from '../../../../assets/images/icons/body-skills/idea (1) 1.svg'
import { ReactComponent as RiskTaking } from '../../../../assets/images/icons/body-skills/Risk-Taking.svg'
import { ReactComponent as DecisionMaking } from '../../../../assets/images/icons/body-skills/decision-making 1.svg'
import { ReactComponent as Patience } from '../../../../assets/images/icons/body-skills/patience.svg'
import { ReactComponent as Energy } from '../../../../assets/images/icons/body-skills/thunderbolt 1.svg'
import { ReactComponent as Enthusiasm } from '../../../../assets/images/icons/body-skills/love 1.svg'
import { ReactComponent as Positivity } from '../../../../assets/images/icons/body-skills/happy-face 1.svg'
import { ReactComponent as GroupLeadership } from '../../../../assets/images/icons/body-skills/human-resouces 1.svg'
import { ReactComponent as Responsibility } from '../../../../assets/images/icons/body-skills/responsible-consumption 1.svg'
import { ReactComponent as Accountability } from '../../../../assets/images/icons/body-skills/clipboard 1.svg'
import { ReactComponent as Leadership } from '../../../../assets/images/icons/body-skills/leadership 1.svg'
import { ReactComponent as ConflictManagement } from '../../../../assets/images/icons/body-skills/business 1.svg'
import { ReactComponent as CrisisManagement } from '../../../../assets/images/icons/body-skills/alert 1.svg'
import { ReactComponent as PeopleManagement } from '../../../../assets/images/icons/body-skills/manager 1.svg'
import { ReactComponent as Adapability } from '../../../../assets/images/icons/body-skills/adaptation 1.svg'
import { ReactComponent as Persistence } from '../../../../assets/images/icons/body-skills/Persistence.svg'
import { ReactComponent as SelfMotivation } from '../../../../assets/images/icons/body-skills/motivated 1.svg'
import { ReactComponent as Initiative } from '../../../../assets/images/icons/body-skills/Initiative.svg'
import { ReactComponent as Drive } from '../../../../assets/images/icons/body-skills/Drive.svg'
import { ReactComponent as WorkEthics } from '../../../../assets/images/icons/body-skills/Work Ethics.svg'
import { ReactComponent as Flexibility } from '../../../../assets/images/icons/body-skills/flexibility 1.svg'
import { ReactComponent as Regularity } from '../../../../assets/images/icons/body-skills/Regularity.svg'
import { ReactComponent as Proactivity } from '../../../../assets/images/icons/body-skills/Proactivity.svg'
import { ReactComponent as Punctuality } from '../../../../assets/images/icons/body-skills/Punctuality.svg'
import { ReactComponent as InterpersonalSkills } from '../../../../assets/images/icons/body-skills/Interpersonal Skills.svg'

import BrainIcon from '../../../../components/icons/Brain'
import EyeIcon from '../../../../components/icons/Eye'
import HeartIcon from '../../../../components/icons/Heart'
import EarIcon from '../../../../components/icons/Ear'
import MouthIcon from '../../../../components/icons/Mouth'
import LungIcon from '../../../../components/icons/Lung'
import GutIcon from '../../../../components/icons/Gut'
import SpineIcon from '../../../../components/icons/Spine'
import FootIcon from '../../../../components/icons/Foot'
import ShoulderIcon from '../../../../components/icons/Shoulder'
import HandIcon from '../../../../components/icons/Hand'
import BodyIcon from '../../../../components/icons/Body'

const slideItems = [
  {
    name: 'Eye',
    icon: EyeIcon,
    icon2: Eye,
    '1': 'Sleepy Eyes',
    '2': 'Alert Eyes',
    '3': 'Eagle Eyes'
  },
  {
    name: 'Brain',
    icon: BrainIcon,
    icon2: Brain,
    '1': 'Daydreaming',
    '2': 'Quick Thinking',
    '3': 'Brilliant Ideas'
  },
  {
    name: 'Heart',
    icon: HeartIcon,
    icon2: Heart,
    '1': 'Shy Heart',
    '2': 'Warm Heart',
    '3': 'Big Heart'
  },
  {
    name: 'Ears',
    icon: EarIcon,
    icon2: Ear,
    '1': 'Distracted Ears',
    '2': 'Attentive Ears',
    '3': 'Sharp Ears'
  },
  {
    name: 'Mouth',
    icon: MouthIcon,
    icon2: Mouth,
    '1': 'Whisper',
    '2': 'Clear Voice',
    '3': 'Powerful Speaker'
  },
  {
    name: 'Hand',
    icon: HandIcon,
    icon2: Hand,
    '1': 'Novice Hands',
    '2': 'Capable Hands',
    '3': 'Masterful Hands'
  },
  {
    name: 'Spine',
    icon: SpineIcon,
    icon2: Spine,
    '1': 'Fragile',
    '2': 'Steady',
    '3': 'Robust'
  },
  {
    name: 'Feet',
    icon: FootIcon,
    icon2: Foot,
    '1': 'Baby Steps',
    '2': 'Steady Pace',
    '3': 'Go-getter'
  },
  {
    name: 'Shoulders',
    icon: ShoulderIcon,
    icon2: Shoulder,
    '1': 'Burden Bearer',
    '2': 'Load Balancer',
    '3': 'Weight Lifter'
  },
  {
    name: 'Lungs',
    icon: LungIcon,
    icon2: Lungs,
    '1': 'Quick Breather',
    '2': 'Steady Pacer',
    '3': 'Marathon Lungs'
  },
  {
    name: 'FullBody',
    icon: BodyIcon,
    icon2: FullBody,
    '1': 'Quiet Presence',
    '2': 'Friendly Posture',
    '3': 'Charismatic Energy'
  },
  {
    name: 'Guts',
    icon: GutIcon,
    icon2: Gut,
    '1': 'Uncertain Feeling',
    '2': 'Trustworthy Hunch',
    '3': 'Strong Sixth Sense'
  }
]

const bodyPartsSkills = {
  Brain,
  Heart,
  Ears: Ear,
  Mouth,
  Spine,
  Feet: Foot,
  Shoulders: Shoulder,
  Lungs,
  Guts: Gut,
  Eye,
  'Critical Thinking': CriticalThinking,
  'Problem Analysis': ProblemAnalysis,
  'Creativity': Creativity,
  'Self Management': SelfManagement,
  'Learning Ability': LearningAbility,
  'Prioritization': Prioritization,
  'Continuous Learning': ContinuousLearning,
  'Time Management': TimeManagement,
  'Self awareness': SelfAwareness,
  'Objectivity': Objectivity,
  'Curiosity': Curiosity,
  'Self Conscious': SelfConscious,
  'Divesity & Inclusion': DivesityAndInclusion,
  'Insight': Insight,
  'Perceptiveness': Perceptiveness,
  'Social Awareness': SocialAwareness,
  'Vision': Vision,
  'Focus': Focus,
  'Listening': Listening,
  'Empathy': Empathy,
  'Diplomacy': Diplomacy,
  'Tactfulness': Tactfullness,
  'Dependability': Dependability,
  'Compassion': Compassion,
  'Integrity': Integrity,
  'Inspiration': Inspiration,
  'Trustworthiness': Trustworthiness,
  'Humility': Humility,
  'Respect': Respect,
  'Self Development': SelfDevelopment,
  'Teamwork': Teamwork,
  'Coordination': Coordination,
  'Written Expression': WrittenExpression,
  'Networking': Networking,
  'Delegation': Delegation,
  'Cooperation': Cooperation,
  'Assertiveness': Assertiveness,
  'Resilience': Resilience,
  'Perseverance': Perseverance,
  'Stress Management': StressManagement,
  'Self Control': SelfControl,
  'Discipline': Discipline,
  'Influence': Influence,
  'Sociability': Sociability,
  'Verbal Expression': VerbalExpression,
  'Communication': Communication,
  'Negotiation': Negotiation,
  'Presentation': Presentation,
  'Persuasion': persuasion,
  'Humor': Humor,
  'Coaching': Coaching,
  'Decisiveness': Decisiveness,
  'Intuition': Intuition,
  'Risk Taking': RiskTaking,
  'Decision making': DecisionMaking,
  'Patience': Patience,
  'Energy': Energy,
  'Enthusiasm': Enthusiasm,
  'Positivity': Positivity,
  'Group Leadership': GroupLeadership,
  'Responsibility': Responsibility,
  'Accountability': Accountability,
  'Leadership': Leadership,
  'Conflict Management': ConflictManagement,
  'Crisis Management': CrisisManagement,
  'People Management': PeopleManagement,
  'Adapability': Adapability,
  'Persistence': Persistence,
  'Self Motivation': SelfMotivation,
  'Initiative': Initiative,
  'Drive': Drive,
  'Work Ethics': WorkEthics,
  'Flexibility': Flexibility,
  'Regularity': Regularity,
  'Proactivity': Proactivity,
  'Punctuality': Punctuality,
  'Interpersonal Skills': InterpersonalSkills
}

export { slideItems, bodyPartsSkills }
