import api from '../../../utils/api'
import { addQuizFormQuestion, allQuizFormQuestions, deleteQuizFormQuestion, updateQuizFormQuestion } from './ActionCreator'

/**
 * Store a newly created resource in storage.
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAddQuizFormQuestion = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/link-quiz-questions', data).then((res) => {
      dispatch(addQuizFormQuestion(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAllQuizFormQuestions = (pageNumber = 1, pageSize = 15) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/link-quiz-questions?page=${pageNumber}&pageSize=${pageSize}`).then((res) => {
      dispatch(allQuizFormQuestions(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Update the specified resource in storage.
 * @param quizFormQuestionId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleUpdateQuizFormQuestion = (quizFormQuestionId, data) => (dispatch) => {
  const updatePromise = new Promise((resolve, reject) => {
    api().put(`/link-quiz-questions/${quizFormQuestionId}`, data, {
    }).then((res) => {
      dispatch(updateQuizFormQuestion(res.data))
      handleGetAllQuizFormQuestions()
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
  const getQuizFormQuestionPromise = new Promise((resolve, reject) => {
    api().get(`/link-quiz-questions?page=${1}&pageSize=15`).then((res) => {
      dispatch(allQuizFormQuestions(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
  return updatePromise.then((result1) => {
    return getQuizFormQuestionPromise
  }).then((result2) => {})
}

/**
 * Remove the specified resource from storage.
 * @returns {function(*): Promise<unknown>}
 * @param quizFormQuestionId
 */
export const handleDeleteQuizFormQuestion = (quizFormQuestionId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().delete(`/link-quiz-questions/${quizFormQuestionId}`).then((res) => {
      dispatch(deleteQuizFormQuestion(quizFormQuestionId))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
