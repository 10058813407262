import React from 'react'
import { Image } from 'antd'
import DefaultImage from '../../../assets/images/dashboard/colleagues.svg'

function SelfAssessmentTitle () {
  return (
        <div className={'flex items-center gap-2 pt-5 md:pt-0'}>
            <Image alt={'Avatar'} src={DefaultImage} preview={false} className={'profile-image !h-12 !w-12'}/>
            <div>
                <h3 className={'text-gray-900 leading-none font-medium text-base'}>
                    Learning & Development
                </h3>
            </div>
        </div>
  )
}

export default SelfAssessmentTitle
