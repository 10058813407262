import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Input, Spin } from 'antd'
import { useDispatch } from 'react-redux'
import { generateRandomPhoneNumberWithCountryCode } from '../../../utils'
import { handleSubmitPublicAssessment } from '../../../actions/user/common/Action'

function TakeUserEmail ({ ifCompleted, answers }) {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const dispatch = useDispatch()
  const onFinish = (values) => {
    setLoading(true)
    const data = answers
    data.user.email = values.email
    data.user.mobileNumber = generateRandomPhoneNumberWithCountryCode()
    dispatch(handleSubmitPublicAssessment(data)).then((res) => {
      setLoading(false)
      ifCompleted(res)
    }).catch(() => {
      setLoading(false)
    })
  }

  return (
        <Spin spinning={ loading }>
            <div className={ 'mx-auto bg-white-base p-5 rounded-lg w-[90%] md:w-[900px]' }>
                <div className={ 'mb-5' }>
                    <h3 className={ 'text-center text-gray-900 font-semibold text-3xl md:text-[40px] leading-[38px] mb-6 mt-[100px]' }>
                        Check your inbox for results
                    </h3>
                    <p className={ 'w-[90%] md:w-[539px] text-center text-gray-500 text-sm mx-auto' }>
                        Answer the questions to the best of your knowledge and understanding.
                        The honest you’re, the better the chances for you to grow.
                    </p>
                </div>
                <Form className={ 'w-[90%] md:w-[594px] mx-auto' } requiredMark={ false }
                      form={ form }
                      layout="vertical"
                      name="take-info-form" onFinish={ onFinish }>
                    <div>
                        <Form.Item
                            label={<span className={'font-medium text-sm text-gray-700'}>Enter Email</span>}
                            name="email"
                                   rules={ [
                                     {
                                       required: true,
                                       message: 'Email is Required'
                                     }
                                   ] }>
                            <Input size={ 'large' } placeholder={ 'name@gmail.com' }/>
                        </Form.Item>
                        <Button size={ 'large' } className={ 'btn btn-primary-600 mx-auto w-[185px]' } htmlType={ 'submit' }>
                            Next
                        </Button>
                    </div>
                </Form>
            </div>
        </Spin>
  )
}

TakeUserEmail.propTypes = {
  ifCompleted: PropTypes.func,
  answers: PropTypes.any
}

export default TakeUserEmail
