import React from 'react'
import { Button, Form, Input, InputNumber } from 'antd'
import { connect, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import TlaModalFormWrapper from '../../../components/tla-modal-form-wrapper'
import CloseModal from '../../../components/close-modal'
// eslint-disable-next-line no-unused-vars
import { FiPlus, FiTrash } from 'react-icons/fi'
import { handleAddQuizForm, handleUpdateQuizForm } from '../../../actions/admin/quiz-forms/Action'
import { formatQuizFormOptions } from '../../../utils'

function QuizForm ({ addForm, updateForm }) {
  const { state } = useLocation()
  const resourceId = state?.data?.id
  const userId = useSelector(state => state.loginReducer?.authUser?.id)
  const formValues = {
    ...state?.data,
    tierOption: state?.data?.tierOption
      ? (Object.values(formatQuizFormOptions(state?.data.tierOption)))
      : [
          {
            title: '',
            description: '',
            range_start: '',
            range_end: ''
          }
        ]
  }
  const formatBeforeSubmit = (values) => {
    values = {
      ...values,
      userId,
      tierOption: formatQuizFormOptions(values.tierOption, true)
    }
    return values
  }
  return (
    <TlaModalFormWrapper beforeSubmit={formatBeforeSubmit}
      initialValues={formValues}
      formTitle={`${resourceId ? 'Update' : 'Create'} Form`}
      resourceId={state?.data?.id ?? null}
      onSubmit={state?.data?.id ? updateForm : addForm}>
      <Form.Item
        className='mb-2'
        name={'titleQuizForm'}
        rules={[
          {
            required: true,
            message: 'Form title is required'
          }
        ]}
        label={'Form Title Quiz Form *'}>
        <Input size={'large'} placeholder={'Enter a Title'} />
      </Form.Item>
      <Form.Item
        className='mb-2'
        name={'titleScoreCard'}
        rules={[
          {
            required: true,
            message: 'Score card is required'
          }
        ]}
        label={'Form Title Score Card *'}>
        <Input size={'large'} placeholder={'Enter a Score Card...'} />
      </Form.Item>
      <Form.Item
        className='mb-2'
        name={'description'}
        rules={[
          {
            required: true,
            message: 'Description is required'
          }
        ]}
        label={'Form Description *'}>
        <Input.TextArea rows={3} size={'large'} placeholder={'Enter Description...'} />
      </Form.Item>
      <Form.Item
        className='mb-2'
        name={'detailLink'}
        rules={[
          { required: true, message: 'Required' }
        ]}
        label={'View detail link *'}>
        <Input type='url' size={'large'} placeholder={'Enter Link'} />
      </Form.Item>
      <Form.Item
        className='mb-2'
        name={'coolDownPeriod'}
        rules={[
          {
            required: true,
            message: 'Cool down period is required'
          }
        ]}
        label={'Cool down period (months) *'}>
        <InputNumber className='w-full' type='number' size={'large'} placeholder={'e.g. 3'} />
      </Form.Item>
      <Form.List name="tierOption">
        {(fields, { add, remove }) => {
          return (
            <>
              <div className={'flex justify-between items-center mb-2'}>
                <h3 className={'text-sm font-medium my-4'}>Tier Option *</h3>
                <Button type="text"
                  className={'btn btn-light text-sm text-gray-500 hover:!bg-transparent'}
                  onClick={() => {
                    add()
                  }} icon={<FiPlus size={20} />}>
                  Add
                </Button>
              </div>
              {fields.map(({ key, name, ...restField }) => (
                <div key={key} className={'flex gap-2'}>
                  <div className={'w-full'}>
                    <Form.Item
                      {...restField}
                      name={[name, 'title']}
                      rules={[
                        {
                          required: true,
                          message: 'Required'
                        }
                      ]}>
                      <Input size={'large'} placeholder="Tier Title" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'description']}
                      rules={[
                        {
                          required: true,
                          message: 'Required'
                        }
                      ]}>
                      <Input.TextArea size={'large'} placeholder="Tier Description" />
                    </Form.Item>
                    <div className="flex gap-5 justify-between">
                      <Form.Item
                        {...restField}
                        name={[name, 'range_start']}
                        rules={[
                          {
                            required: true,
                            message: 'Required'
                          }
                        ]}>
                        <Input size={'large'} placeholder="Tier range start (%age)" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'range_end']}
                        rules={[
                          {
                            required: true,
                            message: 'Required'
                          }
                        ]}>
                        <Input size={'large'} placeholder="Tier range end (%age)" />
                      </Form.Item>
                    </div>
                  </div>
                  <Button icon={<FiTrash />} className={'btn items-center flex justify-center'}
                    danger size={'large'}
                    onClick={() => remove(name)} />
                </div>
              ))}
            </>
          )
        }}
      </Form.List>
      <div className={'flex gap-2'}>
        <div className={'w-full'}>
          <CloseModal />
        </div>
        <Form.Item className={'w-full'}>
          <Button className={'btn-primary w-full'} size={'large'} htmlType="submit">
            {`${resourceId ? 'Update' : 'Create'}`} Form
          </Button>
        </Form.Item>
      </div>
    </TlaModalFormWrapper>
  )
}

QuizForm.propTypes = {
  addForm: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired
}

/**
 * @param state
 * @returns {{skills: ([]|*)}}
 */
const mapStateToProps = (state) => ({
  skills: state.adminSkillsReducer.skills.data,
  lessons: state.adminLessonsReducer.lessons.data
})

/**
 *
 * @param dispatch
 * @returns {{addForm: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  addForm: (data) => dispatch(handleAddQuizForm(data)),
  updateForm: (formId, data) => dispatch(handleUpdateQuizForm(formId, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(QuizForm)
