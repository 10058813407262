import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'

const button = {
  primary: 'bg-primary-400 text-white-base border-primary-400 hover:border-primary-400 hover:!text-white-base hover:bg-primary-400 !ring-primary-400',
  warning: 'bg-yellow-400 text-white-base border-yellow-400 hover:!border-yellow-400 hover:!text-white-base hover:bg-yellow-400 !ring-yellow-400'
}

const ButtonComponent = ({ text, icon, variant = 'primary' }) => (
    <Button className={ button[variant] + ' hover:!outline-none rounded-lg shadow-1xl flex items-center gap-3 !text-sm'} size={'large'}>
        {
            icon && icon
        } {text}
    </Button>
)

ButtonComponent.defaultProps = {
  variant: 'primary'
}
ButtonComponent.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.string,
  icon: PropTypes.any
}

export default (ButtonComponent)
