import React from 'react'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

function CloseModal ({ title, width }) {
  const navigate = useNavigate()
  return (
        <Button className={width} size={'large'} onClick={() => navigate(-1)}>
            {title}
        </Button>
  )
}

CloseModal.defaultProps = {
  title: 'Cancel',
  width: 'w-full'
}

CloseModal.propTypes = {
  title: PropTypes.string,
  width: PropTypes.string
}

export default (CloseModal)
