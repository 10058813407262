import api from '../../../utils/api'
import { addCourseCategory, allCourseCategories, deleteCourseCategory, updateCourseCategory } from './ActionCreator'

/**
 * Store a newly created resource in storage.
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAddCourseCategory = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/courses/categories', data).then((res) => {
      dispatch(addCourseCategory(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAllCourseCategories = (pageNumber = 1) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/courses/categories?page=${pageNumber}&pageSize=15`).then((res) => {
      dispatch(allCourseCategories(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Update the specified resource in storage.
 * @param postId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleUpdateCourseCategory = (postId, data) => (dispatch) => {
  const updatePromise = new Promise((resolve, reject) => {
    api().patch(`/courses/categories/${postId}`, data, {
    }).then((res) => {
      dispatch(updateCourseCategory(res.data))
      handleGetAllCourseCategories()
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
  const getCourseCategoriesPromise = new Promise((resolve, reject) => {
    api().get(`/courses/categories?page=${1}&pageSize=15`).then((res) => {
      dispatch(allCourseCategories(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
  return updatePromise.then((result1) => {
    return getCourseCategoriesPromise
  }).then((result2) => {})
}

/**
 * Remove the specified resource from storage.
 * @returns {function(*): Promise<unknown>}
 * @param postId
 */
export const handleDeleteCourseCategory = (postId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().delete(`/courses/categories/${postId}`).then((res) => {
      dispatch(deleteCourseCategory(postId))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
