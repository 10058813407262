import React from 'react'
import { TlaModal } from '../pop-ups/tla-modal'
import InstantFeedback from '../instant-feedback'
import GoBackHome from '../../pages/feedback/go-back-home'

function JournalFeedback () {
  return (
      <TlaModal width={ 877 } close={ false }>
          <div className={ 'pt-2 pb-5' }>
              <GoBackHome backText={'Back to home'}/>
          </div>
          <h3 className={ 'text-lg font-medium pl-3' }>How are you feeling today?</h3>
          <div className={ 'w-[332px] mx-auto' }>
              <InstantFeedback isQuickAction={ true }/>
          </div>
      </TlaModal>
  )
}

export default JournalFeedback
