import api from '../../../utils/api'
import { addCourseModule, allCourseModules, deleteCourseModule, updateCourseModule } from './ActionCreator'

/**
 * Store a newly created resource in storage.
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAddCourseModule = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/course/module', data).then((res) => {
      dispatch(addCourseModule(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAllCourseModules = (pageNumber = 1, pageSize = 15, filters = null) => (dispatch) => {
  return new Promise((resolve, reject) => {
    console.log(filters && filters.course ? '&courseId=' + filters.course : '')
    api().get(`/course/module?page=${pageNumber}&pageSize=15${filters && filters.course ? '&courseId=' + filters.course : ''}`).then((res) => {
      dispatch(allCourseModules(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Update the specified resource in storage.
 * @param postId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleUpdateCourseModule = (postId, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().patch(`/course/module/${postId}`, data).then((res) => {
      dispatch(updateCourseModule(res.data))
      handleGetAllCourseModules()
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Remove the specified resource from storage.
 * @returns {function(*): Promise<unknown>}
 * @param postId
 */
export const handleDeleteCourseModule = (postId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().delete(`/course/module/${postId}`).then((res) => {
      dispatch(deleteCourseModule(postId))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
