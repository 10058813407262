import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function addContentPost (payload) {
  return {
    type: Types.NEW_CONTENT_POST,
    payload
  }
}

export function importContentPosts (payload) {
  return {
    type: Types.IMPORT_CONTENT_POSTS,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function allContentPosts (payload) {
  return {
    type: Types.ALL_CONTENT_POSTS,
    payload
  }
}

/**
 *
 * @param post
 * @returns {{post, type: string}}
 */
export function deleteContentPost (post) {
  return {
    type: Types.DELETE_CONTENT_POST,
    post
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function updateContentPost (payload) {
  return {
    type: Types.UPDATE_CONTENT_POST,
    payload
  }
}
