import React from 'react'
import PropTypes from 'prop-types'

const EyeIcon = ({ className, isActive = '#98A2B3' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" className={className} viewBox="0 0 22 22" fill="none">
      <g clipPath="url(#clip0_10150_1234)">
        <path d="M20.801 10.0498C20.0839 9.17908 18.3414 7.7402 18.3251 7.72114C16.1486 5.17127 14.9151 3.08776 14.443 1.16427C14.3526 0.79596 13.9808 0.570629 13.6125 0.661087C13.2443 0.75146 13.019 1.12329 13.1094 1.49156C13.4725 2.97121 14.2188 4.50999 15.4122 6.21772C14.5643 5.90584 13.6807 5.68445 12.7745 5.56014C11.4756 4.60929 9.91135 3.35772 9.30684 2.09362C9.14322 1.75148 8.73328 1.60678 8.39123 1.77041C8.04918 1.93403 7.90444 2.34392 8.06802 2.68602C8.55142 3.6969 9.43331 4.6247 10.4132 5.45801C9.68752 5.49384 8.97239 5.59116 8.27438 5.74723C6.36746 5.18993 5.22892 4.59372 4.79387 3.9245C4.58721 3.60661 4.16195 3.51632 3.84397 3.72312C3.52608 3.92978 3.43588 4.35508 3.64259 4.67297C4.09226 5.36463 4.87476 5.93489 6.0993 6.44618C5.63027 6.64555 5.17356 6.87324 4.73178 7.12831C4.44384 7.00516 2.05991 6.78712 1.24887 5.9872C0.978954 5.72093 0.544213 5.72389 0.277901 5.99385C0.011632 6.26381 0.014593 6.69851 0.284552 6.96478C0.939776 7.61107 2.00103 7.96171 3.15056 8.20974C2.74272 8.53677 2.35343 8.89054 1.98511 9.26988C1.68584 9.28773 1.38194 9.19393 1.06182 8.98503C0.74414 8.77781 0.318796 8.86741 0.111617 9.185C-0.0955622 9.50255 -0.00604774 9.92798 0.311544 10.1352C0.530267 10.2778 0.751306 10.3904 0.973977 10.4734C-0.302484 12.385 -0.198594 14.9469 1.28753 16.7516C6.35158 22.9014 15.7473 22.8888 20.801 16.7516C22.3998 14.81 22.3998 11.9915 20.801 10.0498ZM19.7409 15.8788C15.2269 21.3606 6.85275 21.3499 2.34759 15.8788C1.16524 14.4429 1.16524 12.3586 2.34759 10.9228C6.86155 5.44097 15.2357 5.45161 19.7409 10.9228C20.9233 12.3586 20.9233 14.4429 19.7409 15.8788Z" fill={isActive}/>
        <path d="M11.0958 8.30469C8.28597 8.30469 6 10.5907 6 13.4005C6 16.2103 8.28597 18.4963 11.0958 18.4963C13.9056 18.4963 16.1916 16.2103 16.1916 13.4005C16.1916 10.5907 13.9056 8.30469 11.0958 8.30469ZM11.0958 17.1231C9.04315 17.1231 7.37318 15.4531 7.37318 13.4005C7.37318 11.3478 9.04315 9.67787 11.0958 9.67787C13.1485 9.67787 14.8184 11.3478 14.8184 13.4005C14.8184 15.4531 13.1485 17.1231 11.0958 17.1231Z" fill={isActive}/>
        <path d="M11.2071 11.1934C9.99011 11.1934 9 12.1835 9 13.4005C9 14.6175 9.99011 15.6076 11.2071 15.6076C12.4242 15.6076 13.4143 14.6175 13.4143 13.4005C13.4143 12.1835 12.4241 11.1934 11.2071 11.1934ZM11.2071 14.2344C10.7473 14.2344 10.3732 13.8603 10.3732 13.4005C10.3732 12.9406 10.7473 12.5665 11.2071 12.5665C11.667 12.5665 12.0411 12.9406 12.0411 13.4005C12.0411 13.8603 11.6669 14.2344 11.2071 14.2344Z" fill={isActive}/>
      </g>
      <defs>
        <clipPath id="clip0_10150_1234">
          <rect width="22" height="22" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

EyeIcon.propTypes = {
  isActive: PropTypes.string,
  className: PropTypes.string
}
export default EyeIcon
