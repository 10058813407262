import React from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Input, Spin } from 'antd'
import { useDispatch } from 'react-redux'
import { TlaError } from '../../../utils/messages'
import { generateRandomPhoneNumberWithCountryCode, getErrors } from '../../../utils'

function TakeInfo ({ answers, ifCompleted, onSubmit }) {
  const [loading, setLoading] = React.useState(false)
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const onFinish = (values) => {
    setLoading(true)
    values.mobileNumber = generateRandomPhoneNumberWithCountryCode()
    dispatch(onSubmit({ user: values, answers }, true)).then((res) => {
      setLoading(false)
      ifCompleted(res.data)
    }).catch((error) => {
      TlaError(error.response.data.message ?? getErrors(error.response.data?.errors))
      setLoading(false)
    })
  }

  return (
      <div>
          <div className={ 'mb-5' }>
              <h3 className={ 'text-center text-gray-900 font-semibold text-3xl md:text-[40px] leading-[38px] mb-6 mt-5' }>
                  Check your inbox for results
              </h3>
          </div>
              <Form className={ 'w-[90%] md:w-[594px] mx-auto' } requiredMark={ false }
                    form={ form }
                    layout="vertical"
                    name="take-info-form" onFinish={ onFinish }>
                  <Spin spinning={ loading } tip={ 'Please wait...' }>
                      <div>
                          <Form.Item name="email" label="Email*"
                                     rules={ [
                                       {
                                         required: true,
                                         message: 'Email is Required'
                                       }
                                     ] }>
                              <Input size={ 'large' } placeholder={ 'Enter your email' }/>
                          </Form.Item>
                          <Form.Item>
                              <Button
                                  size={ 'large' }
                                  block
                                  className={ 'btn btn-primary-600 !w-[185px] mx-auto' }
                                  htmlType="submit">
                                  Submit
                              </Button>
                          </Form.Item>
                      </div>
                  </Spin>
              </Form>
          </div>
  )
}

TakeInfo.propTypes = {
  answers: PropTypes.any,
  ifCompleted: PropTypes.func,
  onSubmit: PropTypes.func.isRequired
}

export default TakeInfo
