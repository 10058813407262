import { notification } from 'antd'
import React from 'react'

/**
 *
 * @param msg
 * @constructor
 */
export const TlaSuccess = (msg = null) => (
  notification.success({
    message: 'Success',
    description: msg,
    placement: 'top'
  })
)

/**
 *
 * @param msg
 * @constructor
 */
export const TlaWarning = (msg = null) => (
  notification.warning({
    message: 'Warning',
    description: msg,
    placement: 'top'
  })
)

/**
 *
 * @param msg
 * @constructor
 */
export const TlaError = (msg = null) => (
  notification.warning({
    message: 'Error',
    description: (msg),
    placement: 'top'
  })
)

export const TlaNotification = function (errors, errorType, msg = null) {
  // console.log(errors)
  const types = ['error', 'warning', 'success', 'info']
  const type = types.indexOf(errorType) !== -1 ? errorType : 'error'
  const defaultMsg = type.charAt(0).toUpperCase() + type.slice(1)
  notification[type]({
    message: msg ?? defaultMsg,
    description: (
      <>
        {
          errors.map((error) => (
          <p key={error.message}>{error.message}</p>
          ))
        }
      </>
    ),
    placement: 'top'
  })
}
