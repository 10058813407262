import { Types } from '../../actions/admin/quiz-forms/Types'

const initialState = {
  quizForms: {
    data: [],
    meta: {}
  },
  quizForm: {}
}

export default function adminQuizFormsReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_QUIZ_FORM:
      return {
        ...state,
        quizForms: {
          ...state.quizForms,
          data: [
            action.payload.data.quizForm,
            ...state.quizForms.data
          ]
        }
      }
    case Types.ALL_QUIZ_FORMS:{
      return {
        ...state,
        quizForms: {
          ...state.quizForms,
          data: action.payload.data.quizForms,
          meta: {
            total: action.payload.data.count,
            current_page: Number(action.payload.data.page),
            page: action.payload.data.page,
            pageSize: action.payload.data.pageSize
          }
        }
      }
    }
    case Types.UPDATE_QUIZ_FORM:
      return {
        ...state,
        quizForms: {
          ...state.quizForms,
          data: state.quizForms.data.map((quiz) => {
            return quiz.id === action.payload.data.quizForm.id ? action.payload.data.quizForm : quiz
          })
        }
        // quizForm: action.payload.data.upda
      }
    case Types.DELETE_QUIZ_FORM:
      return {
        ...state,
        quizForms: {
          ...state.quizForms,
          data: state.quizForms.data.filter((quizForms) => quizForms.id !== action.quizFormId)
        }
      }

    default:
      return state
  }
}
