import { Types } from './Types'

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function allOrgUsers (payload) {
  return {
    type: Types.ALL_ORG_USERS,
    payload
  }
}
export function updateOrgUser (payload) {
  return {
    type: Types.UPDATE_ORG_USER,
    payload
  }
}
