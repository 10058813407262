/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react'
import Rating from './rating'
import { bodyPartsSkills, slideItems } from './item-list'
import { useSelector } from 'react-redux'
import DashboardNavs from '../../dashboard-navs'
import { ReactComponent as FullBody } from '../../../../assets/images/icons/body-anatomy/full.svg'
import BodyIcon from '../../../../components/icons/Body'
import IconCarousel from './icon-carousel'
import { ReactComponent as StarLevel1 } from '../../../../assets/images/icons/star-level-1.svg'
import { ReactComponent as StarLevel2 } from '../../../../assets/images/icons/star-level-2.svg'
import { ReactComponent as StarLevel3 } from '../../../../assets/images/icons/star-level-3.svg'
import { Spin } from 'antd'

const EffectivenessPersonality = () => {
  const skillsByBodyParts = useSelector((state) => state.commonReducer.skillsByBodyParts)
  const { name: userName } = useSelector((state) => state.loginReducer.authUser?.userInformation)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [direction, setDirection] = useState('')
  const [loading, setLoading] = useState(false)

  const [sliderItems, setSliderItems] = useState([])

  useEffect(() => {
    setLoading(true)
    if (skillsByBodyParts) {
      const filterdSliderItems = slideItems
        .map((item) => {
          if (skillsByBodyParts !== undefined && skillsByBodyParts[item.name] !== undefined) {
            return {
              ...item,
              ...skillsByBodyParts[item.name]
            }
          }
          return undefined
        })
        .filter((item) => item !== undefined)

      const updatedSliderItems = [
        {
          ...skillsByBodyParts.averageOfAllBodyParts,
          averageScore: skillsByBodyParts.averageOfAllBodyParts?.average,
          name: 'Whole Body',
          icon: BodyIcon,
          icon2: FullBody
        },
        ...filterdSliderItems
      ]
      setSliderItems(updatedSliderItems)
    }
    setLoading(false)
  }, [skillsByBodyParts]) // This will run when skillsByBodyParts changes

  const [iconsForSlider, setIconsForSlider] = useState([])

  useEffect(() => {
    setIconsForSlider(sliderItems.map((item, i) => ({ Icon: item.icon, name: item.name, index: i })))
  }, [sliderItems])

  const currentBodyPart = sliderItems[currentSlide] ?? {}
  const handleIconClick = (index) => {
    setDirection(index > currentSlide ? 'next' : 'prev')
    if (index >= 0 && index < sliderItems.length) {
      setCurrentSlide(index)
    }
  }

  const getIconComponent = (IconComponent, isActive) => {
    if (IconComponent) return <IconComponent isActive={isActive} />
    return 'N/A'
  }

  const activeDots = (rating) => {
    if (rating >= 0 && rating <= 3.5) return <StarLevel1 />
    else if (rating > 3.5 && rating <= 7) return <StarLevel2 />
    else if (rating > 7 && rating <= 10) return <StarLevel3 />
    return 0
  }

  useEffect(() => {
    setIconsForSlider(sliderItems.map((item, i) => ({ Icon: item.icon, name: item.name, index: i })))
  }, [sliderItems])

  const [isLgScreen, setIsLgScreen] = useState(false)
  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1024px)')
    setIsLgScreen(mediaQuery.matches)
    const handleResize = () => setIsLgScreen(mediaQuery.matches)
    mediaQuery.addEventListener('change', handleResize)
    return () => mediaQuery.removeEventListener('change', handleResize)
  }, [])

  return (
    <div className="flex justify-between items-start my-5 lg:flex-row flex-col">
      {/* Left side - properties */}
      <div className="flex-1 p-5 pt-0 flex flex-col gap-10">
        <div>
          <h3 className="font-medium text-[18px]">Hi {userName ?? 'Nous User'},</h3>
          <h2 className="font-medium text-3xl">Get to know about your <span className="text-indigo-500">Internal Health</span></h2>
          <p className="text-gray-500">Build your inner strength by developing essential life skills</p>
        </div>
        <div className="tabs">
          <DashboardNavs />
        </div>
        <div className="lg:block hidden">
          <Spin spinning={loading}>
            <div className={`flex-wrap gap-y-10 lg:flex hidden ${currentBodyPart.skills?.length > 2 ? 'justify-between' : 'gap-x-12'}`}>
              {
              sliderItems.length > 1 && isLgScreen
                ? currentBodyPart?.skills?.map((skill, i) => {
                  const IconComponent = bodyPartsSkills[skill.skillTitle]
                  if (IconComponent === undefined) return ''
                  return (
                    <React.Fragment key={skill.id}>
                      <div className="flex flex-col gap-[10px] w-[260px] bg-[#F9FAFB] p-5 rounded-10">
                        <div className="flex items-center gap-[10px]">
                          <IconComponent
                            className="w-[22px] h-[22px]"
                            alt=""
                          />
                          <span className="text-xs text-indigo-500"> {skill.skillTitle} </span>
                        </div>
                        <div className="flex justify-between items-center">
                          <p className="font-semibold text-base text-gray-700 mb-0">{skill.level}</p>
                          <Rating calculateDots={activeDots} rating={skill.averageScore || 0} />
                        </div>
                      </div>
                    </React.Fragment>
                  )
                })
                : <div>
                  <p className='text-gray-500 text-[18px] font-medium text-center lg:p-28 p-10'>
                    You need feedbacks from at least 3 different contacts to see feedback-based profile.
                  </p>
                </div>
              }
            </div>
          </Spin>
        </div>
      </div>

      {/* Right side - slider */}
      <div className={`py-5 px-[34px] text-center w-full lg:w-[450px] bg-[#F5FAFF] rounded-[31px] ${sliderItems.length < 1 && 'hidden'}`}>
        <div className="lg:p-[53.5px] flex flex-col gap-10">
          <div className="flex flex-col gap-[10px]">
            <Rating calculateDots={activeDots} rating={currentBodyPart.averageScore} title={currentBodyPart.name} />
            <h3 className="mt-[10px] text-2xl font-semibold">
              {currentBodyPart.level}
            </h3>
          </div>
          <div className={`justify-center slider-content lg:flex hidden ${direction}`}>
            {
              (() => {
                const CurrentIcon = currentBodyPart?.icon
                return currentBodyPart?.icon !== undefined
                ? <CurrentIcon
                    className="w-[120px] h-[120px]"
                    isActive="#2E90FA"
                  />
                : 'N/A'
              })()
            }
          </div>

          <div>
            <IconCarousel iconsForSlider={iconsForSlider} changeSlide={handleIconClick} />
          </div>
        </div>
      </div>
      <div className='w-full lg:hidden block'>
        <Spin spinning={loading}>
          <div className="flex-wrap justify-between flex gap-7 mt-5">
            {
            sliderItems.length > 1
              ? currentBodyPart?.skills?.map((skill, i) => {
                const IconComponent = bodyPartsSkills[skill.skillTitle]
                if (IconComponent === undefined) return ''
                return (
                  <React.Fragment key={skill.id}>
                    <div className="flex flex-col gap-[10px] w-full bg-[#F9FAFB] p-5 rounded-10">
                      <div className="flex items-center gap-[10px]">
                        <IconComponent
                          className="w-[22px] h-[22px]"
                          alt=""
                        />
                        <span className="text-xs text-indigo-500"> {skill.skillTitle} </span>
                      </div>
                      <div className="flex justify-between items-center">
                        <p className="font-semibold text-base text-gray-700 mb-0">{skill.level}</p>
                        <Rating calculateDots={activeDots} rating={skill.averageScore || 0} />
                      </div>
                    </div>
                  </React.Fragment>
                )
              })
              : <div>
                <p className='text-gray-500 text-[18px] font-medium text-center lg:p-28 p-10'>
                  You need feedbacks from at least 3 different contacts to see feedback-based profile.
                </p>
              </div>
            }
          </div>
        </Spin>
      </div>
    </div>
  )
}

export default EffectivenessPersonality
