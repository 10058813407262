import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { handleGetUserQuizzes } from '../../actions/user/common/Action'
import { Divider, Spin } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import SidebarForm from '../dashboard/layout/sidebar-form'
import SingleNousQuiz from '../../components/public-components/nous-quiz/single-nous-quiz'

function NousQuizzes ({ getQuizzess, quizzes }) {
  const page = useSelector(state => state.commonReducer.quizPage)
  const totalPages = useSelector(state => state.commonReducer.totalQuizzesPages)
  const [loading, setLoading] = useState(false)
  const { data, meta } = quizzes
  const loadMoreData = (refresh) => {
    if (!refresh && page >= totalPages) return
    setLoading(true)
    getQuizzess(page, refresh).then(() => {
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }
  useEffect(() => {
    loadMoreData('refresh')
  }, [])

  return (
    <React.Fragment>
        <div className={'hidden md:block xl:hidden'}>
            <SidebarForm col={'row'}/>
        </div>
        <InfiniteScroll
            dataLength={data.length}
            next={loadMoreData}
            hasMore={data.length < meta.total}
            endMessage={<Divider plain></Divider>}
            scrollableTarget="scrollableDiv">
            <Spin spinning={loading}>
              {
                data.map((quiz, i) => (
                  <div key={i} className='w-full'>
                    <SingleNousQuiz quiz={quiz} />
                  </div>
                ))
              }
            </Spin>
        </InfiniteScroll>
    </React.Fragment>
  )
}

NousQuizzes.propTypes = {
  quizzes: PropTypes.object,
  getQuizzess: PropTypes.func.isRequired
}

NousQuizzes.defaultProps = {
  quizzes: []
}

const mapStateToProps = (state) => ({
  quizzes: state.commonReducer.quizzes
})

const mapDispatchToProps = (dispatch) => ({
  getQuizzess: (page, refresh) => dispatch(handleGetUserQuizzes(page, refresh))
})

export default connect(mapStateToProps, mapDispatchToProps)(NousQuizzes)
