export const Types = {
  GET_USER_FEED: 'GET_USER_FEED',
  GET_USER_CONTENT_POSTS: 'GET_USER_CONTENT_POSTS',
  GET_USER_COURSES: 'GET_USER_COURSES',
  GET_USER_COURSE_DETAILS: 'GET_USER_COURSE_DETAILS',
  COURSE_ENROLLMENT: 'COURSE_ENROLLMENT',
  GET_USER_LESSON: 'GET_USER_LESSON',
  TOGGLE_SKILL: 'TOGGLE_SKILL',
  GET_USER_MODULE_PROGRESS: 'GET_USER_MODULE_PROGRESS',
  UPDATE_USER_LESSON_PROGRESS: 'UPDATE_USER_LESSON_PROGRESS',
  QUESTION_SUBMISSION: 'QUESTION_SUBMISSION',
  // RECORD_CONTENT_POST_CLICK: 'RECORD_CONTENT_POST_CLICK',
  GET_SKILLS: 'GET_SKILLS',
  UPDATE_FEED: 'UPDATE_FEED',
  REMOVE_FEED: 'REMOVE_FEED',
  GET_PROGRESS_OVERTIME: 'GET_PROGRESS_OVERTIME',
  VALIDATE_ORG: 'VALIDATE_ORG',
  SELECT_ORG: 'SELECT_ORG',
  GET_EMOTIONS: 'GET_EMOTIONS',
  GET_USER_QUIZZES: 'GET_USER_QUIZZES',
  GET_USER_GAMES: 'GET_USER_GAMES',
  GET_USER_PLAYED_GAMES: 'GET_USER_PLAYED_GAMES',
  GET_USER_RANDOM_GAME: 'GET_USER_RANDOM_GAME',
  GET_PUBLIC_QUIZZES: 'GET_PUBLIC_QUIZZES',
  GET_QUIZ_QUESTIONS: 'GET_QUIZ_QUESTIONS',
  SET_SINGLE_QUIZ: 'SET_SINGLE_QUIZ',
  SET_SINGLE_PLAYED_GAME: 'SET_SINGLE_PLAYED_GAME',
  SET_SINGLE_QUESTION: 'SET_SINGLE_QUESTION',
  QUIZ_SUBMISSION: 'QUIZ_SUBMISSION',
  SUBMIT_PUBLIC_ASSESSMENT: 'SUBMIT_PUBLIC_ASSESSMENT',
  QUIZ_RESULTS: 'QUIZ_RESULTS',
  SET_INVITATION_CODE: 'SET_INVITATION_CODE'
}
