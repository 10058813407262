import React from 'react'
import { Link } from 'react-router-dom'
import { IoChevronBackOutline } from 'react-icons/io5'
import { formatDate } from '../../../utils'
import PropTypes from 'prop-types'

function ChatHeader ({ date, format }) {
  return (
        <div className={'border-b pb-5 px-6 flex md:hidden items-center gap-4'}>
            <Link className={'block md:hidden'} to={'/nous-gpt'}>
                    <span className={'btn gap-1 flex w-[78px] items-center rounded-lg border border-gray-300 py-2' +
                        ' px-2.5'}>
                        <IoChevronBackOutline size={14}/>
                        Back
                    </span>
            </Link>
            <p className={'text-gray-900 text-lg font-medium'}>{formatDate(date, format)}</p>
        </div>
  )
}

ChatHeader.defaultProps = {
  date: null,
  format: 'DD-MM-YYYY'
}

ChatHeader.propTypes = {
  date: PropTypes.string,
  format: PropTypes.string
}

export default ChatHeader
