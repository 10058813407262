import { Types } from '../../actions/admin/posts/Types'

const initialState = {
  posts: {
    data: [],
    meta: {}
  },
  post: {}
}

export default function adminPostsReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_POST:
      return {
        ...state,
        posts: {
          ...state.posts,
          data: state.posts.data.concat(action.payload.data.newEducationalPost)
        }
      }
    case Types.SINGLE_POST:
      return {
        ...state,
        post: action.payload
      }
    case Types.ALL_POSTS:
      return {
        ...state,
        posts: {
          ...state.posts,
          data: action.payload.data.EducationalPosts,
          meta: {
            total: action.payload.data.count,
            current_page: Number(action.payload.data.page),
            page: action.payload.data.page,
            pageSize: action.payload.data.pageSize
          }
        }
      }

    case Types.UPDATE_POST:
      return {
        ...state,
        posts: {
          ...state.posts,
          data: state.posts.data.map((post) => {
            return post.id === action.payload.data.updatedEducationalPost.id ? action.payload.data.updatedEducationalPost : post
          })
        }
        // post: action.payload.data.upda
      }
    case Types.DELETE_POST:
      return {
        ...state,
        posts: {
          ...state.posts,
          data: state.posts.data.filter((posts) => posts.id !== action.id)
        }
      }

    default:
      return state
  }
}
