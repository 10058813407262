import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function addModuleLesson (payload) {
  return {
    type: Types.NEW_MODULE_LESSON,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function allModuleLessons (payload) {
  return {
    type: Types.ALL_MODULE_LESSONS,
    payload
  }
}

/**
 *
 * @param post
 * @returns {{post, type: string}}
 */
export function deleteModuleLesson (moduleLesson) {
  return {
    type: Types.DELETE_MODULE_LESSON,
    moduleLesson
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function updateModuleLesson (payload) {
  return {
    type: Types.UPDATE_MODULE_LESSON,
    payload
  }
}
