import React from 'react'
import PropTypes from 'prop-types'

const EarIcon = ({ className, isActive = '#98A2B3' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" className={className} viewBox="0 0 22 22" fill="none">
      <g clipPath="url(#clip0_10150_1244)">
        <path d="M16.6829 4.51999C15.9542 3.14721 14.7258 1.65896 13.2643 0.810715C9.39113 -1.43744 4.96504 1.14732 4.25339 6.61986C4.20436 6.99672 4.47013 7.34192 4.84699 7.39095C5.22393 7.44058 5.5691 7.17424 5.61808 6.79734C6.37923 0.944592 11.9912 -1.00223 15.47 5.17014C19.7348 13.2789 7.56933 25.9594 5.78529 18.2034C5.70013 17.833 5.3308 17.6019 4.96044 17.687C4.59008 17.7722 4.35896 18.1415 4.44411 18.5119C5.12889 21.489 7.40229 22.7089 10.0665 21.5905C11.2474 21.0947 12.5563 20.0603 13.2771 19.2824C16.9635 15.3103 19.195 9.28173 16.6829 4.51999Z" fill={isActive}/>
        <path d="M8.34455 13.2234C8.7039 13.3475 9.09551 13.1565 9.21941 12.7974C9.48166 12.0372 9.24599 11.29 8.60447 10.8474C7.42754 10.0354 5.81518 10.9049 5.81518 12.3391V13.7827C5.53646 14.7858 5.40774 16.0374 6.27404 16.5189C7.18417 17.025 8.26456 16.1492 8.97621 15.3915C15.3145 13.5991 12.9253 6.39766 8.08626 6.32549C8.03762 5.21853 8.47743 4.37876 9.54552 3.93219C12.9732 3.18858 16.4926 9.08223 11.664 15.2556C11.4298 15.5549 11.4827 15.9874 11.782 16.2215C12.0807 16.4552 12.5133 16.4034 12.7479 16.1034C18.4204 8.85134 13.9489 1.47946 9.17997 2.60401C9.14673 2.61188 9.11409 2.6222 9.08231 2.63489C6.15612 3.80533 6.17913 7.03534 7.83588 9.7849C8.03198 10.1103 8.45468 10.2153 8.78036 10.0191C9.10583 9.82296 9.21072 9.40013 9.01457 9.07462C8.74157 8.62156 8.52642 8.16471 8.37168 7.72008C11.4383 8.05419 13.3451 12.8531 8.43331 14.1126C8.30154 14.1464 8.18276 14.2185 8.09193 14.3198C7.52882 14.9478 7.16508 15.1699 6.98695 15.2479C6.96815 15.0756 6.97529 14.7181 7.16387 14.0713C7.20167 13.9416 7.1914 13.9978 7.1914 12.3391C7.1914 12.0178 7.55423 11.7948 7.82302 11.9802C7.91191 12.0415 7.9928 12.1333 7.91858 12.3485C7.79455 12.7078 7.98528 13.0996 8.34455 13.2234Z" fill={isActive}/>
      </g>
      <defs>
        <clipPath id="clip0_10150_1244">
          <rect width="22" height="22" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

EarIcon.propTypes = {
  isActive: PropTypes.string,
  className: PropTypes.string
}
export default EarIcon
