import React, { useState } from 'react'
import { FiCopy } from 'react-icons/fi'
import { Tooltip } from 'antd'
import PropTypes from 'prop-types'
// import { useSelector } from 'react-redux'

const SharePublicProfile = ({ userName }) => {
  const [text, setText] = useState('Copy Public Profile Link')
  return (
        <Tooltip title={text}
                 onOpenChange={() => setText('Copy Public Profile Link')}>
            <span onClick={() => {
              navigator.clipboard
                .writeText(`${process.env.REACT_APP_FE_URL}/pp/${userName}`)
                .then(() => setText('Copied'))
            }} className={'flex items-center w-fit gap-x-1' +
                ' text-primary-700 underline' +
                ' rounded-full p-2 text-sm cursor-pointer'}>
                <FiCopy size={16} />
            </span>
        </Tooltip>
  )
}

SharePublicProfile.propTypes = {
  userName: PropTypes.string
}

export default SharePublicProfile
