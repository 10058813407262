import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router'
import TlaTableWrapper from '../../../components/table/tla-table-wrapper'
import { Spin, Table } from 'antd'
import PropTypes from 'prop-types'
import { handleDeleteModule, handleGetAllModules } from '../../../actions/admin/modules/Action'
import { connect } from 'react-redux'
import TlaConfirm from '../../../components/tla-confirm'
import { TlaSuccess } from '../../../utils/messages'
import TlaEdit from '../../../components/tla-edit'
import { FiEdit2 } from 'react-icons/fi'

const Modules = ({ getModules, modules, deleteModule }) => {
  const { setPageInfo } = useOutletContext()
  const { data, meta } = modules
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setPageInfo({
      title: 'Modules',
      buttonText: 'Add Module',
      buttonLink: 'Add Module',
      showImportBtn: false,
      subTitle: '',
      helpingText: '',
      showSearch: false
    })
    getModules().then(() => setLoading(false))
  }, [])

  return (
        <div className={'pb-4'}>
            <Spin spinning={loading}>
                <TlaTableWrapper
                   meta={meta}
                   numberColumn={true} data={data} showHeader={false}
                   callbackFunction={getModules}>
                    <Table.Column title={'Module Title'} render={((record) => record.title ?? 'No Title')}/>
                    <Table.Column title={'Parent Module'} render={((record) => record.parentModule?.title ?? '')}/>
                    <Table.Column title={'Paywall'} render={((record) => record.behindPaywall ? 'Yes' : 'No')}/>
                    <Table.Column title={'Actions'} render={((record) => (
                        <div className={'flex items-center'}>
                            <TlaConfirm title={'Delete Module'}
                                        fullText={'Do you really want to delete this Module?'}
                                        callBack={() => {
                                          deleteModule(record.id).then(() => TlaSuccess('Deleted'))
                                        }}/>
                            <TlaEdit data={record} icon={<FiEdit2 className={'icon'}/>} link={'form'}/>
                        </div>
                    ))}/>
                </TlaTableWrapper>
            </Spin>
        </div>
  )
}

Modules.propTypes = {
  getModules: PropTypes.func.isRequired,
  deleteModule: PropTypes.func.isRequired,
  modules: PropTypes.object.isRequired
}

/**
 * @param state
 * @returns {{modules: *}}
 */
const mapStateToProps = (state) => ({
  modules: state.adminModulesReducer.modules ?? {}
})

/**
 *
 * @param dispatch
 * @returns {{getModules: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  getModules: (pageNumber, skillId) => dispatch(handleGetAllModules(pageNumber, skillId)),
  deleteModule: (moduleId) => dispatch(handleDeleteModule(moduleId))
})

export default connect(mapStateToProps, mapDispatchToProps)(Modules)
