import React from 'react'
import Mail from '../../../../assets/images/icons/mail.svg'
import Title from '../verification/title'
import BackToLogin from '../verification/back-to-login'

export default function Manual () {
  return (
      <div className={ 'h-screen flex items-center justify-start pt-20 flex-col bg-white-base' }>
          <img src={ Mail } alt={ 'mail' }/>
          <div className={ 'mb-8' }>
              <Title/>
          </div>
          {/* <div className={'mb-8'}>
                    <p>Didn’t receive the email? <span className={'text-primary-700'}>Click to resend</span></p>
                </div> */ }
          <BackToLogin/>
      </div>
  )
}
