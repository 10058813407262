import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router'
import TlaTableWrapper from '../../../components/table/tla-table-wrapper'
import { Spin, Table } from 'antd'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { handleDeleteQuestion, handleGetAllQuestions } from '../../../actions/admin/questions/Action'
import TlaConfirm from '../../../components/tla-confirm'
import { TlaSuccess } from '../../../utils/messages'
import TlaEdit from '../../../components/tla-edit'
import { FiEdit2 } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import TlaSelect from '../../../components/TlaSelect'
import { handleGetAllSkillsNoPagination } from '../../../actions/admin/skills/Action'

const Questions = ({ getQuestions, deleteQuestion, questions, skills, getSkills }) => {
  const { setPageInfo } = useOutletContext()
  const [loading, setLoading] = useState(true)
  const [skill, setSkill] = useState(0)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const goToDetails = (record) => {
    dispatch({ type: 'SINGLE_QUESTION', payload: record })
    navigate(`/questions/${record?.questionTitleSelf.slice(0, 50)}...`)
  }
  useEffect(() => {
    setPageInfo({
      title: 'Questions',
      buttonText: 'Add Questions',
      importBtnText: 'Import Questions',
      buttonLink: 'Add Questions',
      subTitle: '',
      helpingText: '',
      showSearch: false,
      showImportBtn: true
    })

    getQuestions().then(() => {
      setLoading(false)
    })
  }, [])
  return (
        <div className={'pb-4'}>
            <Spin spinning={loading}>
              <div className="w-1/4 ml-auto pr-6 mb-5">
                <TlaSelect
                  defaultValue='Select Skill'
                  onFocus={skills.length <= 0
                    ? (getSkills(1, 500).then(() => {}))
                    : null}
                  onChange={(value) => {
                    setLoading(true)
                    getQuestions(1, 15, { skill: value }).then(() => {
                      setLoading(false)
                      setSkill(value)
                    })
                  }}
                  required name={'skillId'} options={[...skills]} optionKey={'title'}/>
              </div>
                <TlaTableWrapper
                    numberColumn={true}
                    meta={questions?.meta} data={questions?.data}
                    showHeader={false}
                    filters={{ skill }}
                    callbackFunction={getQuestions}>
                    <Table.Column title={'Title Self'} width={250} render={((record) => (
                        <span className={'cursor-pointer hover:underline'} onClick={() => goToDetails(record)}>
                            {record.questionTitleSelf }
                        </span>
                    ))}/>
                    <Table.Column title={'Type'} dataIndex={'type'}/>
                    <Table.Column title={'Skill'} render={((record) => (
                        <span className={'bg-blue-200 text-blue-600 py-1 px-2 rounded-2xl text-xs font-bold mr-1 whitespace-nowrap'}>
                            {record.Skill_Question_skillId?.title }
                        </span>
                    ))}/>
                    <Table.Column title={'User Level & Type'} render={((record) => (
                      <div className='w-40 flex gap-2 flex-wrap'>
                        {
                          record.userLevelType.map(level => (
                            <span key={level} className={'bg-blue-200 text-blue-600 py-1 px-2 rounded-2xl text-xs font-bold mr-1 whitespace-nowrap'}>
                              {level}
                            </span>
                          ))
                        }
                      </div>
                    ))}/>
                    <Table.Column title={'Action'} render={((record) => (
                        <div className={'flex items-center'}>
                            <TlaConfirm title={'Delete Skill'}
                                        fullText={'Do you really want to delete this question?'}
                                        callBack={() => {
                                          setLoading(true)
                                          deleteQuestion(record.id)
                                            .then(() => {
                                              setLoading(false)
                                              TlaSuccess('Question Deleted')
                                            })
                                        }}/>
                            <TlaEdit data={record} icon={<FiEdit2 className={'icon'}/>} link={'form'}/>
                        </div>
                    ))}/>
                </TlaTableWrapper>
            </Spin>
        </div>
  )
}

Questions.propTypes = {
  getQuestions: PropTypes.func.isRequired,
  skills: PropTypes.array.isRequired,
  getSkills: PropTypes.func.isRequired,
  deleteQuestion: PropTypes.func.isRequired,
  questions: PropTypes.object.isRequired
}

/**
 * @param state
 * @returns {{skills: *}}
 */
const mapStateToProps = (state) => ({
  questions: state.adminQuestionsReducer.questions,
  skills: state.adminSkillsReducer.skills.data
})

/**
 *
 * @param dispatch
 * @returns {{getQuestions: (function(*): *), deleteQuestion: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  getQuestions: (pageNumber, pageSize, filters) => dispatch(handleGetAllQuestions(pageNumber, pageSize, filters)),
  deleteQuestion: (skillId) => dispatch(handleDeleteQuestion(skillId)),
  getSkills: () => dispatch(handleGetAllSkillsNoPagination())
})

export default connect(mapStateToProps, mapDispatchToProps)(Questions)
