import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router'
import TlaTableWrapper from '../../../components/table/tla-table-wrapper'
import { Spin, Table } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TlaConfirm from '../../../components/tla-confirm'
import { TlaSuccess } from '../../../utils/messages'
import TlaEdit from '../../../components/tla-edit'
import { FiEdit2 } from 'react-icons/fi'
import { handleDeleteQuizForm, handleGetAllQuizForms } from '../../../actions/admin/quiz-forms/Action'

const QuizForms = ({ getQuizForms, quizForms, deleteQuizForm }) => {
  const { setPageInfo } = useOutletContext() || {}
  const { data, meta } = quizForms
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (setPageInfo !== undefined) {
      setPageInfo({
        title: 'Quiz Forms',
        buttonText: 'Add Quiz Form'
      })
    }
    getQuizForms().then(() => setLoading(false))
  }, [])

  return (
    <div className={'pb-4'}>
      <Spin spinning={loading}>
        <TlaTableWrapper
          meta={meta} numberColumn={true} data={data} showHeader={false}
          callbackFunction={getQuizForms}>
          <Table.Column title={'Question Title'} render={((record) => record.titleQuizForm ?? 'No Title')} />
          <Table.Column title={'Score Card Title'} render={((record) => record.titleScoreCard ?? 'No Quiz Title')} />
          <Table.Column title={'Actions'} render={((record) => (
            <div className={'flex items-center'}>
              <TlaConfirm title={'Delete Form'}
                fullText={'Do you really want to delete this Form?'}
                callBack={() => {
                  deleteQuizForm(record.id).then(() => TlaSuccess('Deleted'))
                }} />
              <TlaEdit data={record} icon={<FiEdit2 className={'icon'} />} link={'form'} />
            </div>
          ))} />
        </TlaTableWrapper>
      </Spin>
    </div>
  )
}

QuizForms.propTypes = {
  getQuizForms: PropTypes.func.isRequired,
  deleteQuizForm: PropTypes.func.isRequired,
  quizForms: PropTypes.object.isRequired
}

/**
 * @param state
 * @returns {{quizForms: *}}
 */
const mapStateToProps = (state) => ({
  quizForms: state.adminQuizFormsReducer.quizForms ?? {}
})

/**
 *
 * @param dispatch
 * @returns {{getQuizForms: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  getQuizForms: (pageNumber, pageSize, filters) => dispatch(handleGetAllQuizForms(pageNumber, pageSize, filters)),
  deleteQuizForm: (quizFormId) => dispatch(handleDeleteQuizForm(quizFormId))
})

export default connect(mapStateToProps, mapDispatchToProps)(QuizForms)
