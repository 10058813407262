export const Quotes = [
  {
    text: '“We all need people who will give us feedback. That’s how we improve.”',
    author: 'Bill Gates'
  },
  {
    text: '“Feedback is the breakfast of champions.”',
    author: 'Ken Blanchard'
  },
  {
    text: '“Feedback is a gift. Ideas are the currency of our next success. Letpeople see you value both' +
            ' feedback and ideas.”',
    author: 'Jim Trinka and Les Wallace'
  },
  {
    text: '“True intuitive expertise is learned from prolonged experience with good feedback on mistakes.”',
    author: 'Daniel Kahneman'
  },
  {
    text: '“I think it’s very important to have a feedback loop, where you’re constantly thinking about what you’ve done and how you could be doing it better.”',
    author: 'Elon Musk'
  },
  {
    text: '“Average players want to be left alone. Good players want to be coached. Great players want to be' +
            ' told the truth.”',
    author: 'Doc Rivers'
  },
  {
    text: '“Critique, feedback, reaction to one\'s work or the way they have presented it, regardless of' +
            ' intention, is a gift.“',
    author: 'Mark Brand'
  },
  {
    text: '“When we make progress and get better at something, it is inherently motivating. In order for people' +
            ' to make progress, they have to get feedback and information on how they\'re doing.“',
    author: 'Daniel H. Pink'
  },
  {
    text: '“Feedback is a pleasant thing. I get a lot of letters from unexpected people in unexpected places.“',
    author: 'Brian Aldiss'

  },
  {
    text: '“The only thing worse than not requesting feedback is not acting on it.”',
    author: 'Frank Sonnenberg'
  },
  {
    text: '“If you’re blind to your flaws, you can’t address them.”',
    author: 'Frank Sonnenberg'
  },
  {
    text: '“Treat feedback as a gift, not as a slap in the face.”',
    author: 'Frank Sonnenberg'
  },
  {
    text: '“You may not like it, but you need to take constant feedback about your behaviour with others.' +
            ' Sometime we keep hurting everyone around us without having any clue.”',
    author: 'Sarvesh Jain'
  },
  {
    text: '“Receiving and providing feedback is an essential ingredient in achieving a lifetime full of growth' +
            ' and opportunity.”',
    author: 'Jeff Christian'
  },
  {
    text: '“Most of us don’t experience feedback and critical questions as a creative experience.”',
    author: 'Natalie Nixon'
  },
  {
    text: '“You must invite honest and accurate feedback. Surround yourself with people who care and aren’t' +
            ' afraid to speak up to you directly, face to face.”',
    author: 'Binod Shankar'
  },
  {
    text: '“Without continual growth and progress, such words as improvement, achievement, and success have no' +
            ' meaning.”',
    author: 'Benjamin Franklin'
  },
  {
    text: '“If you reject feedback, you also reject the choice of acting in a way that may bring you abundant' +
            ' success.“',
    author: 'John Mattone'
  }
]

export const QuoteTheme = [
  {
    background: '#FFF',
    fontColor: '#111827',
    footerBackground: '#5F5B5B'
  },
  {
    background: '#101828',
    fontColor: '#FFF',
    footerBackground: '#232125'
  },
  {
    background: '#1570EF',
    fontColor: '#FFF',
    footerBackground: '#243757'
  },
  {
    background: '#292929',
    fontColor: '#FFF',
    footerBackground: '#292525'
  },
  {
    background: '#2D3282',
    fontColor: '#FFF',
    footerBackground: '#2A283C'
  },
  {
    background: '#0040C1',
    fontColor: '#FFF',
    footerBackground: '#2A283C'
  },
  {
    background: '#125D56',
    fontColor: '#FFF',
    footerBackground: '#243231'
  }

]
