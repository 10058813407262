import { Types } from '../../actions/admin/users/Types'

const initialState = {
  users: {
    data: [],
    meta: {}
  }
}

export default function adminUsersReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_USER:
      return {
        ...state,
        users: {
          ...state.users,
          data: state.users.data.concat(action.payload.data.newUser)
        }
      }
    case Types.ALL_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          data: action.payload.data.users,
          meta: {
            total: action.payload.data.count,
            current_page: Number(action.payload.data.page),
            page: action.payload.data.page,
            pageSize: action.payload.data.pageSize,
            from: Number(action.payload.data.page)
          }
        }
      }

    case Types.UPDATE_USER:
      return {
        ...state,
        users: {
          ...state.users,
          data: state.users.data.map((form) => {
            return form.id === action.payload.data.user.id ? action.payload.data.user : form
          })
        }
      }
    case Types.DELETE_USER:
      return {
        ...state,
        users: {
          ...state.users,
          data: state.users.data.filter((users) => users.id !== action.id)
        }
      }

    default:
      return state
  }
}
