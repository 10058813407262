import React, { useEffect, useState } from 'react'
// import { NewChatButton } from './new-chat-button'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { handleGetAllChats } from '../../actions/user/chat/Action'
import { Spin } from 'antd'
import SingleChat from './single-chat'

function ChatList ({ getChatList, chatList }) {
  const [loading, setLoading] = useState(true)
  const [updated, setUpdated] = useState(-1)
  useEffect(() => {
    getChatList().then(() => setLoading(false)).catch(() => setLoading(false))
  }, [updated])
  return (
    <div className={'border-r pt-5 md:pt-0 w-[100vw] md:w-[373px]'}>
      <Spin spinning={loading}>
        <div className={'chat-list'}>
          {
            chatList.map((chat, index) => (
              <div key={chat.chatId}>
                <SingleChat deleteSuccessFunc={setUpdated} chat={chat} />
              </div>
            ))
          }
        </div>
      </Spin>
    </div>
  )
}

ChatList.propTypes = {
  getChatList: PropTypes.func.isRequired,
  chatList: PropTypes.any.isRequired
}

const mapStateToProps = (state) => ({
  chatList: state.chatsReducer.chatList
})

const mapDispatchToProps = (dispatch) => ({
  getChatList: () => dispatch(handleGetAllChats())
})

export default connect(mapStateToProps, mapDispatchToProps)(ChatList)
