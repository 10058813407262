import React from 'react'
import { FiFlag } from 'react-icons/fi'
import SelfAssessmentTitle from './components/self-assessment-title'
import TlaAddNew from '../../components/pop-ups/tla-add-new'
import PropTypes from 'prop-types'

function SelfAssessmentHeader ({ questionId, showReport }) {
  return (
        <div className={ 'bg-white-base rounded-lg mx-auto w-full' }>
            <div>
                <div className="border-b">
                    <div className={ 'p-5 pb-[42px] md:pb-4' }>
                        <h4 className={ 'text-gray-900 text-base font-medium' }>
                            Self Assessment
                        </h4>
                        <span
                            className={ 'text-gray-500' }>Complete self assessment to access all features of the platform.</span>
                    </div>
                </div>

                <div className={ 'p-5' }>
                    <div className={ 'flex flex-col-reverse md:flex-row justify-between' }>
                        <SelfAssessmentTitle/>
                        <div
                            className={ 'flex flex-row md:flex-col items-end justify-between md:justify-center gap-1' }>
                            <p className={ 'text-xs text-indigo-700 font-medium bg-indigo-50 rounded-2xl py-0.5 px-2' }>
                                Self Assessment
                            </p>
                            {
                                showReport &&
                                <TlaAddNew link={ '/report/question' } data={ { questionId } }>
                                    <p className={ 'text-xs text-gray-400 flex justify-end items-center gap-1 mt-1' }>
                                        Report <FiFlag/>
                                    </p>
                                </TlaAddNew>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

  )
}

SelfAssessmentHeader.defaultProps = {
  questionId: null,
  showReport: true
}

SelfAssessmentHeader.propTypes = {
  questionId: PropTypes.number,
  showReport: PropTypes.bool
}
export default SelfAssessmentHeader
