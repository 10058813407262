import React, { useState } from 'react'
import { Spin, Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import { CiCamera } from 'react-icons/ci'
import DefaultUser from '../../assets/images/profile/user.png'
import { TlaError, TlaSuccess } from '../../utils/messages'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { getBase64 } from '../../utils'

export default function UploadPhoto ({ upload }) {
  const [loading, setLoading] = useState(false)
  const profile = useSelector(state => state.loginReducer.authUserInfo?.userInformation)
  const [selectedFile, setSelectedFile] = useState({
    file: null,
    url: null
  })

  const uploadProps = {
    beforeUpload: async (file) => {
      setLoading(true)
      await getBase64(file).then((url) => {
        setSelectedFile({ file, url })

        upload(profile?.userId, {
          photo: file
        }).then(() => {
          setLoading(false)
          TlaSuccess('Upload Successful')
        }).catch(() => {
          setSelectedFile({ file: null, url: null })
          setLoading(false)
          TlaError('Something went wrong')
        })
      })
      return true
    },
    listType: 'picture-circle',
    maxCount: 1,
    onRemove: () => {
      setSelectedFile({
        file: null,
        url: null
      })
    },
    method: 'get',
    showUploadList: false,
    className: 'upload-photo'
  }

  return (
        <Spin spinning={loading} indicator={<LoadingOutlined spin/>}>
          <div className={'relative'}>
            {
              selectedFile.url
                ? <img src={selectedFile.url}
                         className={'h-[100px] w-[100px] md:h-[150px] md:w-[150px]' +
                             ' rounded-full shadow-3.5xl border-white-base border-4'}
                         alt="ProfileImage"/>
                : <>
                    {
                      profile?.photo === 'default.png'
                        ? <div
                              className={'rounded-full p-5 md:p-10 border-white-base border-4' +
                                  ' bg-gray-50 h-[150px] w-[150px] shadow-3.5xl flex items-center justify-center'}>
                            <img src={DefaultUser}
                                 className={'w-12 md:w-full'} alt="ProfileImage"/>
                          </div>

                        : <img src={profile?.photo}
                                 className={'h-[100px] w-[100px] md:h-[150px] md:w-[150px]' +
                                     ' rounded-full shadow-3.5xl border-white-base border-4'}
                                 alt="ProfileImage"/>
                    }

                  </>
            }

            <div className={'w-fit absolute bottom-0 right-0 md:right-5'}>
              <ImgCrop rotationSlider>
                <Upload {...uploadProps}>
                  <CiCamera size={16}/>
                </Upload>
              </ImgCrop>
            </div>
          </div>
        </Spin>
  )
}

UploadPhoto.propTypes = {
  upload: PropTypes.func
}
