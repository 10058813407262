import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { connect, useSelector } from 'react-redux'
import { handleGetEmotions } from '../../../actions/user/common/Action'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { emojis } from '../../../utils/emotions'

function Emotions ({ getEmotions }) {
  const emotions = useSelector(state => state.commonReducer.emotions)

  const [loading, setLoading] = useState(false)
  const { pathname } = useLocation()
  useEffect(() => {
    setLoading(true)
    getEmotions(pathname === '/dashboard/overview/self' ? 'self' : 'feedback')
      .then((res) => {
        setLoading(false)
      })
  }, [pathname])

  return (
        <Spin spinning={ loading } indicator={ <LoadingOutlined/> }>
            <div className={ 'mt-[30px] border rounded-lg py-[11px] px-3.5' }>
                <h3 className={ 'text-lg text-gray-900 font-medium border-b pb-5' }>
                    Your Emotions Journal
                </h3>
                <div className={ 'flex items-center gap-[39px] pt-5 flex-wrap' }>
                    {
                        emotions && Object.keys(emotions)?.map((e, index) => (
                            <div key={ index } className={ 'flex flex-col gap-1 items-center justify-between text-center' }>
                                <img className={ 'rounded-full border p-1 h-[28px] w-[28px]' } src={ emojis[e] } alt={ e }/>
                                <p className={ 'text-gray-700 font-medium text-sm' }>{ emotions[e] }</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </Spin>
  )
}

Emotions.propTypes = {
  getEmotions: PropTypes.func
}

const mapDispatchToProps = (dispatch) => ({
  getEmotions: (type) => dispatch(handleGetEmotions(type))
})

export default connect(null, mapDispatchToProps)(Emotions)
