import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { handleGetPublicCourses } from '../../actions/user/common/PublicActions'
import DisplayCourses from '../courses/components/display-courses'

function Courses ({ getCourses, courses }) {
  return (
        <React.Fragment>
            <div className="bg-white-base rounded-md max-w-screen-2xl px-3 md:px-12 mx-auto py-6">
                <DisplayCourses publicPage courses={ courses } getCourses={ getCourses } options={ [] }/>
            </div>
        </React.Fragment>
  )
}

Courses.propTypes = {
  courses: PropTypes.object.isRequired,
  getCourses: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  courses: state.commonReducer.courses
})

const mapDispatchToProps = (dispatch) => ({
  getCourses: (page, reset, type) => dispatch(handleGetPublicCourses(page, reset, type))
})

export default connect(mapStateToProps, mapDispatchToProps)(Courses)
