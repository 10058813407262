import React, { useEffect } from 'react'
import Header from './header'
import PropTypes from 'prop-types'
import SidebarForm from './sidebar-form'
import { Outlet, useLocation } from 'react-router-dom'
import AppSidebar from './sidebar/app-sidebar'
import { HeaderMenus } from '../../../utils/menu-items'
import MobileMenu from './mobile-menu'
import { Affix } from 'antd'
import MobileProfile from '../../../components/mobile-profile'
import { useParams } from 'react-router'
import { connect, useDispatch, useSelector } from 'react-redux'
import { handleSendInvitation } from '../../../actions/user/common/Action'
import InstantFeedback from '../../../components/instant-feedback'

function Layout ({ sendInvite }) {
  const userId = useSelector(state => state.loginReducer?.authUser?.id)
  const { invitationCode } = useParams()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({
      type: 'SET_INVITATION_CODE',
      payload: invitationCode ?? null
    })
  }, [])

  const savedInvitationCode = useSelector(state => state.commonReducer?.invitationCode)
  const completeInvitation = () => {
    if (savedInvitationCode) {
      sendInvite({
        invitationCode: savedInvitationCode,
        contactUserId: userId
      }).then(() => {
        dispatch({
          type: 'SET_INVITATION_CODE',
          payload: null
        })
      })
    }
  }

  useEffect(() => {
    completeInvitation()
  }, [savedInvitationCode])

  // eslint-disable-next-line no-unused-vars
  const { pathname } = useLocation()

  // eslint-disable-next-line no-unused-vars
  const profilePaths = ['/dashboard/overview/contact', '/dashboard/overview/self']

  return (
        <div className={'bg-white-50'}>
            <Affix offsetTop={ 0 }>
                <div className="bg-white-base">
                    <div className={ 'w-full max-w-screen-2xl mx-auto overflow-x-hidden' }>
                        <Header menuItems={ HeaderMenus }/>
                    </div>
                </div>
            </Affix>
            <div className={ 'bg-white-50 relative max-w-screen-2xl mx-auto max-h-screen' }>

                {/* container start */ }
                <div className={ 'w-full overflow-x-hidden bg-white-50 py-4' }>
                    {
                        profilePaths.includes(pathname) &&
                        <MobileProfile/>
                    }
                    <div className={ 'flex gap-x-3 justify-between mt-5 md:mt-0 mx-2 md:mx-8' }>
                        <AppSidebar/>
                        <div className={ 'mb-5 w-full md:min-w-[720px] flex-1' }>
                            <div>
                                {
                                    profilePaths.includes(pathname) &&
                                    <div className={ 'block md:hidden' }>
                                        <InstantFeedback/>
                                    </div>
                                }
                            </div>
                            <Outlet/>
                        </div>

                        <div className={ 'w-[250px] hidden md:hidden lg:hidden xl:block' }>
                            <Affix offsetTop={ 90 }>{
                                profilePaths.includes(pathname)
                                  ? <InstantFeedback/>
                                  : <SidebarForm/>
                            }
                            </Affix>
                        </div>
                    </div>
                    <div className="relative z-[1000]">
                      <MobileMenu/>
                    </div>
                </div>
                {/* container end */ }

            </div>
        </div>
  )
}

Layout.propTypes = {
  children: PropTypes.any,
  sendInvite: PropTypes.func
}

const mapDispatchToState = (dispatch) => ({
  sendInvite: (data) => dispatch(handleSendInvitation(data))
})

export default connect(null, mapDispatchToState)(Layout)
