import { Types } from '../../actions/user/contacts/Types'

const initialState = {
  contacts: {
    data: [],
    meta: {}
  }
}

export default function contactsReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_CONTACT:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          data: action.payload.data?.contact ? state.contacts.data.concat(action.payload.data.contact) : state.contacts.data
        }
      }
    case Types.ALL_CONTACTS:

      // eslint-disable-next-line no-case-declarations
      const page = Number(action.payload.data.page)
      // eslint-disable-next-line no-case-declarations
      const pageSize = Number(action.payload.data.pageSize)
      return {
        ...state,
        contacts: {
          ...state.contacts,
          data: action.payload.data.contacts,
          meta: {
            total: action.payload.data.count,
            page: action.payload.data.page,
            from: page === 1 ? page : (page * pageSize) - 1,
            current_page: page,
            per_page: action.payload.data.pageSize,
            pageSize: action.payload.data.pageSize
          }
        }
      }

    case Types.UPDATE_CONTACT:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          data: state.contacts.data.map((form) => {
            return form.id === action.payload.data.contact.id ? action.payload.data.contact : form
          })
        }
      }
    case Types.DELETE_CONTACT:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          data: state.contacts.data.filter((contacts) => contacts.id !== action.id)
        }
      }

    default:
      return state
  }
}
