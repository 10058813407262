import React, { useEffect, useState } from 'react'
import { Button, Form, Select, Spin, message } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import TlaModalFormWrapper from '../../../components/tla-modal-form-wrapper'
import CloseModal from '../../../components/close-modal'
import Upload from 'antd/es/upload/Upload'
import { BiUpload } from 'react-icons/bi'
import { handleImportQuestions } from '../../../actions/admin/questions/Action'

function QuestionsImportForm ({ importQuestions }) {
  const [loading, setLoading] = useState(true)
  const [selectedFile, setSelectedFile] = useState(null)
  const { state } = useLocation()
  const questionTypes = [
    {
      value: 'questions',
      label: 'Scenario Base'
    },
    {
      value: 'rating',
      label: 'Rating Base'
    }
  ]
  const formValues = {
    ...state?.data
  }
  useEffect(() => {
    setLoading(false)
  }, [])

  const uploadProps = {

    beforeUpload: (file) => {
      setLoading(true)
      const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel'
      if (!isExcel) {
        message.error(`${file.name} is not an Excel file`)
        setLoading(false)
        return Upload.LIST_IGNORE
      }
      // Store the file
      setSelectedFile(file)
      return false
    },
    maxCount: 1,
    className: 'py-2 px-4 rounded-lg bg-slate-100 border-gray-200 border w-full block my-3 '
  }
  const appendFileBeforeSubmit = (values) => {
    values = {
      ...values,
      file: selectedFile
    }
    console.log(values)
    return values
  }
  // const testCallBack = () => {
  //   alert('function submitted')
  // }
  return (
      <Spin spinning={loading}>
        <TlaModalFormWrapper
            initialValues={formValues}
            formTitle={'Import Question'}
            resourceId={null}
            beforeSubmit={appendFileBeforeSubmit}
            onSubmit={importQuestions}>
            {/* // onSubmit={testCallBack}> */}
            <Form.Item
                name={'type'}
                rules={[
                  {
                    required: true,
                    message: 'Required'
                  }
                ]}
                label={'Question Type'}>
              <Select defaultValue={'Select Question Type'} name={'type'} onChange={(value) => {
                console.log(value)
              }} options={questionTypes} />
            </Form.Item>
            <Form.Item
                name={'file'}
                rules={[
                  {
                    required: true,
                    message: 'Required'
                  }
                ]}
                label={'Question Title *'}>
                {/* <Input size={'large'} placeholder={'Eg Leadership'}/> */}
                <Upload {...uploadProps}>
                  <div className="flex gap-2 w-full align-center">
                    <BiUpload size={30}/>
                    <span className="py-1">Upload</span>
                  </div>
                </Upload>
            </Form.Item>
            <div className={'flex gap-2'}>
                <div className={'w-full'}>
                    <CloseModal/>
                </div>
                <Form.Item className={'w-full'}>
                    <Button className={'btn-primary w-full'} size={'large'} htmlType="submit">
                        Import
                    </Button>
                </Form.Item>
            </div>
        </TlaModalFormWrapper>
      </Spin>
  )
}

QuestionsImportForm.propTypes = {
  importQuestions: PropTypes.func.isRequired
}

/**
 *
 * @param dispatch
 * @returns {{importQuestions: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  importQuestions: (data) => dispatch(handleImportQuestions(data))
})

export default connect(null, mapDispatchToProps)(QuestionsImportForm)
