import React from 'react'
import { useSelector } from 'react-redux'
import { Card } from 'antd'
import { formatBodyOptions } from '../../../utils'
import TlaEdit from '../../../components/tla-edit'

const SingleQuestion = () => {
  const question = useSelector(state => state.adminQuestionsReducer.question)
  // eslint-disable-next-line react/prop-types
  const Item = ({ label, content }) => (
        <div className={'border-b py-3 flex flex-wrap gap-3'}>
            <p className={'font-medium capitalize'}>{label}:</p>
            <div>{content}</div>
        </div>
  )
  return (
        <div className={'pb-4'}>
            <Card
                size={'small'}
                title={question?.type}
                extra={[
                <TlaEdit key={'edit'} link={`edit-${question?.type?.toLowerCase()}`} data={question} text={'Edit'}/>
                ]}>
                <Item label={'Question Title Self'} content={question?.questionTitleSelf}/>
                {
                    question?.type === 'RATING'
                      ? <Item label={'Question Body Self'} content={question?.questionBodySelf}/>
                      : <Item label={'Question Body Self'} content={
                          <React.Fragment>
                              {formatBodyOptions(question?.questionBodySelf)?.options.map((option, index) => (
                                  <div key={index} className={'border-b pb-2'}>
                                      <p><span className={'font-medium'}>Text: </span>{option?.text}</p>
                                      <p><span className={'font-medium'}>Score: </span>{option?.score}</p>
                                      <p><span className={'font-medium'}>Explanation: </span>{option?.explanation}</p>
                                  </div>
                              ))}
                          </React.Fragment>
                        }/>
                }
                <Item label={'Question Title Others'} content={question?.questionTitleOthers}/>
                {
                    question?.type === 'RATING'
                      ? <Item label={'Question Body Others'} content={question?.questionBodyOthers}/>
                      : <Item label={'Question Body Others'} content={
                          <React.Fragment>
                              {
                                  question?.questionBodyOthers !== null &&
                                  formatBodyOptions(question?.questionBodySelf)?.options.map((option, index) => (
                                  <div key={index} className={'border-b pb-2'}>
                                      <p><span className={'font-medium'}>Text: </span>{option?.text}</p>
                                      <p><span className={'font-medium'}>Score: </span>{option?.score}</p>
                                      <p><span className={'font-medium'}>Explanation: </span>{option?.explanation}</p>
                                  </div>
                                  ))
                              }
                          </React.Fragment>
                        }/>
                }
                <Item label={'Achieved'} content={JSON.stringify(question?.achieved)}/>
                {
                    question?.type === 'RATING' &&
                    <React.Fragment>
                        <Item label={'Lowest Score Help'} content={question?.lowestScoreHelp}/>
                        <Item label={'highest Score Help'} content={question?.highestScoreHelp}/>
                    </React.Fragment>
                }
            </Card>
        </div>
  )
}

export default SingleQuestion
