import React, { useState } from 'react'
import { Button, Form, Input, Radio } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import TlaModalFormWrapper from '../../../components/tla-modal-form-wrapper'
import CloseModal from '../../../components/close-modal'
import TlaSelect from '../../../components/TlaSelect'
import { handleGetAllSkills } from '../../../actions/admin/skills/Action'
import { FiPlus, FiTrash } from 'react-icons/fi'
import { handleGetAllLessons } from '../../../actions/admin/lessons/Action'
import { handleAddLessonQuestion, handleUpdateLessonQuestion } from '../../../actions/admin/lesson-questions/Action'

function LessonQuestionsForm ({ addQuestion, updateQuestion, skills, getSkills, lessons, getLessons }) {
  const { state } = useLocation()
  const [questionType, setQuestionType] = useState(state?.data?.type ?? 'RATING')
  const resourceId = state?.data?.id
  const formValues = {
    ...state?.data,
    type: questionType,
    questionOptions: state?.data?.questionOptions
      ? (Object.values(state?.data.questionOptions))
      : [
          {
            text: '',
            explanation: '',
            correct_ans: false
          }
        ]
  }
  const formatBeforeSubmit = (values) => {
    return values
  }
  return (
    <TlaModalFormWrapper beforeSubmit={formatBeforeSubmit}
      initialValues={formValues}
      formTitle={`${resourceId ? 'Update' : 'Create'} Question`}
      resourceId={state?.data?.id ?? null}
      onSubmit={state?.data?.id ? updateQuestion : addQuestion}>
      <Form.Item label="" name="type"
        rules={[
          {
            required: true,
            message: 'Required'
          }
        ]}>
        <div className={'flex flex-wrap gap-x-3'}>
          <p>Question Type</p>
          <Radio.Group value={questionType} onChange={(e) => setQuestionType(e.target.value)}>
            <Radio className='mx-0' value="RATING">Rating</Radio>
            <Radio className='mx-0' value="SCENARIO">Scenario</Radio>
            <Radio className='mx-0' value="MCQ">MCQ</Radio>
            <Radio className='mx-0' value="MULTI_SELECT">Multi Select</Radio>
          </Radio.Group>
        </div>
      </Form.Item>
      <TlaSelect customClass={'my-2'}
        onFocus={skills.length <= 0
          ? (getSkills(1, 500).then(() => { }))
          : null}
        required name={'skillId'} label={'Select Skill *'} options={skills} optionKey={'title'} />
      <TlaSelect customClass={'my-2'}
        onFocus={lessons.length <= 0
          ? (getLessons(1, 1000).then(() => { }))
          : null}
        required name={'lessonId'} label={'Select Lesson *'} options={lessons} optionKey={'title'} />

      <Form.Item
        name={'hint'} className='my-2'
        label={'Question Hint '}>
        <Input size={'large'} placeholder={'SD (rating)'} />
      </Form.Item>
      {
        questionType === 'RATING'
          ? <>
            <Form.Item
              name={'questionTitleOthers'}
              rules={[
                {
                  required: true,
                  message: 'Required'
                }
              ]}
              label={'Add Question Title (Others)*'}>
              <Input.TextArea size={'large'} rows={2} placeholder={'Enter a description...'} />
            </Form.Item>
            <Form.Item
              name={'questionTitleSelf'}
              rules={[
                {
                  required: true,
                  message: 'Required'
                }
              ]}
              label={'Add Question Title (Self)*'}>
              <Input.TextArea size={'large'} rows={2} placeholder={'Enter a description...'} />
            </Form.Item>
          </>
          : <Form.Item
            name={'questionTitleSelf'}
            rules={[
              {
                required: true,
                message: 'Required'
              }
            ]}
            label={'Question Title*'}>
            <Input.TextArea size={'large'} rows={2} placeholder={'Enter a description...'} />
          </Form.Item>
      }
      {
        questionType === 'RATING' &&
        <>
          <Form.Item
            name={'lowestScoreHelp'}
            label={'Lowest Score help text'}>
            <Input size={'large'} placeholder={'Eg Strongly Disagree'} />
          </Form.Item>
          <Form.Item
            name={'highestScoreHelp'}
            label={'Highest Score help text'}>
            <Input size={'large'} placeholder={'Eg Strongly Agree'} />
          </Form.Item>
        </>
      }
      {
        questionType !== 'RATING' &&
        <Form.List name="questionOptions">
          {(fields, { add, remove }) => {
            let total = 0
            return (
              <>
                <div className={'flex justify-between items-center mb-2'}>
                  <h3 className={'text-sm font-medium'}>Options *</h3>
                  <Button type="text"
                    className={'btn btn-light text-sm text-gray-500 hover:!bg-transparent'}
                    onClick={() => {
                      add({ name: '', correct_ans: false })
                      total = total + 1
                    }} icon={<FiPlus size={20} />}>
                    Add
                  </Button>
                </div>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key} className={'flex gap-2'}>
                    <div className={'w-full'}>
                      <Form.Item
                        {...restField}
                        name={[name, 'text']}
                        rules={[
                          {
                            required: true,
                            message: 'Required'
                          }
                        ]}>
                        <Input size={'large'} placeholder="Option Title" />
                      </Form.Item>
                      {
                        questionType === 'SCENARIO' &&
                        <Form.Item
                          {...restField}
                          name={[name, 'explanation']}
                          rules={[
                            {
                              required: true,
                              message: 'Required'
                            }
                          ]}>
                          <Input size={'large'} placeholder="Explanation Title" />
                        </Form.Item>
                      }
                      <div className="flex items-center gap-2 mb-3">
                        <p>Correct Ans</p>
                        <Form.Item
                          {...restField}
                          className='mb-0'
                          name={[name, 'correct_ans']}
                          rules={[
                            {
                              required: true,
                              message: 'Required'
                            }
                          ]}>
                          <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </div>
                    <Button icon={<FiTrash />} className={'btn items-center flex justify-center'}
                      danger size={'large'}
                      onClick={() => remove(name)} />
                  </div>
                ))}
              </>
            )
          }}
        </Form.List>
      }
      <div className={'flex gap-2'}>
        <div className={'w-full'}>
          <CloseModal />
        </div>
        <Form.Item className={'w-full'}>
          <Button className={'btn-primary w-full'} size={'large'} htmlType="submit">
            {`${resourceId ? 'Update' : 'Create'}`} Question
          </Button>
        </Form.Item>
      </div>
    </TlaModalFormWrapper>
  )
}

LessonQuestionsForm.propTypes = {
  addQuestion: PropTypes.func.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  skills: PropTypes.array.isRequired,
  getSkills: PropTypes.func.isRequired,
  lessons: PropTypes.array.isRequired,
  getLessons: PropTypes.func.isRequired
}

/**
 * @param state
 * @returns {{skills: ([]|*)}}
 */
const mapStateToProps = (state) => ({
  skills: state.adminSkillsReducer.skills.data,
  lessons: state.adminLessonsReducer.lessons.data
})

/**
 *
 * @param dispatch
 * @returns {{addQuestion: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  addQuestion: (data) => dispatch(handleAddLessonQuestion(data)),
  updateQuestion: (questionId, data) => dispatch(handleUpdateLessonQuestion(questionId, data)),
  getSkills: (pageNumber, pageSize) => dispatch(handleGetAllSkills(pageNumber, pageSize)),
  getLessons: (pageNumber, pageSize) => dispatch(handleGetAllLessons(pageNumber, pageSize))
})

export default connect(mapStateToProps, mapDispatchToProps)(LessonQuestionsForm)
