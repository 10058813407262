import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router'
import TlaTableWrapper from '../../../components/table/tla-table-wrapper'
import { Spin, Table } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TlaConfirm from '../../../components/tla-confirm'
import { TlaSuccess } from '../../../utils/messages'
import TlaEdit from '../../../components/tla-edit'
import { FiEdit2 } from 'react-icons/fi'
import { handleDeleteQuizFormQuestion, handleGetAllQuizFormQuestions } from '../../../actions/admin/quiz-form-questions/Action'

const QuizFormQuestions = ({ getQuizFormQuestions, quizFormQuestions, deleteQuizFormQuestion }) => {
  const { setPageInfo } = useOutletContext() || {}
  const { data, meta } = quizFormQuestions
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (setPageInfo !== undefined) {
      setPageInfo({
        title: 'Quiz Form Questions',
        buttonText: 'Link Form Questions'
      })
    }
    getQuizFormQuestions().then(() => setLoading(false))
  }, [])

  return (
    <div className={'pb-4'}>
      <Spin spinning={loading}>
        <TlaTableWrapper
          meta={meta} numberColumn={true} numberColumnWidth={100} data={data} showHeader={false}
          callbackFunction={getQuizFormQuestions}>
          <Table.Column title={'Form Title'} render={((record) => record.quizForm?.titleQuizForm ?? 'No Title')} />
          <Table.Column title={'Question Title'} render={((record) => record.quizQuestion?.title ?? 'No Title')} />
          <Table.Column title={'Sequence'} render={((record) => record.sequence)} />
          <Table.Column title={'Actions'} render={((record) => (
            <div className={'flex items-center'}>
              <TlaConfirm title={'Delete Question'}
                fullText={'Do you really want to delete this Question?'}
                callBack={() => {
                  deleteQuizFormQuestion(record.id).then(() => TlaSuccess('Deleted'))
                }} />
              <TlaEdit data={record} icon={<FiEdit2 className={'icon'} />} link={'form'} />
            </div>
          ))} />
        </TlaTableWrapper>
      </Spin>
    </div>
  )
}

QuizFormQuestions.propTypes = {
  getQuizFormQuestions: PropTypes.func.isRequired,
  deleteQuizFormQuestion: PropTypes.func.isRequired,
  quizFormQuestions: PropTypes.object.isRequired
}

/**
 * @param state
 * @returns {{quizFormQuestions: *}}
 */
const mapStateToProps = (state) => ({
  quizFormQuestions: state.adminQuizFormQuestionsReducer.linkQuizQuestions ?? {}
})

/**
 *
 * @param dispatch
 * @returns {{getQuizFormQuestions: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  getQuizFormQuestions: (pageNumber, pageSize, filters) => dispatch(handleGetAllQuizFormQuestions(pageNumber, pageSize, filters)),
  deleteQuizFormQuestion: (quizFormQuestionId) => dispatch(handleDeleteQuizFormQuestion(quizFormQuestionId))
})

export default connect(mapStateToProps, mapDispatchToProps)(QuizFormQuestions)
