import { Types } from '../../actions/user/feedbacks/Types'

const initialState = {
  feedbacks: {
    data: [],
    meta: {}
  },
  currentQuestion: {}
}

export default function feedbacksReducer (state = initialState, action) {
  switch (action.type) {
    case Types.ALL_FEEDBACKS:
      // eslint-disable-next-line no-case-declarations
      const page = Number(action.payload.page)
      // eslint-disable-next-line no-case-declarations
      const pageSize = Number(action.payload.pageSize)

      return {
        ...state,
        feedbacks: {
          ...state.feedbacks,
          data: action.payload.users,
          meta: {
            total: action.payload.totalRequests,
            page: action.payload.page,
            from: page === 1 ? page : (page * pageSize) - 1,
            current_page: page,
            per_page: action.payload.pageSize,
            pageSize: action.payload.pageSize
          }
        }
      }

    case Types.SET_CURRENT_QUESTION: {
      return {
        ...state,
        currentQuestion: action.payload
      }
    }
    case Types.SUBMIT_FEEDBACK: {
      return {
        ...state,
        currentQuestion: action.payload
      }
    }

    default:
      return state
  }
}
