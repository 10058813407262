import api from '../../../utils/api'
import { addUser, allUsers, deleteUser, searchUser, updateUser } from './ActionCreator'

/**
 * Store a newly created resource in storage.
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAddUser = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/user', data).then((res) => {
      dispatch(addUser(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAllUsers = (pageNumber = 1) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/admin/users?page=${pageNumber}&pageSize=15`).then((res) => {
      dispatch(allUsers(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const handleSearchUser = (email) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/users/by-email?email=${email}`).then((res) => {
      dispatch(searchUser(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Update the specified resource in storage.
 * @param userId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleUpdateUser = (userId, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().put(`/user/${userId}`, data, {
      // headers: { 'Content-type': 'multipart/form-data' }
    }).then((res) => {
      dispatch(updateUser(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Remove the specified resource from storage.
 * @returns {function(*): Promise<unknown>}
 * @param userId
 */
export const handleDeleteUser = (userId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().delete(`/user/${userId}`).then((res) => {
      dispatch(deleteUser(userId))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
