import React from 'react'
import { FiInfo } from 'react-icons/fi'

// eslint-disable-next-line react/prop-types
const LearnMore = ({ icon, color, link, text, underline }) => (
    <div className={`text-sm text-primary-700 ${!underline ? 'underline' : ''}`}>
        <a target={'_blank'} href={`${link ?? 'https://noustro.com/what-are-absolute-and-normalized-scores'}`} rel="noreferrer">
            {icon ? <FiInfo className={`${color}`} /> : text ?? 'Learn about absolute and normalized scores'}
        </a>
    </div>
)
export default LearnMore
