import React from 'react'
import PropTypes from 'prop-types'
import ShareGameLink from './play-game/share-game-link'

function GameResult ({ game }) {
  return (
      <div>
          <div className={ 'bg-gray-100 p-3 rounded-lg' }>
              <div className="space-y-4">
                  { Object.keys(game?.playedContent?.decisions)?.map((key, index) => {
                    const data = game?.playedContent?.decisions[key]
                    return (
                          <div key={ index } className="bg-white-base p-4 rounded-lg shadow">
                              <p className="font-semibold">{ data.round }. { data.scenario }</p>
                              <p>Choice: { data.choice } { data.isAutomatic && '(Automatic)' }</p>
                              <p className="text-sm text-gray-600">Result: { data.consequence }</p>
                          </div>
                    )
                  }) }
              </div>
          </div>
          <div className={'py-2'}>
              <ShareGameLink uuid={ game?.uid }/>
          </div>
      </div>
  )
}

GameResult.propTypes = {
  game: PropTypes.object.isRequired
}

export default GameResult
