import React from 'react'
import { Radio } from 'antd'
import { HandshakeIcon } from './handshake-icon'
import { BrainIcon } from './brain-icon'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { MentalHealthIcon } from './metal-health-icon'

function NousgptButtonSet () {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const expert = useSelector(state => state.chatsReducer.gptExpert) // Redux state
  const nousGPTOptions = [
    {
      label: <div className='flex gap-2 py-1 text-gray-900'><HandshakeIcon className='w-8 h-8' fill='#2ED3B7' /> Soft Skills</div>,
      value: 'soft_skills'
    },
    {
      label: <div className='flex gap-2 py-1 text-gray-900'><BrainIcon className='w-8 h-8' fill='#2ED3B7' /> Relationships</div>,
      value: 'relationships'
    },
    {
      label: <div className='flex gap-2 py-1 text-gray-900'><MentalHealthIcon className='w-8 h-8' fill='#2ED3B7' /> Mental Health</div>,
      value: 'mental_health'
    }
  ]

  const clickHandler = (newValue) => {
    dispatch({ type: 'CHANGE_GPT_EXPERT', payload: newValue })
    navigate('new-chat')
  }

  return (
    <Radio.Group
      value={expert}
      buttonStyle="solid"
      className="flex flex-col md:flex-row gap-4"
    >
      {nousGPTOptions.map((option) => (
        <Radio.Button
          key={option.value}
          value={option.value}
          onClick={() => clickHandler(option.value)}
          className='block h-10 nousgpt-button'
        >
          {option.label}
        </Radio.Button>
      ))}
    </Radio.Group>
  )
}

export default NousgptButtonSet
