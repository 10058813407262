import React from 'react'
import { Route, Routes } from 'react-router-dom'
import SignIn from '../../pages/auth/sign-in'
import ForgotPassword from '../../pages/auth/forgot-password'
import ResetPassword from '../../pages/auth/reset-password'
import VerificationMessage from '../../pages/auth/verification-message'
import VerifyEmail from '../../pages/auth/verify-email'
import PublicProfile from '../../pages/my-profile/public-profile'
import publicCoursesRoutes from './public-courses-routes'
import SignUp from '../../pages/auth/sign-up/sign-up'
import Register from '../../pages/auth/register'
import Verify from '../../pages/auth/sign-up/verify'
import Manual from '../../pages/auth/sign-up/verify/manual'
import Verified from '../../pages/auth/sign-up/verify/verified'

export const PublicRoutes = () => {
  return (
        <Routes>
            <Route exact element={ <SignIn/> } path="/"/>
            <Route exact element={ <SignIn/> } path="/sign-in"/>
            <Route element={ <SignUp/> } path={ '/sign-up' }/>
            <Route element={ <Register/> } path={ '/sign-ups' }/>
            <Route element={ <SignUp/> } path={ '/register/:invitationCode/invitation' }/>
            <Route element={ <Verify/> } path={ '/verify' }/>
            <Route element={ <Manual/> } path={ '/verify/manual' }/>
            <Route element={ <Verified/> } path={ '/verify/verified' }/>
            <Route element={ <VerificationMessage/> } path={ '/verify-email-message' }/>
            <Route element={ <VerifyEmail/> } path={ '/verifyemail' }/>
            <Route element={ <ForgotPassword/> } path={ '/forgot-password' }/>
            <Route element={ <ResetPassword/> } path={ '/resetpassword/:token' }/>
            <Route element={ <PublicProfile/> } path={ '/pp/:username' }/>
            {publicCoursesRoutes}
            <Route path={ '*' } element={ <SignIn/> }/>
        </Routes>
  )
}
