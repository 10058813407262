import React from 'react'
import MenuHelper from '../../../../components/navigation/menu-helper'
import { ProfileMenus } from '../../../../utils/menu-items'
import { FiSettings, FiBriefcase, FiUsers } from 'react-icons/fi'

import { RiUser6Line } from 'react-icons/ri'

function ProfileSidebar () {
  return (
        <>
            <div className={'w-[229px] h-[580px] bg-white-base rounded-lg pt-10 hidden md:block'}>
                <MenuHelper
                    linkStyles={{ fontWeight: 'normal', fontSize: 14 }}
                    icons={{
                      organizations: <FiBriefcase className={'text-gray-500'} size={20}/>,
                      settings: <FiSettings className={'text-gray-500'} size={20}/>,
                      'public-profile': <FiUsers className={'text-gray-500'} size={20}/>,
                      profile: <RiUser6Line className={'text-gray-500'} size={20}/>
                    }}
                    menus={ProfileMenus} direction={'inline'}/>
            </div>
        </>
  )
}

export default ProfileSidebar
