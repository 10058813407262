import React from 'react'
import { Button, Form, Input, InputNumber } from 'antd'
import { connect, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import TlaModalFormWrapper from '../../../components/tla-modal-form-wrapper'
import CloseModal from '../../../components/close-modal'
import { FiPlus, FiTrash } from 'react-icons/fi'
import { handleAddQuizQuestion, handleUpdateQuizQuestion } from '../../../actions/admin/quiz-questions/Action'

function QuizQuestionsForm ({ addQuestion, updateQuestion }) {
  const { state } = useLocation()
  const resourceId = state?.data?.id
  const userId = useSelector(state => state.loginReducer?.authUser?.id)
  const formValues = {
    ...state?.data,
    options: state?.data?.options
      ? (Object.values(state?.data.options))
      : [{ title: '', score: '' }]
  }
  const formatBeforeSubmit = (values) => {
    return {
      ...values, userId
    }
  }
  return (
    <TlaModalFormWrapper
      initialValues={formValues} beforeSubmit={formatBeforeSubmit}
      formTitle={`${resourceId ? 'Update' : 'Create'} Question`}
      resourceId={state?.data?.id ?? null}
      onSubmit={state?.data?.id ? updateQuestion : addQuestion}>
      <Form.Item
        name={'title'}
        rules={[
          {
            required: true,
            message: 'Required'
          }
        ]}
        label={'Question Title *'}>
        <Input.TextArea size={'large'} rows={2} placeholder={'Enter a title...'} />
      </Form.Item>
      <Form.List name="options">
        {(fields, { add, remove }) => {
          return (
            <>
              <div className={'flex justify-between items-center mb-2'}>
                <h3 className={'text-sm font-medium'}>Options *</h3>
                <Button type="text"
                  className={'btn btn-light text-sm text-gray-500 hover:!bg-transparent'}
                  onClick={() => {
                    add()
                  }} icon={<FiPlus size={20} />}>
                  Add
                </Button>
              </div>
              {fields.map(({ key, name, ...restField }) => (
                <div key={key} className={'flex gap-2'}>
                  <div className={'w-full'}>
                    <Form.Item
                      {...restField}
                      name={[name, 'title']}
                      rules={[
                        {
                          required: true,
                          message: 'Required'
                        }
                      ]}>
                      <Input size={'large'} placeholder="Option Title" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'score']}
                      rules={[
                        { required: true, message: 'Required' }
                      ]}>
                      <InputNumber min={0} max={10} step={0.1} type='number' className='w-full' size={'large'} placeholder="Option Score (Float)" />
                    </Form.Item>
                  </div>
                  <Button icon={<FiTrash />} className={'btn items-center flex justify-center'}
                    danger size={'large'}
                    onClick={() => remove(name)} />
                </div>
              ))}
            </>
          )
        }}
      </Form.List>
      <div className={'flex gap-2'}>
        <div className={'w-full'}>
          <CloseModal />
        </div>
        <Form.Item className={'w-full'}>
          <Button className={'btn-primary w-full'} size={'large'} htmlType="submit">
            {`${resourceId ? 'Update' : 'Create'}`} Question
          </Button>
        </Form.Item>
      </div>
    </TlaModalFormWrapper>
  )
}

QuizQuestionsForm.propTypes = {
  addQuestion: PropTypes.func.isRequired,
  updateQuestion: PropTypes.func.isRequired
}

/**
 * @param state
 * @returns {{skills: ([]|*)}}
 */
const mapStateToProps = (state) => ({
  quizQuestions: state.adminQuizQuestionsReducer.quizQuestions
})

/**
 *
 * @param dispatch
 * @returns {{addQuestion: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  addQuestion: (data) => dispatch(handleAddQuizQuestion(data)),
  updateQuestion: (questionId, data) => dispatch(handleUpdateQuizQuestion(questionId, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(QuizQuestionsForm)
