import api from '../../../utils/api'
import { addQuizQuestion, allQuizQuestions, deleteQuizQuestion, updateQuizQuestion } from './ActionCreator'

/**
 * Store a newly created resource in storage.
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAddQuizQuestion = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/quiz-questions', data).then((res) => {
      dispatch(addQuizQuestion(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAllQuizQuestions = (pageNumber = 1, pageSize = 15) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/quiz-questions?page=${pageNumber}&pageSize=${pageSize}`).then((res) => {
      dispatch(allQuizQuestions(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Update the specified resource in storage.
 * @param quizQuestionId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleUpdateQuizQuestion = (quizQuestionId, data) => (dispatch) => {
  const updatePromise = new Promise((resolve, reject) => {
    api().put(`/quiz-questions/${quizQuestionId}`, data, {
    }).then((res) => {
      dispatch(updateQuizQuestion(res.data))
      handleGetAllQuizQuestions()
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
  const getQuizQuestionPromise = new Promise((resolve, reject) => {
    api().get(`/quiz-questions?page=${1}&pageSize=15`).then((res) => {
      dispatch(allQuizQuestions(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
  return updatePromise.then((result1) => {
    return getQuizQuestionPromise
  }).then((result2) => {})
}

/**
 * Remove the specified resource from storage.
 * @returns {function(*): Promise<unknown>}
 * @param quizQuestionId
 */
export const handleDeleteQuizQuestion = (quizQuestionId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().delete(`/quiz-questions/${quizQuestionId}`).then((res) => {
      dispatch(deleteQuizQuestion(quizQuestionId))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
