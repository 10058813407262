import api from '../../../utils/api'
import { addContact, allContacts, deleteContact, updateContact } from './ActionCreator'

/**
 * Store a newly created resource in storage.
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAddContact = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/contact/addContact', data).then((res) => {
      dispatch(addContact(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const handleMakeRequest = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().put('/contact/feedback-request-after-one-month', data).then((res) => {
      dispatch(addContact(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const handleSendReminder = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/contact/send-reminder', data).then((res) => {
      // dispatch(addContact(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAllContacts = (userId, pageNumber = 1, pageSize = 15) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/contact/all?userId=${userId}&page=${pageNumber}&pageSize=${pageSize}`).then((res) => {
      dispatch(allContacts(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Update the specified resource in storage.
 * @param contactId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleUpdateContact = (contactId, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().put(`/contact/${contactId}`, data, {
      // headers: { 'Content-type': 'multipart/form-data' }
    }).then((res) => {
      dispatch(updateContact(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Remove the specified resource from storage.
 * @returns {function(*): Promise<unknown>}
 * @param contactId
 */
export const handleDeleteContact = (contactId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().delete(`/contact/${contactId}`).then((res) => {
      dispatch(deleteContact(contactId))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const handleAddFeedback = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/emotions', data).then((res) => {
      // dispatch(addFeedback(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
