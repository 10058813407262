import React from 'react'
import Header from '../../dashboard/layout/header'
import { HeaderMenus, MobileMenus } from '../../../utils/menu-items'
import { Outlet } from 'react-router-dom'
import MobileMenu from '../../dashboard/layout/mobile-menu'
import { Affix } from 'antd'

function GameLayout () {
  return (
        <div className={ 'h-screen' }>
            <Affix offsetTop={ 0 }>
                <div className={ 'max-w-screen-2xl mx-auto border-b shadow-sm' }>
                    <Header menuItems={ HeaderMenus }/>
                </div>
            </Affix>
            <div className="bg-white-base py-4">
                <div className={ 'max-w-screen-md mx-auto' }>
                    <div className={'mb-32 md:mb-0'}>
                        <Outlet/>
                    </div>
                    <div className={ 'block md:hidden fixed pr-4 z-[1000]' }>
                        <MobileMenu fontSize={ 16 } menuItems={ MobileMenus }/>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default GameLayout
