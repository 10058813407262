import React from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function FeedbackContinue ({ doContinue, isSelfAssessment }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const setAssessed = () => {
    if (isSelfAssessment) {
      dispatch({ type: 'UPDATE_SELF_ASSESSED', payload: true })
      navigate('/dashboard/overview/self')
    } else {
      navigate('/feed')
    }
  }

  return (
      <div className={'pb-[40px]'}>
          <div className={'mb-[70px] px-3 md:px-0'}>
              <p className={'font-normal text-lg'}>Thank you for your time. You have submitted 10 questions.</p>
              <br/>
              <br/>
              <br/>
              <p className={'font-normal text-lg'}>Would you like to answer 10 more questions?</p>
          </div>
          <div className={'mx-auto w-fit mb-10 flex gap-3 md:gap-10'}>
              <Button onClick={setAssessed} size={'large'} className={'btn w-fit md:w-[154px]'}>
                  I am done
              </Button>
              <Button onClick={doContinue} size={'large'} className={'btn btn-primary-600 w-[185px]'}>
                  Continue
              </Button>
          </div>
      </div>
  )
}

FeedbackContinue.propTypes = {
  isSelfAssessment: PropTypes.bool,
  doContinue: PropTypes.func
}

export default FeedbackContinue
