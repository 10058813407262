import React from 'react'
import { Image } from 'antd'
import { useSelector } from 'react-redux'
import DefaultImage from '../../assets/images/default.png'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const ProfileInfo = ({ text, showTitle }) => {
  const avatar = DefaultImage
  const profile = useSelector(state => state.loginReducer?.authUserInfo)
  const selectedOrg = useSelector(state => state.loginReducer?.selectedOrg)

  // eslint-disable-next-line react/prop-types
  const InfoContent = ({ tag }) => (
        <div className={'flex items-center gap-2 border-b border-gray-100 py-3 px-4'}>
            <div>
                <Image alt={profile?.userInformation?.name}
                       src={profile?.userInformation?.photo !== 'default.png' ? profile?.userInformation?.photo : avatar}
                       preview={false}
                       className={'rounded-full !h-10 !w-10'}/>
            </div>
            <div>
                <p className={'text-sm font-medium text-gray-700'}>{profile?.userInformation?.name}</p>
                <p className={'text-xs md:text-sm font-normal text-gray-500'}>{tag}</p>
            </div>
        </div>
  )

  return (
        <div className={'hidden md:block'}>
            <InfoContent tag={text === 'Organization' ? profile?.email : selectedOrg?.organization?.name}/>
            {
                showTitle &&
                <div className={'px-4 py-2.5'}>
                    <p className={'text-gray-400 text-xs px-2.5'}>Switch to {text}</p>
                </div>
            }

            {
                text !== 'Organization' &&
                <Link to={'/feed'}>
                    <div className={'border-t border-gray-100 hover:bg-gray-50'}>
                        <InfoContent tag={profile?.email}/>
                    </div>
                </Link>
            }
        </div>
  )
}

ProfileInfo.defaultProps = {
  text: 'Organization',
  showTitle: true
}

ProfileInfo.propTypes = {
  text: PropTypes.string,
  showTitle: PropTypes.bool
}

export default ProfileInfo
