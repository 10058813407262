import { Types } from '../actions/authenticate/Types'
import { Types as OrgTypes } from '../actions/user/common/Types'
import { getCookie } from '../utils'

const data = getCookie('authData') ? JSON.parse(getCookie('authData')) : {}

const initialState = {
  authUser: data?.authUser ?? {},
  authUserInfo: data?.authUserInfo ?? { userInformation: {}, userOrg: [] },
  authToken: data?.authToken ?? 'null',
  authenticated: data?.authenticated ?? false,
  assessmentQuestions: data?.assessmentQuestions ?? {
    questions: [],
    receiver_info: {}
  },
  selectedOrg: {}
}

/**
 *
 * @param state
 * @param action
 *
 * @returns {{authenticated: boolean, authToken: null, authUser: {}}|{authenticated: boolean, authToken: null, authUser: {}}|{authenticated: boolean, authToken, authUser: *}}
 */
export default function loginReducer (state = initialState, action) {
  switch (action.type) {
    case Types.AUTHENTICATE: {
      const authData = {
        authenticated: action.payload.user.verified,
        authUser: action.payload.user,
        authUserInfo: action.payload.user,
        authToken: action.payload.access_token
      }
      document.cookie = `authData=${JSON.stringify(authData)};path=/;`
      return {
        ...state,
        ...authData
      }
    }

    case OrgTypes.VALIDATE_ORG: {
      return {
        ...state,
        authUserInfo: {
          ...state.authUserInfo,
          userOrg: state.authUserInfo.userOrg.concat(action.payload.data.orgUser)
        }
      }
    }

    case OrgTypes.SELECT_ORG: {
      return {
        ...state,
        selectedOrg: action.payload
      }
    }

    case Types.UPDATE_CURRENT_STATUS: {
      return {
        ...state,
        authUser: {
          ...state.authUser,
          userInformation: { ...state.authUser.userInformation, currentStatus: action.payload.currentStatus }
        },
        authUserInfo: {
          ...state.authUserInfo,
          userInformation: { ...state.authUserInfo.userInformation, currentStatus: action.payload.currentStatus }
        }
      }
    }

    case Types.UPDATE_SELF_ASSESSED: {
      const authUser = {
        ...state.authUser, selfAssessed: true
      }
      const authData = {
        authenticated: state.authenticated,
        authUser,
        authUserInfo: state.authUserInfo,
        authToken: state.authToken
      }
      document.cookie = `authData=${JSON.stringify(authData)};path=/;`
      if (action.payload) {
        return {
          ...state,
          authUser: {
            ...state.authUser, selfAssessed: true, assessmentQuestions: []
          }
        }
      }
      return state
    }

    case Types.GET_ASSESSMENT_QUESTIONS: {
      return {
        ...state,
        assessmentQuestions: action.payload.data
      }
    }

    case Types.GET_USER_ASSESSMENT_QUESTIONS: {
      return {
        ...state,
        assessmentQuestions: action.payload.data
      }
    }

    case Types.GET_PROFILE: {
      return {
        ...state,
        authUserInfo: { ...state.authUserInfo, userInformation: action.payload.data.user.userInformation },
        authUser: { ...state.authUser, userInformation: action.payload.data.user.userInformation }
      }
    }

    case Types.INCREMENT_GIVEN_ASSESSMENT: {
      return {
        ...state,
        authUser: {
          ...state.authUser,
          userInformation: {
            ...state.authUser.userInformation,
            assessmentsGivenTotal: state.authUser.userInformation.assessmentsGivenTotal + 1
          }
        }
      }
    }
    // state.authUser.userInformation.assessmentsGivenTotal

    case Types.UPDATE_PROFILE: {
      const info = action.payload.data.user
      return {
        ...state,
        authUserInfo: { ...state.authUserInfo, userInformation: info }
      }
    }

    case Types.REMOVE_AUTH: {
      const authData = {
        authenticated: false,
        authUser: {},
        authUserInfo: {},
        authToken: null
        // assessmentQuestions: []
      }
      localStorage.setItem('initLogout', true)
      document.cookie = `authData=${JSON.stringify(authData)};path=/;`

      return {
        ...state,
        ...authData
      }
    }

    default:
      return state
  }
}
