import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router'
import TlaTableWrapper from '../../../components/table/tla-table-wrapper'
import { Spin, Table } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TlaEdit from '../../../components/tla-edit'
import { FiEdit2 } from 'react-icons/fi'
import TlaConfirm from '../../../components/tla-confirm'
import { TlaSuccess } from '../../../utils/messages'
import {
  handleDeleteSkillRelationship,
  handleGetAllSkillRelationships
} from '../../../actions/admin/skill-relationships/Action'

const SkillRelationships = ({ getSkillRelations, skillRelations, deleteSkillRelation }) => {
  const { setPageInfo } = useOutletContext()

  const { data } = skillRelations
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setPageInfo({
      title: 'Skill Relationships',
      buttonText: 'Add Skill Relationship',
      buttonLink: 'Add Skills',
      subTitle: '',
      helpingText: '',
      showSearch: true
    })
    getSkillRelations().then(() => setLoading(false))
  }, [])

  return (
        <div className={'pb-4'}>
            <Spin spinning={loading}>
                <TlaTableWrapper data={data} numberColumn={true} showHeader={false}
                                 callbackFunction={() => {}}>
                    <Table.Column title={'Parent Skill'} dataIndex={['parentSkill', 'title']}/>
                    <Table.Column title={'Child Skill'} dataIndex={['childSkill', 'title']}/>
                    <Table.Column title={'Skill Type'} dataIndex={''}/>
                    <Table.Column title={'Action'} render={((record) => (
                        <div className={'flex items-center'}>
                            <TlaConfirm title={'Delete Skill'}
                                        fullText={'Do you really want to delete this query?'}
                                        callBack={() => {
                                          deleteSkillRelation(record.id)
                                            .then(() => TlaSuccess('Skill Relation Deleted'))
                                        }}/>
                            <TlaEdit data={record} icon={<FiEdit2 className={'icon'}/>} link={'form'}/>
                        </div>
                    ))}/>
                </TlaTableWrapper>
            </Spin>
        </div>
  )
}

SkillRelationships.propTypes = {
  getSkillRelations: PropTypes.func.isRequired,
  deleteSkillRelation: PropTypes.func.isRequired,
  skillRelations: PropTypes.object.isRequired
}

/**
 * @param state
 * @returns {{skills: *}}
 */
const mapStateToProps = (state) => ({
  skillRelations: state.adminSkillRelationsReducer.skillRelations
})

/**
 *
 * @param dispatch
 * @returns {{getSkills: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  getSkillRelations: (pageNumber) => dispatch(handleGetAllSkillRelationships(pageNumber)),
  deleteSkillRelation: (skillId) => dispatch(handleDeleteSkillRelationship(skillId))
})

export default connect(mapStateToProps, mapDispatchToProps)(SkillRelationships)
