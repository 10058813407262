import React from 'react'
import { TlaModal } from '../pop-ups/tla-modal'
import GiveFeedback from '../../pages/feedback/give-feedback'
import GoBackHome from '../../pages/feedback/go-back-home'

function SelfAssessment () {
  return (
        <TlaModal close={false} width={877}>
            <div className={'pt-2'}>
                <GoBackHome/>
            </div>
            <GiveFeedback isQuickAction={true} isSelfAssessment/>
        </TlaModal>
  )
}

export default SelfAssessment
