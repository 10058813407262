import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function addOrganization (payload) {
  return {
    type: Types.NEW_ORGANIZATION,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function allOrganizations (payload) {
  return {
    type: Types.ALL_ORGANIZATIONS,
    payload
  }
}

/**
 *
 * @param id
 * @returns {{organization, type: string}}
 */
export function deleteOrganization (id) {
  return {
    type: Types.DELETE_ORGANIZATION,
    id
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function updateOrganization (payload) {
  return {
    type: Types.UPDATE_ORGANIZATION,
    payload
  }
}
