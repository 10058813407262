import React, { useEffect, useState } from 'react'
import TlaTableWrapper from '../../components/table/tla-table-wrapper'
import { Radio, Spin, Table } from 'antd'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { handleGetAllFeedbacks } from '../../actions/user/feedbacks/Action'
import { connect } from 'react-redux'
import { formatDate } from '../../utils'
import TlaImage from '../../components/tla-image'
import { handleGetProfile } from '../../actions/authenticate/Actions'
import SidebarForm from '../dashboard/layout/sidebar-form'
import Contacts from '../contacts'

function Feedback ({ feedbacks, getFeedbacks, userId, getProfile }) {
  const [loading, setLoading] = useState(true)
  const [requestType, setRequestType] = useState('received')

  const options = [
    {
      label: 'Received',
      value: 'received'
    },
    {
      label: 'Sent',
      value: 'sent'
    }
  ]
  const changeRequestTypeTab = ({ target: { value } }) => {
    setRequestType(value)
  }
  useEffect(() => {
    getFeedbacks(userId).then(() => {
      setLoading(false)
    })
    getProfile()
  }, [])

  return (
    <React.Fragment>
      <div className={'md:block xl:hidden'}>
        <SidebarForm col={'row'} />
      </div>
      <div className={'bg-white-base rounded-lg p-[13px] pb-6'}>
        <h1 className={'font-medium text-3xl leading-[38px] text-gray-900'}>Requests</h1>
        <Radio.Group className='my-5 dashboard-radio-checked-btn' buttonStyle='outline' options={options} onChange={changeRequestTypeTab} value={requestType} optionType="button" />
        {
          requestType === 'received'
            ? (
              <div className={'rounded-lg shadow-2.5xl pb-1'}>
                <div className={'border-b'}>
                  <p className={'font-normal text-base leading-6 text-gray-500 p-5'}>
                    The following are the requests made by your feedbacks to seek your feedback.
                  </p>
                </div>
                <Spin spinning={loading}>
                  <TlaTableWrapper numberColumnTitle={'Sr. No.'}
                    meta={feedbacks?.meta} showHeader={false} fetchId={userId}
                    callbackFunction={getFeedbacks} data={feedbacks?.data}>
                    <Table.Column width={250} title={'Name'} render={(text, record) => (
                      <div className={'flex gap-2 items-center'}>
                        <TlaImage photo={record?.mainUser?.userInformation?.photo} />
                        <p className={'text-gray-900 text-sm'}>
                          {record?.mainUser?.userInformation?.name}
                        </p>
                      </div>
                    )} />
                    <Table.Column responsive={['md']} title={'Date Requested'} render={(text, record) => (
                      <>{formatDate(record.requestDate)}</>
                    )} />
                    <Table.Column responsive={['md']} title={'Date Assessed'} render={(text, record) => (
                      <>{formatDate(record.lastAssessDate)}</>
                    )} />
                    <Table.Column title={'Actions'} render={(text, record) => (
                      <Link
                        to={'/requests/give-feedback'}
                        state={record}
                        className={'py-2 px-3.5 bg-primary-50 rounded-lg'}>
                        <span className={'underline text-primary-700 text-sm'}>Give&nbsp;Feedback</span>
                      </Link>
                    )} />
                  </TlaTableWrapper>
                </Spin>
              </div>
              )
            : (
              <Contacts />
              )
        }
      </div>

    </React.Fragment>
  )
}

Feedback.propTypes = {
  feedbacks: PropTypes.object.isRequired,
  getFeedbacks: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired
}

const mapStateToProps = (state) => ({
  feedbacks: state.feedbacksReducer.feedbacks,
  userId: state.loginReducer.authUser.id
})

const mapDispatchToProps = (dispatch) => ({
  getProfile: () => dispatch(handleGetProfile()),
  getFeedbacks: (userId, page) => dispatch(handleGetAllFeedbacks(userId, page))
})

export default connect(mapStateToProps, mapDispatchToProps)(Feedback)
