import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { handleGetUserModuleLesson, handleGetUserModuleProgress, handleUpdateUserLessonProgress } from '../../actions/user/common/Action'
import { Spin } from 'antd'
import { RiVipCrownLine } from 'react-icons/ri'
import { useLocation, useNavigate, useParams } from 'react-router'
import MultipleChoiceQuestion from './questions/multiple-choice-question'
import { Link } from 'react-router-dom'
import { FiBookOpen, FiCheck, FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import RatingQuestionCourses from './questions/rating-question-courses'
import { TlaError } from '../../utils/messages'
import MultiSelectQuestion from './questions/multi-select-question'
import ScenarioQuestion from './questions/scenario-question'
import { QuestionIcon } from '../../utils/svgs'
// import CoursesMobileLowerbar from '../../components/public-components/courses-mobile-lowerbar'

function ModuleLesson ({ lesson, progress, getModuleLesson, getModuleProgress, updateLessonProgress }) {
  const [loading, setLoading] = useState(true)
  const { courseId, moduleId, contentId } = useParams()
  // eslint-disable-next-line no-unused-vars
  const [questionSubmitted, setQuestionSubmitted] = useState(false)
  const { state } = useLocation()
  const courseModuleDetails = { courseId: Number(courseId), moduleId: Number(moduleId), questionId: lesson.content?.id }
  const navigate = useNavigate()
  useEffect(() => {
    getModuleProgress(moduleId)
    getModuleLesson(courseId, moduleId, contentId).then((res) => {
      setLoading(false)
    })
  }, [])
  const getLesson = (newContentId) => {
    setLoading(true)
    navigate(`/courses/${courseId}/module/${moduleId}/lessons/${newContentId}`, { state })
    getModuleLesson(courseId, moduleId, newContentId).then((res) => {
      getModuleProgress(moduleId)
      setLoading(false)
      setQuestionSubmitted(false)
    })
  }
  const markAsRead = (lessonId) => {
    setQuestionSubmitted(prev => !prev)
    setLoading(true)
    updateLessonProgress({ progress: 'COMPLETED', type: lesson.contentType }, lessonId).then(() => {
      getModuleLesson(courseId, moduleId, contentId)
    })
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        TlaError('Something went wrong from lesson completion')
      })
    setTimeout(() => {
      getModuleProgress(moduleId)
      setLoading(false)
    }, 200)
  }
  const getQuestionComponent = () => {
    if (lesson.content.type === 'RATING') return <RatingQuestionCourses questionSubmitted={() => markAsRead(lesson.content?.id)} question={lesson.content} courseModuleDetails={courseModuleDetails} />
    else if (lesson.content.type === 'MCQ') return <MultipleChoiceQuestion questionSubmitted={() => markAsRead(lesson.content?.id)} question={lesson.content} courseModuleDetails={courseModuleDetails} />
    else if (lesson.content.type === 'SCENARIO') return <ScenarioQuestion questionSubmitted={() => markAsRead(lesson.content?.id)} question={lesson.content} courseModuleDetails={courseModuleDetails} />
    else if (lesson.content.type === 'MULTI_SELECT') return <MultiSelectQuestion questionSubmitted={questionSubmitted} question={lesson.content} courseModuleDetails={courseModuleDetails} />
    else return <h1 className='text-red-500 font-bold bg-red-50'>Question Not Found</h1>
  }
  return (
    <React.Fragment>
      <div className="bg-white-base rounded-md p-2 md:p-5">
        <Spin spinning={loading}>
          <div className="page-header">
            <Link to={`/courses/${courseId}`} className='inline-flex items-center gap-1 font-medium text-sm text-gray-500 cursor-pointer'><FiChevronLeft /> Back</Link>
          </div>
          <div className="page-body">
            <div className="my-1">
              <div className="flex gap-3 items-start mt-2">
                <div className="w-full md:w-3/5">
                  <h3 className="text-[#175CD3] font-semibold mb-2">{state?.categoryName}</h3>
                  <h1 className="text-xl font-semibold leading-relaxed">{lesson.content?.title}</h1>
                  {
                    lesson.contentType !== 'QUESTION'
                      ? lesson.behindPayWall
                        ? <div className="bg-gray-100 bg-opacity-[0.99] flex flex-column flex-wrap justify-center items-center absolute top-0 left-0 w-full h-full">
                          <div className="w-1/2 text-center">
                            <h2 className="text-4xl font-semibold">Get Full Access</h2>
                            <h3 className="text-lg my-3">Subscribe to get full access to all the courses</h3>
                            <div className='w-full'>
                              <button className="bg-green-100 text-green-600 font-medium border border-green-600 rounded-md py-3 px-5">
                                <div className="flex gap-2">
                                  <RiVipCrownLine className='font-semibold text-xl' /> Enroll For Free
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                        : <div>
                          <div className='my-4 no-font-family prose' dangerouslySetInnerHTML={{ __html: lesson.content?.lesson }}></div>
                        </div>
                      : <div>
                          {getQuestionComponent()}
                        </div>
                  }
                  <div className="hidden md:flex gap-4 justify-between my-8">
                    <div>
                      {
                        lesson.contentType !== 'QUESTION' && (lesson.content?.progress === undefined || lesson.content?.progress !== 'COMPLETED') &&
                        <button onClick={() => markAsRead(lesson.content?.id)} className='border border-blue-500 text-white-base bg-blue-500 hover:text-white py-2 px-4 rounded transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rouned-xl'>
                          Mark As Done
                        </button>
                      }
                      {
                        lesson.contentType === 'QUESTION' && lesson.content?.type === 'MULTI_SELECT' && (lesson.content?.progress !== 'COMPLETED' || !lesson.content.completed) &&
                        <button onClick={() => markAsRead(lesson.content?.id)} className='border border-blue-500 text-white-base bg-blue-500 hover:text-white py-2 px-4 rounded transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rouned-xl'>
                          Submit
                        </button>
                      }
                    </div>
                    <div className='flex gap-4'>
                      <button className={`border-2 font-medium rounded-md py-2 px-4 ${lesson.previousContentId === undefined ? 'text-gray-200 border-gray-100 cursor-not-allowed' : 'border-gray-300'}`} disabled={lesson.previousContentId === undefined} onClick={() => getLesson(lesson.previousContentId)}>Back</button>
                      <button className={`border-2 font-medium rounded-md py-2 px-4 ${lesson.nextContentId === undefined ? 'text-gray-200 border-gray-100 cursor-not-allowed' : 'border-gray-300'}`} disabled={lesson.nextContentId === undefined} onClick={() => getLesson(lesson.nextContentId, lesson.content?.id, lesson.contentType)}>Next</button>
                    </div>
                  </div>
                </div>
                <div className="w-2/5 p-6 bg-gray-50 rounded-xl hidden md:block">
                  <div className="flex justify-between items-center border-b-2 border-gray-200">
                    <h2 className='text-lg font-medium py-4'>Your Progress</h2>
                  </div>
                  <ul className="list-none mt-3 p-3 pt-0">
                    {
                      progress?.map(content => (
                        <li key={content.contentId}>
                          <Link className={`my-2 ${content.contentId === Number(contentId) ? '' : 'underline'} flex items-center gap-1 text-gray-600`} onClick={() => getLesson(content.contentId)} state={{ categoryName: state?.categoryName, moduleContents: state?.moduleContents }} to={`/courses/${courseId}/module/${moduleId}/lessons/${content.contentId}`}>
                            <span className='w-4 h-4 block'>
                              {
                                content.progress === 'COMPLETED'
                                  ? <FiCheck className='bg-green-600 text-white-base inline-block w-4 h-4 rounded-full text-[16px]' />
                                  : content.contentType === 'LESSON'
                                    ? <FiBookOpen className={`inline-block w-4 h-4 ${content.contentId === Number(contentId) ? 'text-blue-300' : 'text-gray-400'}`} />
                                    : <QuestionIcon className='inline-block w-4 h-4' fill={content.contentId === Number(contentId) ? '#2E90FA' : '#98A2B3'} />
                              }
                            </span>
                            <span className={`pt-1 ${content.contentId === Number(contentId) ? 'text-blue-300' : 'text-gray-600'}`}>
                              {
                                content.contentType === 'LESSON' ? content?.lesson?.title : content?.question?.questionTitleSelf
                              }
                            </span>
                          </Link>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className='block md:hidden'>
            <div className='fixed z-[1000] bottom-16 h-[62px] left-0 w-full bg-white-base border-t border-gray-300 p-1 flex justify-between items-center px-2'>
              <div className="flex gap-1 px-3">
                <button className={`inline-flex p-3 items-center gap-1 font-medium text-lg cursor-pointer ${lesson.previousContentId === undefined ? 'text-gray-200 cursor-not-allowed' : 'text-gray-500'}`} disabled={lesson.previousContentId === undefined} onClick={() => getLesson(lesson.previousContentId)}><FiChevronLeft /></button>
                <button className={`inline-flex p-3 items-center gap-1 font-medium text-lg cursor-pointer ${lesson.nextContentId === undefined ? 'text-gray-200 cursor-not-allowed' : 'text-gray-500'}`} disabled={lesson.nextContentId === undefined} onClick={() => getLesson(lesson.nextContentId)}><FiChevronRight /></button>
              </div>
              <div>
                {
                  lesson.contentType !== 'QUESTION' && (lesson.content?.progress === undefined || lesson.content?.progress !== 'COMPLETED') &&
                  <button onClick={() => markAsRead(lesson.content?.id)} className='border border-blue-500 text-white-base bg-blue-500 hover:text-white py-2 px-4 rounded transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rouned-xl'>
                    Mark As Done
                  </button>
                }
                {
                  lesson.contentType === 'QUESTION' && lesson.content?.type === 'MULTI_SELECT' && (lesson.content?.progress !== 'COMPLETED' || !lesson.content.completed) &&
                  <button onClick={() => markAsRead(lesson.content?.id)} className='border border-blue-500 text-white-base bg-blue-500 hover:text-white py-2 px-4 rounded transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rouned-xl'>
                    Submit
                  </button>
                }
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </React.Fragment>
  )
}

ModuleLesson.propTypes = {
  lesson: PropTypes.object.isRequired,
  progress: PropTypes.array.isRequired,
  getModuleLesson: PropTypes.func.isRequired,
  updateLessonProgress: PropTypes.func.isRequired,
  getModuleProgress: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  lesson: state.commonReducer.lesson ?? {},
  progress: state.commonReducer.lessons ?? []
})

const mapDispatchToProps = (dispatch) => ({
  getModuleLesson: (courseId, moduleId, contentId) => dispatch(handleGetUserModuleLesson(courseId, moduleId, contentId)),
  updateLessonProgress: (data, contentId) => dispatch(handleUpdateUserLessonProgress(data, contentId)),
  getModuleProgress: (moduleId) => dispatch(handleGetUserModuleProgress(moduleId))
})

export default connect(mapStateToProps, mapDispatchToProps)(ModuleLesson)
