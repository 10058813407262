import React from 'react'
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

function WisiwigEditor ({ value, setValue }) {
  return (
        <div>
            <ReactQuill style={ { height: '200px' } } theme="snow" value={ value } onChange={ setValue }/>
        </div>
  )
}

WisiwigEditor.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string
}

export default WisiwigEditor
