import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function addQuizQuestion (payload) {
  return {
    type: Types.NEW_QUIZ_QUESTION,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function allQuizQuestions (payload) {
  return {
    type: Types.ALL_QUIZ_QUESTIONS,
    payload
  }
}

/**
 *
 * @param post
 * @returns {{post, type: string}}
 */
export function deleteQuizQuestion (quizQuestionId) {
  return {
    type: Types.DELETE_QUIZ_QUESTION,
    quizQuestionId
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function updateQuizQuestion (payload) {
  return {
    type: Types.UPDATE_QUIZ_QUESTION,
    payload
  }
}
