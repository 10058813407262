import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function addCourseCategory (payload) {
  return {
    type: Types.NEW_COURSE_CATEGORY,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function allCourseCategories (payload) {
  return {
    type: Types.ALL_COURSE_CATEGORIES,
    payload
  }
}

/**
 *
 * @param post
 * @returns {{post, type: string}}
 */
export function deleteCourseCategory (category) {
  return {
    type: Types.DELETE_COURSE_CATEGORY,
    category
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function updateCourseCategory (payload) {
  return {
    type: Types.UPDATE_COURSE_CATEGORY,
    payload
  }
}
