import { combineReducers } from 'redux'
import sessionStorage from 'redux-persist/lib/storage/session'
import { persistReducer } from 'redux-persist'
import loginReducer from './login-reducer'
import jobQueryReducer from './job-query-reducer'
import jobApplicationReducer from './job-application-reducer'
import dashboardReducer from './dashboard-reducer'
import adminSkillsReducer from './admin/admin-skills-reducer'
import adminQuestionsReducer from './admin/admin-questions-reducer'
import adminSkillRelationsReducer from './admin/admin-skill-relation-reducer'
import adminPostsReducer from './admin/admin-posts-reducer'
import adminContentPostsReducer from './admin/admin-content-posts-reducer'
import adminModulesReducer from './admin/admin-modules-reducer'
import adminLessonsReducer from './admin/admin-lessons-reducer'
import adminModuleLessonsReducer from './admin/admin-module-lessons-reducer'
import adminLessonQuestionsReducer from './admin/admin-lesson-questions-reducer'
import adminCoursesReducer from './admin/admin-courses-reducer'
import adminCourseCategoriesReducer from './admin/admin-course-categories-reducer'
import adminCourseModulesReducer from './admin/admin-course-modules-reducer'
import adminPersonalitiesReducer from './admin/admin-personalities-reducer'
import adminQuizFormsReducer from './admin/admin-quiz-forms-reducer'
import adminQuizQuestionsReducer from './admin/admin-quiz-questions-reducer'
import adminQuizFormQuestionsReducer from './admin/admin-quiz-forms-questions-reducer'

import contactsReducer from './user/contacts-reducer'

import adminUsersReducer from './admin/admin-users-reducer'
import feedbacksReducer from './user/feedbacks-reducer'
import commonReducer from './user/common-reducer'
import chatsReducer from './user/chats-reducer'
import organizationReducer from './organization-reducer'
import orgUsersReducer from './admin/org-users-reducer'

const persistConfig = {
  key: 'root',
  storage: sessionStorage
}

const appReducer = combineReducers({
  loginReducer,
  jobQueryReducer,
  jobApplicationReducer,
  dashboardReducer,
  adminSkillsReducer,
  adminQuestionsReducer,
  adminSkillRelationsReducer,
  adminPostsReducer,
  adminContentPostsReducer,
  adminModulesReducer,
  adminLessonsReducer,
  adminModuleLessonsReducer,
  adminLessonQuestionsReducer,
  adminCoursesReducer,
  adminCourseCategoriesReducer,
  adminCourseModulesReducer,
  adminPersonalitiesReducer,
  adminQuizFormsReducer,
  adminQuizQuestionsReducer,
  adminQuizFormQuestionsReducer,
  adminUsersReducer,
  contactsReducer,
  feedbacksReducer,
  commonReducer,
  chatsReducer,
  organizationReducer,
  orgUsersReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'REMOVE_AUTH') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default persistReducer(persistConfig, rootReducer)
