import React from 'react'
import { Progress } from 'antd'
import PropTypes from 'prop-types'
import { createGlobalStyle } from 'styled-components'
import { getPercentage } from '../utils'

const GlobalStyles = createGlobalStyle`
  .mobile-bar {
    display: none;
  }
  @media only screen and (max-width: 600px) {
    .desktop-bar {
      display: none;
    }
    .mobile-bar {
      display: block;
    }
  }
`
function TlaProgress ({ partialValue, totalValue, color, responsive }) {
  const percent = getPercentage(partialValue, totalValue)
  return (
        <div>
            <GlobalStyles/>
            <div className={responsive ? 'desktop-bar' : ''}>
                <Progress strokeColor={color} format={(percent) => (percent / 10).toFixed(1)} percent={percent * 10}/>
            </div>
            {responsive && <span className={'mobile-bar'}>{parseFloat(percent).toFixed(1)}</span>}
        </div>
  )
}

TlaProgress.defaultProps = {
  color: '#1890ff',
  totalValue: 10,
  responsive: true
}

TlaProgress.propTypes = {
  partialValue: PropTypes.number,
  totalValue: PropTypes.number,
  color: PropTypes.string,
  responsive: PropTypes.bool
}

export default TlaProgress
