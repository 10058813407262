import React, { useState } from 'react'
import { Button, Form, Spin } from 'antd'
import PropTypes from 'prop-types'
import { TlaError, TlaNotification, TlaSuccess } from '../utils/messages'
import { useNavigate } from 'react-router-dom'

function TlaFormWrapper (props) {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const { onSubmit, initialValues, children, file, btnBlock, buttonText, afterSubmit, param, raw, formName } = props

  const onFinish = (values) => {
    localStorage.setItem('initLogout', false)
    setLoading(true)
    const formData = new FormData()

    if (file) {
      formData.append('file', file)
    }

    for (const key in values) {
      if (Object.prototype.hasOwnProperty.call(values, key)) {
        formData.append(key, values[key])
      }
    }

    (param ? onSubmit(raw ? values : formData, param) : onSubmit(raw ? values : formData)).then((res) => {
      setLoading(false)
      TlaSuccess(res?.data?.message ?? '')
      form.resetFields()
      afterSubmit && navigate(afterSubmit)
    }).catch((error) => {
      setLoading(false)
      if (error?.response?.data?.errors !== undefined) {
        TlaNotification(error?.response?.data?.errors)
      } else {
        console.log(error)
        TlaError(error?.response?.data?.message)
      }
    })
  }

  return (
        <Spin spinning={loading}>
          <Form
              form={form}
              onFinish={(values) => {
                onFinish(values)
              }}
              layout="vertical"
              name={formName}
              requiredMark={false}
              initialValues={initialValues}>
            {children}
            <div>
              <Button
                  size={'large'}
                  block={btnBlock}
                  className={'btn-primary'}
                  htmlType="submit">
                {buttonText}
              </Button>
            </div>
          </Form>
        </Spin>
  )
}

TlaFormWrapper.defaultProps = {
  file: null,
  width: 520,
  btnBlock: true,
  afterSubmit: null,
  raw: false,
  param: null,
  formName: 'formName'
}

TlaFormWrapper.propTypes = {
  initialValues: PropTypes.object,
  submitValues: PropTypes.object,
  formTitle: PropTypes.string,
  buttonText: PropTypes.string,
  formName: PropTypes.string,
  onSubmit: PropTypes.func,
  file: PropTypes.any,
  width: PropTypes.any,
  btnBlock: PropTypes.bool,
  afterSubmit: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  children: PropTypes.any,
  raw: PropTypes.bool,
  param: PropTypes.any
}

export default TlaFormWrapper
