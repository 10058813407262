import React from 'react'
import PropTypes from 'prop-types'
import { Form, Select } from 'antd'

const TlaSelect = (props) => {
  const { options, optionKey, label, name, required, hasAll, onChange, disabled, onFocus, placeholder, defaultValue, mode, customClass, fieldNames, optionClass } = props
  return (
        <>
          <Form.Item
              label={label}
              name={name}
              className={`mb-0 ${customClass}`}
              rules={[
                {
                  required,
                  message: 'Required'
                }
              ]}>
              <Select size={'large'}
                      disabled={disabled}
                      onChange={onChange} onFocus={onFocus}
                      placeholder={placeholder}
                      defaultValue={defaultValue}
                      mode={mode}
                      fieldNames={fieldNames}
                      filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      showSearch>
                  {hasAll && <Select.Option value={'all'}>All</Select.Option>}
                  {
                    options.map((option) => (
                        <Select.Option className={optionClass}
                            key={option.id || option.name || option}
                            value={optionKey === 'self' ? option : option.id ?? option.value}>
                            {optionKey === 'self' ? option : option[optionKey]}
                        </Select.Option>
                    ))
                  }
              </Select>
          </Form.Item>
        </>
  )
}
TlaSelect.propTypes = {
  required: false,
  hasAll: false,
  disabled: false,
  defaultValue: 'Select',
  options: [],
  placeholder: 'Select',
  customClass: ''
}

TlaSelect.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  hasAll: PropTypes.bool,
  optionKey: PropTypes.node,
  required: PropTypes.bool,
  name: PropTypes.string,
  mode: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.any,
  customClass: PropTypes.string,
  optionClass: PropTypes.string,
  disabled: PropTypes.bool,
  fieldNames: PropTypes.object,
  options: PropTypes.array
}

export default TlaSelect
