import api from '../../../utils/api'
import { addQuestion, allQuestions, deleteQuestion, updateQuestion, importQuestions } from './ActionCreator'
import { isObject } from '../../../utils/index'
/**
 * Store a newly created resource in storage.
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAddQuestion = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/question', data).then((res) => {
      dispatch(addQuestion(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const handleImportQuestions = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api('multipart/form-data').post('/auth/seed-' + data.type, data).then((res) => {
      dispatch(importQuestions(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAllQuestions = (pageNumber = 1, pageSize, skill) => (dispatch) => {
  skill = isObject(skill) ? skill.skill : null
  return new Promise((resolve, reject) => {
    api().get(`/question/all?&page=${pageNumber}&pageSize=15${skill ? '&skillId=' + skill : ''}`).then((res) => {
      dispatch(allQuestions(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Update the specified resource in storage.
 * @param questionId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleUpdateQuestion = (questionId, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().put(`/question/${questionId}`, data, {
    }).then((res) => {
      dispatch(updateQuestion(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Remove the specified resource from storage.
 * @returns {function(*): Promise<unknown>}
 * @param questionId
 */
export const handleDeleteQuestion = (questionId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().delete(`/question/${questionId}`).then((res) => {
      dispatch(deleteQuestion(questionId))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
