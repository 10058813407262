import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function addPersonality (payload) {
  return {
    type: Types.NEW_PERSONALITY,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function allPersonalities (payload) {
  return {
    type: Types.ALL_PERSONALITIES,
    payload
  }
}

/**
 *
 * @param id
 * @returns {{personality, type: string}}
 */
export function deletePersonality (id) {
  return {
    type: Types.DELETE_PERSONALITY,
    id
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function updatePersonality (payload) {
  return {
    type: Types.UPDATE_PERSONALITY,
    payload
  }
}
