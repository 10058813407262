import { Types } from '../actions/admin/organization/Types'

const initialState = {
  organizations: {
    data: [],
    meta: {}
  }
}

/**
 *
 * @param state
 * @param action
 *
 * @returns {{authenticated: boolean, authToken: null, authUser: {}}|{authenticated: boolean, authToken: null, authUser: {}}|{authenticated: boolean, authToken, authUser: *}}
 */
export default function organizationReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_ORGANIZATION:
      return {
        ...state,
        organizations: {
          ...state.organizations,
          data: state.organizations.data.concat(action.payload.data.organization)
        }
      }
    case Types.ALL_ORGANIZATIONS: {
      return {
        ...state,
        organizations: {
          data: action.payload.data.organizations,
          meta: {
            total: action.payload.data.count,
            from: action.payload.data.page,
            current_page: parseInt(action.payload.data.page),
            to: action.payload.data.pageSize
          }
        }
      }
    }

    case Types.UPDATE_ORGANIZATION:
      return {
        ...state,
        organizations: {
          ...state.organizations,
          data: state.organizations.data.map((org) => {
            return org.id === action.payload.data.organization.id ? action.payload.data.organization : org
          })
        }
      }
    case Types.DELETE_ORGANIZATION: {
      return {
        ...state,
        organizations: {
          ...state.organizations,
          data: state.organizations.data.filter((query) => query.id !== action.id)
        }
      }
    }

    default:
      return state
  }
}
