import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router'
import TlaTableWrapper from '../../../components/table/tla-table-wrapper'
import { Spin, Table } from 'antd'
import PropTypes from 'prop-types'
import { handleDeleteCourse, handleGetAllCourses } from '../../../actions/admin/courses/Action'
import { connect } from 'react-redux'
import TlaConfirm from '../../../components/tla-confirm'
import { TlaSuccess } from '../../../utils/messages'
import TlaEdit from '../../../components/tla-edit'
import { FiEdit2 } from 'react-icons/fi'

const Courses = ({ getCourses, courses, deleteCourse }) => {
  const { setPageInfo } = useOutletContext()
  const { data, meta } = courses
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setPageInfo({
      title: 'Courses',
      buttonText: 'Add Course',
      buttonLink: 'Add Course',
      showImportBtn: false,
      subTitle: '',
      helpingText: '',
      showSearch: false
    })
    getCourses().then(() => setLoading(false))
  }, [])

  return (
        <div className={'pb-4'}>
            <Spin spinning={loading}>
                <TlaTableWrapper
                   meta={meta}
                   numberColumn={true} data={data} showHeader={false}
                   callbackFunction={getCourses}>
                    <Table.Column title={'Course Title'} render={((record) => record.title ?? 'No Title')}/>
                    <Table.Column title={'Paywall'} render={((record) => record.behindPaywall ? 'Yes' : 'No')}/>
                    <Table.Column title={'Actions'} render={((record) => (
                        <div className={'flex items-center'}>
                            <TlaConfirm title={'Delete Course'}
                                        fullText={'Do you really want to delete this Course?'}
                                        callBack={() => {
                                          deleteCourse(record.id).then(() => TlaSuccess('Deleted'))
                                        }}/>
                            <TlaEdit data={record} icon={<FiEdit2 className={'icon'}/>} link={'form'}/>
                        </div>
                    ))}/>
                </TlaTableWrapper>
            </Spin>
        </div>
  )
}

Courses.propTypes = {
  getCourses: PropTypes.func.isRequired,
  deleteCourse: PropTypes.func.isRequired,
  courses: PropTypes.object.isRequired
}

/**
 * @param state
 * @returns {{courses: *}}
 */
const mapStateToProps = (state) => ({
  courses: state.adminCoursesReducer.courses ?? {}
})

/**
 *
 * @param dispatch
 * @returns {{getCourses: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  getCourses: (pageNumber, skillId) => dispatch(handleGetAllCourses(pageNumber, skillId)),
  deleteCourse: (courseId) => dispatch(handleDeleteCourse(courseId))
})

export default connect(mapStateToProps, mapDispatchToProps)(Courses)
