export const Types = {
  AUTHENTICATE: 'AUTHENTICATE',
  UPDATE_CURRENT_STATUS: 'UPDATE_CURRENT_STATUS',
  GET_ASSESSMENT_QUESTIONS: 'GET_ASSESSMENT_QUESTIONS',
  GET_USER_ASSESSMENT_QUESTIONS: 'GET_USER_ASSESSMENT_QUESTIONS',
  REGISTER: 'REGISTER',
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  REMOVE_AUTH: 'REMOVE_AUTH',
  ADD_AUTH: 'ADD_AUTH',
  GET_PROFILE: 'GET_PROFILE',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  INCREMENT_GIVEN_ASSESSMENT: 'INCREMENT_GIVEN_ASSESSMENT',
  UPDATE_SELF_ASSESSED: 'UPDATE_SELF_ASSESSED'
}
