import React, { useState } from 'react'
import TakeUserLevel from '../../public-pages/self-assessment/take-user-level'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Spin } from 'antd'
import { handleUpdateStatus } from '../../../actions/authenticate/Actions'

function SetCurrentStatus ({ onFinish }) {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const setStatus = (value) => {
    setLoading(true)
    dispatch(handleUpdateStatus(value)).then(() => {
      setLoading(false)
      onFinish()
    })
  }
  return (
        <Spin spinning={ loading }>
            <TakeUserLevel
                ifCompleted={ setStatus }
                title={ 'Set your Current Status' }
                subTitle={ 'We need your current status to bring custom status-specific questions for your self assessment.' }
                buttonText={ 'Start Self Assessment' }
            />
        </Spin>
  )
}

SetCurrentStatus.propTypes = {
  onFinish: PropTypes.func.isRequired
}

export default SetCurrentStatus
