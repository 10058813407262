import React from 'react'
import PropTypes from 'prop-types'
import Pagination from 'react-js-pagination'
import { Card, Typography } from 'antd'
import TlaIcon from '../tla-icon'

// eslint-disable-next-line react/prop-types
const NavItem = ({ icon, title, iconFirst }) => (
    <div className={'flex items-center gap-2'}>
        {
            iconFirst
              ? <React.Fragment>{icon} <span className={'hidden md:block'}>{title}</span></React.Fragment>
              : <React.Fragment><span className={'hidden md:block'}>{title}</span> {icon}</React.Fragment>
        }
    </div>
)

function TlaPagination (props) {
  const { meta, loadData, children, showHeader } = props
  return (
        <div className={'mb-4'}>
            {
                showHeader &&
                <Card size={'small'}>
                    <Typography.Text>
                        Showing {meta.from * parseInt(meta.pageSize - 1)} - {meta.to} of {meta.total}
                    </Typography.Text>
                </Card>
            }
            {children}
            {/* { */}
            {/*  Math.ceil(meta.total / meta.pageSize) > 1 */}
            {/*    ? */}
                  <div style={{ marginTop: 10 }}>
                    <Pagination
                        activePage={meta.current_page ?? 1}
                        // itemsCountPerPage={Number(meta.per_page)}
                        itemsCountPerPage={ meta.pageSize !== undefined ? Number(meta.pageSize) : 10 }
                        totalItemsCount={meta.total ?? 0}
                        onChange={loadData}
                        pageRangeDisplayed={8}
                        itemClass="page-item"
                        linkClass="page-link"
                        firstPageText="First"
                        lastPageText="Last"
                        hideFirstLastPages={false}
                        nextPageText={<NavItem icon={<TlaIcon name={'arrow-right'}/>} iconFirst/>}
                        prevPageText={<NavItem icon={<TlaIcon name={'arrow-left'}/>}/>}
                    />
                </div>
            {/*    : '' */}
            {/* } */}
        </div>

  )
}

TlaPagination.defaultProps = {
  meta: {
    from: 0,
    to: 0,
    total: 0
  },
  showHeader: false
}

TlaPagination.propTypes = {
  meta: PropTypes.object.isRequired,
  children: PropTypes.node,
  loadData: PropTypes.func.isRequired,
  showHeader: PropTypes.bool
}

export default TlaPagination
