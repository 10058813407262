import { Types } from '../../actions/admin/lessons/Types'

const initialState = {
  lessons: {
    data: [],
    meta: {}
  },
  lesson: {}
}

export default function adminLessonsReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_LESSON:
      return {
        ...state,
        lessons: {
          ...state.lessons,
          data: [
            action.payload.data.lesson,
            ...state.lessons.data
          ]
        }
      }
    case Types.ALL_LESSONS:
      return {
        ...state,
        lessons: {
          ...state.lessons,
          data: action.payload.data.lessons,
          meta: {
            total: action.payload.data.count,
            current_page: Number(action.payload.data.page),
            page: action.payload.data.page,
            pageSize: action.payload.data.pageSize
          }
        }
      }

    case Types.UPDATE_LESSON:
      return {
        ...state,
        lessons: {
          ...state.lessons,
          data: state.lessons.data.map((lesson) => {
            console.log(lesson, action.payload.data.lesson)
            return lesson.id === action.payload.data.lesson.id ? action.payload.data.lesson : lesson
          })
        }
        // lesson: action.payload.data.upda
      }
    case Types.DELETE_LESSON:
      return {
        ...state,
        lessons: {
          ...state.lessons,
          data: state.lessons.data.filter((lessons) => lessons.id !== action.lesson)
        }
      }

    default:
      return state
  }
}
