import React from 'react'
import PropTypes from 'prop-types'
import FeedbackItem from './feedback-item'
import { createGlobalStyle } from 'styled-components'
import { emotions } from '../../utils/emotions'

const GlobalStyles = createGlobalStyle`
    .search-name {
      color: var(--Gray-900);
    }
    .search-email {
      color: var(--Gray-500);
      font-size: 12px;
    }
`

function Emotions (props) {
  return (
        <div className={'grid grid-cols-3 gap-3 items-end'}>
            <GlobalStyles/>
            {
                emotions.map((icon) => (
                    <div key={icon.name}>
                        <FeedbackItem action={props.action} title={icon.name} icon={icon.ico}/>
                    </div>
                ))
            }
        </div>
  )
}

Emotions.defaultProps = {
  icon: <>ICON</>,
  title: 'Title'
}

Emotions.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  action: PropTypes.func
}

export default Emotions
