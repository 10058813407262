import React from 'react'
import logo from '../../../assets/images/noustrobeta.svg'
import NavProfile from '../../../components/navigation/nav-profile'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import TlaIcon from '../../../components/tla-icon'

function SelfHeader () {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const user = useSelector(state => state.loginReducer.authUser)
  const items = [

    {
      key: 'profile',
      link: '/dashboard/overview/self',
      label: 'Profile'
    },
    {
      key: 'assess',
      link: '/feed',
      label: 'Assess'
    },
    {
      label: 'Learn',
      key: 'learn',
      link: '/learn'
    },
    {
      key: 'nous-gpt',
      link: '/nous-gpt',
      label: 'NousGPT'
    },
    {
      key: 'requests',
      link: '/requests',
      label: 'Requests'
    },
    {
      link: '/courses',
      key: 'courses',
      label: 'Courses'
    }
  ]

  // eslint-disable-next-line react/prop-types
  const LinkItem = ({ children }) => (
        <span rel="opener"
              className={'text-gray-300 cursor-not-allowed rounded-md text-base px-4 py-2.5 font-medium'}>
            {children}
        </span>
  )

  return (
        <div>
            <div className={'flex items-center bg-white-base px-6 md:px-[49px] h-[73px]'}>
                <div onClick={() => {
                  navigate('/feed')
                }} className={'cursor-pointer md:pl-0'}>
                    <img src={logo} width={130} alt={'Nouscard'} height={'auto'}/>
                </div>
                <div className="hidden md:block ml-20">
                    <div className={'flex gap-x-1 ml-4 w-full'}>
                        {
                            user?.role === 'user' &&
                            <React.Fragment>
                                {
                                    items.map(({ key, link, label }) => (
                                        <LinkItem key={key} link={link} active={label === 'Dashboard' ? (pathname === link || pathname === '/dashboard/overview/contact') : pathname === link}>
                                            {label}
                                            {
                                                label === 'Requests' &&
                                                <React.Fragment>
                                                    &nbsp;
                                                    <span
                                                        className={'bg-error-100 text-error-900 px-2.5 py-0.5 rounded-full'}>
                                                        {user?.userInformation?.totalRequests}
                                                    </span>
                                                </React.Fragment>
                                            }
                                        </LinkItem>
                                    ))
                                }
                            </React.Fragment>
                        }
                    </div>
                </div>

                <div className={'flex items-center gap-x-4 ml-auto'}>
                    <a title={'Customer Service'} target={'_blank'}
                       href="https://docs.google.com/forms/d/e/1FAIpQLSfwPVbOwnCE7pxU-8UAKT037SCSHwcmhaH5enYwiiTvE9dK9A/viewform"
                       rel="noreferrer">
                        <TlaIcon name={'customer-service'}/>
                    </a>
                    <a title={'User Guide'} target={'_blank'} href="https://noustro.com/how-it-works/" rel="noreferrer">
                        <TlaIcon name={'questionMark'}/>
                    </a>
                    <div className={'ml-auto'}>
                        <NavProfile/>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default SelfHeader
