import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { handleGetUserContentPosts } from '../../actions/user/common/Action'
import { Divider, Spin } from 'antd'
// import ContactsQuote from '../quotes/contacts-quotes'
import InfiniteScroll from 'react-infinite-scroll-component'
import SidebarForm from '../dashboard/layout/sidebar-form'
import ContentPostCard from '../../components/cards/content-post-card'

function Learn ({ getContentPosts, userContentPosts, totalContentPosts }) {
  const page = useSelector(state => state.commonReducer.contentPostPage)
  const totalPages = useSelector(state => state.commonReducer.totalContentPostPages)
  const [loading, setLoading] = useState(false)
  const loadMoreData = (refresh) => {
    if (!refresh && page >= totalPages) return
    setLoading(true)
    getContentPosts(page, refresh).then(() => {
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }
  useEffect(() => {
    loadMoreData('refresh')
  }, [])

  return (
    <React.Fragment>
        <div className={'hidden md:block xl:hidden'}>
            <SidebarForm col={'row'}/>
        </div>
        <InfiniteScroll
            dataLength={userContentPosts.length}
            next={loadMoreData}
            hasMore={userContentPosts.length < totalContentPosts}
            endMessage={<Divider plain>Nothing more 🤐</Divider>}
            scrollableTarget="scrollableDiv">
            <Spin spinning={loading}>
              {
                userContentPosts.map((post, i) => (
                  <div key={i}>
                    <ContentPostCard post={post} />
                  </div>
                ))
              }
            </Spin>
        </InfiniteScroll>
    </React.Fragment>
  )
}

Learn.propTypes = {
  userContentPosts: PropTypes.array.isRequired,
  totalContentPosts: PropTypes.number.isRequired,
  getContentPosts: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  userContentPosts: state.commonReducer.userContentPosts,
  totalContentPosts: state.commonReducer.totalContentPosts
})

const mapDispatchToProps = (dispatch) => ({
  getContentPosts: (page, refresh) => dispatch(handleGetUserContentPosts(page, refresh))
})

export default connect(mapStateToProps, mapDispatchToProps)(Learn)
