import React from 'react'
import Dos from './dos'
import Donts from './donts'

function DosAndDonts () {
  return (
        <div>
            <Dos/>
            <Donts/>
        </div>
  )
}

export default DosAndDonts
