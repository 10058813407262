import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useMatch, useParams } from 'react-router'
import {
  handleGetPrivateGame,
  handleGetRandomPublicGame,
  handlePublicGameSubmission
} from '../../../actions/user/common/Action'
import PublicRandomGame from './public-random-game'
import TakeInfo from '../quizzes/take-info'
import TakePassword from '../quizzes/take-password'
import RandomGameResult from './random-game-result'

function RandomNousGame ({ getGame, getPrivateGame }) {
  const { id } = useParams()
  const match = useMatch('/public/played-game/private/:id')
  const [loading, setLoading] = useState(true)
  const [answers, setAnswers] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [current, setCurrent] = useState(0)
  const items = [
        <div key={ 'game' } className={ 'w-full mx-auto mt-5' }>
            <PublicRandomGame ifCompleted={ (answers) => {
              setAnswers(answers)
              setCurrent(1)
            } }/>
        </div>,
        <TakeInfo
            onSubmit={ handlePublicGameSubmission }
            key={ 'info' }
            answers={answers}
            ifCompleted={ (res) => {
              setCurrent(res.data?.newUserStatus ? 2 : 3)
            } }/>,
      <TakePassword isGame answers={ answers } key={ 'password' } ifCompleted={ () => {
        setCurrent(3)
      } }/>,
      <RandomGameResult key={'results'}/>
  ]
  useEffect(() => {
    if (match) {
      getPrivateGame(id).then((res) => {
        setLoading(false)
      })
    } else {
      getGame(id).then((res) => {
        setLoading(false)
      })
    }
  }, [])
  if (loading) {
    return (<div className={ 'w-fit mx-auto bg-white h-screen' }>Please Wait...</div>)
  }

  return (
        <div>
            <div className={'bg-white-base w-[90%] md:w-[900px] mx-auto p-3 rounded-lg my-10'}>
                { items[current] }
            </div>
        </div>
  )
}

RandomNousGame.propTypes = {
  getGame: PropTypes.func.isRequired,
  getPrivateGame: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => ({
  getGame: (categoryId) => dispatch(handleGetRandomPublicGame(categoryId)),
  getPrivateGame: (uuid) => dispatch(handleGetPrivateGame(uuid))
})

export default connect(null, mapDispatchToProps)(RandomNousGame)
