import React, { useState } from 'react'
import { Divider, Dropdown, theme } from 'antd'
import { useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import Logout from '../../../components/navigation/logout'
import TlaImage from '../../../components/tla-image'
import ProfileInfo from '../../../components/navigation/profile-info'

const NavProfile = () => {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false)
  const profile = useSelector(state => state.loginReducer?.authUserInfo)

  const { useToken } = theme
  const { token } = useToken()

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    paddingBottom: 10
  }

  const menuStyle = {
    boxShadow: 'none',
    paddingLeft: 10,
    paddingRight: 10
  }

  const items = []

  return (
        <Dropdown menu={{ items }}
                  dropdownRender={(menu) => (
                      <div style={contentStyle}>
                          {profile.role === 'user' && <ProfileInfo text={'Personal Profile'}/>}
                          <Divider style={{ margin: 0 }}/>
                          {React.cloneElement(menu, { style: menuStyle })}
                          <Logout key={'logout'} setLoading={setLoading}/>
                      </div>
                  )}>
            <a onClick={(e) => e.preventDefault()}
               className={'border min-w-[40px] h-10 rounded-full flex items-center justify-center cursor-pointer'}>
                {loading ? <LoadingOutlined/> : <TlaImage photo={profile?.userInformation?.photo}/>}
            </a>
        </Dropdown>
  )
}

export default NavProfile
