import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import RatingQuestion from '../feedback/give-feedback/rating-question'
import FeedbackHeader from '../feedback/feedback-header'
import PropTypes from 'prop-types'
import { Button, Progress, Spin } from 'antd'
import { handleGetSubmitFeedback } from '../../actions/user/feedbacks/Action'
import { TlaError, TlaSuccess } from '../../utils/messages'
import LearningAndDevHeader from '../feedback/learning-and-dev-header'

function AnonymousFeedback ({ data, submitFeedback }) {
  const [loading, setLoading] = useState(false)
  const [answers, setAnswers] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [timer, setTimer] = useState(0)

  const formValues = {
    giver: {
      id: data?.userGiver.id,
      email: data?.userGiver.email
    },
    receiver: {
      id: data?.userReceiver.id,
      email: data?.userReceiver.email
    },
    questions: answers
  }

  useEffect(() => {
    if (submitting === true) {
      setTimeout(() => {
        setTimer(timer + 40)
        if (timer >= 90) {
          setSubmitting(false)
          setTimer(0)

          setLoading(true)
          submitFeedback(formValues, data.id).then(() => {
            setLoading(false)
            TlaSuccess('Feedback Submitted')
          }).catch(() => {
            setTimer(0)
            TlaError('Something went wrong')
          })
        }
      }, 1000)
    } else {
      setTimer(0)
    }
  }, [timer, submitting])

  return (

        <div className={'w-full rounded-10 bg-white-base p-5 md:p-[30px]'}>
            {
                (data.userGiverId === data.userReceiverId)
                  ? <LearningAndDevHeader questionId={data?.question?.id}/>
                  : <FeedbackHeader
                        questionId={data?.question?.id}
                        header1={false}
                        data={data?.userReceiver?.userInformation}/>
            }
            <div className={'mt-2 md:mt-0'}>
                <Spin spinning={loading || submitting}>
                    <RatingQuestion
                        receiver={data?.userReceiver?.userInformation?.name}
                        self={data.userGiverId === data.userReceiverId}
                        value={answers[0]?.newQuestionScore}
                        setValue={(value) => {
                          const newAnswers = answers.filter(obj => obj.skillId !== value.skillId).concat(value)
                          setAnswers(newAnswers)
                          setSubmitting(true)
                        }}
                        highScoreHelp={data?.question?.highestScoreHelp}
                        lowScoreHelp={data?.question?.lowestScoreHelp}
                        question={data?.question}/>
                </Spin>
                {
                    submitting &&
                    <div className={'flex items-center justify-center gap-x-3 flex-wrap'}>
                        <p>Submitting</p>
                        <div className={'w-1/2'}>
                            <Progress size={'small'} className={'pt-2'} percent={timer} showInfo={false}/>
                        </div>
                        <Button size={'small'} type={'text'} danger onClick={() => {
                          setSubmitting(false)
                          setTimer(0)
                          setAnswers([])
                        }} className={'btn'}>Cancel</Button>
                    </div>
                }
            </div>
        </div>
  )
}

AnonymousFeedback.propTypes = {
  data: PropTypes.object.isRequired,
  submitFeedback: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => ({
  submitFeedback: (data, feedId) => dispatch(handleGetSubmitFeedback(data, feedId))
})

export default connect(null, mapDispatchToProps)(AnonymousFeedback)
