import { Card } from 'antd'
import React, { useState } from 'react'
import FeedbackItem from './emotions'
import SubmitFeedback from './submit-feedback'
import { createGlobalStyle } from 'styled-components'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

const GlobalStyles = createGlobalStyle`
  .instant-title {
    color: var(--Gray-900);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
  }

  .instant-sub-title {
    color: var(--Gray-500);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 15px;
  }
`
const backTitle = { display: 'flex', cursor: 'pointer', alignItems: 'center' }
const InstantFeedback = ({ isQuickAction }) => {
  const [selected, setSelected] = useState({})
  const [current, setCurrent] = useState(0)
  const prev = () => {
    setCurrent(current - 1)
  }
  const navigate = useNavigate()
  const steps = [
    {
      title: isQuickAction ? '' : <h3 className='instant-title'>How are you feeling today?</h3>,
      subTitle: '',
      content: <FeedbackItem action={(value) => {
        setSelected(value)
        setCurrent(current + 1)
      }}/>
    },
    {
      title: <span onClick={() => prev()}
                         style={backTitle}><MdOutlineArrowBackIosNew/>&nbsp;Change emotion</span>,
      subTitle: '',
      content: <SubmitFeedback selected={selected} onSuccess={() => {
        isQuickAction ? navigate(-1) : setCurrent(current - 1)
      }}/>
    }
  ]

  return (
        <>
            <Card className={'border-0'} size={ 'small' } style={ { marginBottom: 10, paddingBottom: 10 } }>
                <GlobalStyles/>
                { steps[current].title }
                {/* { current === 0 && <p className={ 'text-gray-500' }>How are you feeling today</p> } */}
                <h4 className={ 'instant-sub-title' }>{ steps[current].subTitle } </h4>
                <div className="steps-content">{ steps[current].content }</div>
            </Card>
        </>

  )
}

InstantFeedback.defaultProps = {
  isQuickAction: false
}

InstantFeedback.propTypes = {
  isQuickAction: PropTypes.bool
}

export default InstantFeedback
