import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router'
import TlaTableWrapper from '../../../components/table/tla-table-wrapper'
import { Spin, Table } from 'antd'
import PropTypes from 'prop-types'
import { handleDeleteQuizQuestion, handleGetAllQuizQuestions } from '../../../actions/admin/quiz-questions/Action'
import { connect } from 'react-redux'
import TlaConfirm from '../../../components/tla-confirm'
import { TlaSuccess } from '../../../utils/messages'
import TlaEdit from '../../../components/tla-edit'
import { FiEdit2 } from 'react-icons/fi'
import TlaAddNew from '../../../components/pop-ups/tla-add-new'

const QuizQuestions = ({ getQuizQuestions, quizQuestions, deleteQuizQuestion }) => {
  const { setPageInfo } = useOutletContext() || {}
  const { data, meta } = quizQuestions
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (setPageInfo !== undefined) {
      setPageInfo({
        title: 'Quiz Questions',
        buttonText: 'Add Quiz Question'
      })
    }
    getQuizQuestions().then(() => setLoading(false))
  }, [])

  return (
    <div className={'pb-4'}>
      <Spin spinning={loading}>
        <TlaTableWrapper
          meta={meta} numberColumn={true} numberColumnWidth={100} data={data} showHeader={false}
          callbackFunction={getQuizQuestions}>
          <Table.Column title={'Question Title'} render={((record) => record.title ?? 'No Title')} />
          <Table.Column title={'Actions'} render={((record) => (
            <div className={'flex items-center gap-x-2'}>
              <TlaAddNew link={'/quiz-form-questions/form'} data={{
                quizQuestionId: record.id,
                title: record.title
              }}>
                Link
              </TlaAddNew>
              <TlaConfirm title={'Delete Question'}
                fullText={'Do you really want to delete this Question?'}
                callBack={() => {
                  deleteQuizQuestion(record.id).then(() => TlaSuccess('Deleted'))
                }} />
              <TlaEdit data={record} icon={<FiEdit2 className={'icon'} />} link={'form'} />
            </div>
          ))} />
        </TlaTableWrapper>
      </Spin>
    </div>
  )
}

QuizQuestions.propTypes = {
  getQuizQuestions: PropTypes.func.isRequired,
  deleteQuizQuestion: PropTypes.func.isRequired,
  quizQuestions: PropTypes.object.isRequired
}

/**
 * @param state
 * @returns {{quizQuestions: *}}
 */
const mapStateToProps = (state) => ({
  quizQuestions: state.adminQuizQuestionsReducer.quizQuestions
})

/**
 *
 * @param dispatch
 * @returns {{getQuizQuestions: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  getQuizQuestions: (pageNumber, pageSize, filters) => dispatch(handleGetAllQuizQuestions(pageNumber, pageSize, filters)),
  deleteQuizQuestion: (quizQuestionId) => dispatch(handleDeleteQuizQuestion(quizQuestionId))
})

export default connect(mapStateToProps, mapDispatchToProps)(QuizQuestions)
