import React from 'react'
import { Image } from 'antd'
import DefaultImage from '../../assets/images/dashboard/colleagues.svg'
import { FiFlag } from 'react-icons/fi'
import TlaAddNew from '../../components/pop-ups/tla-add-new'
import PropTypes from 'prop-types'

const LearningAndDevHeader = ({ questionId }) => {
  return (
      <div className={'flex flex-wrap flex-col-reverse justify-between md:flex-row mb-5'}>
          <div className={'flex items-center gap-2 pt-5 md:pt-0'}>
              <Image alt={'Avatar'} src={DefaultImage} preview={false} className={'profile-image !h-12 !w-12'}/>
              <div>
                  <h3 className={'text-gray-600 leading-none'}>Learning & Development</h3>
              </div>
          </div>
          <div className={'flex flex-row md:flex-col justify-between md:items-end'}>
              <p className={'text-xs text-indigo-700 font-medium bg-indigo-50 rounded-2xl py-0.5 px-2 w-fit flex gap-x-1'}>
                  Self Assessment
              </p>
              <TlaAddNew link={'/report/question'} data={{ questionId }}>
                  <p className={'text-xs text-gray-400 flex justify-end items-center gap-1'}>
                      Report <FiFlag/>
                  </p>
              </TlaAddNew>
          </div>
      </div>
  )
}
LearningAndDevHeader.propTypes = {
  questionId: PropTypes.number
}
export default LearningAndDevHeader
