import { Types } from '../../actions/admin/skills/Types'

const initialState = {
  skills: {
    data: [],
    meta: {}
  }
}

export default function adminSkillsReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_SKILL:
      return {
        ...state,
        skills: {
          ...state.skills,
          data: state.skills.data.concat(action.payload.data.newSkill)
        }
      }
    case Types.ALL_SKILLS:
      return {
        ...state,
        skills: {
          ...state.skills,
          data: action.payload.data.skills,
          meta: {
            total: action.payload.data.count,
            current_page: Number(action.payload.data.page),
            page: action.payload.data.page,
            pageSize: action.payload.data.pageSize,
            from: Number(action.payload.data.page)
          }
        }
      }

    case Types.UPDATE_SKILL:{
      return {
        ...state,
        skills: {
          ...state.skills,
          data: state.skills.data.map((form) => {
            return form.id === action.payload.data.skill.id ? action.payload.data.skill : form
          })
        }
      }
    }
    case Types.DELETE_SKILL:
      return {
        ...state,
        skills: {
          ...state.skills,
          data: state.skills.data.filter((skills) => skills.id !== action.skill)
        }
      }
    case Types.IMPORT_SKILL:
      return {
        ...state,
        skills: {
          ...state.skills,
          data: [...state.skills.data, ...action.payload.data.data]
        }
      }
    default:
      return state
  }
}
