import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function addSkillRelationship (payload) {
  return {
    type: Types.NEW_SKILL_RELATIONSHIP,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function allSkillRelationships (payload) {
  return {
    type: Types.ALL_SKILL_RELATIONSHIPS,
    payload
  }
}

/**
 *
 * @param skill_relationship
 * @returns {{skill_relationship, type: string}}
 */
export function deleteSkillRelationship (skillRelationship) {
  return {
    type: Types.DELETE_SKILL_RELATIONSHIP,
    skillRelationship
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function updateSkillRelationship (payload) {
  return {
    type: Types.UPDATE_SKILL_RELATIONSHIP,
    payload
  }
}
