import React from 'react'
import Header from './header'
import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'
import AppSidebar from './sidebar/app-sidebar'
import { Affix } from 'antd'
import MobileProfile from '../../../components/mobile-profile'
import MobileMenu from '../../dashboard/layout/mobile-menu'

function Layout () {
  return (
        <div className={'w-full max-w-screen-2xl mx-auto overflow-x-hidden bg-white-50'}>
            <Affix offsetTop={0}>
                <Header showMenu={false}/>
            </Affix>
            <MobileProfile/>
            <div className={'flex gap-x-3 justify-between mt-5 md:mt-0 mx-2 md:mx-8 relative'}>
                <AppSidebar/>
                <div className={'mb-5 w-full md:min-w-[720px] 2xl:max-w-[1290px] flex-1 bg-white-base p-5'}>
                    <Outlet/>
                </div>
            </div>
            <div className="relative z-[1000]">
              <MobileMenu/>
            </div>
        </div>
  )
}

Layout.propTypes = {
  children: PropTypes.any
}

export default Layout
