import React from 'react'
import PropTypes from 'prop-types'
import { getInitials } from '../../utils'

const OrgLogo = ({ logo, altText }) => {
  return (
    <div className={'hidden md:block'}>
        {
            logo
              ? <div className={'border-gray-400 border rounded-full p-1'}>
                    <img className={'h-7 w-7 rounded-full'} src={logo} alt={altText}/>
                </div>
              : <div className={'w-9 h-9 border-gray-400 border rounded-full p-1 flex items-center justify-center'}>
                    {getInitials(altText)}
                </div>
        }
    </div>
  )
}

OrgLogo.propTypes = {
  logo: PropTypes.string,
  altText: PropTypes.string.isRequired
}

export default OrgLogo
