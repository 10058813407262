import React from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import TlaConfirm from '../../components/tla-confirm'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

function GoBackHome ({ backText }) {
  const navigate = useNavigate()
  return (
      <TlaConfirm
          callBack={() => { navigate(-1) }}
          okText={ 'Yes Continue' }
          fullText={ 'You will lose the data with this action, do you  want to continue?' }
          showIcon={ false }>
          <div className={ 'flex gap-x-2 items-center text-gray-500 mb-5 font-medium' }>
              <FiChevronLeft size={ 24 } className={ '-ml-2' }/> { backText }
          </div>
      </TlaConfirm>

  )
}

GoBackHome.defaultProps = {
  backText: 'Back'
}

GoBackHome.propTypes = {
  backText: PropTypes.string
}

export default GoBackHome
