import { Types } from '../../actions/admin/lesson-questions/Types'

const initialState = {
  questions: {
    data: [],
    meta: {}
  },
  question: {}
}

export default function adminLessonQuestionsReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_LESSON_QUESTION:
      return {
        ...state,
        questions: {
          ...state.questions,
          data: [
            action.payload.data.courseQuestion,
            ...state.questions.data
          ]
        }
      }
    case Types.ALL_LESSON_QUESTIONS:{
      return {
        ...state,
        questions: {
          ...state.questions,
          data: action.payload.data.courseQuestions,
          meta: {
            total: action.payload.data.count,
            current_page: Number(action.payload.data.page),
            page: action.payload.data.page,
            pageSize: action.payload.data.pageSize
          }
        }
      }
    }
    case Types.UPDATE_LESSON_QUESTION:
      return {
        ...state,
        questions: {
          ...state.questions,
          data: state.questions.data.map((question) => {
            return question.id === action.payload.data.courseQuestion.id ? action.payload.data.courseQuestion : question
          })
        }
        // question: action.payload.data.upda
      }
    case Types.DELETE_LESSON_QUESTION:
      return {
        ...state,
        questions: {
          ...state.questions,
          data: state.questions.data.filter((questions) => questions.id !== action.courseQuestion)
        }
      }

    default:
      return state
  }
}
