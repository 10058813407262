import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Select, Spin } from 'antd'
import { contactTypes } from '../../../utils'

function TakeUserLevel ({ ifCompleted, title, subTitle, buttonText }) {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const onFinish = (values) => {
    setLoading(true)
    ifCompleted(values)
    setLoading(false)
  }

  return (
        <Spin spinning={ loading }>
            <div className={ 'mx-auto bg-white-base w-[90%] md:w-[900px] p-5 rounded-lg' }>
                <div className={ 'mb-8' }>
                    <h3 className={ 'text-center text-gray-900 font-semibold text-3xl md:text-[40px] leading-[38px] mb-6 mt-[100px]' }>
                        { title }
                    </h3>
                    <p className={ 'w-[90%] md:w-[539px] text-center text-gray-500 text-sm mx-auto' }>
                        {subTitle}
                    </p>
                </div>
                <Form className={ 'w-[90%] md:w-[594px] mx-auto' } requiredMark={ false }
                      form={ form }
                      layout="vertical"
                      name="take-info-form" onFinish={ onFinish }>
                    <div className={ 'pb-10' }>
                        <Form.Item className={'mb-8'}
                            name={ 'currentStatus' }
                             label={<span className={'font-medium text-sm text-gray-700'}>Your Current Status</span>}
                            rules={ [
                              {
                                required: true,
                                message: 'Current Status is Required'
                              }
                            ] }>
                            <Select size={ 'large' }
                                    placeholder="Select One"
                                    options={ contactTypes }
                            />
                        </Form.Item>
                        <Button size={ 'large' } className={ 'btn btn-primary-600 mx-auto min-w-[185px]' } htmlType={ 'submit' }>
                            { buttonText }
                        </Button>
                    </div>
                </Form>
            </div>
        </Spin>
  )
}

TakeUserLevel.defaultProps = {
  title: 'Know Deep insights about YOU!',
  subTitle: 'Answer the questions to the best of your knowledge and understanding. The honest you’re, the better the chances for you to grow.',
  buttonText: 'Start Quiz'
}

TakeUserLevel.propTypes = {
  ifCompleted: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  buttonText: PropTypes.string
}

export default TakeUserLevel
