import api from '../../../utils/api'
import { addQuizForm, allQuizForms, deleteQuizForm, updateQuizForm } from './ActionCreator'

/**
 * Store a newly created resource in storage.
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAddQuizForm = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/quiz-forms', data).then((res) => {
      dispatch(addQuizForm(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAllQuizForms = (pageNumber = 1, pageSize = 15) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/quiz-forms?page=${pageNumber}&pageSize=${pageSize}`).then((res) => {
      dispatch(allQuizForms(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Update the specified resource in storage.
 * @param quizFormId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleUpdateQuizForm = (quizFormId, data) => (dispatch) => {
  const updatePromise = new Promise((resolve, reject) => {
    api().put(`/quiz-forms/${quizFormId}`, data, {
    }).then((res) => {
      dispatch(updateQuizForm(res.data))
      handleGetAllQuizForms()
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
  const getQuizFormPromise = new Promise((resolve, reject) => {
    api().get(`/quiz-forms?page=${1}&pageSize=15`).then((res) => {
      dispatch(allQuizForms(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
  return updatePromise.then((result1) => {
    return getQuizFormPromise
  }).then((result2) => {})
}

/**
 * Remove the specified resource from storage.
 * @returns {function(*): Promise<unknown>}
 * @param quizFormId
 */
export const handleDeleteQuizForm = (quizFormId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().delete(`/quiz-forms/${quizFormId}`).then((res) => {
      dispatch(deleteQuizForm(quizFormId))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
