import React, { useEffect, useState } from 'react'
import { Button, Form, InputNumber, Radio } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import CloseModal from '../../../components/close-modal.js'
import { handleAddModuleLesson, handleUpdateModuleLesson } from '../../../actions/admin/module-lessons/Action.js'
import TlaSelect from '../../../components/TlaSelect.js'
import { handleGetAllModules } from '../../../actions/admin/modules/Action.js'
import { handleGetAllLessons } from '../../../actions/admin/lessons/Action.js'
import TlaModalFormWrapper from '../../../components/tla-modal-form-wrapper.js'
import { handleGetAllLessonQuestions } from '../../../actions/admin/lesson-questions/Action.js'

function ModuleLessonsForm ({ addModuleLesson, updateModuleLesson, modules, getModules, lessons, getLessons, questions, getQuestions }) {
  const { state } = useLocation()
  const [contentType, setContentType] = useState(state?.data?.contentType ?? 'QUESTION')
  const resourceId = state?.data?.id
  const formValues = {
    ...state?.data,
    contentType
  }
  console.log(formValues)
  useEffect(() => {
    getModules(1, 1000)
    getLessons(1, 1000)
    getQuestions(1, 1000)
  }, [])
  return (
      <TlaModalFormWrapper
          initialValues={formValues}
          formTitle={`${resourceId ? 'Update Link' : 'Link'} Lesson Module`}
          resourceId={state?.data?.id ?? null}
          onSubmit={state?.data?.id ? updateModuleLesson : addModuleLesson}>

                    <TlaSelect
                        customClass='my-4'
                        name={'moduleId'}
                        placeholder={'Select Module'}
                        options={modules}
                        optionKey={'title'} label={'Select Module *'} required/>
                    <Form.Item label="" name="contentType"
                              rules={[
                                {
                                  required: true,
                                  message: 'Required'
                                }
                              ]}>
                        <div className={'flex flex-wrap gap-x-3'}>
                            <p>Content Type</p>
                            <Radio.Group value={contentType} onChange={(e) => setContentType(e.target.value)}>
                                <Radio value="QUESTION">Question</Radio>
                                <Radio value="LESSON">Lesson</Radio>
                            </Radio.Group>
                        </div>
                    </Form.Item>
                    {
                      contentType === 'LESSON'
                        ? <TlaSelect
                          customClass='mb-4 mt-[-10px]'
                          name={'lessonId'}
                          placeholder={'Select Lesson'}
                          options={lessons}
                          optionKey={'title'} label={'Select Lesson *'} required/>
                        : <TlaSelect
                          customClass='mb-4 mt-[-10px]'
                          name={'questionId'}
                          placeholder={'Select Question'}
                          options={questions}
                          optionKey={'questionTitleSelf'} label={'Select Question *'} required/>
                    }
                    <Form.Item
                        name={'sequence'}
                        rules={[
                          {
                            required: true,
                            message: 'Required'
                          }
                        ]}
                        label={'Sequence Number *'}>
                        <InputNumber className='w-full' size={'large'} placeholder={'Eg 3'}/>
                    </Form.Item>
                    <div className={'flex gap-2'}>
                        <div className={'w-full'}>
                            <CloseModal/>
                        </div>
                        <Form.Item className={'w-full'}>
                            <Button className={'btn-primary w-full'} size={'large'} htmlType="submit">
                                {`${resourceId ? 'Update' : 'Create'}`} Module Lesson Link
                            </Button>
                        </Form.Item>
                    </div>
      </TlaModalFormWrapper>
  )
}

ModuleLessonsForm.propTypes = {
  addModuleLesson: PropTypes.func.isRequired,
  updateModuleLesson: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  modules: PropTypes.array.isRequired,
  getModules: PropTypes.func.isRequired,
  lessons: PropTypes.array.isRequired,
  getLessons: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
  getQuestions: PropTypes.func.isRequired
}

/**
 * @param state
 * @returns {{modules: *}}
 */
const mapStateToProps = (state) => ({
  modules: state.adminModulesReducer.modules?.data,
  lessons: state.adminLessonsReducer.lessons?.data,
  questions: state.adminLessonQuestionsReducer.questions?.data,
  userId: state.loginReducer.authUser.id
})

/**
 *
 * @param dispatch
 * @returns {{addModuleLesson: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  addModuleLesson: (data) => dispatch(handleAddModuleLesson(data)),
  updateModuleLesson: (moduleLessonId, data) => dispatch(handleUpdateModuleLesson(moduleLessonId, data)),
  getModules: (pageNumber, pageSize) => dispatch(handleGetAllModules(pageNumber, pageSize)),
  getLessons: (pageNumber, pageSize) => dispatch(handleGetAllLessons(pageNumber, pageSize)),
  getQuestions: (pageNumber, pageSize) => dispatch(handleGetAllLessonQuestions(pageNumber, pageSize))
})

export default connect(mapStateToProps, mapDispatchToProps)(ModuleLessonsForm)
