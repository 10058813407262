import React, { useState } from 'react'
import { Button, Form, Input, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { TlaError, TlaSuccess } from '../../utils/messages'
import { handleValidateOrg } from '../../actions/user/common/Action'
import { getErrors } from '../../utils'

function Organizations () {
  const profile = useSelector(state => state.loginReducer?.authUserInfo)

  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const dispatch = useDispatch()
  const onFinish = (values) => {
    setLoading(true)
    dispatch(handleValidateOrg(values)).then(() => {
      TlaSuccess('Organization validated')
      form.resetFields()
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      TlaError(error.response.data.message ?? getErrors(error.response.data?.errors))
    })
  }

  return (
        <div className={'w-full bg-white-base p-5'}>
            <p className={'text-base text-gray-900 mb-[70px]'}>Organizations</p>
            <Spin spinning={loading}>
                <Form form={form} initialValues={{ userId: profile?.id }}
                      className={'flex flex-wrap items-center'}
                      name="basic"
                      labelAlign={'left'}
                      colon={false}
                      requiredMark={false}
                      onFinish={onFinish}
                      autoComplete="off">
                    <div className={'mr-0 md:mr-[150px] pb-4 text-sm text-gray-700 font-medium'}>
                        Organization Code
                    </div>
                    <Form.Item hidden name="userId"
                               rules={[
                                 {
                                   required: true,
                                   message: 'userId is Required!'
                                 }
                               ]}>
                        <Input size={'large'}/>
                    </Form.Item>
                    <Form.Item
                        name="code"
                        rules={[
                          {
                            required: true,
                            message: 'Organization Code is Required!'
                          }
                        ]}>
                        <Input size={'large'} rootClassName={'w-full sm:w-[200px] md:w-[300px] lg:w-[512px] mr-8'}/>
                    </Form.Item>
                    <Form.Item>
                        <Button size={'large'} className={'btn btn-primary'} htmlType="submit">
                            Validate
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
            <div>
                <p className={'text-xl font-medium mb-[30px] mt-5 md:mt-0'}>Validated Organizations</p>
                {
                    profile?.userOrg?.map(org => (
                        <div key={org.id}>
                            <p className={'text-lg text-gray-900 font-medium'}>
                                {org.organization.name} ({org.organization.website})
                            </p>
                        </div>
                    ))
                }
            </div>
        </div>
  )
}

export default Organizations
