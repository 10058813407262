import Kudos from '../assets/images/emotions/kudos.svg'
import Happy from '../assets/images/emotions/happy.svg'
import Inspiration from '../assets/images/emotions/inspiration.svg'
import Fear from '../assets/images/emotions/fear.svg'
import Disgust from '../assets/images/emotions/disgust.svg'
import Muted from '../assets/images/emotions/gratitude.svg'
import Care from '../assets/images/emotions/care.svg'
import Surprise from '../assets/images/emotions/surprise.svg'
import Sad from '../assets/images/emotions/sad.svg'
import Growth from '../assets/images/emotions/growth.svg'
import Respect from '../assets/images/emotions/respect.svg'
import Disrespect from '../assets/images/emotions/disrespect.svg'

export const emotions = [
  { name: 'Kudos', ico: Kudos },
  { name: 'Happy', ico: Happy },
  { name: 'Inspiration', ico: Inspiration },
  { name: 'Fear', ico: Fear },
  { name: 'Disgust', ico: Disgust },
  { name: 'Muted', ico: Muted },
  { name: 'Care', ico: Care },
  { name: 'Surprise', ico: Surprise },
  { name: 'Sad', ico: Sad },
  { name: 'Growth', ico: Growth },
  { name: 'Respect', ico: Respect },
  { name: 'Disrespect', ico: Disrespect }
]
export const emojis = {
  Kudos,
  Happy,
  Inspiration,
  Fear,
  Disgust,
  Muted,
  Care,
  Surprise,
  Sad,
  Growth,
  Respect,
  Disrespect
}
