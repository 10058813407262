import React from 'react'
import { Button, Form, Input } from 'antd'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import TlaModalFormWrapper from '../../components/tla-modal-form-wrapper'
import CloseModal from '../../components/close-modal'
import { handleReportQuestion } from '../../actions/user/feedbacks/Action'
import PropTypes from 'prop-types'

function ReportQuestion ({ reportQuestion }) {
  const { state } = useLocation()

  return (
        <TlaModalFormWrapper
            width={624}
            initialValues={state?.data}
            formTitle={'Report Question'}
            onSubmit={reportQuestion} successMessage={'Comment sent successfully'}>
            <div>
                <p className={'text-gray-500 text0base font-normal'}>
                    Provide detail what needs to be fixed in this question.
                    An action will be taken after assessing this report.
                </p>
            </div>
            <div className={'mt-5'}>
                <Form.Item hidden name={'questionId'}
                    rules={[
                      {
                        required: true,
                        message: 'Required'
                      }
                    ]}
                    label={'questionId'}>
                    <Input/>
                </Form.Item>
                <Form.Item name={'comment'}
                    rules={[
                      {
                        required: true,
                        message: 'Required'
                      }
                    ]}
                    label={'Description'}>
                    <Input.TextArea rows={3} size={'large'} placeholder={'Provide detail what needs to be fixed in' +
                        ' this' +
                        ' question.'}/>
                </Form.Item>
            </div>

            <div className={'flex gap-2 w-fit mx-auto'}>
                <CloseModal width={'w-[100px]'} block/>
                <Button className={'btn btn-primary w-[100px]'} size={'large'} htmlType="submit">
                    Report
                </Button>
            </div>
        </TlaModalFormWrapper>
  )
}

ReportQuestion.propTypes = {
  reportQuestion: PropTypes.func.isRequired
}

/**
 *
 * @param dispatch
 * @returns {{addExperience: (function(*, *): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  reportQuestion: (data) => dispatch(handleReportQuestion(data))
})

export default connect(null, mapDispatchToProps)(ReportQuestion)
