import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router'
import TlaTableWrapper from '../../../components/table/tla-table-wrapper'
import { Spin, Table } from 'antd'
import PropTypes from 'prop-types'
import { handleDeleteCourseCategory, handleGetAllCourseCategories } from '../../../actions/admin/course-categories/Action'
import { connect } from 'react-redux'
import TlaConfirm from '../../../components/tla-confirm'
import { TlaSuccess } from '../../../utils/messages'
import TlaEdit from '../../../components/tla-edit'
import { FiEdit2 } from 'react-icons/fi'

const CourseCategories = ({ getCourseCategories, courseCategories, deleteCourseCategory }) => {
  const { setPageInfo } = useOutletContext()
  const { data, meta } = courseCategories
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setPageInfo({
      title: 'Category',
      buttonText: 'Add Category',
      buttonLink: 'Add Category',
      showImportBtn: false,
      subTitle: '',
      helpingText: '',
      showSearch: false
    })
    getCourseCategories().then(() => setLoading(false))
  }, [])

  return (
        <div className={'pb-10'}>
            <Spin spinning={loading}>
                <TlaTableWrapper
                   meta={meta}
                   numberColumn={true} data={data} showHeader={false}
                   callbackFunction={getCourseCategories}>
                    <Table.Column title={'Category Name'} render={((record) => record.title ?? 'No Title')}/>
                    <Table.Column title={'Actions'} render={((record) => (
                        <div className={'flex items-center'}>
                            <TlaConfirm title={'Delete Course Category'}
                                        fullText={'Do you really want to delete this Course Category?'}
                                        callBack={() => {
                                          deleteCourseCategory(record.id).then(() => TlaSuccess('Deleted'))
                                        }}/>
                            <TlaEdit data={record} icon={<FiEdit2 className={'icon'}/>} link={'form'}/>
                        </div>
                    ))}/>
                </TlaTableWrapper>
            </Spin>
        </div>
  )
}

CourseCategories.propTypes = {
  getCourseCategories: PropTypes.func.isRequired,
  deleteCourseCategory: PropTypes.func.isRequired,
  courseCategories: PropTypes.object.isRequired
}

/**
 * @param state
 * @returns {{courseCategories: *}}
 */
const mapStateToProps = (state) => ({
  courseCategories: state.adminCourseCategoriesReducer.courseCategories ?? {}
})

/**
 *
 * @param dispatch
 * @returns {{getCourseCategories: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  getCourseCategories: (pageNumber, skillId) => dispatch(handleGetAllCourseCategories(pageNumber, skillId)),
  deleteCourseCategory: (courseCategorieId) => dispatch(handleDeleteCourseCategory(courseCategorieId))
})

export default connect(mapStateToProps, mapDispatchToProps)(CourseCategories)
