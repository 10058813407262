import { Types } from '../../actions/admin/questions/Types'

const initialState = {
  questions: {
    data: [],
    meta: {}
  },
  question: {}
}

export default function adminQuestionsReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_QUESTION:
      return {
        ...state,
        questions: {
          ...state.questions,
          data: state.questions.data.concat(action.payload.data.newQuestion)
        }
      }
    case Types.IMPORT_QUESTION:
      return {
        ...state,
        questions: {
          ...state.questions,
          data: state.questions.data.concat(action.payload.data.data)
        }
      }
    case Types.SINGLE_QUESTION:
      return {
        ...state,
        question: action.payload
      }
    case Types.ALL_QUESTIONS:
      return {
        ...state,
        questions: {
          ...state.questions,
          data: action.payload.data.questions,
          meta: {
            total: action.payload.data.count,
            current_page: Number(action.payload.data.page),
            page: action.payload.data.page,
            pageSize: action.payload.data.pageSize
          }
        }
      }

    case Types.UPDATE_QUESTION:
      return {
        ...state,
        questions: {
          ...state.questions,
          data: state.questions.data.map((form) => {
            return form.id === action.payload.data.updatedQuestion.id ? action.payload.data.updatedQuestion : form
          })
        },
        question: action.payload.data.updatedQuestion
      }
    case Types.DELETE_QUESTION:
      return {
        ...state,
        questions: {
          ...state.questions,
          data: state.questions.data.filter((question) => question.id !== action.id)
        }
      }

    default:
      return state
  }
}
