import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { handleCourseEnrollment, handleGetUserCourseDetails } from '../../actions/user/common/Action'
import { Spin } from 'antd'
// import { RiVipCrownLine } from 'react-icons/ri'
import { useParams } from 'react-router'
import { TlaError, TlaSuccess } from '../../utils/messages'
import { Link } from 'react-router-dom'
import { FiChevronLeft } from 'react-icons/fi'
import DisplaySingleCourse from './components/display-single-course'

// import QuestionImg from '../../assets/images/icons/question.svg'

function SingleCourse ({ getCourseDetails, course, courseEnrollment }) {
  const { courseId } = useParams()
  const enroll = () => {
    setLoading(true)
    courseEnrollment(course.id).then((res) => {
      setLoading(false)
      TlaSuccess(res.data.message)
      getCourseDetails(courseId)
    }).catch(error => {
      TlaError(error.response?.data?.message)
    })
  }

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    getCourseDetails(courseId ?? 2).then((res) => {
      setLoading(false)
    })
  }, [])

  return (
    <React.Fragment>
      <div className="bg-white-base rounded-md p-2 md:p-5">
        <Spin spinning={loading}>
          <div className="page-hesader">
            <Link to={'/courses'} className='inline-flex items-center gap-1 font-medium text-sm text-gray-500 cursor-pointer'><FiChevronLeft /> Back</Link>
          </div>
          <DisplaySingleCourse course={course} enroll={enroll}/>
        </Spin>
      </div>
    </React.Fragment>
  )
}

SingleCourse.propTypes = {
  course: PropTypes.object.isRequired,
  getCourseDetails: PropTypes.func.isRequired,
  courseEnrollment: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  course: state.commonReducer.course ?? {}
})

const mapDispatchToProps = (dispatch) => ({
  getCourseDetails: (courseId) => dispatch(handleGetUserCourseDetails(courseId)),
  courseEnrollment: (courseId) => dispatch(handleCourseEnrollment(courseId))
})

export default connect(mapStateToProps, mapDispatchToProps)(SingleCourse)
