import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function addQuestion (payload) {
  return {
    type: Types.NEW_QUESTION,
    payload
  }
}
export function importQuestions (payload) {
  return {
    type: Types.IMPORT_QUESTION,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function allQuestions (payload) {
  return {
    type: Types.ALL_QUESTIONS,
    payload
  }
}

/**
 *
 * @param id
 * @returns {{question, type: string}}
 */
export function deleteQuestion (id) {
  return {
    type: Types.DELETE_QUESTION,
    id
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function updateQuestion (payload) {
  return {
    type: Types.UPDATE_QUESTION,
    payload
  }
}
