import React from 'react'
import MenuHelper from '../../../components/navigation/menu-helper'
import { AdminSidebarMenus } from '../../../utils/menu-items'
import { HiOutlineChartSquareBar } from 'react-icons/hi'
import { FiBriefcase, FiFileText, FiSettings, FiUser } from 'react-icons/fi'
import { BiBookReader } from 'react-icons/bi'
import { BsPencilSquare, BsQuestionCircle } from 'react-icons/bs'
import { MdOutlinePlayLesson, MdOutlineQuiz } from 'react-icons/md'
import { FaToolbox } from 'react-icons/fa'
// import TlaIcon from '../../../components/tla-icon'

export const Sidebar = () => {
  return (
        <div className={'w-[229px] max-w-[229px] min-h-[580px] bg-white-base rounded-lg pt-10'}>
          <MenuHelper customClass={'admin-sidebar'} linkStyles={{ fontWeight: 'normal', fontSize: 14 }} menus={AdminSidebarMenus} icons={{
            chart: <HiOutlineChartSquareBar className={'text-gray-500'} size={24}/>,
            // questions: <TlaIcon name={'question'}/>,
            questions: <BsQuestionCircle className={'text-gray-500'} size={24} name={'question'}/>,
            users: <FiBriefcase className={'text-gray-500'} size={24}/>,
            skills: <BiBookReader className={'text-gray-500'} size={24}/>,
            settings: <FiSettings className={'text-gray-500'} size={24}/>,
            contentPost: <BsPencilSquare className={'text-gray-500'} size={24}/>,
            file: <FiFileText className={'text-gray-500'} size={24}/>,
            module: <MdOutlinePlayLesson className={'text-gray-500'} size={24}/>,
            personality: <FiUser className={'text-gray-500'} size={24}/>,
            quiz: <MdOutlineQuiz className={'text-gray-500'} size={24}/>,
            lesson: <FaToolbox className={'text-gray-500'} size={22}/>
          }} direction={'inline'}/>
        </div>
  )
}
