import api from '../../../utils/api'
import { addPersonality, allPersonalities, deletePersonality, updatePersonality } from './ActionCreator'

function isObject (item) {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null)
}
/**
 * Store a newly created resource in storage.
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAddPersonality = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/personality', data).then((res) => {
      dispatch(addPersonality(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAllPersonalities = (pageNumber = 1, skill) => (dispatch) => {
  console.log(skill)
  let skillId = null
  if (isObject(skill)) {
    skillId = skill.skill ?? null
  }
  return new Promise((resolve, reject) => {
    api().get(`/personality?&page=${pageNumber}&pageSize=15${skillId ? '&skillId=' + skillId : ''}`).then((res) => {
      dispatch(allPersonalities(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Update the specified resource in storage.
 * @param personalityId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleUpdatePersonality = (personalityId, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().patch(`/personality/${personalityId}`, data, {
    }).then((res) => {
      dispatch(updatePersonality(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Remove the specified resource from storage.
 * @returns {function(*): Promise<unknown>}
 * @param personalityId
 */
export const handleDeletePersonality = (personalityId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().delete(`/personality/${personalityId}`).then((res) => {
      dispatch(deletePersonality(personalityId))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
