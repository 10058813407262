import React from 'react'
import PropTypes from 'prop-types'

const ShoulderIcon = ({ className, isActive = '#98A2B3' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" className={className} viewBox="0 0 22 22" fill="none">
      <g clipPath="url(#clip0_10156_1286)">
        <path d="M6.15585 2.91432C6.15585 4.02705 6.7951 4.82553 7.98053 5.15165C8.34372 5.25157 8.67931 5.49429 8.92547 5.83516C9.4399 6.54745 10.1629 6.92243 11.0163 6.91959C11.0489 6.91951 11.0814 6.91706 11.1136 6.91237C12.7368 6.67481 13.6467 4.89771 14.4338 4.76334L21.3118 4.76334C21.6919 4.76334 22 4.4552 22 4.07514V1.22324C22 0.828259 21.6675 0.513322 21.2721 0.536205C17.4969 0.754536 13.6675 1.03923 11.6328 0.571948C9.07352 -0.684789 6.15585 0.177223 6.15585 2.91432ZM11.0842 1.83647C11.1317 1.86069 11.1819 1.87931 11.2338 1.89191C13.3805 2.41451 16.9411 2.16956 20.6236 1.95278V3.3869L14.3875 3.3869C13.0142 3.3869 12.078 5.3317 10.9614 5.54255C10.5737 5.5312 10.2884 5.3714 10.0413 5.02928C9.61225 4.4352 9.01003 4.00735 8.34557 3.82455C7.62142 3.62536 7.41461 3.26134 7.58288 2.48131C7.87958 1.10535 9.81385 1.18935 11.0842 1.83647Z" fill={isActive}/>
        <path d="M4.99338 21.3053L4.99338 10.0224C4.99338 9.40476 5.38609 8.56645 6.09919 7.66198C6.73534 6.85498 6.68196 5.68375 5.97506 4.93757C5.46045 4.39437 5.17339 3.81478 5.12195 3.21493C5.00654 1.8701 3.67686 0.987741 2.4067 1.41189C0.967246 1.89255 6.81877e-05 3.20052 6.81877e-05 4.66651L6.81877e-05 21.3053C6.81877e-05 21.6854 0.308166 21.9935 0.688267 21.9935H4.30518C4.68528 21.9935 4.99338 21.6854 4.99338 21.3053ZM1.37647 20.6171L1.37647 4.66651C1.37647 3.6583 2.11288 2.96107 2.84276 2.71736C3.27327 2.57361 3.71183 2.88076 3.75062 3.33252C3.8283 4.23763 4.25198 5.11999 4.97588 5.8841C5.2164 6.13809 5.23412 6.536 5.01829 6.80982C4.08844 7.98922 3.61698 9.07012 3.61698 10.0224L3.61698 20.6171H1.37647V20.6171Z" fill={isActive}/>
        <path d="M9.57371 21.3053L9.57371 12.608C9.57371 12.2502 9.66941 11.8948 9.85045 11.58C10.2635 10.8617 10.7754 9.77646 10.4511 8.64971C10.0798 7.35723 7.83794 6.62447 6.47273 8.03098C5.69184 8.83561 5.51342 9.43408 5.51342 10.2476C5.51351 11.4971 6.0411 11.7577 6.0411 12.63L6.0411 21.3053C6.0411 21.6854 6.3492 21.9935 6.7293 21.9935H8.88555C9.26561 21.9935 9.57371 21.6854 9.57371 21.3053ZM7.4175 20.6171L7.4175 12.63C7.4175 11.233 6.69811 11.0485 6.93657 9.75866C6.97374 9.62927 7.09112 9.37012 7.46042 8.98959C7.87898 8.55831 8.57174 8.52484 9.00466 8.91501C9.06642 8.97067 9.11283 9.02585 9.14066 9.07639C9.25503 9.54342 9.10062 10.123 8.65729 10.8938C8.35642 11.417 8.19736 12.0098 8.19736 12.608L8.19736 20.6172L7.4175 20.6171Z" fill={isActive}/>
      </g>
      <defs>
        <clipPath id="clip0_10156_1286">
          <rect width="22" height="22" fill="white" transform="matrix(0 1 -1 0 22 0)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

ShoulderIcon.propTypes = {
  isActive: PropTypes.string,
  className: PropTypes.string
}
export default ShoulderIcon
