import React from 'react'
import { ReactComponent as Question } from '../assets/images/icons/question.svg'
import { ReactComponent as UserPlus } from '../assets/images/icons/add-icon.svg'
import { ReactComponent as ArrowLeft } from '../assets/images/icons/arrow-left.svg'
import { ReactComponent as Upload } from '../assets/images/icons/upload.svg'
import { ReactComponent as ArrowRight } from '../assets/images/icons/arrow-right.svg'
import { ReactComponent as Avatar } from '../assets/images/icons/avata.svg'
import { ReactComponent as CustomerService } from '../assets/images/icons/support.svg'
import { ReactComponent as UserGuide } from '../assets/images/icons/user-guide.svg'
import { ReactComponent as Exclamation } from '../assets/images/icons/exclamation.svg'
import { ReactComponent as Message } from '../assets/images/icons/message.svg'
import { ReactComponent as GPT } from '../assets/images/icons/chatbot 1.svg'
import { ReactComponent as QuestionMark } from '../assets/images/icons/question-mark.svg'
import { ReactComponent as Learn } from '../assets/images/icons/learn.svg'
import { ReactComponent as Assess } from '../assets/images/icons/assess.svg'
import { ReactComponent as Profile } from '../assets/images/icons/profile.svg'
import { ReactComponent as Requests } from '../assets/images/icons/requests.svg'
import { ReactComponent as NousQuiz } from '../assets/images/icons/nous-quiz.svg'
import { ReactComponent as NousGame } from '../assets/images/icons/game-console.svg'
import PropTypes from 'prop-types'

const iconTypes = {
  question: Question,
  'customer-service': CustomerService,
  'user-guide': UserGuide,
  'user-plus': UserPlus,
  avatar: Avatar,
  'arrow-left': ArrowLeft,
  upload: Upload,
  'arrow-right': ArrowRight,
  exclamation: Exclamation,
  message: Message,
  questionMark: QuestionMark,
  learn: Learn,
  assess: Assess,
  profile: Profile,
  requests: Requests,
  'nous-quiz': NousQuiz,
  gpt: GPT,
  NousGame
}

const TlaIcon = ({ name, ...props }) => {
  const Icon = iconTypes[name]
  return <Icon {...props} />
}

TlaIcon.propTypes = {
  name: PropTypes.string.isRequired
}
export default TlaIcon
