import React from 'react'
import { Button, Form, Input, Select } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import TlaModalFormWrapper from '../../../components/tla-modal-form-wrapper'
import CloseModal from '../../../components/close-modal'
import { handleAddSkill, handleUpdateSkill } from '../../../actions/admin/skills/Action'
import { contactTypes } from '../../../utils'

function SkillsForm ({ addSkill, updateSkill }) {
  const { state } = useLocation()
  const formValues = {
    ...state?.data
  }
  return (
        <TlaModalFormWrapper
            initialValues={formValues}
            formTitle={'Create Skill'}
            resourceId={state?.data?.id ?? null}
            onSubmit={state?.data?.id ? updateSkill : addSkill}>
            <Form.Item
                name={'title'}
                rules={[
                  {
                    required: true,
                    message: 'Required'
                  }
                ]}
                label={'Skill Title *'}>
                <Input size={'large'} placeholder={'Eg Leadership'}/>
            </Form.Item>
            <Form.Item
                name={'contact_type'}
                rules={[
                  {
                    required: true,
                    message: 'Required'
                  }
                ]}
                label={'Contact Type *'}>
                <Select
                  mode='multiple'
                  onChange={(value) => {
                    if (value.includes(''))value.splice(0, 1)
                    console.log(value)
                  }}
                  options={[...contactTypes]}
                />
            </Form.Item>
            <Form.Item
                name={'description'}
                rules={[
                  {
                    required: true,
                    message: 'Required'
                  }
                ]}
                label={'Description'}>
                <Input.TextArea size={'large'} rows={4} placeholder={'Enter a description...'}/>
            </Form.Item>
            <div className={'flex gap-2'}>
                <div className={'w-full'}>
                    <CloseModal/>
                </div>
                <Form.Item className={'w-full'}>
                    <Button className={'btn-primary w-full'} size={'large'} htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </div>
        </TlaModalFormWrapper>
  )
}

SkillsForm.propTypes = {
  addSkill: PropTypes.func.isRequired,
  updateSkill: PropTypes.func.isRequired
}

/**
 *
 * @param dispatch
 * @returns {{addSkill: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  addSkill: (data) => dispatch(handleAddSkill(data)),
  updateSkill: (skillId, data) => dispatch(handleUpdateSkill(skillId, data))
})

export default connect(null, mapDispatchToProps)(SkillsForm)
