import React from 'react'
import PropTypes from 'prop-types'

// eslint-disable-next-line react/prop-types
const OptionItemCourses = ({ text, explanation, selected, type, colors, ...props }) => {
  return (
        <React.Fragment>
            <div
                {...props}
                className={`w-full md:w-fit scenario-btn ${colors} bg-opacity-0`} >
                {text}
            </div>
            {
                selected && type === 'SCENARIO' &&
                <div className={`p-2.5 rounded-10 w-full ${colors} bg-opacity-10`}>
                    <h6 className={'font-semibold text-sm mb-[10px]'}>Explanation:</h6>
                    <p className={'text-sm m-0'}>
                        {explanation}
                    </p>
                </div>
            }

        </React.Fragment>
  )
}

OptionItemCourses.defaultProps = {
  selected: false
}

OptionItemCourses.propTypes = {
  selected: PropTypes.bool,
  colors: PropTypes.string,
  type: PropTypes.string
}

export default OptionItemCourses
