import React from 'react'
import { createGlobalStyle } from 'styled-components'
import PropTypes from 'prop-types'
import { Image, Space } from 'antd'

const GlobalStyles = createGlobalStyle`
    .feedback-title {
      color: var(--Gray-500);
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 11px;
    }

    .selected-container {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 74.5px;
      height: 68.88px;
      background: var(--Primary-25);
      border: solid 1px var(--Primary-400);
      box-shadow: 0px 5.47794px 7.66912px #FDFBF5;
      border-radius: 4px;
    }
`

function FeedbackItem ({ icon, title, action, selected }) {
  return (
        <div className={selected ? 'selected-container' : undefined}>
            <Space onClick={() => {
              action({ icon, title })
            }} direction={'vertical'}
                   style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                <GlobalStyles/>
                <Image preview={false} src={icon}/>
                <p className={'feedback-title'}>{title}</p>
            </Space>
        </div>
  )
}

FeedbackItem.defaultProps = {
  icon: <>ICON</>,
  title: 'Title',
  action: () => {
  },
  selected: false
}

FeedbackItem.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  action: PropTypes.func,
  selected: PropTypes.bool
}

export default FeedbackItem
