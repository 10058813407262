import React from 'react'
import PropTypes from 'prop-types'

const Rating = ({ rating = 0, title = '', calculateDots }) => {
  return (
    <div className='flex flex-col gap-[10px] items-center'>
      {
        title &&
        <p className="text-center text-gray-500 text-sm">{title}</p>
      }
      <div className="inline-flex gap-1 justify-center px-[10px] py-[5px]">
        {calculateDots(rating)}
      </div>
    </div>
  )
}

Rating.propTypes = {
  rating: PropTypes.any,
  calculateDots: PropTypes.func.isRequired,
  title: PropTypes.string
}

export default Rating
