import api from '../../../utils/api'
import { addSkillRelationship, allSkillRelationships, deleteSkillRelationship, updateSkillRelationship } from './ActionCreator'

/**
 * Store a newly created resource in storage.
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAddSkillRelationship = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/skill-relationship', data).then((res) => {
      dispatch(addSkillRelationship(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAllSkillRelationships = (pageNumber = 1) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/skill-relationship/all?&page=${pageNumber}&pageSize=15`).then((res) => {
      dispatch(allSkillRelationships(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Update the specified resource in storage.
 * @param skillRelationshipId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleUpdateSkillRelationship = (skillRelationshipId, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().put(`/skill-relationship/${skillRelationshipId}`, data, {
      // headers: { 'Content-type': 'multipart/form-data' }
    }).then((res) => {
      dispatch(updateSkillRelationship(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Remove the specified resource from storage.
 * @returns {function(*): Promise<unknown>}
 * @param skillRelationshipId
 */
export const handleDeleteSkillRelationship = (skillRelationshipId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().delete(`/skill-relationship/${skillRelationshipId}`).then((res) => {
      dispatch(deleteSkillRelationship(skillRelationshipId))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
