import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function addChat (payload) {
  return {
    type: Types.NEW_CHAT,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function allChatList (payload) {
  return {
    type: Types.GET_CHAT_LIST,
    payload
  }
}

export function getChat (payload, chatId) {
  return {
    type: Types.GET_CHAT,
    payload,
    chatId
  }
}

export function deleteChat (payload, chatId) {
  return {
    type: Types.DELETE_CHAT,
    payload,
    chatId
  }
}

export function updateChatTitle (payload, chatId) {
  return {
    type: Types.UPDATE_CHAT,
    payload,
    chatId
  }
}

export function changeGPTExpert (payload) {
  return {
    type: Types.CHANGE_GPT_EXPERT,
    payload
  }
}
