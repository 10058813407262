import React from 'react'
import { Route, Routes } from 'react-router-dom'
import JobQueryForm from '../../pages/quotes/job-query-form'
import CvDifference from '../cv-difference'
import ReportQuestion from '../../pages/feedback/report-question'
import JournalFeedback from '../quick-actions/journal-feedback'
import SelfAssessment from '../quick-actions/self-assessment'
import QuickGiveFeedback from '../quick-actions/quick-give-feedback'

export const ModalRoute = () => {
  return (
        <Routes>
            <Route path={'/quick-action/journal-feedback'} element={<JournalFeedback/>}/>
            <Route path={'/quick-action/self-assessment'} element={<SelfAssessment/>}/>
            <Route path={'/quick-action/give-feedback'} element={<QuickGiveFeedback/>}/>
            <Route path={'/quotes/form'} element={<JobQueryForm/>}/>
            <Route path={'/report/question'} element={<ReportQuestion/>}/>
            <Route path={'/job-applications/:title/difference'} element={<CvDifference/>}/>
        </Routes>
  )
}
