import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Spin, Progress, Button } from 'antd'
import PropTypes from 'prop-types'
import { TlaError, TlaSuccess } from '../../../utils/messages'
import OptionItemCourses from './option-item-courses'
import { handleQuestionSubmission } from '../../../actions/user/common/Action'

function ScenarioQuestion ({ question, submitQuestion, questionSubmitted, courseModuleDetails }) {
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [timer, setTimer] = useState(0)
  const [selected, setSelected] = useState(question.questionAnswer ? question.questionAnswer.answer : null)
  const correctAns = question.questionOptions?.reduce((acc, option, i) => {
    if (option.correct_ans === true) {
      acc.push(i)
    }
    return acc
  }, [])
  // console.log(correctAns, selected)
  const checkAns = (index) => {
    const isCorrect = correctAns.includes(index)
    const isChecked = selected === index
    console.log(index, isCorrect, isChecked)
    if (submitted || question.completed === true) {
      if (isCorrect && isChecked) return 'border-green-500 bg-green-500 text-green-500 font-semibold'
      else if (!isCorrect && isChecked) return 'border-red-500 bg-red-500 text-red-500 font-semibold'
      else if (isCorrect && !isChecked) return 'border-blue-500 bg-blue-500 text-blue-500 font-semibold'
      else return 'border-gray-300 text-gray-300'
    }
    if (isChecked) return 'border-blue-500 bg-blue-500 text-blue-500'
    else return 'border-gray-300 text-black-900'
  }
  const submit = () => {
    setTimeout(() => {
      setTimer(timer + 20)
      if (timer >= 100) {
        setLoading(true)
        setSubmitting(false)
        setTimer(0)
        setSubmitted(true)
        submitQuestion({ ...courseModuleDetails, questionAnswer: { answer: selected } }).then(() => {
          setLoading(false)
          questionSubmitted()
          TlaSuccess('Question Submitted')
        }).catch((err) => {
          console.error('API Error:', err)
          TlaError('Something went wrong in question submission')
        })
      }
    }, 1000)
  }
  const options = question.questionOptions !== undefined ? question.questionOptions : []
  useEffect(() => {
    if (submitting) {
      submit()
    }
  }, [timer, submitting])
  return (
        <div className={'w-full rounded-10 bg-white-base p-5 md:p-[30px]'}>
            <div className={'mb-5'}>
                <p className={'text-black-900 font-medium text-lg'}>
                    {question.questionTitle ?? question.questionTitleSelf}
                </p>
                {
                  question.type === 'MULTI_SELECT' &&
                  <p className='font-semibold leading-6 my-2'>
                    Please select all possible options
                  </p>
                }
            </div>
            <Spin spinning={loading}>
                <div className={`flex flex-col items-start justify-start gap-4 ${question.completed ? 'pointer-events-none' : ''}`}>
                    {
                      options.map(({ score, text, explanation }, index) => {
                        return (
                          <OptionItemCourses
                            onClick={() => {
                              setSelected(index)
                              setSubmitting(true)
                            }}
                            key={index} text={text}
                            explanation={explanation}
                            type={question.type}
                            colors={checkAns(index)}
                            selected={selected === index}
                          />
                        )
                      })
                    }
                </div>
                {
                  submitting &&
                  <div className={'flex items-center justify-center gap-x-3 flex-wrap'}>
                      <p>Submitting</p>
                      <div className={'w-1/2'}>
                          <Progress size={'small'} className={'pt-2'} percent={timer} showInfo={false}/>
                      </div>
                      <Button size={'small'} type={'text'} danger onClick={() => {
                        setSubmitting(false)
                        setTimer(0)
                        setSelected(null)
                      }} className={'btn'}>Cancel</Button>
                  </div>
                }
            </Spin>
        </div>
  )
}

ScenarioQuestion.propTypes = {
  question: PropTypes.object,
  courseModuleDetails: PropTypes.object,
  isMulti: PropTypes.bool,
  questionSubmitted: PropTypes.bool,
  submitQuestion: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => ({
  submitQuestion: (data) => dispatch(handleQuestionSubmission(data))
})

export default connect(null, mapDispatchToProps)(ScenarioQuestion)
