import React, { useEffect, useState } from 'react'
import { Radio, Spin, Progress, Button } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { TlaError, TlaSuccess } from '../../../utils/messages'
import { handleQuestionSubmission } from '../../../actions/user/common/Action'

function RatingQuestionCourses ({ question, questionSubmitted, submitQuestion, courseModuleDetails }) {
  const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState(question.questionAnswer ? question.questionAnswer.answer : 0)
  const [submitting, setSubmitting] = useState(false)
  const [timer, setTimer] = useState(0)
  const { state } = useLocation()

  const getQuestionStatement = () => {
    const statement = self ? question?.questionTitleSelf : question?.questionTitleOthers?.replace('{person}', state?.mainUser?.userInformation?.name)

    statement?.replace('{Person}', state?.mainUser?.userInformation?.name)

    return statement
  }
  const submit = () => {
    setTimeout(() => {
      setTimer(timer + 20)
      if (timer >= 100) {
        console.log('reaching inside')
        setSubmitting(false)
        setTimer(0)
        setLoading(true)
        submitQuestion({ ...courseModuleDetails, questionAnswer: { answer: selected } }).then((res) => {
          setLoading(false)
          questionSubmitted()
          TlaSuccess('Question Submitted')
        }).catch((err) => {
          console.error('API Error:', err)
          TlaError('Something went wrong in question submission in rating question')
        })
      }
    }, 1000)
  }
  useEffect(() => {
    setSelected(question.questionAnswer ? question.questionAnswer.answer : 0)
  }, [question])
  useEffect(() => {
    if (submitting) {
      submit()
    }
  }, [timer, submitting])
  return (
    <Spin spinning={loading}>
        <div>
            <p className={'text-black-900 font-medium text-lg my-4'}>
              {getQuestionStatement()}
            </p>
            <Radio.Group className={`rating-wrapper ${question.completed ? 'pointer-events-none' : ''}`} value={selected} onChange={(e) => {
              setSelected(e.target.value)
              setSubmitting(true)
            }} options={options} optionType="button" buttonStyle="solid"/>
            <div className={'flex justify-between text-gray-500 text-xs mt-[15px]'}>
                <span>{question.lowestScoreHelp ?? 'Strongly Disagree'}</span>
                <span>{question.highestScoreHelp ?? 'Strongly Agree'}</span>
            </div>
        </div>
        {
          submitting &&
          <div className={'flex items-center justify-center gap-x-3 flex-wrap'}>
              <p>Submitting</p>
              <div className={'w-1/2'}>
                  <Progress size={'small'} className={'pt-2'} percent={timer} showInfo={false}/>
              </div>
              <Button size={'small'} type={'text'} danger onClick={() => {
                setSubmitting(false)
                setTimer(0)
                setSelected(null)
              }} className={'btn'}>Cancel</Button>
          </div>
        }
      </Spin>
  )
}

RatingQuestionCourses.defaultProps = {
  lowScoreHelp: null,
  highScoreHelp: null
}

RatingQuestionCourses.propTypes = {
  question: PropTypes.object,
  courseModuleDetails: PropTypes.object,
  submitQuestion: PropTypes.func,
  questionSubmitted: PropTypes.func
}

const mapDispatchToProps = (dispatch) => ({
  submitQuestion: (data) => dispatch(handleQuestionSubmission(data))
})

export default connect(null, mapDispatchToProps)(RatingQuestionCourses)
