import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router'
import TlaTableWrapper from '../../../components/table/tla-table-wrapper'
import { Spin, Table } from 'antd'
import PropTypes from 'prop-types'
import { handleDeleteUser, handleGetAllUsers } from '../../../actions/admin/users/Action'
import { connect } from 'react-redux'
import TlaEdit from '../../../components/tla-edit'
import { FiEdit2 } from 'react-icons/fi'
import TlaConfirm from '../../../components/tla-confirm'
import { TlaSuccess } from '../../../utils/messages'

const Users = ({ getUsers, users, deleteUser }) => {
  const { setPageInfo } = useOutletContext()

  const { data } = users
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setPageInfo({
      title: 'Users',
      buttonText: 'Add Users',
      buttonLink: 'Add Users',
      subTitle: '',
      helpingText: '',
      showSearch: true
    })
    getUsers().then(() => setLoading(false))
  }, [])

  return (
        <div className={'pb-4'}>
            <Spin spinning={loading}>
                <TlaTableWrapper data={data} showHeader={false} meta={users?.meta} callbackFunction={getUsers}>
                    <Table.Column title={'Email'} dataIndex={'email'}/>
                    <Table.Column title={'Competency Type'} dataIndex={'type'}/>
                    <Table.Column title={'Action'} render={((record) => (
                        <div className={'flex items-center'}>
                            <TlaConfirm title={'Delete User'}
                                        fullText={'Do you really want to delete this query?'}
                                        callBack={() => {
                                          deleteUser(record.id).then(() => TlaSuccess('Deleted'))
                                        }}/>
                            <TlaEdit data={record} icon={<FiEdit2 className={'icon'}/>} link={'form'}/>
                        </div>
                    ))}/>
                </TlaTableWrapper>
            </Spin>
        </div>
  )
}

Users.propTypes = {
  getUsers: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired
}

/**
 * @param state
 * @returns {{users: *}}
 */
const mapStateToProps = (state) => ({
  users: state.adminUsersReducer.users
})

/**
 *
 * @param dispatch
 * @returns {{getUsers: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  getUsers: (pageNumber) => dispatch(handleGetAllUsers(pageNumber)),
  deleteUser: (userId) => dispatch(handleDeleteUser(userId))
})

export default connect(mapStateToProps, mapDispatchToProps)(Users)
