import React from 'react'
import { useSelector } from 'react-redux'
import GameStats from '../../games/play-game/game-stats'
import GameResult from '../../games/game-result'
import { useNavigate } from 'react-router-dom'
import { Button } from 'antd'

function RandomGameResult () {
  const { userGamesPlayed } = useSelector(state => state.commonReducer.singlePlayedGame)
  const navigate = useNavigate()
  return (
      <div className={'pb-10'}>
          <div className="rounded-lg p-2 m-4">
              <h2 className="text-2xl font-semibold mb-4">{ userGamesPlayed?.playedContent?.title }</h2>
              <div className={ 'mb-2' }>
                  <GameStats stats={ userGamesPlayed?.finalStats?.final }
                             statsInfo={ userGamesPlayed?.finalStats?.titles }/>
              </div>
              <GameResult game={ userGamesPlayed }/>
              <Button onClick={ () => {
                navigate('/public/nous-games')
              } } size={ 'large' }
                      className="mt-4 w-full bg-blue-500 hover:bg-blue-600 transition-colors text-lg text-white-base hover:!text-white-base">
                  Play New Game
              </Button>
          </div>
      </div>
  )
}

export default RandomGameResult
