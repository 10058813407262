import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'

const AccordionItem = ({ title, subTitle, children, titleClass }) => {
  const [isOpen, setIsOpen] = useState(false)
  const contentRef = useRef(null)
  const [height, setHeight] = useState(0)
  useEffect(() => {
    if (isOpen && contentRef.current) {
      setHeight(contentRef.current.scrollHeight)
    } else {
      setHeight(0)
    }
  }, [isOpen])
  return (
    <div>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className='px-4 py-2 w-full text-left focus:outline-none flex justify-between items-top'
      >
        <div>
          <h2 className={titleClass}>
            {title}
          </h2>
          <h3 className='text-xs text-[#344054]'>
            {subTitle}
          </h3>
        </div>
        <div className='m-1'>
          {!isOpen ? <FiPlusCircle className='w-5 h-5 text-[#98A2B3]' /> : <FiMinusCircle className='w-5 h-5 text-[#98A2B3]' />}
        </div>
      </button>
      <div
        ref={contentRef}
        style={{ maxHeight: `${height}px` }}
        className="overflow-hidden transition-max-height duration-700 ease-in-out"
      >
        <div className="p-2 md:p-4">
          {children}
        </div>
      </div>
    </div>
  )
}

const Accordion = ({ items, titleClass }) => {
  return (
    <div className="flex flex-col">
      {items?.map(item => (
        <AccordionItem
          key={item.title}
          titleClass={titleClass}
          title={item.title}
          subTitle={item.subTitle}
        >{item.description}</AccordionItem>
      ))}
    </div>
  )
}

AccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  titleClass: PropTypes.string,
  children: PropTypes.string.isRequired
}
Accordion.propTypes = {
  titleClass: PropTypes.string,
  items: PropTypes.array.isRequired
}

export default Accordion
