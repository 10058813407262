import React, { useEffect, useMemo, useState } from 'react'
import { Button, Spin } from 'antd'
import { FiArrowRight, FiClock } from 'react-icons/fi'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { handleGameSubmission, handleGetRandomGame } from '../../../actions/user/common/Action'
import { getErrors, getRandomHexColor } from '../../../utils'
import { useNavigate } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import { TlaError } from '../../../utils/messages'
import ShareGameLink from './share-game-link'

const GlobalStyles = createGlobalStyle`
    body {
        background: white;
    }
`
const PlayGame = ({ singleGame, getGame, randomGame, submitGame, userId }) => {
  const scenarios = randomGame?.game?.gameContent?.Scenarios ?? []

  function reformatStats (stats) {
    const reformatted = {}
    const defaultStats = {}

    let i = 0
    for (const [key, title] of Object.entries(stats)) {
      reformatted[key] = {
        title,
        color: getRandomHexColor(i)
      }
      defaultStats[key] = 50
      i++
    }

    // Ensure all keys in defaultStats have corresponding entries in reformatted
    Object.keys(defaultStats).forEach((key, index) => {
      if (!reformatted[key]) {
        reformatted[key] = {
          title: key, // Use the key as the title if no title is provided
          color: getRandomHexColor(index)
        }
      }
    })

    return { reformatted, defaultStats }
  }

  const { reformatted: statInfo, defaultStats } = useMemo(() => {
    const gameStats = randomGame?.game?.stats
    if (typeof gameStats !== 'object' || gameStats === null) {
      console.error('Invalid game stats:', gameStats)
      return { reformatted: {}, defaultStats: {} }
    }
    return reformatStats(gameStats)
  }, [randomGame?.game?.stats])

  const [stats, setStats] = useState(defaultStats || {})
  const [animations, setAnimations] = useState({})

  // Update stats when randomGame changes
  useEffect(() => {
    if (randomGame?.game?.stats) {
      setStats(defaultStats)
    }
  }, [randomGame, defaultStats])

  const [loading, setLoading] = useState(true)
  const [gameState, setGameState] = useState('intro')
  const [currentRound, setCurrentRound] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [lastConsequence, setLastConsequence] = useState(null)
  const [timeLeft, setTimeLeft] = useState(60)
  const [isTimerActive, setIsTimerActive] = useState(false)
  const [isWarningTime, setIsWarningTime] = useState(false)
  const [decisions, setDecisions] = useState([])
  const [showNextButton, setShowNextButton] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [gameUid, setGameUid] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    let timer
    if (isTimerActive && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          const newTime = prevTime - 1
          if (newTime <= 10 && !isWarningTime) {
            setIsWarningTime(true)
          }
          return newTime
        })
      }, 1000)
    } else if (timeLeft === 0) {
      handleTimeUp()
    }
    return () => clearInterval(timer)
  }, [isTimerActive, timeLeft])

  useEffect(() => {
    if (Object.keys(animations).length > 0) {
      const timer = setTimeout(() => setAnimations({}), 2000)
      return () => clearTimeout(timer)
    }
  }, [animations])

  useEffect(() => {
    getGame(singleGame.id, userId).then(() => {
      setLoading(false)
    }).catch(() => setLoading(false))
  }, [])

  const startGame = () => {
    setGameState('playing')
    setCurrentRound(0)
    setStats(defaultStats ?? {})
    setDecisions([])
    startTimer()
  }

  const startTimer = () => {
    setTimeLeft(60)
    setIsTimerActive(true)
    setIsWarningTime(false)
    setShowNextButton(false)
    setSelectedOption(null)
  }

  const handleTimeUp = () => {
    setIsTimerActive(false)
    const randomOption = scenarios[currentRound].options[Math.floor(Math.random() * scenarios[currentRound].options.length)]
    handleChoice(randomOption, true)
  }

  const handleChoice = (option, isAutomatic = false) => {
    setIsTimerActive(false)
    const newStats = { ...stats }
    const newAnimations = {}
    Object.entries(option.statChanges).forEach(([key, change]) => {
      newStats[key] = Math.max(0, Math.min(100, newStats[key] + change))
      newAnimations[key] = change
    })
    setStats(newStats)
    setAnimations(newAnimations)
    setLastConsequence(option.consequences)
    setSelectedOption({ ...option, isAutomatic })

    const newDecisions = [...decisions, {
      round: currentRound + 1,
      scenario: scenarios[currentRound].title,
      choice: option.text,
      consequence: option.consequences,
      isAutomatic
    }]
    setDecisions(newDecisions)

    if ((currentRound + 1) === scenarios.length) {
      setLoading(true)
      submitGame({
        gameId: randomGame?.game?.id + '',
        playedContent: {
          title: randomGame?.game?.topic,
          description: singleGame?.description,
          scenarios: randomGame?.game?.gameContent?.Scenarios ?? [],
          decisions: Object.assign({}, newDecisions)
        },
        finalStats: { final: newStats, titles: randomGame?.game?.stats },
        userId: userId + ''
      }).then((res) => {
        setGameUid(res.data?.data?.userGamesPlayed?.uid)
        setLoading(false)
        setGameState('gameOver')
      }).catch((error) => {
        setLoading(false)
        TlaError(error.response.data.message ?? getErrors(error.response.data?.errors))
      })
    } else {
      setShowNextButton(true)
    }
  }

  const moveToNextRound = () => {
    if (currentRound + 1 < scenarios.length) {
      setCurrentRound(currentRound + 1)
      startTimer()
    }
  }

  const renderTimer = () => (
        <div
            className={ `flex items-center justify-center text-xl font-bold mb-4 ${isWarningTime ? 'animate-pulse text-red-600' : ''}` }>
            <FiClock className="mr-2"/>
            { timeLeft } seconds
        </div>
  )

  const renderStats = () => (
        <div className="grid grid-cols-2 items-end gap-4 p-4 bg-gray-100 rounded-lg w-full">
            { Object.entries(stats)?.map(([key, value], index) => {
              const { title, color } = statInfo[key] || { title: key, color: getRandomHexColor(index) }
              return (
                    <div key={ key } className="relative">
                        <div className="flex justify-between items-center mb-1">
                            <span className="text-sm font-medium text-gray-700">{ title }</span>
                            <span className="text-sm font-bold text-gray-700">{ value }%</span>
                        </div>
                        <div className="h-2 bg-gray-300 rounded-full overflow-hidden">
                            <div
                                className="h-full transition-all duration-1000 ease-out"
                                style={ { width: `${value}%`, backgroundColor: color } }
                            />
                        </div>

                        { animations[key] && (
                            <div
                                className={ `absolute right-0 -top-6 text-sm font-bold ${animations[key] > 0 ? 'text-green-600' : 'text-red-600'}` }
                                style={ { animation: 'fadeOutUp 2s ease-out' } }
                            >
                                { animations[key] > 0 ? '+' : '' }{ animations[key] }
                            </div>
                        ) }
                    </div>
              )
            }) }
        </div>
  )

  const renderGameContent = () => {
    const scenario = scenarios[currentRound]

    return (
            <Spin spinning={ loading }>
                <div className="flex flex-col bg-white text-gray-800 p-4">
                    <div className="mb-4 flex justify-between items-center">
                        <h1 className="text-2xl font-bold">{ randomGame?.game?.topic }</h1>
                        <div className="text-sm">{ currentRound + 1 }/{ scenarios.length }</div>
                    </div>
                    { renderStats() }
                    <div className={ 'pt-5' }>
                        { !showNextButton && renderTimer() }
                    </div>
                    <div className="flex-grow overflow-y-auto my-4 bg-gray-100 rounded-lg p-4">
                        <h2 className="text-xl font-semibold mb-2">{ scenario.title }</h2>
                        <p className="mb-4">{ scenario.description }</p>
                        { !selectedOption
                          ? (
                                <div className="space-y-2">
                                    { scenario.options.map((option, index) => (
                                        <div
                                            key={ index }
                                            onClick={ () => handleChoice(option) }
                                            className="cursor-pointer rounded-lg w-full !bg-[#000] hover:!bg-gray-800 !border-[#000] transition-colors text-left justify-start h-auto py-2 px-4 text-white-base hover:!text-white-base"
                                        >
                                            { option.text }
                                        </div>
                                    )) }
                                </div>
                            )
                          : (
                                <div className="bg-white-base p-4 rounded-lg shadow">
                                    <p className="font-semibold">Your Choice: { selectedOption.text }</p>
                                    <p className="text-sm text-gray-600 mt-2">Consequence: { selectedOption.consequences }</p>
                                    { selectedOption.isAutomatic && (
                                        <p className="text-sm text-red-600 mt-2">This choice was made automatically due
                                            to
                                            time running out.</p>
                                    ) }
                                </div>
                            ) }
                    </div>
                    {/* { lastConsequence && (
                    <div className="bg-yellow-100 border-yellow-400 text-yellow-800 flex items-center gap-x-2 p-3 mb-5">
                        <FiAlertCircle className="h-4 w-4"/>
                        <div>
                            <div>Decision Impact</div>
                            <div>{ lastConsequence }</div>
                        </div>
                    </div>
                ) } */ }
                    { showNextButton && (
                        <Button size={ 'large' }
                                onClick={ moveToNextRound }
                                className="mt-4 w-full bg-green-500 border border-green-500
                         hover:!border-green-500 hover:bg-green-600 transition-colors
                          text-lg py-3 text-white-base hover:!text-white-base flex items-center justify-center"
                        >
                            {
                                (currentRound + 1) === scenarios.length
                                  ? 'Submit'
                                  : 'Next Round'
                            }
                            <FiArrowRight className="ml-2"/>
                        </Button>
                    ) }
                </div>
            </Spin>
    )
  }

  const renderIntro = () => (
        <Spin spinning={ loading }>
            {
                !randomGame
                  ? <div className={ 'text-center pt-20' }>
                        <div>
                            {
                                loading
                                  ? 'Please wait'
                                  : <div>
                                        <p className={ 'text-xl mb-3' }>No Game Found</p>
                                        <Button size={ 'large' }
                                                className={ 'bg-blue-500 hover:bg-blue-600 transition-colors text-lg text-white-base hover:!text-white-base' }
                                                onClick={ () => navigate(-1) }>
                                            Go Back
                                        </Button>
                                    </div>
                            }
                        </div>
                    </div>
                  : <div>
                        {
                            randomGame?.subscriptionStatus === false
                              ? <div>
                                    <div className={ 'text-center' }>
                                        <p className={ 'text-xl mb-3' }>Subscription Required</p>
                                        <Button size={ 'large' }
                                                className={ 'btn btn-primary w-fit mx-auto' }
                                                onClick={ () => navigate(-1) }>
                                            Subscribe
                                        </Button>
                                    </div>
                                </div>
                              : <div>
                                    <h1 className="text-xl font-bold mb-2 w-[90%] md:w-[450px] px-4">{ randomGame?.game?.topic }</h1>
                                    <div
                                        className="flex flex-col items-center justify-center bg-white text-gray-800 px-4">
                                        { renderStats() }
                                        <p className="text-left mt-5">Your starting stats</p>
                                        <div className="p-6 rounded-lg max-w-md w-full">
                                            <Button onClick={ startGame } size={ 'large' }
                                                    className="w-full btn btn-primary">
                                                Start Game
                                            </Button>
                                            <p className={ 'mt-5 text-xs' }>
                                                All games are designed for educational purposes only. The content of the games
                                                has
                                                been inspired from prevalent scientific research on psychology, behavioral
                                                science,
                                                and neuroplasticity.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
            }
        </Spin>
  )

  const renderGameOver = () => (
        <div className="flex flex-col bg-white text-gray-800 p-4 overflow-auto">
            <h1 className="text-3xl font-bold mb-6 text-center">Game Over</h1>
            <div className="bg-gray-100 rounded-lg p-4 mb-4">
                <h2 className="text-2xl font-semibold mb-4">Your Final Business Stats</h2>
                { renderStats() }
            </div>
            <div className="bg-gray-100 rounded-lg p-4 mb-4">
                <h2 className="text-2xl font-semibold mb-4">Your Journey</h2>
                <div className="space-y-4">
                    { decisions.map((decision, index) => (
                        <div key={ index } className="bg-white-base p-4 rounded-lg shadow">
                            <p className="font-semibold">{ decision.round }. { decision.scenario }</p>
                            <p>Choice: { decision.choice } { decision.isAutomatic && '(Automatic)' }</p>
                            <p className="text-sm text-gray-600">Result: { decision.consequence }</p>
                        </div>
                    )) }
                </div>
            </div>
            <ShareGameLink uuid={ gameUid }/>
            <Button onClick={ () => {
              navigate('/nous-games/play')
            } } size={ 'large' }
                    className="mt-4 w-full bg-blue-500 hover:bg-blue-600 transition-colors text-lg text-white-base hover:!text-white-base">
                Play New Game
            </Button>
        </div>
  )

  switch (gameState) {
    case 'intro':
      return <><GlobalStyles/> { renderIntro() }</>
    case 'playing':
      return <><GlobalStyles/> { renderGameContent() }</>
    case 'gameOver':
      return <><GlobalStyles/> { renderGameOver() }</>
    default:
      return null
  }
}

PlayGame.propTypes = {
  singleGame: PropTypes.object,
  randomGame: PropTypes.object,
  getGame: PropTypes.func,
  submitGame: PropTypes.func,
  userId: PropTypes.number
}

const mapStateToProps = (state) => ({
  singleGame: state.commonReducer.singleGame,
  randomGame: state.commonReducer.randomGame,
  userId: state.loginReducer.authUser.id
})

const mapDispatchToProps = (dispatch) => ({
  getGame: (categoryId, userId) => dispatch(handleGetRandomGame(categoryId, userId)),
  submitGame: (data) => dispatch(handleGameSubmission(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PlayGame)
