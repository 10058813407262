import React, { useEffect, useState } from 'react'
import AuthLayout from './auth-layout'
import AuthBottomLink from './auth-bottom-link'
import { connect } from 'react-redux'
import { handleEmailVerification } from '../../actions/authenticate/Actions'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

function VerifyEmail ({ verifyEmail }) {
  const [loading, setLoading] = useState(true)

  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const email = query.get('email')
  const code = query.get('verificationCode')

  useEffect(() => {
    verifyEmail(email, code).then(() => setLoading(false))
  }, [])

  return (
        <AuthLayout pageTitle={'Verify your email'}>
            <div className={'text-center'}>
                {
                    loading
                      ? <p className={'text-xl'}>Verification in progress. Please wait!</p>
                      : <div>
                            <p className={'text-2xl'}>Verification Complete</p>
                            <AuthBottomLink size={'sm'} text={''} link={'/'} linkText={'Login Here'}/>
                        </div>
                }
            </div>
        </AuthLayout>
  )
}

VerifyEmail.propTypes = {
  verifyEmail: PropTypes.func.isRequired
}
const mapDispatchToProps = (dispatch) => ({
  verifyEmail: (email, code) => dispatch(handleEmailVerification(email, code))
})

export default connect(null, mapDispatchToProps)(VerifyEmail)
