import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { useSelector } from 'react-redux'
import Clients from '../../../../assets/images/dashboard/clients.svg'
import FamilyAndFriends from '../../../../assets/images/dashboard/f-and-f.svg'
import TotalAssessment from '../../../../assets/images/dashboard/total-assement.svg'
import { Col, Row } from 'antd'
import DashItem from './dash-item'

const GlobalStyles = createGlobalStyle`

  .dash-item{
    margin-bottom: 12px;
    text-align: left;
    display: flex;
    align-items: center;
    align-content: center;
    height: 50px;
  }
  
  .supporting-text {
    padding-top: 8px;
    margin-bottom: -8px;
    font-weight: 700;
  }
`

function DashItems () {
  const info = useSelector((state) => state.loginReducer.authUser?.userInformation)
  return (
        <div>
            <GlobalStyles/>
            <Row>
                <Col span={24}>
                    <DashItem icon={TotalAssessment}
                              title={info?.assessmentsReceivedTotal ?? 33}
                              subTitle={'Feedbacks In'}/>
                </Col>
                <Col span={24}>
                    <DashItem icon={FamilyAndFriends} title={info?.assessmentsGivenTotal ?? 22} subTitle={'Feedbacks' +
                        ' Out'}/>
                </Col>
                {/* <Col span={24}>
                    <DashItem icon={Colleagues} title={info?.assessmentGivenTotal ?? 33} subTitle={'Nous Assessment'}/>
                </Col> */}
                <Col span={24}>
                    <DashItem icon={Clients} title={info?.totalContacts ?? 33} subTitle={'Contacts'}/>
                </Col>
            </Row>
        </div>
  )
}
export default DashItems
