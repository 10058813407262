import api from '../../../utils/api'
import { addContentPost, allContentPosts, deleteContentPost, updateContentPost, importContentPosts } from './ActionCreator'

/**
 * Store a newly created resource in storage.
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAddContentPost = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/content-post', data).then((res) => {
      dispatch(addContentPost(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const handleImportContentPosts = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api('multipart/form-data').post('/auth/seed-content-posts/', data).then((res) => {
      dispatch(importContentPosts(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAllContentPosts = (pageNumber = 1, filters = null) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/content-post?page=${pageNumber}&pageSize=15&skillId=${filters?.skill}`).then((res) => {
      dispatch(allContentPosts(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Update the specified resource in storage.
 * @param postId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleUpdateContentPost = (postId, data) => (dispatch) => {
  const updatePromise = new Promise((resolve, reject) => {
    api().put(`/content-post/${postId}`, data, {
    }).then((res) => {
      dispatch(updateContentPost(res.data))
      handleGetAllContentPosts()
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
  const getContentPostPromise = new Promise((resolve, reject) => {
    api().get(`/content-post?page=${1}&pageSize=15`).then((res) => {
      dispatch(allContentPosts(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
  return updatePromise.then((result1) => {
    return getContentPostPromise
  }).then((result2) => {
  })
  // return new Promise((resolve, reject) => {
  //   api().put(`/content-post/${postId}`, data, {
  //   }).then((res) => {
  //     dispatch(updateContentPost(res.data))
  //     handleGetAllContentPosts()
  //     resolve(res)
  //   }).catch((err) => {
  //     reject(err)
  //   })
  // })
}

/**
 * Remove the specified resource from storage.
 * @returns {function(*): Promise<unknown>}
 * @param postId
 */
export const handleDeleteContentPost = (postId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().delete(`/content-post/${postId}`).then((res) => {
      dispatch(deleteContentPost(postId))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
