import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function addQuizForm (payload) {
  return {
    type: Types.NEW_QUIZ_FORM,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function allQuizForms (payload) {
  return {
    type: Types.ALL_QUIZ_FORMS,
    payload
  }
}

/**
 *
 * @param post
 * @returns {{post, type: string}}
 */
export function deleteQuizForm (quizFormId) {
  return {
    type: Types.DELETE_QUIZ_FORM,
    quizFormId
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function updateQuizForm (payload) {
  return {
    type: Types.UPDATE_QUIZ_FORM,
    payload
  }
}
