import React from 'react'
import ProfileBg from '../assets/images/dashboard/profile-bg.svg'
import DefaultImage from '../assets/images/default.png'
import { useSelector } from 'react-redux'
import ShareFeedback from './share-feedback'
import TotalAssessment from '../assets/images/dashboard/total-assement.svg'
import FamilyAndFriends from '../assets/images/dashboard/f-and-f.svg'
import DashItem from '../pages/dashboard/layout/sidebar/dash-item'

const MobileProfile = () => {
  const avatar = DefaultImage
  const profile = useSelector(state => state.loginReducer?.authUserInfo?.userInformation)
  return (
        <div className={'rounded-lg bg-white-base block md:hidden pb-5 mx-2'}>
            <div className={'rounded-t-lg h-[40px] bg-cover'}
                 style={{ background: `url(${ProfileBg})`, backgroundSize: 'cover' }}>
            </div>
            <div className={'mx-8'}>
                <div className={'flex items-center gap-x-4 -mt-6'}>
                    <img alt={''} className={'border-2 border-white-base rounded-full h-[80px] w-[80px]'}
                         src={profile?.photo !== 'default.png' ? profile?.photo : avatar}/>
                    <div className={'mt-4'}>
                        <h3 className={'text-base'}>{profile?.name ?? ''}</h3>
                        <h4 className={'text-gray-500 text-sm'}>{profile?.jobTitle ?? ''}</h4>
                    </div>
                </div>
                <div className={'mr-auto w-fit mt-[22px] mb-[19px]'}>
                    <ShareFeedback/>
                </div>
                <div className={'flex justify-between items-center'}>
                    <DashItem icon={TotalAssessment}
                              title={profile?.assessmentsReceivedTotal ?? 33}
                              subTitle={'Feedbacks In'}/>
                    <DashItem icon={FamilyAndFriends} title={profile?.assessmentsGivenTotal ?? 22} subTitle={'Feedbacks' +
                        ' Out'}/>
                </div>
            </div>
        </div>
  )
}
export default MobileProfile
