import React, { useEffect, useState } from 'react'
import TlaTableWrapper from '../../components/table/tla-table-wrapper'
import { Spin, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { handleDeleteOrganization, handleGetAllOrganizations } from '../../actions/admin/organization/Action'
import { useOutletContext } from 'react-router'
import TlaConfirm from '../../components/tla-confirm'
import TlaEdit from '../../components/tla-edit'
import { FiEdit2 } from 'react-icons/fi'
import OrgLogo from '../../components/navigation/org-logo'
import { TlaSuccess } from '../../utils/messages'

const Index = () => {
  const userId = useSelector(state => state.loginReducer.authUser.id)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const { setPageInfo } = useOutletContext()
  const { data, meta } = useSelector(state => state.organizationReducer.organizations)

  useEffect(() => {
    setPageInfo({
      title: 'Organizations',
      buttonText: 'Add Organization',
      buttonLink: 'Add Organization',
      subTitle: '',
      helpingText: '',
      showSearch: true
    })
    dispatch(handleGetAllOrganizations(userId)).then(_ => {
      setLoading(false)
    })
  }, [])

  return (
        <div className={'pb-4'}>
            <Spin spinning={loading}>
                <TlaTableWrapper
                    numberColumn={false}
                    meta={meta}
                    data={data}
                    showHeader={false} hasSelection
                    callbackFunction={() => {
                    }}>
                    <Table.Column title={'Action'} render={((record) => (
                        <div className={'flex items-center gap-x-2'}>
                            <OrgLogo altText={record.name} logo={record.logo}/>
                            <p>{record.name}</p>
                        </div>
                    ))}/>
                    <Table.Column title={'Name'} dataIndex={'name'}/>
                    <Table.Column title={'Status'} dataIndex={'status'}/>
                    <Table.Column title={'Allowed Users'} dataIndex={'allowed_users'}/>
                    <Table.Column title={'Paid Access'} dataIndex={'paid_access'}/>
                    <Table.Column title={'Action'} render={((record) => (
                        <div className={'flex items-center'}>
                            <TlaConfirm title={'Delete Organization'}
                                        fullText={'Do you really want to delete?'}
                                        callBack={() => {
                                          dispatch(handleDeleteOrganization(record.id))
                                            .then(() => TlaSuccess('Organization Deleted'))
                                        }}/>
                            <TlaEdit data={record} icon={<FiEdit2 className={'icon'}/>} link={'form'}/>
                        </div>
                    ))}/>
                </TlaTableWrapper>
            </Spin>
        </div>
  )
}

export default Index
