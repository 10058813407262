import OrgLayout from '../../pages/organization/layout'
import { Route } from 'react-router-dom'
import React from 'react'
import OrgUsers from '../../pages/organization/org-users'

export default [
    <Route key={'org-routes'} exact element={<OrgLayout/>} path="/:name">
        <Route index element={<OrgUsers/>} path={'users'}/>
    </Route>
]
