import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Spin } from 'antd'
import { handleMakeRequest, handleSendReminder } from '../../actions/user/contacts/Action'
import { connect } from 'react-redux'
import { TlaError, TlaSuccess } from '../../utils/messages'
import { LoadingOutlined } from '@ant-design/icons'
import { dateDifference } from '../../utils'

function RequestButton ({ record, addContact, sendReminder }) {
  const [loading, setLoading] = useState(false)
  const makeRequest = () => {
    setLoading(true)
    /* const data = {
              mainUserId: record?.mainUserId,
              name: record?.contactUser?.userInformation?.name,
              email: record?.contactUser?.email,
              type: 'Invite'
            } */
    const data = {
      mainUserId: record?.mainUserId,
      contactUserId: record?.contactUser?.id
      // name: record?.contactUser?.userInformation?.name,
      // email: record?.contactUser?.email,
      // type: 'Add',
      // feedbackUrl: 'requests/give-feedback',
      // startDate: dayjs().toString(),
      // requestDate: dayjs().toString(),
      // feedQuantity: 10
    }
    addContact(data).then((res) => {
      TlaSuccess(res.data.data.message)
      setLoading(false)
    }).catch((error) => {
      TlaError(error.response.data.message)
      setLoading(false)
    })
  }

  const submitReminder = () => {
    setLoading(true)
    sendReminder({ userId: record?.contactUser?.id }).then((res) => {
      TlaSuccess(res.data.data.message)
      setLoading(false)
    }).catch((error) => {
      TlaError(error.response.data.message)
      setLoading(false)
    })
  }

  return (
        <div>
            {
                (record?.requestDate && !record?.lastAssessDate) &&
                <Spin spinning={loading} indicator={<LoadingOutlined/>}>
                    <Button
                        onClick={submitReminder}
                        className={'py-2 px-3.5 bg-primary-50 rounded-lg text-primary-700 text-sm font-medium' +
                            ' border-primary-50 flex items-center justify-center hover:border-primary-50' +
                            ' hover:text-primary-700'}>
                        Send Reminder
                    </Button>
                </Spin>
            }

            {
                (record?.requestDate && record?.lastAssessDate) &&
                <Spin spinning={loading} indicator={<LoadingOutlined/>}>
                    <Button disabled={dateDifference(record?.lastAssessDate) < 1}
                            onClick={makeRequest}
                            className={'py-2 px-3.5 bg-primary-50 rounded-lg text-primary-700 text-sm font-medium' +
                                ' border-primary-50 flex items-center justify-center hover:border-primary-50' +
                                ' hover:text-primary-700'}>
                        Request
                    </Button>
                </Spin>
            }
        </div>
  )
}

RequestButton.propTypes = {
  record: PropTypes.object,
  addContact: PropTypes.func.isRequired,
  sendReminder: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => ({
  addContact: (data) => dispatch(handleMakeRequest(data)),
  sendReminder: (data) => dispatch(handleSendReminder(data))
})

export default connect(null, mapDispatchToProps)(RequestButton)
