import React from 'react'
import PlayNewGame from '../../games/play-new-game'
import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
    //body {
    //    background: white;
    //}
`

function PublicGameCategories () {
  return (
        <div className={ 'w-[90%] md:w-[900px] bg-white-base rounded-lg p-10 mx-auto mt-5' }>
            <GlobalStyles/>
            <div className={ 'mb-8' }>
                <h3 className={ 'text-center text-gray-900 font-semibold text-3xl md:text-[40px] leading-[38px] mb-6' +
                    ' mt-[50px]' }>
                    Play Nous Games
                </h3>
                <p className={ 'w-[90%] md:w-[539px] text-center text-gray-500 text-sm mx-auto' }>
                    All games are designed for educational purposes only. The content of the games has been inspired
                    from prevalent scientific research on psychology, behavioral science, and neuroplasticity.
                </p>
            </div>
            <PlayNewGame/>
        </div>
  )
}

export default PublicGameCategories
