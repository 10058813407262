import { createGlobalStyle } from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

const Styles = createGlobalStyle`
  .title {
    margin-top: 10px;
    font-size: 12px;
    line-height: 1;
    color: var(--Gray-800);
    text-align: center;
  }
`
const format = (value) => {
  return (Math.round(value * 100) / 100).toFixed(1)
}
const styles = (fill) => (
  buildStyles({
    trailColor: '#EFF8FF',
    pathColor: fill
  })
)
function TlaCircleProgress ({ title, absoluteScore, normalizedScore }) {
  return (
        <div id="chart" className={'text-center max-h-[90px] md:max-h-[100px] max-w-[90px] md:max-w-[100px]'}>
            <Styles/>
            <div>
                <CircularProgressbarWithChildren
                    value={(absoluteScore / 10) * 100}
                    strokeWidth={5}
                    styles={styles('#1849A9')}>
                    <div style={{ width: '85%' }}>
                        <CircularProgressbarWithChildren
                            value={(normalizedScore / 10) * 100}
                            strokeWidth={5}
                            styles={styles('#53B1FD')}>
                            <div style={{ marginTop: 15, fontSize: 12, lineHeight: 0 }}>
                                <p style={{ paddingBottom: 10, color: '#1849A9' }}>{format(absoluteScore)}</p>
                                <p style={{ paddingTop: 6, marginBottom: 10, color: '#53B1FD' }}>{format(normalizedScore)}</p>
                            </div>
                        </CircularProgressbarWithChildren>
                    </div>
                </CircularProgressbarWithChildren>
                <p className={'title'}>{title}</p>
            </div>
        </div>
  )
}

TlaCircleProgress.defaultProps = {
  title: '',
  absoluteScore: 0,
  normalizedScore: 0
}

TlaCircleProgress.propTypes = {
  title: PropTypes.string,
  absoluteScore: PropTypes.number,
  normalizedScore: PropTypes.number
}

export default TlaCircleProgress
