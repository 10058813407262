import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router'
import TlaTableWrapper from '../../../components/table/tla-table-wrapper'
import { Spin, Table } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { handleDeletePersonality, handleGetAllPersonalities } from '../../../actions/admin/personalities/Action'
import TlaConfirm from '../../../components/tla-confirm'
import { TlaSuccess } from '../../../utils/messages'
import TlaEdit from '../../../components/tla-edit'
import { FiEdit2 } from 'react-icons/fi'
import TlaSelect from '../../../components/TlaSelect'
import { handleGetAllSkillsNoPagination } from '../../../actions/admin/skills/Action'

const Personalities = ({ getPersonalities, deletePersonality, personalities, skills, getSkills }) => {
  const { setPageInfo } = useOutletContext()
  const [loading, setLoading] = useState(true)
  const [skill, setSkill] = useState(0)

  useEffect(() => {
    setPageInfo({
      title: 'Personalities',
      buttonText: 'Add Personalities',
      buttonLink: 'Add Personalities',
      subTitle: '',
      helpingText: '',
      showSearch: false
    })
    getSkills(1, 500).then(() => {})
    getPersonalities().then(() => {
      setLoading(false)
    })
  }, [])
  return (
        <div className={'pb-4'}>
            <Spin spinning={loading}>
              <div className="w-1/4 ml-auto pr-6 mb-5">
                <TlaSelect
                  defaultValue='Select Skill'
                  onChange={(value) => {
                    setLoading(true)
                    getPersonalities(1, { skill: value }).then(() => {
                      setLoading(false)
                      setSkill(value)
                    })
                  }}
                  required name={'skillId'} options={[...skills]} optionKey={'title'}/>
              </div>
                <TlaTableWrapper
                    numberColumn={true}
                    meta={personalities?.meta} data={personalities?.data}
                    showHeader={false}
                    filters={{ skill }}
                    callbackFunction={getPersonalities}>
                    <Table.Column title={'Personality Title'} width={250} render={((record) => record.personalityTitle)}/>
                    <Table.Column title={'Type'} render={((record) => record.personalityType)}/>
                    <Table.Column title={'Status'} render={((record) => (
                        <span className={'bg-blue-200 text-blue-600 py-1 px-2 rounded-2xl text-xs font-bold mr-1 whitespace-nowrap'}>
                            {record.professionalStatus }
                        </span>
                    ))}/>
                    <Table.Column title={'Action'} render={((record) => (
                        <div className={'flex items-center'}>
                            <TlaConfirm title={'Delete Skill'}
                                        fullText={'Do you really want to delete this personality?'}
                                        callBack={() => {
                                          setLoading(true)
                                          deletePersonality(record.id)
                                            .then(() => {
                                              setLoading(false)
                                              TlaSuccess('Personality Deleted')
                                            })
                                        }}/>
                            <TlaEdit data={record} icon={<FiEdit2 className={'icon'}/>} link={'form'}/>
                        </div>
                    ))}/>
                </TlaTableWrapper>
            </Spin>
        </div>
  )
}

Personalities.propTypes = {
  getPersonalities: PropTypes.func.isRequired,
  skills: PropTypes.array.isRequired,
  getSkills: PropTypes.func.isRequired,
  deletePersonality: PropTypes.func.isRequired,
  personalities: PropTypes.object.isRequired
}

/**
 * @param state
 * @returns {{skills: *}}
 */
const mapStateToProps = (state) => ({
  personalities: state.adminPersonalitiesReducer.personalities,
  skills: state.adminSkillsReducer.skills.data
})

/**
 *
 * @param dispatch
 * @returns {{getPersonalities: (function(*): *), deletePersonality: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  getPersonalities: (pageNumber, skillId) => dispatch(handleGetAllPersonalities(pageNumber, skillId)),
  deletePersonality: (skillId) => dispatch(handleDeletePersonality(skillId)),
  getSkills: () => dispatch(handleGetAllSkillsNoPagination())
})

export default connect(mapStateToProps, mapDispatchToProps)(Personalities)
