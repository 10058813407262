import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { handleGetPlayedGame } from '../../actions/user/common/Action'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import { useParams } from 'react-router'
import { createGlobalStyle } from 'styled-components'
import GameResult from '../games/game-result'
import GameStats from '../games/play-game/game-stats'

const GlobalStyles = createGlobalStyle`
    body {
      //background: white;
    }  
`

function PublicPlayedGame ({ getPlayedGame }) {
  const [loading, setLoading] = React.useState(true)
  const { uuid } = useParams()
  const [game, setGame] = useState(null)

  useEffect(() => {
    getPlayedGame(uuid)
      .then((res) => {
        setGame(res.data?.data?.userGamesPlayed)
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }, [])
  return (
        <div>
            <GlobalStyles/>
            <Spin spinning={loading}>
                {
                    loading
                      ? 'Please Wait'
                      : <div className="rounded-lg p-4 m-4 w-[90%] md:w-1/2 mx-auto bg-white-base">
                            <h2 className="text-2xl font-semibold mb-4">{ game?.playedContent?.title }</h2>
                            <div className={ 'mb-2' }>
                                <GameStats stats={ game?.finalStats?.final }
                                           statsInfo={ game?.finalStats?.titles }/>
                            </div>
                            <GameResult game={ game }/>
                        </div>
                }

            </Spin>
        </div>
  )
}

PublicPlayedGame.propTypes = {
  getPlayedGame: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => ({
  getPlayedGame: (uuid) => dispatch(handleGetPlayedGame(uuid))
})

export default connect(null, mapDispatchToProps)(PublicPlayedGame)
