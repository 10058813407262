import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Spin } from 'antd'

function SingleNousGame ({ quiz }) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const setQuiz = () => {
    dispatch({ type: 'SET_SINGLE_QUESTION', payload: quiz })
    navigate(pathname === '/public/nous-games' ? `/public/random-nous-game/${quiz.id}` : '/game/play')
  }
  useEffect(() => {
    setLoading(false)
  }, [])
  if (!quiz) {
    return (
      <div className='bg-white-base p-4 mb-3 rounded-xl w-full flex justify-center'>
        <Spin spinning={loading}></Spin>
      </div>
    )
  }
  return (
    <div className={'bg-gray-25 py-4 mb-3 rounded-xl w-full border-[#F4F4F4] border-2'}>
      <div className="px-4">
        <h3 className="font-semibold text-lg text-gray-900">{quiz.title}</h3>
        <p className={'text-base'}>{quiz.description}</p>
        <div className="my-1 pb-3 mt-4 flex justify-end">
          <div onClick={setQuiz} className='text-center cursor-pointer w-full md:w-fit bg-[#1570EF] text-white-base rounded-lg px-4 py-2 hover:text-white-base hover:bg-blue-600'>
            Play Now
          </div>
        </div>
      </div>
    </div>
  )
}

SingleNousGame.propTypes = {
  quiz: PropTypes.object
}

export default SingleNousGame
