import React from 'react'
import PropTypes from 'prop-types'

function SectionHeader ({ title, children }) {
  return (
        <div className={'flex flex-wrap gap-2 justify-between items-center'}>
            <h3 className={'text-lg font-medium leading-[28px]'}>{title}</h3>
            <div className={'flex gap-3'}>
                {children}
            </div>
        </div>
  )
}

SectionHeader.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
}

export default SectionHeader
