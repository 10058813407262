import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'

function Results () {
  return (
      <div className={ 'bg-white-base rounded-lg mx-auto w-[90%] md:w-[800px] p-5' }>
          <div className={'mb-[100px]'}>
              <h3 className={ 'text-center text-gray-900 font-semibold text-3xl md:text-[40px] leading-[38px] mb-6 mt-[100px]' }>
                  Results
              </h3>
              <p className={ 'w-[90%] md:w-fit text-center text-gray-500 text-xl mx-auto' }>
                  Log into account to see more your results.
              </p> <br/>
              <Link to={ '/login' }>
                  <Button className={ 'btn btn-primary-600 !w-[185px] mx-auto' } size={ 'large' }>Login</Button>
              </Link>
          </div>
      </div>
  )
}

export default Results
