import api from '../../../utils/api'
import { addOrganization, allOrganizations, deleteOrganization, updateOrganization } from './ActionCreator'

/**
 * Store a newly created resource in storage.
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAddOrganization = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/organization', data, {
      headers: { 'Content-type': 'multipart/form-data' }
    }).then((res) => {
      dispatch(addOrganization(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAllOrganizations = (userId, pageNumber = 1) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/organization/all?page=${pageNumber}&pageSize=15&userId=${userId}`).then((res) => {
      dispatch(allOrganizations(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Update the specified resource in storage.
 * @param organizationId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleUpdateOrganization = (organizationId, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().put(`/organization/${organizationId}`, data, {
      headers: { 'Content-type': 'multipart/form-data' }
    }).then((res) => {
      dispatch(updateOrganization(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Remove the specified resource from storage.
 * @returns {function(*): Promise<unknown>}
 * @param organizationId
 */
export const handleDeleteOrganization = (organizationId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().delete(`/organization/${organizationId}`).then((res) => {
      dispatch(deleteOrganization(organizationId))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
