import React, { useEffect } from 'react'
import { Button, Form, InputNumber } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import CloseModal from '../../../components/close-modal.js'
import { handleAddCourseModule, handleUpdateCourseModule } from '../../../actions/admin/course-modules/Action.js'
import TlaSelect from '../../../components/TlaSelect.js'
import { handleGetAllCourses } from '../../../actions/admin/courses/Action.js'
import { handleGetAllModules } from '../../../actions/admin/modules/Action.js'
import TlaModalFormWrapper from '../../../components/tla-modal-form-wrapper.js'

function CourseModulesForm ({ addCourseModule, updateCourseModule, courses, getCourses, modules, getModules, questions }) {
  const { state } = useLocation()
  const resourceId = state?.data?.id
  const formValues = {
    ...state?.data
  }
  useEffect(() => {
    getCourses()
    getModules()
  }, [])
  return (
      <TlaModalFormWrapper
          initialValues={formValues}
          formTitle={`${resourceId ? 'Update Link' : 'Link'} Course Module`}
          resourceId={state?.data?.id ?? null}
          onSubmit={state?.data?.id ? updateCourseModule : addCourseModule}>

                    <TlaSelect
                        customClass='my-2'
                        name={'courseId'}
                        placeholder={'Select Course'}
                        options={courses}
                        optionKey={'title'} label={'Select Course *'} required/>
                    <TlaSelect
                        customClass='my-2 mb-3'
                        name={'moduleId'}
                        placeholder={'Select Module'}
                        options={modules}
                        optionKey={'title'} label={'Select Module *'} required/>

                    <Form.Item
                        name={'sequence'}
                        rules={[
                          {
                            required: true,
                            message: 'Required'
                          }
                        ]}
                        label={'Sequence Number *'}>
                        <InputNumber className='w-full' size={'large'} placeholder={'Eg 3'}/>
                    </Form.Item>
                    <div className={'flex gap-2'}>
                        <div className={'w-full'}>
                            <CloseModal/>
                        </div>
                        <Form.Item className={'w-full'}>
                            <Button className={'btn-primary w-full'} size={'large'} htmlType="submit">
                                {`${resourceId ? 'Update' : 'Create'}`} Course Module Link
                            </Button>
                        </Form.Item>
                    </div>
      </TlaModalFormWrapper>
  )
}

CourseModulesForm.propTypes = {
  addCourseModule: PropTypes.func.isRequired,
  updateCourseModule: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  courses: PropTypes.array.isRequired,
  getCourses: PropTypes.func.isRequired,
  modules: PropTypes.array.isRequired,
  questions: PropTypes.array.isRequired,
  getModules: PropTypes.func.isRequired
}

/**
 * @param state
 * @returns {{courses: *}}
 */
const mapStateToProps = (state) => ({
  courses: state.adminCoursesReducer.courses?.data,
  modules: state.adminModulesReducer.modules?.data,
  userId: state.loginReducer.authUser.id
})

/**
 *
 * @param dispatch
 * @returns {{addCourseModule: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  addCourseModule: (data) => dispatch(handleAddCourseModule(data)),
  updateCourseModule: (courseModuleId, data) => dispatch(handleUpdateCourseModule(courseModuleId, data)),
  getCourses: (pageNumber, pageSize) => dispatch(handleGetAllCourses(pageNumber, pageSize)),
  getModules: (pageNumber, pageSize) => dispatch(handleGetAllModules(pageNumber, pageSize))
})

export default connect(mapStateToProps, mapDispatchToProps)(CourseModulesForm)
