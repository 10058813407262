import React, { useState } from 'react'
import { createGlobalStyle } from 'styled-components'
import { connect, useSelector } from 'react-redux'
import { Button, Divider, Form, Select } from 'antd'
import PropTypes from 'prop-types'
import FeedbackItem from './feedback-item'
import { TlaSuccess, TlaWarning } from '../../utils/messages'
import { handleAddFeedback, handleGetAllContacts } from '../../actions/user/contacts/Action'

const GlobalStyles = createGlobalStyle`
    .active-text {
      color: var(--Primary-700);
    }
    .helping-text {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 11px;
      color: var(--Gray-500);
      margin-bottom: 20px;
    }
  
`

function SubmitFeedback (props) {
  const [adding, setAdding] = useState(false)
  const { selected, addFeedback, searchContact, onSuccess } = props

  const user = useSelector(state => state.loginReducer.authUser)
  const contacts = useSelector(state => state.contactsReducer.contacts)
  const [loading, setLoading] = useState(false)
  const [userId, setUserId] = useState(null)

  const fetchContact = () => {
    setLoading(true)
    searchContact(user.id).then(() => {
      setLoading(false)
    })
  }
  const [form] = Form.useForm()
  const onFinish = () => {
    const data = {
      receiverId: userId ? Number(userId) : null,
      emotion: selected.title
    }
    setAdding(true);

    (addFeedback(data)).then(() => {
      TlaSuccess('Feedback Submitted')
      form.resetFields()
      setAdding(false)
      onSuccess()
    }).catch((error) => {
      TlaWarning(error.response.data.message)
      setAdding(false)
    })
  }

  return (
        <>
            <GlobalStyles/>
            <div>
                <div className={'mx-auto w-fit'}>
                    <FeedbackItem icon={selected.icon} title={selected.title} selected={true}/>
                </div>
                <Divider style={{ paddingTop: 0, paddingBottom: 0 }}/>
                <h4 className={'text-gray-900 font-medium text-sm'}>Did someone trigger this feeling?</h4>
                <h6 className={'helping-text leading-5 mt-1'}>
                    This anonymously helps others to learn and grow.&nbsp;
                    <span className={'active-text'}>
                        <a target={'_blank'}
                           className={'text-primary-400 no-underline'}
                           href="https://noustro.com/how-it-works/" rel="noreferrer">
                            How anonymous works?
                        </a>
                    </span>
                </h6>
            </div>
            <Form form={form} onFinish={onFinish}>
                <Form.Item name={'name'} style={{ marginBottom: 10 }}>
                    <Select
                        size={'large'}
                        loading={loading}
                        onFocus={fetchContact}
                        onChange={(value, option) => {
                          setUserId(option.key)
                        }}
                        showArrow={false} style={{ width: '100%' }} placeholder="Name (Optional)" showSearch>
                        {
                            contacts?.data?.map((contact, index) => (
                                <Select.Option key={contact.contactUser.id} value={contact.contactUser.id}>
                                    <>
                                        <p className={'text-gray-900 text-[11px] font-normal'}>{contact.contactUser.userInformation.name}</p>
                                        <p className={'text-gray-400 text-[11px] font-normal'}>{contact.contactUser.email}</p>
                                    </>
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <div style={{ marginBottom: 10 }}>
                    <Button loading={adding} htmlType={'submit'} block size={'large'} className={'btn btn-primary'}
                            type={'primary'}>Submit Feedback</Button>
                </div>
            </Form>
        </>
  )
}

SubmitFeedback.defaultProps = {}

SubmitFeedback.propTypes = {
  selected: PropTypes.object,
  addFeedback: PropTypes.func,
  searchContact: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => ({
  searchContact: (userId) => dispatch(handleGetAllContacts(userId, 1, 500)),
  addFeedback: (data) => dispatch(handleAddFeedback(data))
})

export default connect(null, mapDispatchToProps)(SubmitFeedback)
