import React from 'react'
import PropTypes from 'prop-types'

const LungIcon = ({ className, isActive = '#98A2B3' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" className={className} viewBox="0 0 22 22" fill="none">
      <g clipPath="url(#clip0_10150_1206)">
        <path d="M12.33 21.9974C10.9725 21.9975 9.59897 21.6652 8.87988 21.3215C6.7509 20.3027 6.81975 17.7517 5.51617 17.3183C4.68856 17.0431 3.16894 17.4648 1.00024 18.5718C0.543572 18.8048 0 18.4729 0 17.9593V15.3698C0 15.1613 0.0945921 14.9641 0.257174 14.8336C2.81159 12.7831 5.28027 12.4145 7.5947 13.7382C10.1228 15.0463 12.6219 9.9019 9.73702 5.99762C8.34676 4.19392 8.16698 2.09166 8.26106 0.64493C8.28457 0.283237 8.58476 0.00195312 8.94723 0.00195312H11.7005C12.0955 0.00195312 12.4091 0.334294 12.387 0.728134C12.3538 1.3195 12.4376 1.86268 12.6423 2.37243C16.032 0.299181 19.6336 1.46957 21.1929 5.66979C21.8678 7.48758 22.1213 9.72887 21.9463 12.3314C21.8473 13.9394 21.3289 15.8857 20.3967 17.4506C18.6321 20.4131 15.5489 21.9974 12.33 21.9974ZM9.45715 20.073C11.6276 20.9599 14.3766 20.7787 16.5591 19.4482C19.1104 17.893 20.3214 15.1872 20.5746 12.2331C21.1156 4.15537 17.0845 0.72938 12.7999 3.92544C12.4798 4.16414 12.0234 4.08089 11.8087 3.74348C11.3401 3.00724 11.079 2.22773 11.0183 1.37717H9.61608C9.6346 2.97191 10.0795 4.1448 10.8383 5.17379C13.0473 8.15742 12.756 11.973 10.993 14.032C9.7983 15.4271 8.16982 15.6532 6.92212 14.9378C5.39003 14.0573 3.61663 14.014 1.37526 15.7058V16.8615C3.44039 15.917 4.87495 15.6559 5.95006 16.0133C8.03333 16.7059 7.8432 19.2832 9.45715 20.073Z" fill="#98A2B3"/>
      </g>
      <defs>
        <clipPath id="clip0_10150_1206">
          <rect width="22" height="22" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

LungIcon.propTypes = {
  isActive: PropTypes.string,
  className: PropTypes.string
}
export default LungIcon
