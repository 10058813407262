import { Types } from '../../actions/admin/personalities/Types'

const initialState = {
  personalities: {
    data: [],
    meta: {}
  },
  personality: {}
}

export default function adminPersonalitiesReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_PERSONALITY:
      return {
        ...state,
        personalities: {
          ...state.personalities,
          data: state.personalities.data.concat(action.payload.data.personality)
        }
      }
    case Types.SINGLE_PERSONALITY:
      return {
        ...state,
        personality: action.payload
      }
    case Types.ALL_PERSONALITIES:
      return {
        ...state,
        personalities: {
          ...state.personalities,
          data: action.payload.data.personalities,
          meta: {
            total: action.payload.data.count,
            current_page: Number(action.payload.data.page),
            page: action.payload.data.page,
            per_page: action.payload.data.pageSize,
            pageSize: action.payload.data.pageSize
          }
        }
      }

    case Types.UPDATE_PERSONALITY:
      return {
        ...state,
        personalities: {
          ...state.personalities,
          data: state.personalities.data.map((personality) => {
            return personality.id === action.payload.data.personality.id ? action.payload.data.personality : personality
          })
        },
        personality: action.payload.data.updatedPersonality
      }
    case Types.DELETE_PERSONALITY:
      return {
        ...state,
        personalities: {
          ...state.personalities,
          data: state.personalities.data.filter((personality) => personality.id !== action.id)
        }
      }

    default:
      return state
  }
}
