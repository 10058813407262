import React from 'react'
import { Button } from 'antd'
import { connect } from 'react-redux'
import { EditOutlined } from '@ant-design/icons'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

function TlaEdit ({ link, data, text, icon, type, meta }) {
  const location = useLocation()
  return (
        <Link to={link} state={{ background: location, data, meta }}>
            <Button className={'btn btn-light'} type={type} icon={icon} size={'small'}>
                {text}
            </Button>
        </Link>
  )
}

TlaEdit.defaultProps = {
  text: '',
  type: 'text',
  icon: <EditOutlined/>
}

TlaEdit.propTypes = {
  text: PropTypes.any,
  icon: PropTypes.node,
  type: PropTypes.any,
  meta: PropTypes.object,
  data: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired
}

export default connect()(TlaEdit)
