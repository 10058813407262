import React, { useState } from 'react'
import { connect } from 'react-redux'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import PropTypes from 'prop-types'
import { QuoteTheme } from '../../utils/quotes'
import { handleUpdateUserFeed } from '../../actions/user/common/Action'

function ContactsQuote ({ consumed, posts, updateUserFeed, feedId }) {
  const [index, setIndex] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [theme, setTheme] = useState(QuoteTheme[Math.floor(Math.random() * QuoteTheme.length)])

  const getBody = (body) => {
    if (typeof body !== 'object') {
      try {
        return JSON.parse(body)
      } catch (e) {
        return body
      }
    }

    return body
  }

  const content = [{
    slide_title: posts?.title,
    content: ''
  }, ...Object.values(getBody(posts?.body, posts?.id))]

  const setConsumed = () => {
    if (!consumed) {
      updateUserFeed(feedId, {
        consumed: true
      })
    }
  }

  return (
        <div className={'w-full h-[452px] rounded-10 flex flex-col justify-end text-white-base'}
             style={{ background: theme?.background }}>
            <div className={'px-[15px] md:px-[145px] pb-[98px] pt-[160px]'} style={{ color: theme?.fontColor }}>
                <h3 className={'mb-[50px] font-semibold text-2xl leading-8'}>{content[index]?.slide_title}</h3>
                <p className={'text-xl leading-6'}>
                    {content[index]?.content}
                </p>
            </div>
            <div className={'flex justify-between py-5 px-6 md:px-[55px] rounded-b-10'}
                 style={{ background: theme?.footerBackground }}>
                <p className={'text-gray-400 font-normal text-sm'}>
                    {posts?.link ? <>Source:&nbsp;{posts?.link}</> : ''}
                </p>
                <div className={'flex items-center gap-x-2.5'}>
                    <p className={'text-xs text-gray-400 font-normal mr-2.5'}>{index + 1}/{content.length}</p>
                    <div className={'post-arrow-icon'} onClick={() => {
                      if (index >= 1) {
                        setIndex(index - 1)
                      }
                    }}>
                        <IoIosArrowBack size={25} color={'white'}/>
                    </div>
                    <div className={'post-arrow-icon'}
                         onClick={() => {
                           if ((index + 1) >= (content.length / 2) - 1) {
                             setConsumed()
                           }

                           if (index < content.length - 1) {
                             setIndex(index + 1)
                           }
                         }}>
                        <IoIosArrowForward size={25} color={'white'}/>
                    </div>
                </div>
            </div>
        </div>
  )
}

ContactsQuote.propTypes = {
  consumed: PropTypes.bool,
  feedId: PropTypes.any,
  posts: PropTypes.object,
  updateUserFeed: PropTypes.func
}

const mapDispatchToProps = (dispatch) => ({
  updateUserFeed: (feedId, data) => dispatch(handleUpdateUserFeed(feedId, data))
})

export default connect(null, mapDispatchToProps)(ContactsQuote)
