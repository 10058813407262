import api from '../../utils/api'
import { deleteJobQuery, getJobQueries, getJobsScraped, submitCv } from './ActionCreators'

/**
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleSubmitCv = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    return api('multipart/form-data')
      .post('/job/upload-cv', data)
      .then((response) => {
        dispatch(submitCv(response.data))
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
  })
}

export const handleUpdateCv = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    return api('multipart/form-data')
      .put(`/job/update-cv/${data.get('id')}`, data)
      .then((response) => {
        dispatch(submitCv(response.data))
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
  })
}

export const handleGetAllJobQueries = (userId, pageNumber = 1) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/job/user-queries?userId=${userId}&page=${pageNumber}&pageSize=15`).then((res) => {
      dispatch(getJobQueries(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const handleDeleteJobQuery = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().delete(`/job/delete-cv/${id}`).then((res) => {
      dispatch(deleteJobQuery(id))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const handleGetJobsScraped = (jobQueryId, pageNumber = 1) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/job/user-jobs?userId=2&page=${pageNumber}&pageSize=15&jobQueryId=${jobQueryId}`)
      .then((res) => {
        dispatch(getJobsScraped(res.data))
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}
