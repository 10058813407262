import React from 'react'
import { Space } from 'antd'
import { createGlobalStyle } from 'styled-components'
// import { GoPrimitiveDot } from 'react-icons/go'
import { Link } from 'react-router-dom'

const options = [
  {
    label: 'Self-Assessment',
    link: '/dashboard/overview/self',
    paragraph: 'This is how you perceive you make others feel with your presence.'
  },
  {
    label: 'Contacts\' Feedback',
    link: '/dashboard/overview/contact',
    paragraph: 'This is how others actually feel in your presence.'
  }
]

const activePara = location.pathname === '/dashboard/overview/self' ? 'This is how you perceive you make others feel with your presence.' : 'This is how others actually feel in your presence.'
const GlobalStyles = createGlobalStyle`
  .c-nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px !important;
    margin: 0 !important;
    padding: 10px 16px !important;
    color: var(--Gray-800) !important;
    font-size: 14px !important;
    line-height: 30px !important;
    background: #fff !important;
    border: 1px solid #d9d9d9 !important;
    border-top-width: 1.02px !important;
    border-left-width: 0 !important;
    cursor: pointer !important;
  }

  @media only screen and (max-width: 600px) {
    .c-nav-item, .c-nav-item:hover {
      font-size: 12px !important;
      padding: 5px 15px !important;
    }

    .c-nav-item-active {
      //font-size: 10px !important;
      //padding: 5px 4px !important;
    }

    .c-nav-item:hover {
      //font-size: 10px !important;
      //padding: 5px 4px !important;
    }
  }

  .c-nav-item:hover {
    background-color: var(--Primary-50) !important;
    border: 1px solid #d9d9d9 !important;
    padding: 10px 16px;
    font-size: 14px;
    line-height: 30px !important;
    color: var(--Gray-800) !important;
    border-top-width: 1.02px !important;
    border-left-width: 0 !important;
  }

  .c-nav-item-active {
    background-color: var(--Primary-50) !important;
    //border: 1px solid #d9d9d9 !important;
  }

  .c-nav-item:focus {
    outline: none !important;
    box-shadow: none !important;
    transition: border-color 0ms !important;
  }

  .c-nav-item-first, .c-nav-item-first:hover {
    border-left-width: 1px !important;
    border-radius: 8px 0 0 8px !important;
  }

  .c-nav-item-last {
    border-radius: 0 8px 8px 0;
  }
`

const DashboardNavs = () => {
  // const [active, setActive] = useState(0)
  return (
        <>
            <GlobalStyles/>
            <Space size={0}>
                {/* {
                    options.map((option, index) => (
                        <Link to={option.link} key={option.label}>
                            <span onClick={() => { setActive(index) }}
                                  className={`w-full md:w-fit c-nav-item ${active === index ? 'c-nav-item-active' : ''}
                                          ${index === 0 ? 'c-nav-item-first' : ''}
                                          ${options.length - 1 === index ? 'c-nav-item-last' : ''}`}>
                            {active === index ? <GoPrimitiveDot style={{ color: 'green' }}/> : ''}{option.label}
                        </span>
                        </Link>
                    ))
                } */}
                <div className="flex flex-col gap-4">
                  <div className="flex w-full ">
                    {options.map((option) => (
                      <div key={option.link} className="flex-1 text-center">
                        <Link
                          to={option.link}
                          className={`py-3 px-4 text-sm font-medium whitespace-nowrap w-full inline-block ${
                            location.pathname === option.link
                              ? 'bg-blue-50 border-b-[2px] text-primary-700 border-primary-700 font-semibold'
                              : 'text-gray-500 hover:text-gray-700 border-b-[1px] border-gray-500'
                          }`}
                        >
                          {option.label}
                        </Link>
                      </div>
                    ))}
                  </div>
                  <p className='text-gray-500'>
                    {activePara}
                  </p>
                </div>
            </Space>
        </>
  )
}

export default DashboardNavs
