import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { getRandomHexColor } from '../../../utils'

const GameStats = ({ stats, statsInfo }) => {
  // eslint-disable-next-line no-unused-vars
  const [animations, setAnimations] = useState(stats)
  return (
      <div className="grid grid-cols-2 items-end gap-4 p-4 bg-gray-100 rounded-lg w-full">
        { Object.keys(stats)?.map((key, index) => {
          const color = getRandomHexColor(index)
          return (
              <div key={ key } className="relative">
                <div className="flex justify-between items-center mb-1">
                  <span className="text-sm font-medium text-gray-700">{ statsInfo[key] }</span>
                  <span className="text-sm font-bold text-gray-700">{ stats[key] }%</span>
                </div>
                <div className="h-2 bg-gray-300 rounded-full overflow-hidden">

                  <div
                      className="h-full transition-all duration-1000 ease-out"
                      style={ { width: `${stats[key]}%`, backgroundColor: color } }
                  />
                </div>
                { animations[key] && (
                    <div
                        className={ `absolute right-0 -top-6 text-sm font-bold ${animations[key] > 0 ? 'text-green-600' : 'text-red-600'}` }
                        style={ { animation: 'fadeOutUp 2s ease-out' } }
                    >
                    </div>
                ) }
              </div>
          )
        }) }
        {/* { Object.entries(stats)?.map(([key, value]) => {
          const { title, color } = statsInfo[key] || { title: key, color: getRandomHexColor(key) }
          return (
              <div key={ key } className="relative">
                <div className="flex justify-between items-center mb-1">
                  <span className="text-sm font-medium text-gray-700">{ title }</span>
                  <span className="text-sm font-bold text-gray-700">{ value }%</span>
                </div>
                <div className="h-2 bg-gray-300 rounded-full overflow-hidden">
                  <div
                      className="h-full transition-all duration-1000 ease-out"
                      style={ { width: `${value}%`, backgroundColor: color } }
                  />
                </div>
                { animations[key] && (
                    <div
                        className={ `absolute right-0 -top-6 text-sm font-bold ${animations[key] > 0 ? 'text-green-600' : 'text-red-600'}` }
                        style={ { animation: 'fadeOutUp 2s ease-out' } }
                    >
                      { animations[key] > 0 ? '+' : '' }{ animations[key] }
                    </div>
                ) }
              </div>
          )
        }) } */}
      </div>
  )
}

GameStats.propTypes = {
  stats: PropTypes.object,
  statsInfo: PropTypes.object
}

export default GameStats
