import React, { useState } from 'react'
import { TlaModal } from '../pop-ups/tla-modal'
import GoBackHome from '../../pages/feedback/go-back-home'
import { Button, Select } from 'antd'
import PropTypes from 'prop-types'
import { handleAddFeedback, handleGetAllContacts } from '../../actions/user/contacts/Action'
import { connect, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import GiveFeedback from '../../pages/feedback/give-feedback'

function QuickGiveFeedback ({ searchContact }) {
  const contacts = useSelector(state => state.contactsReducer.contacts)
  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const user = useSelector(state => state.loginReducer.authUser)
  const { state } = useLocation()

  const fetchContact = () => {
    setLoading(true)
    searchContact(user.id).then(() => {
      setLoading(false)
    })
  }

  return (
        <TlaModal close={ false } width={ 877 }>
            <div className={ 'pt-2' }>
                <GoBackHome/>
            </div>
            {
                step === 0 &&
                <div>
                  <p>Select a Contact</p>
                    <Select
                        size={ 'large' }
                        loading={ loading }
                        onFocus={ fetchContact }
                        onChange={ (value, option) => {
                          // console.log(contacts?.data?.find(item => item?.contactUser?.id === value))
                          state.mainUser = contacts?.data?.find(item => item?.contactUser?.id === value)?.contactUser
                          state.contactUserId = user.id
                        } }
                        showArrow={ false } style={ { width: '100%' } } placeholder="Name" showSearch>
                        {
                            contacts?.data?.map((contact, index) => (
                                <Select.Option key={ contact.contactUser.id } value={ contact.contactUser.id }>
                                    <>
                                        <p className={ 'text-gray-900 text-[11px] font-normal' }>{ contact.contactUser.userInformation.name }</p>
                                        <p className={ 'text-gray-400 text-[11px] font-normal' }>{ contact.contactUser.email }</p>
                                    </>
                                </Select.Option>
                            ))
                        }
                    </Select>

                    <Button onClick={() => setStep(1)} size={'large'} className={'btn btn-primary-600 w-full' +
                        ' md:w-[185px] mt-2 mx-auto'}>Next</Button>
                </div>
            }
            {
                step === 1 &&
                <GiveFeedback changePerson={() => {
                  setStep(0)
                }} isSelfAssessment={false} isQuickAction={true}/>
            }
        </TlaModal>
  )
}

QuickGiveFeedback.propTypes = {
  searchContact: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => ({
  searchContact: (userId) => dispatch(handleGetAllContacts(userId, 1, 500)),
  addFeedback: (data) => dispatch(handleAddFeedback(data))
})

export default connect(null, mapDispatchToProps)(QuickGiveFeedback)
