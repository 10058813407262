import { Types } from '../actions/dashboard/Types'

const initialState = {
  statistics: {}
}

/**
 *
 * @param state
 * @param action
 * @returns {{statistics}|{statistics: {}}}
 */
export default function dashboardReducer (state = initialState, action) {
  switch (action.type) {
    case Types.GET_STATISTICS: {
      return {
        ...state,
        statistics: action.payload.data
      }
    }

    default:
      return state
  }
}
