import { Types } from '../../actions/admin/content-posts/Types'

const initialState = {
  contentPosts: {
    data: [],
    meta: {}
  },
  contentPost: {}
}

export default function adminPostsReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_CONTENT_POST:
      return {
        ...state,
        contentPosts: {
          ...state.contentPosts,
          data: state.contentPosts.data.concat(action.payload.data)
        }
      }
    case Types.SINGLE_CONTENT_POST:
      return {
        ...state,
        contentPost: action.payload
      }
    case Types.ALL_CONTENT_POSTS:
      return {
        ...state,
        contentPosts: {
          ...state.contentPosts,
          data: action.payload.data.contentPosts,
          meta: {
            total: action.payload.data.count,
            current_page: Number(action.payload.data.page),
            page: action.payload.data.page,
            pageSize: action.payload.data.pageSize
          }
        }
      }

    case Types.UPDATE_CONTENT_POST:
      return {
        ...state,
        contentPosts: {
          ...state.contentPosts,
          data: state.contentPosts.data.map((contentPost) => {
            return contentPost.id === action.payload.data.contentPost.id ? action.payload.data.contentPost : contentPost
          })
        }
        // contentPost: action.payload.data.upda
      }
    case Types.DELETE_CONTENT_POST:
      return {
        ...state,
        contentPosts: {
          ...state.contentPosts,
          data: state.contentPosts.data.filter((contentPosts) => contentPosts.id !== action.post)
        }
      }

    default:
      return state
  }
}
