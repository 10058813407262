import React, { useState } from 'react'
import TlaModalFormWrapper from '../../components/tla-modal-form-wrapper'
import { Button, Form, Input } from 'antd'
import ChangePicture from '../../components/change-picture'
import CloseModal from '../../components/close-modal'
import { connect } from 'react-redux'
import { handleSubmitCv, handleUpdateCv } from '../../actions/job-queries/Actions'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

function JobQueryForm ({ submitCv, updateCv, userId }) {
  const [selectedFile, setSelectedFile] = useState(null)
  const { state } = useLocation()
  const formValues = {
    id: 0,
    userId,
    ...state?.data
  }
  return (
        <TlaModalFormWrapper
            file={selectedFile}
            initialValues={formValues}
            formTitle={'Job Query'}
            onSubmit={formValues.id === 0 ? submitCv : updateCv}>
            <Form.Item hidden
                       name={'id'}
                       rules={[
                         {
                           required: true,
                           message: 'Required'
                         }
                       ]}
                       label={'id'}>
                <Input size={'large'}/>
            </Form.Item>
            <Form.Item hidden
                       name={'userId'}
                       rules={[
                         {
                           required: true,
                           message: 'Required'
                         }
                       ]}
                       label={'userId'}>
                <Input size={'large'}/>
            </Form.Item>
            <Form.Item name={'jobTitles'}
                       rules={[
                         {
                           required: true,
                           message: 'Required'
                         }
                       ]}
                       label={'Job Titles'}
                       help={'Add multiple titles by separating with commas(,)'}>
                <Input size={'large'} placeholder={'Eg: developer, engineer'}/>
            </Form.Item>
            <Form.Item name={'locations'}
                       rules={[
                         {
                           required: true,
                           message: 'Required'
                         }
                       ]}
                       label={'Locations'}
                       help={'Add multiple locations by separating with commas(,)'}>
                <Input size={'large'} placeholder={'Eg: ghana, united state'}/>
            </Form.Item>
            <div className={'mb-5'}>
                <p className={'mb-2 text-gray-900'}>Curriculum Vitae</p>
                <ChangePicture
                    isDocument
                    hasFile={selectedFile === null}
                    setFile={setSelectedFile}/>
            </div>
            <div className={'flex justify-end gap-2'}>
                <div>
                    <CloseModal/>
                </div>
                <Form.Item>
                    <Button className={'btn-primary'} size={'large'} htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </div>
        </TlaModalFormWrapper>
  )
}

JobQueryForm.propTypes = {
  submitCv: PropTypes.func.isRequired,
  updateCv: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired
}

/**
 *
 * @param state
 * @returns {{userId: BufferSource}}
 */
const mapStateToProps = (state) => ({
  userId: state.loginReducer.authUser.id
})

/**
 *
 * @param dispatch
 * @returns {{submitCv: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  submitCv: (data) => dispatch(handleSubmitCv(data)),
  updateCv: (data) => dispatch(handleUpdateCv(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(JobQueryForm)
