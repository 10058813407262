import React from 'react'
import PropTypes from 'prop-types'

export const QuestionIcon = ({ className = '', fill }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path xmlns="http://www.w3.org/2000/svg" d="M14.086 19.4143C14.478 19.8063 14.991 20.0003 15.504 20.0003C16.013 20.0003 16.521 19.8093 16.903 19.4253L19.696 16.7183C20.092 16.3343 20.102 15.7003 19.717 15.3043C19.331 14.9083 18.699 14.8983 18.303 15.2833L15.489 17.9903L13.815 16.3673C16.174 14.8583 17.781 12.4343 17.977 9.65231C18.162 7.03931 17.209 4.48231 15.364 2.63731C13.519 0.791314 10.959 -0.164686 8.349 0.0233142C3.823 0.343314 0 4.58931 0 9.29631V14.3343C0 16.3553 1.643 18.0003 3.661 18.0003H8.138C9.474 18.0003 10.752 17.7563 11.92 17.3153L14.086 19.4143ZM3.661 16.0003C2.745 16.0003 2 15.2533 2 14.3343V9.29631C2 5.60031 4.972 2.26731 8.489 2.01831C8.656 2.00631 8.822 2.00031 8.987 2.00031C10.846 2.00031 12.632 2.73231 13.949 4.05131C15.385 5.48631 16.125 7.47631 15.982 9.51131C15.729 13.0893 12.21 16.0003 8.137 16.0003H3.661ZM11.953 6.46631C12.177 7.73731 11.572 9.00831 10.447 9.62931C10 9.87531 10 9.94731 10 10.0003C10 10.5533 9.553 11.0003 9 11.0003C8.447 11.0003 8 10.5533 8 10.0003C8 8.69231 9.038 8.12131 9.481 7.87731C9.77 7.71731 10.076 7.34231 9.983 6.81231C9.914 6.41931 9.581 6.08631 9.19 6.01831C8.88 5.96031 8.587 6.03931 8.358 6.23431C8.13 6.42431 8 6.70431 8 7.00131C8 7.55431 7.553 8.00131 7 8.00131C6.447 8.00131 6 7.55431 6 7.00131C6 6.11231 6.391 5.27431 7.072 4.70231C7.753 4.13031 8.65 3.88831 9.536 4.04931C10.746 4.26031 11.741 5.25531 11.953 6.46731V6.46631ZM10.25 13.2503C10.25 13.9403 9.69 14.5003 9 14.5003C8.31 14.5003 7.75 13.9403 7.75 13.2503C7.75 12.5603 8.31 12.0003 9 12.0003C9.69 12.0003 10.25 12.5603 10.25 13.2503ZM24 16.0003V21.0003C24 22.6573 22.657 24.0003 21 24.0003H16C13.045 24.0003 10.465 22.3853 9.08 19.9963C9.849 19.9903 10.598 19.9053 11.322 19.7483C12.423 21.1193 14.109 22.0003 16 22.0003H21C21.552 22.0003 22 21.5523 22 21.0003V16.0003C22 14.1093 21.119 12.4233 19.748 11.3223C19.904 10.5983 19.99 9.84931 19.996 9.08031C22.385 10.4653 24 13.0453 24 16.0003Z" fill={fill}/>
  </svg>
)
QuestionIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string.isRequired
}
