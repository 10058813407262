import React, { useState } from 'react'
import { Button, Form, message, Spin } from 'antd'
import { useDispatch } from 'react-redux'
import { handleRegistration } from '../../../actions/authenticate/Actions'
import SignUpContent from './sign-up-content'
import { useNavigate } from 'react-router-dom'
import AuthLayout from '../auth-layout'
import AuthBottomLink from '../auth-bottom-link'
import { TlaError } from '../../../utils/messages'

const SignUp = () => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const onFinish = async (values) => {
    setLoading(true)

    // @ts-ignore

    dispatch(handleRegistration(values)).then(() => {
      message.success('Account crated successfully!')
      navigate('/verify/manual', {
        state: {
          email: values.email
        }
      })
    }).catch((error) => {
      setLoading(false)
      console.error('Error submitting form:', error)
      TlaError(error?.response?.data?.message)
    })
  }

  return (
      <AuthLayout pageTitle={ 'Create an account' }>
          <Form requiredMark={ false }
                form={ form }
                layout="vertical"
                name="job-query-form" onFinish={ onFinish }>
              <Spin spinning={ loading } tip={ 'Please wait...' }>
                  <div>
                      <SignUpContent/>
                      <Form.Item>
                          <Button
                              size={'large'}
                              block
                              className={'btn-primary'}
                              htmlType="submit">
                              Sign up with email
                          </Button>
                      </Form.Item>
                  </div>
              </Spin>
          </Form>
          <div className={ 'text-center w-[350px] mx-auto mt-4' }>
              <p className={ 'text-gray-500 text-sm' }>
                  By signing up, you agree to our&nbsp;
                  <a className={ 'text-primary-700' }
                     href={ 'https://noustro.com/terms-conditions' }
                     target={ '_blank' }
                     rel="noreferrer">Terms</a> and&nbsp; <br/>
                  <a className={ 'text-primary-700' } href="https://noustro.com/privacy-policy/"
                     target={ '_blank' }
                     rel="noreferrer">Privacy Policy</a>.
              </p>
          </div>
          <AuthBottomLink size={ 'sm' } text={ 'Already have an account?' } link={ '/' } linkText={ 'Login' }/>
      </AuthLayout>
  )
}

export default SignUp
