import api from '../../../utils/api'
import { addCourse, allCourses, deleteCourse, updateCourse } from './ActionCreator'

/**
 * Store a newly created resource in storage.
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAddCourse = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/courses', data).then((res) => {
      dispatch(addCourse(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAllCourses = (pageNumber = 1) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/courses?page=${pageNumber}&pageSize=15`).then((res) => {
      dispatch(allCourses(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Update the specified resource in storage.
 * @param postId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleUpdateCourse = (postId, data) => (dispatch) => {
  const updatePromise = new Promise((resolve, reject) => {
    api().patch(`/courses/${postId}`, data, {
    }).then((res) => {
      dispatch(updateCourse(res.data))
      handleGetAllCourses()
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
  const getCoursePromise = new Promise((resolve, reject) => {
    api().get(`/courses?page=${1}&pageSize=15`).then((res) => {
      dispatch(allCourses(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
  return updatePromise.then((result1) => {
    return getCoursePromise
  }).then((result2) => {})
}

/**
 * Remove the specified resource from storage.
 * @returns {function(*): Promise<unknown>}
 * @param postId
 */
export const handleDeleteCourse = (postId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().delete(`/courses/${postId}`).then((res) => {
      dispatch(deleteCourse(postId))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
