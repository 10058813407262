import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router'
import TlaTableWrapper from '../../../components/table/tla-table-wrapper'
import { Spin, Table } from 'antd'
import PropTypes from 'prop-types'
import { handleDeleteSkill, handleGetAllSkills } from '../../../actions/admin/skills/Action'
import { connect } from 'react-redux'
import TlaEdit from '../../../components/tla-edit'
import { FiEdit2 } from 'react-icons/fi'
import TlaConfirm from '../../../components/tla-confirm'
import { TlaSuccess } from '../../../utils/messages'

const Skills = ({ getSkills, skills, deleteSkill }) => {
  const { setPageInfo } = useOutletContext()

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setPageInfo({
      title: 'Skills',
      buttonText: 'Add Skills',
      importBtnText: 'Import Skills',
      showImportBtn: true,
      buttonLink: 'Add Skills',
      subTitle: '',
      helpingText: '',
      showSearch: false
    })
    getSkills().then(() => setLoading(false))
  }, [])

  return (
        <div className={'pb-4'}>
            <Spin spinning={loading}>
                <TlaTableWrapper data={skills?.data}
                                 meta={skills?.meta} showHeader={true}
                                 callbackFunction={getSkills}>
                    <Table.Column title={'Skill Titles'} dataIndex={'title'}/>
                    <Table.Column className='w-28' width={'100px'} title={'Contact Type(s)'} dataIndex={'type'} render={(text, record) => {
                      return record?.contact_type.map((type) => <span className='bg-blue-200 text-blue-600 py-1 px-2 rounded-2xl text-xs font-bold mr-1 my-1 whitespace-nowrap' key={type}>{type}</span>)
                    }}/>
                    <Table.Column title={'Action'} render={((record) => (
                        <div className={'flex items-center'}>
                            <TlaConfirm title={'Delete Skill'}
                                        fullText={'Do you really want to delete this query?'}
                                        callBack={() => {
                                          console.log(record.id)
                                          deleteSkill(record.id).then(() => TlaSuccess('Deleted'))
                                        }}/>
                            <TlaEdit data={record ?? {}} icon={<FiEdit2 className={'icon'}/>} link={'form'}/>
                        </div>
                    ))}/>
                </TlaTableWrapper>
            </Spin>
        </div>
  )
}

Skills.propTypes = {
  getSkills: PropTypes.func.isRequired,
  deleteSkill: PropTypes.func.isRequired,
  skills: PropTypes.object.isRequired
}

/**
 * @param state
 * @returns {{skills: *}}
 */
const mapStateToProps = (state) => ({
  skills: state.adminSkillsReducer.skills
})

/**
 *
 * @param dispatch
 * @returns {{getSkills: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  getSkills: (pageNumber) => dispatch(handleGetAllSkills(pageNumber)),
  deleteSkill: (skillId) => dispatch(handleDeleteSkill(skillId))
})

export default connect(mapStateToProps, mapDispatchToProps)(Skills)
