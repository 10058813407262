import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function addCourseModule (payload) {
  return {
    type: Types.NEW_COURSE_MODULE,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function allCourseModules (payload) {
  return {
    type: Types.ALL_COURSE_MODULES,
    payload
  }
}

/**
 *
 * @param post
 * @returns {{post, type: string}}
 */
export function deleteCourseModule (courseModule) {
  return {
    type: Types.DELETE_COURSE_MODULE,
    courseModule
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function updateCourseModule (payload) {
  return {
    type: Types.UPDATE_COURSE_MODULE,
    payload
  }
}
