import React, { useEffect, useState } from 'react'
import AuthLayout from './auth-layout'
import TlaFormWrapper from '../../components/tla-form-wrapper'
import { Form, Input, Select } from 'antd'
import AuthBottomLink from './auth-bottom-link'
import { handleRegistration } from '../../actions/authenticate/Actions'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useParams } from 'react-router'

function Register ({ registerUser }) {
  const { invitationCode } = useParams()
  const [value, setValue] = useState()
  const dispatch = useDispatch()

  const contactTypes = [
    {
      value: 'Student',
      label: 'Student'
    },
    {
      value: 'Early Career',
      label: 'Early Career (0-3 yrs)'
    },
    {
      value: 'Mid Senior',
      label: 'Mid Senior (4-7 yrs)'
    },
    {
      value: 'Senior',
      label: 'Senior (8+)'
    },
    {
      value: 'Executive',
      label: 'CXOs (Executive)'
    }
  ]
  useEffect(() => {
    dispatch({
      type: 'SET_INVITATION_CODE',
      payload: invitationCode
    })
  }, [])

  return (
        <AuthLayout pageTitle={ 'Create an account' }>
            <div>
                <TlaFormWrapper raw
                    formName={ 'register-form' }
                    initialValues={ { invitationCode: invitationCode ?? '' } }
                    afterSubmit={ '/verify-email-message' }
                    buttonText={ 'Sign up with email' }
                    onSubmit={ registerUser }>
                    <Form.Item hidden name="invitationCode" label="invitationCode*">
                        <Input size={ 'large' } placeholder={ 'Enter your name' }/>
                    </Form.Item>
                    <Form.Item name="name" label="Name*"
                               rules={ [
                                 {
                                   required: true,
                                   message: 'Name is Required'
                                 }
                               ] }>
                        <Input size={ 'large' } placeholder={ 'Enter your name' }/>
                    </Form.Item>
                    <Form.Item name="email" label="Email*"
                               rules={ [
                                 {
                                   required: true,
                                   message: 'Email is Required'
                                 }
                               ] }>
                        <Input size={ 'large' } placeholder={ 'Enter your email' }/>
                    </Form.Item>
                    <Form.Item
                        rules={ [
                          {
                            type: 'string',
                            min: 10,
                            message: 'Provide a valid phone number'
                          },
                          {
                            required: true,
                            message: 'Mobile Number required'
                          }
                        ] }
                        name={ 'mobileNumber' }
                        label={ 'Mobile Number *' }>

                        <PhoneInput flags
                                    placeholder=""
                                    value={ value }
                                    onChange={ setValue }/>
                    </Form.Item>
                    <div className={'grid grid-cols-1 md:grid-cols-2 gap-2'}>
                        <Form.Item name="password"
                                   label={ 'Password*' }
                                   rules={ [
                                     { required: true, message: 'Password is required!' },
                                     { min: 8, message: '' }
                                   ] }>
                            <Input.Password
                                size={ 'large' }
                                type={ 'password' }
                                placeholder="••••••••"/>
                        </Form.Item>
                        <Form.Item
                            dependencies={ ['password'] }
                            name="passwordConfirm"
                            label={ 'Confirm password*' }
                            rules={ [
                              {
                                required: true,
                                message: 'Password is required!'
                              }, ({ getFieldValue }) => ({
                                validator (_, value) {
                                  if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve()
                                  }
                                  return Promise.reject(new Error('Password mismatch'))
                                }
                              }),
                              {
                                min: 8,
                                message: 'Password should be at least 8 characters'
                              }
                            ] }>
                            <Input.Password
                                size={ 'large' }
                                type="password"
                                placeholder="••••••••"
                            />
                        </Form.Item>
                    </div>
                    <Form.Item
                        name={ 'currentStatus' }
                        rules={ [
                          {
                            required: false,
                            message: 'Current Status is Required'
                          }
                        ] }
                        label={ 'Current Status *' }>
                        <Select
                            placeholder="Select Current Status"
                            className='form-select'
                            options={ contactTypes }
                        />
                    </Form.Item>
                </TlaFormWrapper>
                <div className={ 'text-center w-[350px] mx-auto mt-4' }>
                    <p className={ 'text-gray-500 text-sm' }>
                        By signing up, you agree to our&nbsp;
                        <a className={ 'text-primary-700' }
                           href={ 'https://noustro.com/terms-conditions' }
                           target={ '_blank' }
                           rel="noreferrer">Terms</a> and&nbsp; <br/>
                        <a className={ 'text-primary-700' } href="https://noustro.com/privacy-policy/"
                           target={ '_blank' }
                           rel="noreferrer">Privacy Policy</a>.
                    </p>
                </div>
                <AuthBottomLink size={ 'sm' } text={ 'Already have an account?' } link={ '/' } linkText={ 'Login' }/>
            </div>
        </AuthLayout>
  )
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => {
  return {
    registerUser: (values) => dispatch(handleRegistration(values))
  }
}

export default connect(null, mapDispatchToProps)(Register)
