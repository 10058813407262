import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import GameResult from './game-result'
import { FiChevronLeft } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import GameStats from './play-game/game-stats'

function SinglePlayedGame () {
  const navigate = useNavigate()
  const playedGamedGame = useSelector(state => state.commonReducer.singlePlayedGame)
  return (
        <div className={ 'bg-white-base p-5 rounded-lg' }>
            <div onClick={ () => navigate(-1) } className={ 'flex gap-x-2 items-center text-gray-500 mb-5 font-medium' +
                ' cursor-pointer' }>
                <FiChevronLeft size={ 24 } className={ '-ml-2' }/> Back
            </div>
            <h2 className="text-2xl font-semibold my-2">{ playedGamedGame?.playedContent?.title }</h2>
            <div className={'mb-2'}>
                <GameStats stats={playedGamedGame?.finalStats?.final} statsInfo={playedGamedGame?.finalStats?.titles} />
            </div>
            <GameResult game={ playedGamedGame ?? {} }/>
        </div>
  )
}

SinglePlayedGame.propTypes = {
  game: PropTypes.object
}

export default SinglePlayedGame
