import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { handleContentPostClick } from '../../actions/user/common/Action'
import BlankContentPostImage from '../../assets/images/blank-images/No-preview.png.img.png'

function ContentPostCard ({ post, contentPostClick }) {
  return (
    <>
      <div className="p-6 bg-white-base rounded-lg mb-5">
        {/* Detail Section */}
        <div className="flex flex-col md:flex-row gap-y-2 justify-between items-start">
          <div className="flex order-2 md:order-1">
            <span className='w-12 h-12 bg-[#53b1fd] text-3xl inline-flex justify-center items-center text-white-base rounded-full ' alt="">N</span>
            <div className="p-2">
              <h4 className='font-medium'>Noustro</h4>
              <p className='text-xs text-gray-400'>Learning and Development</p>
            </div>
          </div>
          <span className="text-green-600 bg-green-100 px-4 py-1 text-xs rounded-2xl order-1 md:order-2">{post.skillName}</span>
        </div>
        {/* Description */}
        <div className='my-4'>
          <h3 className='font-semibold text-lg my-2'>{post.title}</h3>
          {post.brief}
        </div>
        {/* Link Preview Section */}
        {/* <iframe src={post.link} className='w-full aspect-video' onClick={() => contentPostClick(post.id)}></iframe> */}
        {
          post.use_iframe
            ? <iframe src={post.link} className='w-full aspect-video' onClick={() => contentPostClick(post.id)}></iframe>
            : <a href={post.link} target='_blank' rel="noopener noreferrer" className="round-lg mt-6 block" onClick={() => contentPostClick(post.id)}>
                <img src={post.link_feature_image ?? BlankContentPostImage} alt={'alternative text'} className='rounded-t-lg w-full' />
                <div className="bg-blue-100 p-4 px-8 rounded-b-lg">
                  <h4 className='font-medium text-gray-700 text-sm'>
                    {post.link_title}
                  </h4>
                </div>
              </a>
        }
      </div>
    </>
  )
}

ContentPostCard.propTypes = {
  post: PropTypes.object.isRequired,
  contentPostClick: PropTypes.func
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({
  contentPostClick: (postId) => dispatch(handleContentPostClick(postId))
})

export default connect(mapStateToProps, mapDispatchToProps)(ContentPostCard)
