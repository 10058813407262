import React, { useEffect } from 'react'
import { Button, Form, Input } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import CloseModal from '../../../components/close-modal'
import { handleAddPost, handleUpdatePost } from '../../../actions/admin/posts/Action'
import TlaSelect from '../../../components/TlaSelect'
import { FiPlus, FiTrash } from 'react-icons/fi'
import { handleGetAllSkills } from '../../../actions/admin/skills/Action'
import TlaModalFormWrapper from '../../../components/tla-modal-form-wrapper'

function PostsForm ({ addPost, updatePost, skills, userId, getSkills }) {
  const { state } = useLocation()
  const resourceId = state?.data?.id
  useEffect(() => {
    getSkills(1, 500)
  }, [])
  const formValues = {
    ...state?.data,
    body: state?.data?.body
      ? Object.values(state?.data?.body)
      : [
          {
            slide_title: '',
            content: ''
          }
        ]
  }
  const formatBeforeSubmit = (values) => {
    const formatBody = values.body.map((slide, index) => ({ ...slide, slide_number: index + 1 }))

    values.body = { ...formatBody }
    values.totalSlide = formatBody.length
    values.userId = userId

    return values
  }

  return (
      <TlaModalFormWrapper beforeSubmit={formatBeforeSubmit}
          initialValues={formValues}
          formTitle={`${resourceId ? 'Update' : 'Create'} Post`}
          resourceId={state?.data?.id ?? null}
          onSubmit={state?.data?.id ? updatePost : addPost}>
                    <Form.Item
                        name={'title'}
                        rules={[
                          {
                            required: true,
                            message: 'Required'
                          }
                        ]}
                        label={'Add Post Title *'}>
                        <Input.TextArea rows={2} size={'large'} placeholder={'Enter post title'}/>
                    </Form.Item>
                    <TlaSelect
                        name={'skillId'}
                        options={skills}
                        optionKey={'title'} label={'Select skill *'} required/>
                    <Form.Item
                        name={'link'}
                        rules={[
                          {
                            type: 'url',
                            message: 'Enter a valid url'
                          }
                        ]}
                        label={'Add Post source link'}>
                        <Input size={'large'} placeholder={'Enter post source link'}/>
                    </Form.Item>
                    <Form.List name="body">
                        {(fields, { add, remove }) => (
                            <>
                                <div className={'flex justify-between items-center mb-2'}>
                                    <h3 className={'text-sm font-medium'}>Slide*</h3>
                                    <Button type="text"
                                            className={'btn btn-light text-sm text-gray-500 hover:!bg-transparent'}
                                            onClick={() => add()} icon={<FiPlus size={20}/>}>
                                        Add Slide
                                    </Button>
                                </div>
                                {fields.map(({ key, name, ...restField }) => (
                                    <div key={key} className={'flex gap-2'}>
                                        <div className={'w-full'}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'slide_title']}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Required'
                                                  }
                                                ]}>
                                                <Input size={'large'} placeholder="Slide Title"/>
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'content']}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Required'
                                                  }
                                                ]}>
                                                <Input.TextArea size={'large'} placeholder="Slide Description"/>
                                            </Form.Item>
                                        </div>
                                        <Button icon={<FiTrash/>} className={'btn'} danger
                                                size={'large'}
                                                onClick={() => remove(name)}/>
                                    </div>
                                ))}
                            </>
                        )}
                    </Form.List>
                    <div className={'flex gap-2'}>
                        <div className={'w-full'}>
                            <CloseModal/>
                        </div>
                        <Form.Item className={'w-full'}>
                            <Button className={'btn-primary w-full'} size={'large'} htmlType="submit">
                                {`${resourceId ? 'Edit' : 'Create'}`} Post
                            </Button>
                        </Form.Item>
                    </div>
      </TlaModalFormWrapper>
  )
}

PostsForm.propTypes = {
  addPost: PropTypes.func.isRequired,
  updatePost: PropTypes.func.isRequired,
  getSkills: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  skills: PropTypes.array.isRequired
}

/**
 * @param state
 * @returns {{skills: *}}
 */
const mapStateToProps = (state) => ({
  skills: state.adminSkillsReducer.skills?.data,
  userId: state.loginReducer.authUser.id

})

/**
 *
 * @param dispatch
 * @returns {{addPost: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  addPost: (data) => dispatch(handleAddPost(data)),
  updatePost: (postId, data) => dispatch(handleUpdatePost(postId, data)),
  getSkills: (pageNumber, pageSize) => dispatch(handleGetAllSkills(pageNumber, pageSize))
})

export default connect(mapStateToProps, mapDispatchToProps)(PostsForm)
