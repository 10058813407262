import React, { useState } from 'react'
import { Button, Form, Input, Radio } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import CloseModal from '../../../components/close-modal.js'
import { handleGetAllModules, handleAddModule, handleUpdateModule } from '../../../actions/admin/modules/Action.js'
import TlaSelect from '../../../components/TlaSelect.js'
import TlaModalFormWrapper from '../../../components/tla-modal-form-wrapper.js'

function ModulesForm ({ addModule, updateModule, modules, getModules }) {
  const { state } = useLocation()
  const resourceId = state?.data?.id
  const [paywall, setPaywall] = useState(state?.data?.behindPaywall ?? false)
  const [type, setType] = useState(state?.data?.personalityType ?? 'PERSONAL')
  const formValues = {
    ...state?.data,
    personalityType: state?.data?.personalityType ?? type
  }
  const formatBeforeSubmit = (values) => {
    if (values.parentModuleId === null) delete values.parentModuleId
    values = {
      ...values,
      behindPaywall: values.behindPaywall === 'true' || values.behindPaywall === true
    }
    return values
  }

  return (
      <TlaModalFormWrapper
          initialValues={formValues}
          formTitle={`${resourceId ? 'Update' : 'Create'} Module`}
          resourceId={state?.data?.id ?? null}
          beforeSubmit={formatBeforeSubmit}
          onSubmit={state?.data?.id ? updateModule : addModule}>
                    <Form.Item
                        name={'title'}
                        rules={[
                          {
                            required: true,
                            message: 'Required'
                          }
                        ]}
                        label={'Module Title *'}>
                        <Input size={'large'} placeholder={'Enter Module title'}/>
                    </Form.Item>
                    <Form.Item
                        name={'hint'}
                        label={'Module Hint '}>
                        <Input size={'large'} placeholder={'SD'}/>
                    </Form.Item>
                    <TlaSelect
                        name={'parentModuleId'}
                        options={modules}
                        optionKey={'title'} label={'Select Parent Module'}/>
                    <Form.Item label="" name="behindPaywall" className='my-4'
                              rules={[
                                {
                                  required: false,
                                  message: 'Required'
                                }
                              ]}>
                        <div className={'flex flex-wrap gap-x-3'}>
                            <p>Behind Paywall</p>
                            <Radio.Group value={paywall} onChange={(e) => setPaywall(e.target.value)}>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </div>
                    </Form.Item>
                    <Form.Item label="" name="personalityType" className='my-4'
                              rules={[
                                {
                                  required: false,
                                  message: 'Required'
                                }
                              ]}>
                        <div className={'flex flex-wrap gap-x-3'}>
                            <p>Personality Type</p>
                            <Radio.Group value={type} onChange={(e) => setType(e.target.value)}>
                                <Radio value={'PROFESSIONAL'}>Professional</Radio>
                                <Radio value={'PERSONAL'}>Personal</Radio>
                            </Radio.Group>
                        </div>
                    </Form.Item>
                    <div className={'flex gap-2'}>
                        <div className={'w-full'}>
                            <CloseModal/>
                        </div>
                        <Form.Item className={'w-full'}>
                            <Button className={'btn-primary w-full'} size={'large'} htmlType="submit">
                                {`${resourceId ? 'Edit' : 'Create'}`} Module
                            </Button>
                        </Form.Item>
                    </div>
      </TlaModalFormWrapper>
  )
}

ModulesForm.propTypes = {
  addModule: PropTypes.func.isRequired,
  updateModule: PropTypes.func.isRequired,
  getModules: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  modules: PropTypes.array.isRequired
}

/**
 * @param state
 * @returns {{modules: *}}
 */
const mapStateToProps = (state) => ({
  modules: state.adminModulesReducer.modules?.data,
  userId: state.loginReducer.authUser.id
})

/**
 *
 * @param dispatch
 * @returns {{addModule: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  addModule: (data) => dispatch(handleAddModule(data)),
  updateModule: (moduleId, data) => dispatch(handleUpdateModule(moduleId, data)),
  getModules: (pageNumber, pageSize) => dispatch(handleGetAllModules(pageNumber, pageSize))
})

export default connect(mapStateToProps, mapDispatchToProps)(ModulesForm)
