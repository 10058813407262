import React, { useState } from 'react'
import { createGlobalStyle } from 'styled-components'
import { Outlet } from 'react-router'
import PropTypes from 'prop-types'
import TlaAddNew from '../../../components/pop-ups/tla-add-new'
import PrimaryButton from '../../../components/buttons/primary-button'
import ButtonComponent from '../../../components/buttons/button-component'
import { FiPlus } from 'react-icons/fi'
import { Input } from 'antd'
import { AiOutlineSearch } from 'react-icons/ai'
import { useLocation } from 'react-router-dom'

const GlobalStyles = createGlobalStyle`
  .page-title {
    font-family: var(--Popins);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: var(--Gray-900);
    margin-bottom: 3px;
  }
`

function PageWrapper () {
  const [pageInfo, setPageInfo] = useState({
    showSidebar: true
  })
  const { pathname } = useLocation()
  return (
        <div className={'bg-white-base shadow-2xl rounded-lg'}>
            <GlobalStyles/>
            <div className={'flex justify-between items-center px-6 py-5 border-b'}>
                <h2 className={'page-title'}>{pageInfo?.title}</h2>
                <div className='flex gap-2'>
                  <TlaAddNew link={`${pathname}/form`}>
                      <PrimaryButton icon={<FiPlus/>} text={pageInfo?.buttonText}/>
                  </TlaAddNew>
                  {
                    pageInfo.showImportBtn &&
                    <TlaAddNew link={`${pathname}/import`}>
                        <ButtonComponent icon={<FiPlus/>} variant='warning' text={pageInfo?.importBtnText} />
                    </TlaAddNew>
                  }
                </div>
            </div>
            <div>
                <div className={'py-3 px-6 flex justify-end'}>
                {
                  pageInfo.showSearch && <Input className={'w-[400px]'} size={'large'} placeholder={'Search'} addonBefore={<AiOutlineSearch/>}/>
                }
                  {/* {
                    pathname !== '/questions'
                      ? (<Input className={'w-[400px]'} size={'large'} placeholder={'Search'}
                      addonBefore={<AiOutlineSearch/>}/>)
                      : ''
                  } */}
                </div>
                <Outlet context={{ setPageInfo }}/>
            </div>
        </div>
  )
}

PageWrapper.propTypes = {
  pageInfo: PropTypes.object
}

export default PageWrapper
