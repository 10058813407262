import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Table } from 'antd'
import TlaPagination from './tla-pagination'
import { FiPlus } from 'react-icons/fi'
import TlaAddNew from '../pop-ups/tla-add-new'

function TlaTableWrapper ({
  meta,
  data,
  callbackFunction,
  children,
  numberColumn,
  numberColumnTitle,
  numberColumnWidth,
  hasSelection,
  filters,
  showHeader,
  fetchId,
  title,
  subTitle,
  addLink,
  rowKey
}) {
  const [loading, setLoading] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }
  return (
        <TlaPagination
            showHeader={showHeader}
            meta={meta}
            loadData={(pageNumber) => {
              const params = fetchId ? ([fetchId, pageNumber]) : filters && Object.keys(filters).length ? [pageNumber, 15, filters] : [pageNumber]
              setLoading(true);
              (callbackFunction(...params)).then(() => {
                setLoading(false)
              })
            }}>
            {
                (title || subTitle || addLink) &&
                <div className={'flex justify-between items-center mx-6 py-5'}>
                    <div>
                        <h3 className={'text-base font-medium text-gray-900'}>{title}</h3>
                        <p className={'text-sm text-gray-500'}>{subTitle}</p>
                    </div>
                    <div>
                        {
                            addLink &&
                            <TlaAddNew link={addLink}>
                                <Button type={'primary'}
                                        className={'btn-primary h-10 rounded-lg flex items-center'}>
                                    <FiPlus/>&nbsp;Add Job Query
                                </Button>
                            </TlaAddNew>
                        }
                    </div>
                </div>
            }
            {
                data.length > 0 &&
                <Table rowClassName={'tla-table-row'} className='no-border-row' size={'small'} rowSelection={hasSelection ? rowSelection : null}
                       pagination={false} loading={loading}
                       dataSource={data} scroll={{ x: 50 }} rowKey={rowKey}>
                    {
                        numberColumn &&
                        <Table.Column responsive={['lg']}
                                      width={numberColumnWidth}
                                      className='text-center py-4'
                                      title={numberColumnTitle}
                                      render={(text, record, index) => {
                                        let number = index + parseInt(meta.from)
                                        if (meta.pageSize && meta.page) {
                                          number = index + 1 + parseInt(meta.pageSize) * (parseInt(meta.page) - 1)
                                        }
                                        return <>{`${number++}.`}</>
                                      }}/>
                    }

                    {children}
                </Table>
            }
        </TlaPagination>
  )
}

TlaTableWrapper.defaultProps = {
  meta: {
    from: 1,
    to: 10,
    total: 10
  },
  data: [],
  rowKey: 'id',
  numberColumnTitle: 'S no.',
  numberColumn: true,
  numberColumnWidth: 20,
  hasSelection: false,
  showHeader: true,
  fetchId: null,
  filters: null,
  title: null,
  subTitle: null,
  addLink: null
}

TlaTableWrapper.propTypes = {
  meta: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  callbackFunction: PropTypes.func.isRequired,
  children: PropTypes.any,
  hasSelection: PropTypes.bool,
  numberColumnTitle: PropTypes.string,
  numberColumn: PropTypes.any,
  numberColumnWidth: PropTypes.string,
  showHeader: PropTypes.bool,
  filters: PropTypes.object,
  fetchId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  title: PropTypes.string,
  subTitle: PropTypes.string,
  rowKey: PropTypes.string,
  addLink: PropTypes.string
}

export default TlaTableWrapper
