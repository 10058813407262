import React from 'react'
import { FiFlag } from 'react-icons/fi'
import PropTypes from 'prop-types'
import SelfAssessmentTitle from './components/self-assessment-title'
import FeedbackTitle from './components/feedback-title'
import { useLocation } from 'react-router-dom'
import TlaAddNew from '../../components/pop-ups/tla-add-new'
import LearnMore from '../../components/learn-more'

function FeedbackHeader ({ data, header1, questionId, showReport, receiver }) {
  const { state } = useLocation()

  return (
        <div className={'bg-white-base rounded-lg mx-auto w-full'}>
            <div>
                {
                    header1 &&
                    <div className={`border-b ${state ? 'py-4 px-6' : ''}`}>
                        <h4 className={'text-gray-900 text-base font-medium'}>
                            {(state || data) ? 'Anonymous Feedback' : 'Self Assessment'}
                        </h4>
                        <p className={'text-sm text-gray-500'}>
                            {/* {(state || data) ? `Help ${state?.mainUser?.userInformation?.name ?? data?.name} Anonymously` : <>&nbsp;</>} */}
                            {(state || data) ? <LearnMore underline text={'Learn how anonymous feedback works'} link={'https://noustro.com/how-it-works/'}/> : <>&nbsp;</>}
                        </p>
                    </div>
                }
                <div className={`${state ? 'p-2 md:p-5' : 'py-2 md:py-5'}`}>
                    <div className={'flex flex-col-reverse md:flex-row justify-between gap-3'}>
                        {(state || data)
                          ? <FeedbackTitle
                                jobTitle={state?.mainUser?.userInformation?.jobTitle ?? data?.jobTitle}
                                name={state?.mainUser?.userInformation?.name ?? data?.name}
                                photo={state?.mainUser?.userInformation?.photo ?? data?.photo}/>
                          : <SelfAssessmentTitle/>}
                        <div className={'text-right flex flex-row md:flex-col items-end justify-between' +
                            ' md:justify-end mb-1 md:mb-0'}>
                            <div className={'text-xs text-indigo-700 font-medium bg-indigo-50 rounded-2xl py-0.5 px-2' +
                                ' w-fit flex gap-x-1'}>
                                {(state || data || receiver) ? <>Anonymous Feedback <LearnMore link={'https://noustro.com/how-it-works/'} icon color={'text-indigo-700'}/></> : 'Self Assessment'}
                            </div>
                            {
                                showReport &&
                                <TlaAddNew link={'/report/question'} data={{ questionId }}>
                                    <p className={'text-xs text-gray-400 flex justify-end items-center gap-1 mt-1'}>
                                        Report <FiFlag/>
                                    </p>
                                </TlaAddNew>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

FeedbackHeader.defaultProps = {
  data: null,
  header1: true,
  questionId: null,
  receiver: false,
  showReport: true
}

FeedbackHeader.propTypes = {
  data: PropTypes.object,
  header1: PropTypes.bool,
  showReport: PropTypes.bool,
  receiver: PropTypes.bool,
  questionId: PropTypes.number
}
export default FeedbackHeader
