import { Types } from './Types'

export const authenticate = (payload) => {
  return {
    type: Types.AUTHENTICATE,
    payload
  }
}

export const addAuth = () => {
  return {
    type: Types.ADD_AUTH
  }
}

export const removeAuth = () => {
  return {
    type: Types.REMOVE_AUTH
  }
}

export const register = () => {
  return {
    type: Types.REGISTER
  }
}

export const verifyEmail = () => {
  return {
    type: Types.VERIFY_EMAIL
  }
}

export const getProfile = (payload) => {
  return {
    type: Types.GET_PROFILE,
    payload
  }
}
export const updateProfile = (payload) => {
  return {
    type: Types.UPDATE_PROFILE,
    payload
  }
}
export const updateSelfAssessed = (payload) => {
  return {
    type: Types.UPDATE_SELF_ASSESSED,
    payload
  }
}
export const getAssessmentQuestions = (payload) => {
  return {
    type: Types.GET_ASSESSMENT_QUESTIONS,
    payload
  }
}
export const incrementGivenAssessment = (payload) => {
  return {
    type: Types.INCREMENT_GIVEN_ASSESSMENT,
    payload
  }
}

export const getUserAssessmentQuestions = (payload) => {
  return {
    type: Types.GET_USER_ASSESSMENT_QUESTIONS,
    payload
  }
}
