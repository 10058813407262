import React from 'react'
import { createGlobalStyle } from 'styled-components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Profile from './profile'

const GlobalStyles = createGlobalStyle`
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #ffffff00 !important;
  }
`

function AppSidebar ({ permissions, authUser }) {
  return (
        <>
            <GlobalStyles/>
            <div className={'min-w-[250px] basis-[250px] hidden md:hidden lg:block'}>
                 <Profile userInfo={authUser}/>
            </div>
        </>
  )
}

AppSidebar.propTypes = {
  collapsed: PropTypes.bool,
  permissions: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  authUser: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    permissions: [],
    roles: [],
    authUser: state.loginReducer.authUser
  }
}

export default connect(mapStateToProps)(AppSidebar)
