import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router'
import TlaTableWrapper from '../../../components/table/tla-table-wrapper'
import { Spin, Table } from 'antd'
import PropTypes from 'prop-types'
import { handleDeleteModuleLesson, handleGetAllModuleLessons } from '../../../actions/admin/module-lessons/Action'
import { connect } from 'react-redux'
import TlaConfirm from '../../../components/tla-confirm'
import { TlaSuccess } from '../../../utils/messages'
import TlaEdit from '../../../components/tla-edit'
import { FiEdit2 } from 'react-icons/fi'
import TlaSelect from '../../../components/TlaSelect'
import { handleGetAllModules } from '../../../actions/admin/modules/Action'

const ModuleLessons = ({ getModuleLessons, moduleLessons, deleteModuleLesson, modules, getModules }) => {
  const { setPageInfo } = useOutletContext()
  const { data, meta } = moduleLessons
  const [loading, setLoading] = useState(true)
  const [module, setModule] = useState(0)
  useEffect(() => {
    setPageInfo({
      title: 'Module Lessons',
      buttonText: 'Add Module Lesson Link',
      buttonLink: 'Add Module Lesson Link',
      showImportBtn: false,
      subTitle: '',
      helpingText: '',
      showSearch: false
    })
    setLoading(false)
    getModuleLessons().then(() => setLoading(false))
    getModules(1, 1000)
  }, [])

  return (
        <div className={'pb-4'}>
            <Spin spinning={loading}>
                <div className="w-1/4 ml-auto pr-6 mb-5">
                  <TlaSelect
                    defaultValue='Select module'
                    onChange={(value) => {
                      setLoading(true)
                      getModuleLessons(1, 15, { module: value }).then(() => {
                        setLoading(false)
                        setModule(value)
                      })
                    }}
                    required name={'module'} options={modules} optionKey={'title'}/>
                </div>
                <TlaTableWrapper
                   meta={meta} filters={{ module }}
                   numberColumn={true} data={data} showHeader={false}
                   callbackFunction={getModuleLessons}>
                    <Table.Column title={'Lesson Title'} render={((record) => record.contentType === 'QUESTION' ? record.question?.questionTitleSelf : record.lesson?.title)}/>
                    <Table.Column title={'Module Title'} render={((record) => record.module.title ?? '')}/>
                    <Table.Column title={'Type'} render={((record) => record.contentType)}/>
                    <Table.Column title={'Sequence'} render={((record) => record.sequence ?? '')}/>
                    <Table.Column title={'Actions'} render={((record) => (
                        <div className={'flex items-center'}>
                            <TlaConfirm title={'Delete ModuleLesson'}
                                        fullText={'Do you really want to delete this ModuleLesson?'}
                                        callBack={() => {
                                          deleteModuleLesson(record.id).then(() => TlaSuccess('Deleted'))
                                        }}/>
                            <TlaEdit data={record} icon={<FiEdit2 className={'icon'}/>} link={'form'}/>
                        </div>
                    ))}/>
                </TlaTableWrapper>
            </Spin>
        </div>
  )
}

ModuleLessons.propTypes = {
  getModuleLessons: PropTypes.func.isRequired,
  getModules: PropTypes.func.isRequired,
  modules: PropTypes.array.isRequired,
  deleteModuleLesson: PropTypes.func.isRequired,
  moduleLessons: PropTypes.object.isRequired
}

/**
 * @param state
 * @returns {{moduleLessons: *}}
 */
const mapStateToProps = (state) => ({
  moduleLessons: state.adminModuleLessonsReducer.moduleLessons ?? {},
  modules: state.adminModulesReducer.modules.data ?? []
})

/**
 *
 * @param dispatch
 * @returns {{getModuleLessons: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  getModuleLessons: (pageNumber, pageSize, filters) => dispatch(handleGetAllModuleLessons(pageNumber, pageSize, filters)),
  deleteModuleLesson: (moduleLessonId) => dispatch(handleDeleteModuleLesson(moduleLessonId)),
  getModules: (pageNumber, pageSize) => dispatch(handleGetAllModules(pageNumber, pageSize))
})

export default connect(mapStateToProps, mapDispatchToProps)(ModuleLessons)
