import React from 'react'
import { Button, Form, Input } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import CloseModal from '../../../components/close-modal.js'
import { handleAddCourseCategory, handleUpdateCourseCategory } from '../../../actions/admin/course-categories/Action.js'
import TlaModalFormWrapper from '../../../components/tla-modal-form-wrapper.js'

function CourseCategoriesForm ({ addCourseCategory, updateCourseCategory }) {
  const { state } = useLocation()
  const resourceId = state?.data?.id
  const formValues = {
    ...state?.data
  }

  return (
      <TlaModalFormWrapper
          initialValues={formValues}
          formTitle={`${resourceId ? 'Update' : 'Create'} Category`}
          resourceId={state?.data?.id ?? null}
          onSubmit={state?.data?.id ? updateCourseCategory : addCourseCategory}>
                    <Form.Item
                        name={'title'}
                        rules={[
                          {
                            required: true,
                            message: 'Required'
                          }
                        ]}
                        label={'Category Title *'}>
                        <Input size={'large'} placeholder={'Eg Leadership'}/>
                    </Form.Item>
                    <div className={'flex gap-2'}>
                        <div className={'w-full'}>
                            <CloseModal/>
                        </div>
                        <Form.Item className={'w-full'}>
                            <Button className={'btn-primary w-full'} size={'large'} htmlType="submit">
                                {`${resourceId ? 'Update' : 'Create'}`} Category
                            </Button>
                        </Form.Item>
                    </div>
      </TlaModalFormWrapper>
  )
}

CourseCategoriesForm.propTypes = {
  addCourseCategory: PropTypes.func.isRequired,
  updateCourseCategory: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({})
/**
 *
 * @param dispatch
 * @returns {{addCourseCategory: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  addCourseCategory: (data) => dispatch(handleAddCourseCategory(data)),
  updateCourseCategory: (courseCategorieId, data) => dispatch(handleUpdateCourseCategory(courseCategorieId, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CourseCategoriesForm)
