import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import OrgMenus from './org-menus'

function AppSidebar () {
  return (
        <div className={'min-w-[250px] basis-[250px] hidden md:hidden lg:block bg-white-base rounded-lg min-h-screen'}>
            <OrgMenus/>
        </div>
  )
}

AppSidebar.propTypes = {
  collapsed: PropTypes.bool,
  authUser: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    authUser: state.loginReducer.authUser
  }
}

export default connect(mapStateToProps)(AppSidebar)
