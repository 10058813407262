import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const ResponsiveText = ({ text, buttonClasses }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const [displayText, setDisplayText] = useState('')
  const [isExpanded, setIsExpanded] = useState(false)
  const [showButton, setShowButton] = useState(false) // State to manage button visibility

  // Effect to handle resizing and adjust text based on device width
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 768
      setIsMobile(mobile)
      updateText(mobile, isExpanded)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isExpanded])

  // Function to update the text and button visibility based on the device and expanded state
  const updateText = (mobile, expanded) => {
    const maxLength = mobile ? 150 : 250 // Set max length based on device type
    setDisplayText(expanded ? text : text.substring(0, maxLength) + (text.length > maxLength ? '...' : ''))
    setShowButton(text.length > maxLength) // Show button only if text exceeds max length
  }

  // Initial setting
  useEffect(() => {
    updateText(isMobile, isExpanded)
  }, [isMobile, isExpanded])

  // Toggle function for expanding or collapsing the text
  const toggleText = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div>
      <p className="responsive-text">{displayText}</p>
      {showButton && ( // Conditionally render the button based on showButton state
        <div className='flex justify-end'>
          <span onClick={toggleText} className={buttonClasses}>
            {isExpanded ? 'View Less' : 'View More'}
          </span>
        </div>
      )}
    </div>
  )
}

ResponsiveText.propTypes = {
  buttonClasses: PropTypes.string,
  text: PropTypes.string
}

export default ResponsiveText
