import { Types } from '../../actions/admin/quiz-questions/Types'

const initialState = {
  quizQuestions: {
    data: [],
    meta: {}
  },
  quizQuestion: {}
}

export default function adminQuizQuestionsReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_QUIZ_QUESTION:
      return {
        ...state,
        quizQuestions: {
          ...state.quizQuestions,
          data: [
            action.payload.data.quizQuestion,
            ...state.quizQuestions.data
          ]
        }
      }
    case Types.ALL_QUIZ_QUESTIONS:{
      return {
        ...state,
        quizQuestions: {
          ...state.quizQuestions,
          data: action.payload.data.quizQuestions,
          meta: {
            total: action.payload.data.count,
            current_page: Number(action.payload.data.page),
            page: action.payload.data.page,
            pageSize: action.payload.data.pageSize
          }
        }
      }
    }
    case Types.UPDATE_QUIZ_QUESTION:
      return {
        ...state,
        quizQuestions: {
          ...state.quizQuestions,
          data: state.quizQuestions.data.map((quiz) => {
            return quiz.id === action.payload.data.quizQuestion.id ? action.payload.data.quizQuestion : quiz
          })
        }
        // quizQuestion: action.payload.data.upda
      }
    case Types.DELETE_QUIZ_QUESTION:
      return {
        ...state,
        quizQuestions: {
          ...state.quizQuestions,
          data: state.quizQuestions.data.filter((quizQuestions) => quizQuestions.id !== action.quizQuestionId)
        }
      }

    default:
      return state
  }
}
