import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router'
import TlaTableWrapper from '../../../components/table/tla-table-wrapper'
import { Spin, Table } from 'antd'
import PropTypes from 'prop-types'
import { handleDeleteLessonQuestion, handleGetAllLessonQuestions } from '../../../actions/admin/lesson-questions/Action'
import { connect } from 'react-redux'
import TlaConfirm from '../../../components/tla-confirm'
import { TlaSuccess } from '../../../utils/messages'
import TlaEdit from '../../../components/tla-edit'
import { FiEdit2 } from 'react-icons/fi'
import TlaSelect from '../../../components/TlaSelect'
import { handleGetAllSkills } from '../../../actions/admin/skills/Action'

const LessonQuestions = ({ getLessonQuestions, lessonQuestions, deleteLessonQuestion, skills, getSkills }) => {
  const { setPageInfo } = useOutletContext() || {}
  const { data, meta } = lessonQuestions
  const [loading, setLoading] = useState(true)
  const [skill, setSkill] = useState(0)
  useEffect(() => {
    if (setPageInfo !== undefined) {
      setPageInfo({
        title: 'Lesson Questions',
        buttonText: 'Add Lesson Question',
        buttonLink: 'Add Lesson Question',
        showImportBtn: false,
        subTitle: '',
        helpingText: '',
        showSearch: false
      })
    }
    getSkills(1, 1000).then()
    getLessonQuestions().then(() => setLoading(false))
  }, [])

  return (
    <div className={'pb-4'}>
      <Spin spinning={loading}>
        <div className="w-1/4 ml-auto pr-6 mb-5">
          <TlaSelect
            defaultValue='Select Skill'
            onChange={(value) => {
              setLoading(true)
              getLessonQuestions(1, 15, { skill: value }).then(() => {
                setLoading(false)
                setSkill(value)
              })
            }}
            required name={'skillId'} options={skills} optionKey={'title'} />
        </div>
        <TlaTableWrapper
          meta={meta} filters={{ skill }}
          numberColumn={true} data={data} showHeader={false}
          callbackFunction={getLessonQuestions}>
          <Table.Column title={'Question Title'} render={((record) => record.questionTitleSelf ?? 'No Title')} />
          <Table.Column title={'Lesson'} render={((record) => record.lesson?.title ?? 'No Lesson Title')} />
          <Table.Column title={'Actions'} render={((record) => (
            <div className={'flex items-center'}>
              <TlaConfirm title={'Delete Question'}
                fullText={'Do you really want to delete this Question?'}
                callBack={() => {
                  deleteLessonQuestion(record.id).then(() => TlaSuccess('Deleted'))
                }} />
              <TlaEdit data={record} icon={<FiEdit2 className={'icon'} />} link={'form'} />
            </div>
          ))} />
        </TlaTableWrapper>
      </Spin>
    </div>
  )
}

LessonQuestions.propTypes = {
  getLessonQuestions: PropTypes.func.isRequired,
  getSkills: PropTypes.func.isRequired,
  deleteLessonQuestion: PropTypes.func.isRequired,
  skills: PropTypes.array.isRequired,
  lessonQuestions: PropTypes.object.isRequired
}

/**
 * @param state
 * @returns {{lessonQuestions: *}}
 */
const mapStateToProps = (state) => ({
  lessonQuestions: state.adminLessonQuestionsReducer.questions ?? {},
  skills: state.adminSkillsReducer.skills.data ?? {}
})

/**
 *
 * @param dispatch
 * @returns {{getLessonQuestions: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  getLessonQuestions: (pageNumber, pageSize, filters) => dispatch(handleGetAllLessonQuestions(pageNumber, pageSize, filters)),
  getSkills: (pageNumber, pageSize) => dispatch(handleGetAllSkills(pageNumber, pageSize)),
  deleteLessonQuestion: (lessonQuestionId) => dispatch(handleDeleteLessonQuestion(lessonQuestionId))
})

export default connect(mapStateToProps, mapDispatchToProps)(LessonQuestions)
