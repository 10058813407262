import { Types } from './Types'

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export const submitCv = (payload) => {
  return {
    type: Types.SUBMIT_CV,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export const getJobQueries = (payload) => {
  return {
    type: Types.GET_JOB_QUERIES,
    payload
  }
}

export const deleteJobQuery = (id) => {
  return {
    type: Types.DELETE_JOB_QUERY,
    id
  }
}

export const getJobsScraped = (payload) => {
  return {
    type: Types.GET_JOBS_SCRAPED,
    payload
  }
}
