import React, { useEffect } from 'react'
import { Button, Form } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import TlaModalFormWrapper from '../../../components/tla-modal-form-wrapper'
import CloseModal from '../../../components/close-modal'
import {
  handleAddSkillRelationship,
  handleUpdateSkillRelationship
} from '../../../actions/admin/skill-relationships/Action'
import TlaSelect from '../../../components/TlaSelect'
import { handleGetAllSkills } from '../../../actions/admin/skills/Action'

function SkillRelationshipForm ({ addSkillRelation, updateSkillRelation, skills, getSkills }) {
  const { state } = useLocation()
  useEffect(() => {
    getSkills(1, 500)
  }, [])
  const formValues = {
    ...state?.data
  }

  return (
        <TlaModalFormWrapper
            initialValues={formValues}
            formTitle={'Create Skill Relationship'}
            resourceId={state?.data?.id ?? null}
            onSubmit={state?.data?.id ? updateSkillRelation : addSkillRelation}>
            <TlaSelect name={'child_id'}
                       options={skills} optionKey={'title'} label={'Select skill *'} required/>
            <TlaSelect name={'parent_id'}
                       options={skills} optionKey={'title'} label={'Select Parent Skill *'} required/>
            <div className={'flex gap-2 mt-5'}>
                <div className={'w-full'}>
                    <CloseModal/>
                </div>
                <Form.Item className={'w-full'}>
                    <Button className={'btn-primary w-full'} size={'large'} htmlType="submit">
                        Add Relationship
                    </Button>
                </Form.Item>
            </div>
        </TlaModalFormWrapper>
  )
}

SkillRelationshipForm.propTypes = {
  addSkillRelation: PropTypes.func.isRequired,
  updateSkillRelation: PropTypes.func.isRequired,
  getSkills: PropTypes.func.isRequired,
  skills: PropTypes.array.isRequired
}

/**
 * @param state
 * @returns {{skills: *}}
 */
const mapStateToProps = (state) => ({
  skills: state.adminSkillsReducer.skills?.data
})

/**
 *
 * @param dispatch
 * @returns {{addSkillRelation: (function(*): *), updateSkillRelation: (function(*, *): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  addSkillRelation: (data) => dispatch(handleAddSkillRelationship(data)),
  updateSkillRelation: (skillId, data) => dispatch(handleUpdateSkillRelationship(skillId, data)),
  getSkills: (pageNumber, pageSize) => dispatch(handleGetAllSkills(pageNumber, pageSize))
})

export default connect(mapStateToProps, mapDispatchToProps)(SkillRelationshipForm)
