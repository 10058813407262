import React from 'react'
import { Progress } from 'antd'
import PropTypes from 'prop-types'
import { createGlobalStyle } from 'styled-components'
import { getPercentage } from '../../../utils'

const GlobalStyles = createGlobalStyle`
  .mobile-bar {
    display: none;
  }
  @media only screen and (max-width: 600px) {
    .desktop-bar {
      display: none;
    }
    .mobile-bar {
      display: block;
    }
  }
`
function ProgressBar ({ partialValue, totalValue, color, totalQuestions, questionNumber }) {
  const percent = getPercentage(partialValue, totalValue) * 10
  return (
        <div>
            <GlobalStyles/>
            <div className={'w-full md:w-[440px] mx-auto'}>
                <div className={'flex justify-between'}>
                    <span className={'font-bold text-gray-700'}>{percent}%</span>
                    <p className={'text-gray-700'}>
                        <span className={'font-semibold'}>{questionNumber}</span> out of
                        <span className={'font-bold'}> {totalQuestions}</span>
                    </p>
                </div>
                <Progress strokeColor={color} percent={percent} showInfo={false}/>
            </div>
        </div>
  )
}

ProgressBar.defaultProps = {
  color: '#1890ff',
  totalValue: 10,
  questionNumber: 10,
  totalQuestions: 10

}

ProgressBar.propTypes = {
  partialValue: PropTypes.number,
  totalValue: PropTypes.number,
  totalQuestions: PropTypes.number,
  questionNumber: PropTypes.number,
  color: PropTypes.string
}

export default ProgressBar
