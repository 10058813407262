import React from 'react'
import { Outlet, Route, Routes, useLocation } from 'react-router-dom'
import { ModalRoute } from './modal-route'
import ForgotPassword from '../../pages/auth/forgot-password'
import ResetPassword from '../../pages/auth/reset-password'
import General from '../../pages/my-profile/general'
import ChangePassword from '../../pages/my-profile/change-password'
// import Dashboard from '../../pages/dashboard'
import Layout from '../../pages/dashboard/layout'
import Contacts from '../../pages/contacts'
import ProfileLayout from '../../pages/dashboard/layout/profile-layout'
import Feedback from '../../pages/feedback'
import GiveFeedback from '../../pages/feedback/give-feedback'
import FeedbackLayout from '../../pages/feedback/give-feedback/feedback-layout'
import Home from '../../pages/home'
import NousGpt from '../../pages/nous-gpt'
import { useSelector } from 'react-redux'
import NoChat from '../../pages/nous-gpt/no-chat'
import ChatContent from '../../pages/nous-gpt/chat-detail/chat-content'
import SkillsOverview from '../../pages/dashboard/skills-overview'
import NewChat from '../../pages/nous-gpt/new-chat'
import Learn from '../../pages/learn'
import PublicProfileSettings from '../../pages/public-profile-settings'
import PublicProfile from '../../pages/my-profile/public-profile'
import Courses from '../../pages/courses'
import CourseLayout from '../../pages/dashboard/layout/course-layout'
import SingleCourse from '../../pages/courses/single-course'
import ModuleLesson from '../../pages/courses/module-lesson'
import orgRoutes from './org-routes'
import Organizations from '../../pages/my-profile/organizations'
import NousQuizzes from '../../pages/nous-quizzes'
import QuizQuestions from '../public-components/nous-quiz/quiz-questions'
import publicCoursesRoutes from './public-courses-routes'
import GiveFeedbackPage from '../../pages/feedback/give-feedback/give-feedback-page'
import NousGames from '../../pages/games'
import PlayGame from '../../pages/games/play-game'
import GameLayout from '../../pages/feedback/give-feedback/game-layout'
import SinglePlayedGame from '../../pages/games/single-play-game'
import PlayNewGame from '../../pages/games/play-new-game'
import PlayedGames from '../../pages/games/played-games'

export const ProtectedRoutes = () => {
  const location = useLocation()
  const user = useSelector((state) => state.loginReducer.authUser)
  const background = location.state && location.state.background

  return (
        <React.Fragment>
            <Routes location={ background || location }>
                {
                    !user.selfAssessed
                      ? <Route exact element={ <FeedbackLayout first/> } path="*">
                            <Route exact element={ <GiveFeedback isSelfAssessment/> } path="*"/>
                        </Route>
                      : <React.Fragment>
                            <Route exact element={ <Layout/> } path="/">
                                <Route index element={ <Home/> }/>
                                <Route exact element={ <Home/> } path={ 'feed' }/>
                                <Route exact element={ <Learn/> } path={ 'learn' }/>
                                {/* <Route exact element={ <FeedbackLayout/> }> */}
                                {/* </Route> */}
                                <Route exact element={ <Contacts/> } path="contacts"/>
                                <Route exact element={ <Feedback/> } path='requests'/>
                                <Route exact element={ <NousQuizzes/> } path='nous-quizzes'/>
                                <Route exact element={ <NousGames/> } path='nous-games'>
                                    <Route index element={ <PlayNewGame/> } path='play'/>
                                    <Route exact element={ <PlayedGames/> } path='played'/>
                                </Route>
                                <Route exact element={ <SinglePlayedGame/> } path='played-game/:uuid/result'/>
                            </Route>
                            <Route exact element={ <GameLayout/> } path="/">
                                <Route exact element={ <PlayGame/> } path='game/play'/>
                            </Route>
                            <Route exact element={ <FeedbackLayout/> } path="/">
                                <Route element={ <SkillsOverview/> } index path="dashboard/overview/self"/>
                                <Route exact element={ <SkillsOverview/> } path="dashboard/overview/contact"/>
                                <Route exact element={ <NousGpt/> } path={ 'nous-gpt' }>
                                    <Route exact element={ <NoChat/> } index/>
                                    <Route exact element={ <NewChat/> } path={ 'new-chat' }/>
                                    <Route exact element={ <ChatContent/> } path={ 'chat/:id/detail' }/>
                                </Route>
                                <Route exact element={ <GiveFeedbackPage/> } path="requests/give-feedback"/>
                                <Route exact element={ <QuizQuestions/> } path="quiz/:id/questions"/>
                            </Route>
                            <Route exact element={ <CourseLayout/> } path="/">
                                <Route exact element={ <Courses/> } path='courses'/>
                                <Route exact element={ <SingleCourse/> } path='courses/:courseId'/>
                                <Route exact element={ <ModuleLesson/> }
                                       path='/courses/:courseId/module/:moduleId/lessons/:contentId'/>
                            </Route>
                            <Route exact element={ <ProfileLayout/> }>
                                <Route path="profile" element={ <General/> }/>
                                <Route path="organizations" element={ <Organizations/> }/>
                                <Route path="settings" element={ <ChangePassword/> }/>
                                <Route exact element={ <PublicProfileSettings/> } path={ 'public-profile-settings' }/>
                            </Route>
                            { orgRoutes }
                            <Route exact element={ <Layout/> } path="/register/:invitationCode/invitation">
                                <Route index element={ <Home/> }/>
                            </Route>
                            <Route element={ <ForgotPassword/> } path={ '/forgot-password' }/>
                            <Route element={ <ResetPassword/> } path={ '/resetpassword/:token' }/>
                            <Route element={ <PublicProfile/> } path={ '/pp/:username' }/>
                        </React.Fragment>
                }
                { publicCoursesRoutes }
            </Routes>

            { background && (<><ModalRoute/> <Outlet/></>) }
        </React.Fragment>
  )
}
