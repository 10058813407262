import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { handleCourseEnrollment } from '../../actions/user/common/Action'
import { Spin } from 'antd'
import { useParams } from 'react-router'
import { Link, useNavigate } from 'react-router-dom'
import { FiChevronLeft } from 'react-icons/fi'
import DisplaySingleCourse from '../courses/components/display-single-course'
import { handleGetPublicCourseDetails } from '../../actions/user/common/PublicActions'

function PublicSingleCourse ({ getCourseDetails, course, courseEnrollment }) {
  const { courseId } = useParams()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getCourseDetails(courseId ?? 2).then((res) => {
      setLoading(false)
    })
  }, [])

  const navigate = useNavigate()
  return (
        <React.Fragment>
            <div className="bg-white-base rounded-md max-w-screen-2xl px-3 md:px-12 mx-auto">
                <Spin spinning={ loading }>
                    <div className="page-hesader">
                        <Link to={ '/public/courses' }
                              className='inline-flex items-center gap-1 font-medium text-sm text-gray-500 cursor-pointer'>
                            <FiChevronLeft/> Back
                        </Link>
                    </div>
                    <DisplaySingleCourse enroll={ () => navigate('/login') } course={ course }/>
                </Spin>
            </div>
        </React.Fragment>
  )
}

PublicSingleCourse.propTypes = {
  course: PropTypes.object.isRequired,
  getCourseDetails: PropTypes.func.isRequired,
  courseEnrollment: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  course: state.commonReducer.course ?? {}
})

const mapDispatchToProps = (dispatch) => ({
  getCourseDetails: (courseId) => dispatch(handleGetPublicCourseDetails(courseId)),
  courseEnrollment: (courseId) => dispatch(handleCourseEnrollment(courseId))
})

export default connect(mapStateToProps, mapDispatchToProps)(PublicSingleCourse)
