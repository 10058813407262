import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function addContact (payload) {
  return {
    type: Types.NEW_CONTACT,
    payload
  }
}
export function makeRequest (payload) {
  return {
    type: Types.NEW_REQUEST,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function allContacts (payload) {
  return {
    type: Types.ALL_CONTACTS,
    payload
  }
}

/**
 *
 * @param contact
 * @returns {{contact, type: string}}
 */
export function deleteContact (contact) {
  return {
    type: Types.DELETE_CONTACT,
    contact
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function updateContact (payload) {
  return {
    type: Types.UPDATE_CONTACT,
    payload
  }
}
