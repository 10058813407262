import React from 'react'
import GiveFeedback from './index'

function GiveFeedbackPage () {
  return (
        <div className={ 'bg-white-base rounded-lg mx-auto w-[877px]' }>
            <GiveFeedback/>
        </div>
  )
}

export default GiveFeedbackPage
