import React, { useState } from 'react'
// import PropTypes from 'prop-types'
import AllSkills from '../dashboard/skills-overview/all-skills'
// import ContactsQuote from '../quotes/contacts-quotes'
import SidebarForm from '../dashboard/layout/sidebar-form'
import { Spin } from 'antd'

function PublicProfileSettings () {
  const [loading, setLoading] = useState(false)
  return (
    <React.Fragment>
        <div className={'hidden md:block xl:hidden'}>
            <SidebarForm col={'row'}/>
        </div>
        <Spin spinning={loading}>
          <div className={'bg-white-base rounded-lg'}>
              <AllSkills isLoading={setLoading}/>
          </div>
        </Spin>
    </React.Fragment>
  )
}

export default PublicProfileSettings
