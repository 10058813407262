import React from 'react'
import { Typography } from 'antd'

// eslint-disable-next-line react/prop-types
export const Title = ({ title, color, value, headerText, headerTitle, active, setActive }) => (
    <div className={''}>
        <span className={`${color} text-xs italic font-semibold uppercase ml-1`}>{title}</span>
        <Action value={value} headerText={headerText} headerTitle={headerTitle} active={active} setActive={setActive}/>
    </div>
)

// eslint-disable-next-line react/prop-types
export const Action = ({ value, headerTitle, headerText, active, setActive }) => (
    <div>
        <div className={`${value === active ? 'hidden' : ''}`}>
            <span className={'text-2xl text-gray-900 font-semibold -mt-7 mb-3'}>{headerTitle}</span>
            <Typography.Paragraph
                className={'text-violet-700 text-base mt-3'}
                ellipsis={{ rows: 2, expanded: false }}>
                <span dangerouslySetInnerHTML={{ __html: headerText }}></span>
            </Typography.Paragraph>
            <div
                className={'text-gray-500 text-sm flex items-center justify-between'}>
                <span className={'font-medium'}>Read more about Do’s and Don’ts</span>
                <span className={'cursor-pointer underline'} onClick={() => {
                  setActive(value)
                }}>View&nbsp;More</span>
            </div>
        </div>
    </div>
)
