import React from 'react'
import PropTypes from 'prop-types'

const FootIcon = ({ className, isActive = '#98A2B3' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" className={className} viewBox="0 0 22 22" fill="none">
      <g clipPath="url(#clip0_10150_1229)">
        <path d="M21.1945 17.9893C20.6754 17.5858 20.0055 17.4702 19.3425 17.6552C17.7679 16.5891 16.4281 16.9327 15.1753 16.2022C12.3952 14.0844 9.31636 13.4743 9.1738 11.8875C9.04633 7.12205 9.18529 3.60429 9.61087 0.818147C9.67434 0.403119 9.35273 0.0283203 8.93255 0.0283203H0.686332C0.266157 0.0283632 -0.0557609 0.403248 0.00809948 0.818833C1.3149 9.31959 1.25769 8.94883 1.2612 8.96808C2.28318 14.4519 0.232018 15.3871 0.139466 18.2603C0.0838398 19.4721 0.435093 20.3953 1.18349 21.0044C2.34494 21.9498 4.36883 22.0348 7.37044 21.2644C8.24484 21.0399 9.16608 21.0502 10.0347 21.2942C12.0431 21.8582 14.374 22.2558 16.3881 21.7152C16.9472 21.565 17.5116 21.5532 18.0204 21.6808C18.6304 21.8339 19.3432 21.8571 19.896 21.8602C19.9616 21.8604 20.0269 21.8515 20.09 21.8333C22.3246 21.1889 22.4683 18.9791 21.1945 17.9893ZM20.6142 19.7619C20.5534 20.0779 20.2654 20.3333 19.7994 20.487C19.3418 20.482 18.7884 20.4586 18.3543 20.3497C17.6195 20.1654 16.8166 20.1792 16.0322 20.3897C14.6071 20.7724 12.7667 20.636 10.4058 19.9729C9.32875 19.6704 8.16104 19.6445 7.02926 19.935C3.89642 20.7391 2.59224 20.3815 2.04983 19.94C1.65114 19.6156 1.47479 19.0854 1.51064 18.3194C1.63098 15.7919 3.68211 14.4828 2.61239 8.72744L1.48611 1.40078H8.14345C7.79194 4.13876 7.68356 7.52005 7.80245 11.9437C7.84645 13.7043 9.74335 14.6445 11.2968 15.4578C13.897 16.8192 14.1524 17.19 14.4388 17.3612C16.068 18.3357 17.4242 17.8667 18.8071 18.9635C19.0114 19.1254 19.2897 19.1577 19.5255 19.0468C19.8488 18.8947 20.1347 18.9038 20.3524 19.0729C20.5575 19.2324 20.6627 19.5093 20.6142 19.7619Z" fill={isActive}/>
        <path d="M6.14423 15.189C5.75554 15.6027 5.01353 15.5923 4.63603 15.1895C4.37677 14.9131 3.94257 14.8991 3.66607 15.1582C3.38961 15.4175 3.37563 15.8517 3.63481 16.1282C4.55613 17.1109 6.21607 17.117 7.14451 16.1287C7.40402 15.8525 7.39043 15.4182 7.11423 15.1588C6.83803 14.8993 6.4037 14.9128 6.14423 15.189Z" fill={isActive}/>
      </g>
      <defs>
        <clipPath id="clip0_10150_1229">
          <rect width="22" height="22" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

FootIcon.propTypes = {
  isActive: PropTypes.string,
  className: PropTypes.string
}
export default FootIcon
