import { Types } from './Types'

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function allFeedbacks (payload) {
  return {
    type: Types.ALL_FEEDBACKS,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function submitFeedback (payload) {
  return {
    type: Types.SUBMIT_FEEDBACK,
    payload
  }
  // UPDATE_SELF_ASSESSED
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function setCurrentQuestion (payload) {
  return {
    type: Types.SET_CURRENT_QUESTION,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function reportQuestion (payload) {
  return {
    type: Types.REPORT_QUESTION,
    payload
  }
}
