import api from '../../../utils/api'
import { addModule, allModules, deleteModule, updateModule } from './ActionCreator'

/**
 * Store a newly created resource in storage.
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAddModule = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/module', data).then((res) => {
      dispatch(addModule(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAllModules = (pageNumber = 1) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/module?page=${pageNumber}&pageSize=15`).then((res) => {
      dispatch(allModules(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Update the specified resource in storage.
 * @param postId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleUpdateModule = (postId, data) => (dispatch) => {
  const updatePromise = new Promise((resolve, reject) => {
    api().patch(`/module/${postId}`, data, {
    }).then((res) => {
      dispatch(updateModule(res.data))
      handleGetAllModules()
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
  const getModulePromise = new Promise((resolve, reject) => {
    api().get(`/module?page=${1}&pageSize=15`).then((res) => {
      dispatch(allModules(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
  return updatePromise.then((result1) => {
    return getModulePromise
  }).then((result2) => {})
}

/**
 * Remove the specified resource from storage.
 * @returns {function(*): Promise<unknown>}
 * @param postId
 */
export const handleDeleteModule = (postId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().delete(`/module/${postId}`).then((res) => {
      dispatch(deleteModule(postId))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
