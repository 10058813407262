import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Radio, Select } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import TlaModalFormWrapper from '../../../components/tla-modal-form-wrapper'
import CloseModal from '../../../components/close-modal'
import { handleAddQuestion, handleUpdateQuestion } from '../../../actions/admin/questions/Action'
import TlaSelect from '../../../components/TlaSelect'
import { handleGetAllSkillsNoPagination } from '../../../actions/admin/skills/Action'
import { FiPlus, FiTrash } from 'react-icons/fi'
import { contactTypes, formatBodyOptions } from '../../../utils'

function QuestionsForm ({ addQuestion, updateQuestion, skills, getSkills }) {
  const { state } = useLocation()
  const [questionType, setQuestionType] = useState(state?.data?.type ?? 'RATING')
  const resourceId = state?.data?.id
  useEffect(() => {
    getSkills(1, 500)
  }, [])
  const bodys = formatBodyOptions(state?.data?.questionBodySelf ?? state?.data?.questionBodyOthers)

  console.log(bodys.options, 'bodys')
  const formValues = {
    ...state?.data,
    type: questionType,
    questionBodyOthers: bodys?.options?.length > 0
      ? bodys.options
      : [
          {
            text: '',
            explanation: ''
          }
        ]
  }
  const formatBeforeSubmit = (values) => {
    if (questionType !== 'RATING') {
      let i = 0
      const formatBody = values.questionBodyOthers.map((slide) => {
        i = i + 2
        return { ...slide, score: i }
      })
      values.scenarioOptionsTotal = formatBody.length
      values.questionBodySelf = { ...formatBody }
    }
    values.questionBodyOthers = {}
    return values
  }
  return (
        <TlaModalFormWrapper beforeSubmit={formatBeforeSubmit}
                             initialValues={formValues}
                             formTitle={`${resourceId ? 'Update' : 'Create'} Question`}
                             resourceId={state?.data?.id ?? null}
                             onSubmit={state?.data?.id ? updateQuestion : addQuestion}>
            <Form.Item label="" name="type"
                       rules={[
                         {
                           required: true,
                           message: 'Required'
                         }
                       ]}>
                <div className={'flex flex-wrap gap-x-3'}>
                    <p>Response Type</p>
                    <Radio.Group value={questionType} onChange={(e) => setQuestionType(e.target.value)}>
                        <Radio value="RATING">Rating</Radio>
                        <Radio value="SCENARIO">Scenario</Radio>
                    </Radio.Group>
                </div>
            </Form.Item>
            <TlaSelect
                onFocus={skills.length <= 0
                  ? (getSkills(1, 500).then(() => {}))
                  : null}
                required name={'skillId'} label={'Select Skill *'} options={skills} optionKey={'title'}/>
            <Form.Item label="User Level & Type" name="userLevelType"
                    rules={[
                      {
                        required: true,
                        message: 'Required'
                      }
                    ]}>
              <Select
                defaultValue={state?.data?.userLevelType}
                style={{ width: '100%' }}
                mode='multiple'
                onChange={(value) => {
                  if (value.includes(''))value.splice(0, 1)
                }}
                options={contactTypes}
              />
            </Form.Item>
            <Form.Item
                name={'questionTitleOthers'}
                rules={[
                  {
                    required: true,
                    message: 'Required'
                  }
                ]}
                label={'Add Question Title (Others)*'}>
                <Input.TextArea size={'large'} rows={2} placeholder={'Enter a description...'}/>
            </Form.Item>
            <Form.Item
                name={'questionTitleSelf'}
                rules={[
                  {
                    required: true,
                    message: 'Required'
                  }
                ]}
                label={'Add Question Title (Self)*'}>
                <Input.TextArea size={'large'} rows={2} placeholder={'Enter a description...'}/>
            </Form.Item>
            {
                questionType === 'RATING' &&
                <>
                    <Form.Item
                        name={'lowestScoreHelp'}
                        label={'Lowest Score help text'}>
                        <Input size={'large'} placeholder={'Eg Strongly Disagree'}/>
                    </Form.Item>
                    <Form.Item
                        name={'highestScoreHelp'}
                        label={'Highest Score help text'}>
                        <Input size={'large'} placeholder={'Eg Strongly Agree'}/>
                    </Form.Item>
                </>
            }
            {
                questionType !== 'RATING' &&
                <Form.List name="questionBodyOthers">
                    {(fields, { add, remove }) => {
                      let total = 0
                      return (
                            <>
                                <div className={'flex justify-between items-center mb-2'}>
                                    <h3 className={'text-sm font-medium'}>Options *</h3>
                                    <Button type="text"
                                            className={'btn btn-light text-sm text-gray-500 hover:!bg-transparent'}
                                            onClick={() => {
                                              add()
                                              total = total + 1
                                            }} icon={<FiPlus size={20}/>}>
                                        Add
                                    </Button>
                                </div>
                                {fields.map(({ key, name, ...restField }) => (
                                    <div key={key} className={'flex gap-2'}>
                                        <div className={'w-full'}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'text']}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Required'
                                                  }
                                                ]}>
                                                <Input size={'large'} placeholder="Option Title"/>
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'explanation']}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Required'
                                                  }
                                                ]}>
                                                <Input size={'large'} placeholder="Explanation"/>
                                            </Form.Item>
                                        </div>
                                        <Button icon={<FiTrash/>} className={'btn items-center flex justify-center'}
                                                danger size={'large'}
                                                onClick={() => remove(name)}/>
                                    </div>
                                ))}
                            </>
                      )
                    }}
                </Form.List>
            }
            <div className={'flex gap-2'}>
                <div className={'w-full'}>
                    <CloseModal/>
                </div>
                <Form.Item className={'w-full'}>
                    <Button className={'btn-primary w-full'} size={'large'} htmlType="submit">
                        {`${resourceId ? 'Update' : 'Create'}`} Question
                    </Button>
                </Form.Item>
            </div>
        </TlaModalFormWrapper>
  )
}

QuestionsForm.propTypes = {
  addQuestion: PropTypes.func.isRequired,
  skills: PropTypes.array.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  getSkills: PropTypes.func.isRequired
}

/**
 * @param state
 * @returns {{skills: ([]|*)}}
 */
const mapStateToProps = (state) => ({
  skills: state.adminSkillsReducer.skills.data
})

/**
 *
 * @param dispatch
 * @returns {{addQuestion: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  addQuestion: (data) => dispatch(handleAddQuestion(data)),
  updateQuestion: (questionId, data) => dispatch(handleUpdateQuestion(questionId, data)),
  getSkills: () => dispatch(handleGetAllSkillsNoPagination())

})

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsForm)
