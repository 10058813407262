import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { handleGetUserCourses } from '../../actions/user/common/Action'
import DisplayCourses from './components/display-courses'

function Courses ({ getCourses, courses }) {
  const options = [
    {
      label: 'All',
      value: 'courses'
    },
    {
      label: 'In-Progress',
      value: 'IN_PROGRESS'
    },
    {
      label: 'Completed',
      value: 'COMPLETED'
    }
  ]

  return (
        <React.Fragment>
            <div className="bg-white-base rounded-md p-5 md:px-12 py-6">
                <DisplayCourses courses={ courses } getCourses={ getCourses } options={ options }/>
            </div>
        </React.Fragment>
  )
}

Courses.propTypes = {
  courses: PropTypes.object.isRequired,
  getCourses: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  courses: state.commonReducer.courses
})

const mapDispatchToProps = (dispatch) => ({
  getCourses: (page, reset, type) => dispatch(handleGetUserCourses(page, reset, type))
})

export default connect(mapStateToProps, mapDispatchToProps)(Courses)
