import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function addLessonQuestion (payload) {
  return {
    type: Types.NEW_LESSON_QUESTION,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function allLessonQuestions (payload) {
  return {
    type: Types.ALL_LESSON_QUESTIONS,
    payload
  }
}

/**
 *
 * @param post
 * @returns {{post, type: string}}
 */
export function deleteLessonQuestion (courseQuestion) {
  return {
    type: Types.DELETE_LESSON_QUESTION,
    courseQuestion
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function updateLessonQuestion (payload) {
  return {
    type: Types.UPDATE_LESSON_QUESTION,
    payload
  }
}
