import React from 'react'
import { Form, Input } from 'antd'

function SignUpContent () {
  return (
        <>
            <Form.Item hidden name="invitationCode" label="invitationCode*">
                <Input size={ 'large' } placeholder={ 'Enter your name' }/>
            </Form.Item>
            {/* <Form.Item name="name" label="Name*"
                       rules={ [
                         {
                           required: true,
                           message: 'Name is Required'
                         }
                       ] }>
                <Input size={ 'large' } placeholder={ 'Enter your name' }/>
            </Form.Item> */}
            <Form.Item name="email" label="Email*"
                       rules={ [
                         {
                           required: true,
                           message: 'Email is Required'
                         }
                       ] }>
                <Input size={ 'large' } placeholder={ 'Enter your email' }/>
            </Form.Item>
           {/* <Form.Item
                rules={ [
                  {
                    type: 'string',
                    min: 10,
                    message: 'Provide a valid phone number'
                  },
                  {
                    required: true,
                    message: 'Mobile Number required'
                  }
                ] }
                name={ 'mobileNumber' }
                label={ 'Mobile Number *' }>

                <PhoneInput flags
                            placeholder=""
                            value={ value }
                            onChange={ setValue }/>
            </Form.Item> */}
            <Form.Item name="password"
                       label={ 'Password*' }
                       rules={ [
                         { required: true, message: 'Password is required!' },
                         { min: 8, message: '' }
                       ] }>
                <Input.Password
                    size={ 'large' }
                    type={ 'password' }
                    placeholder="••••••••"/>
            </Form.Item>
            {/* <div className={'grid grid-cols-1 md:grid-cols-2 gap-2'}>
                <Form.Item
                    dependencies={ ['password'] }
                    name="passwordConfirm"
                    label={ 'Confirm password*' }
                    rules={ [
                      {
                        required: true,
                        message: 'Password is required!'
                      }, ({ getFieldValue }) => ({
                        validator (_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve()
                          }
                          return Promise.reject(new Error('Password mismatch'))
                        }
                      }),
                      {
                        min: 8,
                        message: 'Password should be at least 8 characters'
                      }
                    ] }>
                    <Input.Password
                        size={ 'large' }
                        type="password"
                        placeholder="••••••••"
                    />
                </Form.Item>
            </div> */}
            {/* <Form.Item
                name={ 'currentStatus' }
                rules={ [
                  {
                    required: false,
                    message: 'Current Status is Required'
                  }
                ] }
                label={ 'Current Status *' }>
                <Select
                    placeholder="Select Current Status"
                    className='form-select'
                    options={ contactTypes }
                />
            </Form.Item> */}
        </>
  )
}

export default SignUpContent
