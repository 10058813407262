import { Types } from '../../actions/admin/modules/Types'

const initialState = {
  modules: {
    data: [],
    meta: {}
  },
  module: {}
}

export default function adminModulesReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_MODULE:
      return {
        ...state,
        modules: {
          ...state.modules,
          data: [
            action.payload.data.module,
            ...state.modules.data
          ]
        }
      }
    case Types.ALL_MODULES: {
      return {
        ...state,
        modules: {
          ...state.modules,
          data: action.payload.data.modules,
          meta: {
            total: action.payload.data.count,
            current_page: Number(action.payload.data.page),
            page: action.payload.data.page,
            pageSize: action.payload.data.pageSize
          }
        }
      }
    }
    case Types.UPDATE_MODULE:
      return {
        ...state,
        modules: {
          ...state.modules,
          data: state.modules.data.map((module) => {
            return module.id === action.payload.data.module.id ? action.payload.data.module : module
          })
        }
        // module: action.payload.data.upda
      }
    case Types.DELETE_MODULE:
      return {
        ...state,
        modules: {
          ...state.modules,
          data: state.modules.data.filter((modules) => modules.id !== action.module)
        }
      }

    default:
      return state
  }
}
