import React, { useState } from 'react'
import { Tooltip } from 'antd'
import { FiCopy } from 'react-icons/fi'
import PropTypes from 'prop-types'
import {
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from 'react-share'

const ShareGameLink = ({ uuid }) => {
  const [text, setText] = useState('Copy')
  return (
      <div className={ 'flex items-center gap-x-3' }>
        <Tooltip title={ text }
                 onOpenChange={ () => setText('Copy') }>
          <p onClick={ () => {
            navigator.clipboard
              .writeText(`What do you think about my choices on this topic? ${process.env.REACT_APP_FE_URL}/public/played-game/${uuid}`)
              .then(() => setText('Copied'))
          } } className={ 'flex items-center w-fit gap-x-1 cursor-pointer' }>
            Copy link and share <FiCopy/>
          </p>
        </Tooltip>
        <WhatsappShareButton url={ `${process.env.REACT_APP_FE_URL}/public/played-game/${uuid}` }>
          <WhatsappIcon size={ 30 } round={ true }/>
        </WhatsappShareButton>
        <TwitterShareButton url={ `${process.env.REACT_APP_FE_URL}/public/played-game/${uuid}` }>
          <TwitterIcon size={ 30 } round={ true }/>
        </TwitterShareButton>
        <LinkedinShareButton title={ 'Share your results' } source={ 'Noustro' } summary={ 'Copy and share' }
                             url={ `${process.env.REACT_APP_FE_URL}/public/played-game/${uuid}` }>
          <LinkedinIcon size={ 30 } round={ true }/>
        </LinkedinShareButton>
      </div>
  )
}

ShareGameLink.propTypes = {
  uuid: PropTypes.string.isRequired
}

export default ShareGameLink
