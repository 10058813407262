import React, { useState } from 'react'
import { Collapse, theme } from 'antd'
import { MdCheck, MdOutlineClose } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { Title } from './common'

function Donts () {
  const [active, setActive] = useState(1)
  const personalities = useSelector((state) => state.commonReducer?.personalities?.[0])

  const { token } = theme.useToken()

  const getItems = (panelStyle) => [
    {
      key: '2',
      label: <Title title={'WITH FAMILY & FRIENDS'} color={'text-cyan-700'} value={2}
                    active={active} setActive={setActive}
                          headerTitle={personalities?.homePersonality?.personalityTitle}
                          headerText={personalities?.homePersonality?.personalityDescription}/>,
      children: <div>
                <h3 className={'text-2xl text-gray-900 font-semibold -mt-7 mb-3'}>
                    {personalities?.homePersonality?.personalityTitle}
                </h3>
                <div className={'text-violet-700 text-base'}
                     dangerouslySetInnerHTML={{ __html: personalities?.homePersonality?.personalityDescription }}/>

                <div className={'bg-[#FBFAFF] p-4 mt-6'}>
                    <div>
                        <div className={'flex items-center gap-x-2'}>
                            <div className={'bg-success-500 rounded-full text-sm p-1'}>
                                <MdCheck className={'text-white-base'}/>
                            </div>
                            <h3 className={'text-gray-800 font-semibold text-2xl'}>DO’s</h3>
                        </div>
                        <div className={'text-gray-800 text-base mt-6'}>
                            <p className={'mb-2'}>
                                <p className={'mb-2'}
                                   dangerouslySetInnerHTML={{ __html: personalities?.homePersonality?.dos }}></p>
                            </p>
                        </div>
                    </div>
                    <div className={'text-gray-800 text-base mt-6'}>
                    <div className={'flex items-center gap-x-2'}>
                            <div className={'bg-error-100 rounded-full text-sm p-1'}>
                                <MdOutlineClose className={'text-error-600'}/>
                            </div>
                            <h3 className={'text-gray-800 font-semibold text-2xl'}>DONT’s</h3>
                        </div>
                        <div className={'mt-6'}>
                            <p className={'mb-2'}
                               dangerouslySetInnerHTML={{ __html: personalities?.homePersonality?.donts }}></p>
                        </div>
                    </div>
                </div>
          <div className={`${active !== 2 ? 'hidden' : ''} text-end mt-5 text-gray-500`}>
                      <span className={'cursor-pointer underline text-sm'}
                            onClick={() => {
                              setActive(1)
                            }}>
                          Show&nbsp;Less
                      </span>
                </div>
            </div>,
      style: {
        ...panelStyle, background: '#ECFDFF'
      },
      showArrow: false
    }
  ]

  const panelStyle = {
    marginBottom: 40,
    borderRadius: token.borderRadiusLG,
    border: 'none'
  }

  return (

        <Collapse
            collapsible={'icon'}
            ghost accordion activeKey={active}
            bordered={false}
            defaultActiveKey={[active]}
            items={getItems(panelStyle)}
        />

  )
}

export default Donts
