import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { handleCourseEnrollment } from '../../../actions/user/common/Action'
import Accordion from '../../../components/public-components/accordian'
import ModuleContents from './module-content'

function DisplaySingleCourse ({ course, enroll }) {
  const formatAccordianItems = (modules) => {
    return modules?.map(module => {
      return {
        id: module.moduleId,
        title: module.title,
        subTitle: module.moduleContents.length + ' lessons',
        description: (
                    <ModuleContents course={ course } module={module} moduleContents={ module.moduleContents ?? [] }/>
        )
      }
    })
  }

  return (
        <div className="page-body">
            <div className="p-3 flex gap-6 my-4 flex-wrap lg:flex-nowrap">
                <div className="w-full lg:w-1/2 order-2 lg:order-1">
                    <h3 className="text-[#175CD3] font-semibold">{ course.categoryName }</h3>
                    <h1 className="text-4xl font-semibold leading-relaxed tracking-tighter">{ course.title }</h1>
                    <div className='my-4' dangerouslySetInnerHTML={ { __html: course.description } }></div>
                    <div className="flex gap-2">
                        {
                            !course.enrolled &&
                            <button onClick={ enroll }
                                    className="bg-blue-600 text-white-base font-medium rounded-md py-3 px-5">
                                Enroll For Free
                            </button>
                        }
                    </div>

                    <div className='my-10'>
                        <Accordion titleClass='text-lg font-medium text-[#101828]'
                                   items={ formatAccordianItems(course.modules) }/>
                    </div>

                </div>
                <div className="w-full lg:w-1/2 order-1 lg:order-2">
                    <img
                        src={ course.featureImageUrl ? course.featureImageUrl : 'https://www.shutterstock.com/image-vector/elearning-banner-online-education-home-260nw-1694176021.jpg' }
                        className='w-full' alt=""/>
                </div>
            </div>
        </div>
  )
}

DisplaySingleCourse.propTypes = {
  course: PropTypes.object.isRequired,
  enroll: PropTypes.func
}

const mapStateToProps = (state) => ({
  course: state.commonReducer.course ?? {}
})

const mapDispatchToProps = (dispatch) => ({
  courseEnrollment: (courseId) => dispatch(handleCourseEnrollment(courseId))
})

export default connect(mapStateToProps, mapDispatchToProps)(DisplaySingleCourse)
