import React, { useState } from 'react'
import TakeUserLevel from './take-user-level'
import TakeSelfAssessment from './take-self-assessment'
import Results from './results'
import TakeUserEmail from './take-user-email'
import TakePassword from '../quizzes/take-password'
import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
 
`
function SelfAssessment () {
  const [current, setCurrent] = useState(0)
  const [answers, setAnswers] = useState({})

  const items = [
        <TakeUserLevel
            answers={ answers }
            key={ 'take-user-level' }
            ifCompleted={ (values) => {
              setAnswers(values)
              setCurrent(1)
            } }/>,
        <TakeSelfAssessment ifCompleted={ (values) => {
          const data = {
            questions: values,
            user: answers
          }
          setAnswers(data)
          setCurrent(2)
        } } key={ 'take-self-assessment' } answers={ answers }/>,
        <TakeUserEmail
            answers={ answers }
            key={ 'take-user-email' }
            ifCompleted={ (res) => {
              // setAnswers(res)
              setCurrent(res.data?.newUserStatus ? 3 : 4)
              // setCurrent(3)
            } }/>,
        /* <VerifyAccount
            ifCompleted={ () => setCurrent(4) }
            key={ 'verify-account' }/> */
        <TakePassword
            type={ 'self-assessment' }
            answers={ answers }
            key={ 'password' } ifCompleted={ () => {
              setCurrent(4)
            } }/>,
        <Results key={ 'results' }/>
  ]
  return (
        <div className={ 'flex items-center justify-center pt-10' }>
            <GlobalStyles/>
            { items[current] }
        </div>
  )
}

export default SelfAssessment
