import React from 'react'
import { FiLogOut } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { handleLogout } from '../../actions/authenticate/Actions'
import PropTypes from 'prop-types'

const Logout = ({ setLoading }) => {
  const dispatch = useDispatch()
  const initiateLogout = () => {
    setLoading(true)
    dispatch(handleLogout()).then(() => {
      window.location.replace('/sign-in')
      setLoading(false)
      return false
    })
  }
  return (
        <div className='flex items-center gap-x-2 hover:bg-gray-100 h-10 cursor-pointer rounded-lg px-2.5 mx-3'
             onClick={() => initiateLogout()}>
            <FiLogOut className={'hidden md:block h-4 w-4'}/> Logout
        </div>
  )
}

Logout.propTypes = {
  setLoading: PropTypes.func.isRequired
}
export default Logout
