import React, { useEffect, useState } from 'react'
import AnonymousFeedback from '../quotes/anonymous-feedback'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { handleGetUserFeed, handleGetUserQuizzes } from '../../actions/user/common/Action'
import { Divider, Skeleton, Spin } from 'antd'
import ContactsQuote from '../quotes/contacts-quotes'
import ScenarioQuestion from '../feedback/scenario-question'
import InfiniteScroll from 'react-infinite-scroll-component'
import SidebarForm from '../dashboard/layout/sidebar-form'
import ContentPostCard from '../../components/cards/content-post-card'
import SingleNousQuiz from '../../components/public-components/nous-quiz/single-nous-quiz'

function Home ({ getFeed, userFeed, totalFeed, quizzes, getQuizzess }) {
  const userId = useSelector(state => state.loginReducer.authUserInfo?.id)
  const page = useSelector(state => state.commonReducer.page)
  const [quizIndex, setQuizIndex] = useState(0)
  const [loading, setLoading] = useState(false)
  const loadMoreData = (reset = null) => {
    setLoading(true)
    getFeed(page, userId, reset).then(() => {
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }
  useEffect(() => {
    loadMoreData('refresh')
    getQuizzess(1, 'refresh').then(res => setQuizIndex(0))
  }, [])
  return (
        <React.Fragment>
            <div className={'md:block xl:hidden'}>
                <SidebarForm col={'row'}/>
            </div>
            {
              quizzes.length ? <SingleNousQuiz quizNo={quizIndex} showFooter={true} totalQuizzes={quizzes.length} changeQuiz={setQuizIndex} quiz={quizzes[quizIndex]} /> : ''
            }
            <InfiniteScroll
                dataLength={userFeed.length}
                next={loadMoreData}
                hasMore={userFeed.length < totalFeed}
                loader={
                    <Skeleton avatar paragraph={{ rows: 1 }} active/>
                }
                endMessage={<Divider plain>Nothing more 🤐</Divider>}
                scrollableTarget="scrollableDiv">
                <Spin spinning={loading}>
                    <div className={'flex flex-col gap-6'}>
                        {
                            userFeed.map((feed, index) => index % 3 !== 2
                              ? (
                                  <React.Fragment key={feed.id + index + Math.random()}>
                                      {feed?.educationalPost &&
                                          <ContactsQuote
                                              consumed={feed.consumed}
                                              feedId={feed.id}
                                              posts={feed.educationalPost}/>}
                                      {feed?.question &&
                                          <React.Fragment>
                                              {
                                                  feed.question.type === 'SCENARIO'
                                                    ? <ScenarioQuestion data={feed}/>
                                                    : <AnonymousFeedback data={feed}/>
                                              }
                                          </React.Fragment>
                                      }
                                  </React.Fragment>
                                )
                              : (
                                  <div key={feed.id + index + Math.random()}>
                                    <ContentPostCard post={feed} />
                                  </div>
                                )
                            )
                        }
                    </div>
                </Spin>
            </InfiniteScroll>
        </React.Fragment>
  )
}

Home.propTypes = {
  userFeed: PropTypes.array.isRequired,
  quizzes: PropTypes.array,
  totalFeed: PropTypes.number.isRequired,
  getQuizzess: PropTypes.func.isRequired,
  getFeed: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  userFeed: state.commonReducer.userFeed,
  quizzes: state.commonReducer.quizzes.data,
  totalFeed: state.commonReducer.totalFeed
})

const mapDispatchToProps = (dispatch) => ({
  getQuizzess: (page, refresh) => dispatch(handleGetUserQuizzes(page, refresh)),
  getFeed: (page, userId, reset) => dispatch(handleGetUserFeed(page, userId, reset))
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
