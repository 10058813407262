import React from 'react'
import { Outlet, Route, Routes, useLocation } from 'react-router-dom'
import SignIn from '../../pages/auth/sign-in'
import Register from '../../pages/auth/register'
import ForgotPassword from '../../pages/auth/forgot-password'
import ResetPassword from '../../pages/auth/reset-password'
import Layout from '../../pages/admin/layout'
import { Dashboard } from '../../pages/admin/dashboard'
import Skills from '../../pages/admin/skills'
import PageWrapper from '../../pages/admin/layout/page-wrapper'
import { AdminModalRoute } from './admin-modal-route'
import Questions from '../../pages/admin/questions'
import SkillRelationships from '../../pages/admin/skill-relationships'
import Posts from '../../pages/admin/posts'
import Users from '../../pages/admin/users'
import SinglePost from '../../pages/admin/posts/single-post'
import SingleQuestion from '../../pages/admin/questions/single-question'
import ContentPosts from '../../pages/admin/content-posts'
import Modules from '../../pages/admin/modules'
import LessonQuestions from '../../pages/admin/lesson-questions'
import Lessons from '../../pages/admin/lessons'
import ModuleLessons from '../../pages/admin/module-lessons'
import Courses from '../../pages/admin/courses'
import CourseModules from '../../pages/admin/course-modules'
import CourseCategories from '../../pages/admin/course-categories'
import Personalities from '../../pages/admin/personalities'
import orgRoutes from './org-routes'
import Organization from '../../pages/organization'
import QuizQuestions from '../../pages/admin/quiz-questions'
import QuizForms from '../../pages/admin/quiz-forms'
import QuizFormQuestions from '../../pages/admin/quiz-form-questions'

export const AdminProtectedRoutes = () => {
  const location = useLocation()
  const background = location.state && location.state.background

  return (
        <>
            <Routes location={background || location}>
                <Route exact element={<Layout/>} path="/">
                    <Route index element={<Dashboard/>}/>
                    <Route exact element={<Dashboard/>} path="dashboard"/>
                    <Route exact element={<PageWrapper/>}>
                        <Route exact element={<Organization/>} path={'organizations'}/>
                        <Route exact element={<Skills/>} path="skills"/>
                        <Route exact element={<SkillRelationships/>} path="skill-relationships"/>
                        <Route exact element={<Questions/>} path="questions"/>
                        <Route exact element={<Posts/>} path="posts"/>
                        <Route exact element={<ContentPosts/>} path="content-posts"/>
                        <Route exact element={<Modules/>} path="modules"/>
                        <Route exact element={<Lessons/>} path="lessons"/>
                        <Route exact element={<ModuleLessons/>} path="module-lessons"/>
                        <Route exact element={<LessonQuestions/>} path="lesson-questions"/>
                        <Route exact element={<Courses/>} path="courses"/>
                        <Route exact element={<CourseModules/>} path="course-modules"/>
                        <Route exact element={<CourseCategories/>} path="course-categories"/>
                        <Route exact element={<Personalities/>} path="personalities"/>
                        <Route exact element={<QuizQuestions/>} path="quiz-questions"/>
                        <Route exact element={<QuizForms/>} path="quiz-forms"/>
                        <Route exact element={<QuizFormQuestions/>} path="quiz-form-questions"/>
                        <Route exact element={<Users/>} path="users"/>
                    </Route>
                    <Route exact element={<SinglePost/>} path="posts/:title"/>
                    <Route exact element={<SingleQuestion/>} path="questions/:title"/>
                    <Route exact element={<>Settings</>} path="settings"/>
                </Route>
                <Route exact element={<Layout/>} path="/assess"/>
                {orgRoutes}
                <Route exact element={<SignIn/>} path="/sign-in"/>
                <Route element={<Register/>} path={'/sign-up'}/>
                <Route element={<ForgotPassword/>} path={'/forgot-password'}/>
                <Route element={<ResetPassword/>} path={'/reset-password'}/>
                <Route path={'*'} element={<SignIn/>}/>
            </Routes>

            {background && (<><AdminModalRoute/> <Outlet/></>)}
        </>
  )
}
