import React from 'react'
import { connect } from 'react-redux'
import { TlaModal } from './pop-ups/tla-modal'
import CloseModal from './close-modal'
import { useLocation } from 'react-router-dom'

function CvDifference () {
  const { state } = useLocation()
  return (
        <TlaModal title={'CV Difference'}>
            <div className={'mt-5 max-h-[450px] overflow-auto'}>
                {state?.data.difference}
            </div>
            <div className={'flex justify-end mt-5'}>
                <CloseModal title={'Close'}/>
            </div>
        </TlaModal>
  )
}

export default connect()(CvDifference)
