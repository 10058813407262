import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Spin } from 'antd'
import api from '../../utils/api'
import { Link } from 'react-router-dom'
import TlaCircleProgress from '../dashboard/skills-overview/competencies/tla-circle-progress'
import { useSelector } from 'react-redux'
import Header from '../dashboard/layout/header'
import { HeaderMenus } from '../../utils/menu-items'
import PublicHeader from '../../components/public-components/public-header'
import DefaultUserImage from '../../assets/images/profile/user.png'

function PublicProfile () {
  const [loading, setLoading] = useState(true)
  const user = useSelector(store => store.loginReducer.authUser.userInformation)
  const [userData, setUserData] = useState({})
  const [userError, setUserError] = useState('')
  const { username } = useParams()
  useEffect(() => {
    api().get(`/public-profile/${username}`).then((res) => {
      setLoading(false)
      const data = res.data.data
      setUserData(data)
    }).catch((err) => {
      setLoading(false)
      setUserError('This User Name is Not Registered In Noustro...')
      console.log(err)
    })
  }, [username])
  return (
    <>
      {/* <Affix offsetTop={0}> */}
        {
          user !== undefined
            ? <Header menuItems={HeaderMenus}/>
            : <PublicHeader />
        }

      {/* </Affix> */}
      <div className="bg-blue-500 text-white p-3 pt">
        <div className="max-w-[1188px] px-3 mx-auto flex flex-col md:flex-row justify-between items-center text-white-700">
          <div>
            <p className="mb-0 font-semibold text-white-base">Want your own profile like this?</p>
            <p className='text-[#D1E9FF]'>Register for free today with Noustro</p>
          </div>
          <div className="mt-3 md:mt-0">
            <Link to={'/sign-up'} className="bg-blue-50 text-blue-700 font-medium px-3 py-2 rounded-lg hover:bg-blue-600 hover:text-white-base w-full md:w-auto">
              Register For Free
            </Link>
          </div>
        </div>
      </div>
      <Spin spinning={loading}>
        {
          userData.user
            ? <div className="py-5 my-3">
              <div className="max-w-[1188px] px-3 mx-auto">
                <div className="flex items-center gap-3">
                  <img src={userData.user?.photo !== 'default.png' ? userData.user?.photo : DefaultUserImage} className="rounded-full w-32 h-32" alt="profile" />
                  <div>
                    <h1 className="text-5xl font-medium mb-2">{userData.user?.name}</h1>
                    <p className='text-[18px]'>{userData.user?.jobTitle}</p>
                  </div>
                </div>
                <div className="flex justify-between mt-5">
                  <h4 className='font-medium text-xl'>Assessed Skills</h4>
                  <div className="flex flex-wrap justify-end gap-x-10 items-center">
                    <p className="mb-0 text-sm">
                      <span className="inline-block mx-2 rounded-full bg-blue-500 w-3 h-3"></span>
                      Absolute
                    </p>
                    <p className="mb-0 text-sm">
                      <span className="inline-block mx-2 rounded-full bg-blue-300 w-3 h-3"></span>
                      Normalized
                    </p>
                    <p className="w-full text-right">
                      <a href="#" className="text-gray-800 hover:text-gray-600 text-xs underline">How scores are calculated</a>
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-10 lg:gap-x-14 gap-x-4 mt-5 lg:px-6">
                  {
                    userData.skillScores.length && userData.skillScores.map((skill, i) => (
                    <div className="col-span-1 h-full w-3/4 md:w-full mx-auto shadow-sm" key={i}>
                      <div className="card border-0 rounded-lg px-5 pt-6 pb-4 bg-[#F5FAFF] text-center my-3 h-full">
                        <div className="inline-block mx-auto text-left mb-4">
                          <div className="relative w-24 h-24">
                            <TlaCircleProgress
                            absoluteScore={skill.absoluteScore}
                            normalizedScore={skill.normalizedScore}
                            />
                          </div>
                        </div>
                        <h4 className="font-medium text-xl">{skill.skill?.title}</h4>
                        <p>{skill.skill?.description}</p>
                      </div>

                    </div>
                    ))
                  }
                </div>
              </div>
            </div>
            : <h2 className="text-4xl text-center font-semibold text-red-600 py-10 md:w-1/2 w-3/4 mx-auto">
              {userError}
            </h2>
          }
        </Spin>
    </>
  )
}

export default PublicProfile
