import api from '../../../utils/api'
import { addLesson, allLessons, deleteLesson, updateLesson } from './ActionCreator'
import { isObject } from '../../../utils/index'

/**
 * Store a newly created resource in storage.
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAddLesson = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/lessons', data).then((res) => {
      dispatch(addLesson(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAllLessons = (pageNumber = 1, pageSize = 15, filters = null) => (dispatch) => {
  const skill = isObject(filters) ? filters.skill : null
  return new Promise((resolve, reject) => {
    api().get(`/lessons?page=${pageNumber}&pageSize=${pageSize}${skill ? '&skillId=' + skill : ''}`).then((res) => {
      dispatch(allLessons(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Update the specified resource in storage.
 * @param postId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleUpdateLesson = (postId, data) => (dispatch) => {
  const updatePromise = new Promise((resolve, reject) => {
    api().patch(`/lessons/${postId}`, data, {
    }).then((res) => {
      dispatch(updateLesson(res.data))
      handleGetAllLessons()
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
  const getLessonPromise = new Promise((resolve, reject) => {
    api().get(`/lessons?page=${1}&pageSize=15`).then((res) => {
      dispatch(allLessons(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
  return updatePromise.then((result1) => {
    return getLessonPromise
  }).then((result2) => {})
}

/**
 * Remove the specified resource from storage.
 * @returns {function(*): Promise<unknown>}
 * @param postId
 */
export const handleDeleteLesson = (postId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().delete(`/lessons/${postId}`).then((res) => {
      dispatch(deleteLesson(postId))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
