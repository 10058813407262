import React, { useEffect, useState } from 'react'
import TlaTableWrapper from '../../../components/table/tla-table-wrapper'
import { Spin, Switch, Table } from 'antd'
import PageTitle from '../commons/page-title'
import { useDispatch, useSelector } from 'react-redux'
import { handleGetAllOrgUsers, handleUpdateOrgUser } from '../../../actions/admin/org-users/Action'
import { TlaError, TlaSuccess } from '../../../utils/messages'
import { getErrors } from '../../../utils'

const OrgUsers = () => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  // eslint-disable-next-line no-unused-vars
  const { id } = useSelector(state => state.loginReducer.authUserInfo)
  const selectedOrg = useSelector(state => state.loginReducer.selectedOrg)
  const { data, meta } = useSelector(state => state.orgUsersReducer.orgUsers)
  useEffect(() => {
    dispatch(handleGetAllOrgUsers(selectedOrg.orgId)).then(() => {
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }, [])

  const onChange = (checked, name, orgUserId) => {
    const obj = { [name]: checked }
    dispatch(handleUpdateOrgUser(orgUserId, obj)).then(() => {
      TlaSuccess('Status update')
    }).catch((error) => {
      TlaError(error.response.data.message ?? getErrors(error.response.data?.errors))
    })
  }

  // eslint-disable-next-line react/prop-types
  const SetStatus = ({ userId, status, name, orgUserId }) => (
      <Switch disabled={id === userId} onChange={(checked) => {
        onChange(checked, name, orgUserId)
      }} defaultChecked={status}/>
  )
  return (
        <div className={'pb-4 border rounded-lg p-3'}>
            <PageTitle title={'Employees'} addButton={false}/>
            <Spin spinning={loading}>
                <TlaTableWrapper numberColumn={false} meta={meta} data={data} showHeader={false} hasSelection
                                 callbackFunction={() => {
                                 }}>
                    <Table.Column title={'Name'} dataIndex={['user', 'userInformation', 'name']}/>
                    <Table.Column title={'Given'} dataIndex={['user', 'userInformation', 'assessmentsGivenTotal']}/>
                    <Table.Column title={'Received'} dataIndex={['user', 'userInformation', 'assessmentsReceivedTotal']}/>
                    <Table.Column width={400} title={'Top 5 Soft Skills'} render={(record) => (
                        <div className={'flex flex-wrap gap-2'}>
                            {
                                record.user?.UserSkillScore?.map((score, index) => (
                                    <div className={'text-primary-700 font-medium text-sm rounded-full bg-primary-50 py-0.5 px-2'} key={index}>
                                        {score?.skill?.title}
                                    </div>
                                ))
                            }
                        </div>
                    )}/>
                    <Table.Column title={'Admin'} render={(record) => (
                        <SetStatus status={record.isAdmin} name={'isAdmin'} userId={record.user.id} orgUserId={record.id}/>
                    )}/>
                    <Table.Column title={'Paid'} render={(record) => (
                        <SetStatus status={record.isPaid} name={'isPaid'} userId={record.user.id} orgUserId={record.id}/>
                    )}/>
                    <Table.Column title={'Active'} render={(record) => (
                        <SetStatus status={record.isActive} name={'isActive'} userId={record.user.id} orgUserId={record.id}/>
                    )}/>
                </TlaTableWrapper>
            </Spin>
        </div>
  )
}

export default OrgUsers
