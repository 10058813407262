import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function addLesson (payload) {
  return {
    type: Types.NEW_LESSON,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function allLessons (payload) {
  return {
    type: Types.ALL_LESSONS,
    payload
  }
}

/**
 *
 * @param post
 * @returns {{post, type: string}}
 */
export function deleteLesson (lesson) {
  return {
    type: Types.DELETE_LESSON,
    lesson
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function updateLesson (payload) {
  return {
    type: Types.UPDATE_LESSON,
    payload
  }
}
