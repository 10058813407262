import { Types } from '../../actions/admin/course-categories/Types'

const initialState = {
  courseCategories: {
    data: [],
    meta: {}
  },
  courseCategory: {}
}

export default function adminCourseCategoriesReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_COURSE_CATEGORY:
      return {
        ...state,
        courseCategories: {
          ...state.courseCategories,
          data: [
            action.payload.data.category,
            ...state.courseCategories.data
          ]
        }
      }
    case Types.ALL_COURSE_CATEGORIES:{
      return {
        ...state,
        courseCategories: {
          ...state.courseCategories,
          data: action.payload.data.categories,
          meta: {
            total: action.payload.data.count,
            current_page: Number(action.payload.data.page),
            page: action.payload.data.page,
            pageSize: action.payload.data.pageSize
          }
        }
      }
    }
    case Types.UPDATE_COURSE_CATEGORY:
      return {
        ...state,
        courseCategories: {
          ...state.courseCategories,
          data: state.courseCategories.data.map((category) => {
            return category.id === action.payload.data.category.id ? action.payload.data.category : category
          })
        }
        // courseCategory: action.payload.data.upda
      }
    case Types.DELETE_COURSE_CATEGORY:{
      return {
        ...state,
        courseCategories: {
          ...state.courseCategories,
          data: state.courseCategories.data.filter((category) => category.id !== action.category)
        }
      }
    }
    default:
      return state
  }
}
