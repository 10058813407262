import { Types } from '../../actions/admin/module-lessons/Types'

const initialState = {
  moduleLessons: {
    data: [],
    meta: {}
  },
  moduleLesson: {}
}

export default function adminModuleLessonsReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_MODULE_LESSON:{
      return {
        ...state,
        moduleLessons: {
          ...state.moduleLessons,
          data: [
            action.payload.data.moduleContent,
            ...state.moduleLessons.data
          ]
        }
      }
    }
    case Types.ALL_MODULE_LESSONS:{
      return {
        ...state,
        moduleLessons: {
          ...state.moduleLessons,
          data: action.payload.data.moduleContents,
          meta: {
            total: action.payload.data.count,
            current_page: Number(action.payload.data.page),
            page: action.payload.data.page,
            pageSize: action.payload.data.pageSize
          }
        }
      }
    }
    case Types.UPDATE_MODULE_LESSON:
      return {
        ...state,
        moduleLessons: {
          ...state.moduleLessons,
          data: state.moduleLessons.data.map((moduleLesson) => {
            return moduleLesson.id === action.payload.data.moduleContent.id ? action.payload.data.moduleContent : moduleLesson
          })
        }
        // lesson: action.payload.data.upda
      }
    case Types.DELETE_MODULE_LESSON:
      return {
        ...state,
        moduleLessons: {
          ...state.moduleLessons,
          data: state.moduleLessons.data.filter((moduleLessons) => moduleLessons.id !== action.moduleLesson)
        }
      }

    default:
      return state
  }
}
