import React from 'react'
import PropTypes from 'prop-types'

export const HandshakeIcon = ({ className = '', fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 30 30" fill="none">
    <rect width="30" height="30" rx="15" fill={fill} />
    <g clipPath="url(#clip0_8475_34595)">
      <path d="M17.576 9.29055L19.183 7.68359L24.1705 12.6711L22.6056 14.236C22.7472 14.9442 22.5255 15.6762 22.0149 16.1869L20.4174 17.7844" stroke="white" strokeWidth="1.3" strokeMiterlimit="10" />
      <path d="M14.6399 9.20839C13.3126 7.88103 11.867 8.73358 11.867 8.73358L10.817 7.68359L5.82947 12.671L7.45065 14.2922C7.30902 15.0004 7.5307 15.7325 8.04136 16.2432L9.87465 18.0765L9.87711 18.075C10.2006 18.4007 13.8817 22.0835 13.9844 22.1862C14.4852 22.687 15.2972 22.687 15.798 22.1862C16.2988 21.6854 16.2988 20.8734 15.798 20.3725C15.798 20.3725 16.13 20.702 16.224 20.7961C16.7249 21.2969 17.5369 21.2969 18.0377 20.7961C18.5294 20.3043 18.538 19.5129 18.0641 19.0103C18.1788 19.1255 18.2867 19.1977 18.425 19.336C18.9199 19.8309 19.7186 19.8367 20.2207 19.3535C20.7388 18.8548 20.7291 18.0129 20.2207 17.5044L14.9494 12.2694" stroke="white" strokeWidth="1.3" strokeMiterlimit="10" />
      <path d="M18.1652 19.1117L15.2214 16.168" stroke="white" strokeWidth="1.3" strokeMiterlimit="10" />
      <path d="M15.9449 20.5213L13.2087 17.7852" stroke="white" strokeWidth="1.3" strokeMiterlimit="10" />
      <path d="M18.7977 10.2513L17.9061 9.40184C16.9113 8.45387 15.3418 8.47284 14.3701 9.44454L11.9978 11.8168C11.5276 12.287 11.5276 13.0495 11.9978 13.5198C12.4681 13.9901 13.2306 13.9901 13.7009 13.5198L15.8698 11.3509" stroke="white" strokeWidth="1.3" strokeMiterlimit="10" />
    </g>
    <defs>
      <clipPath id="clip0_8475_34595">
        <rect x="5" y="5" width="20" height="20" rx="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
HandshakeIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string.isRequired
}
