import React, { useState } from 'react'
import ChatForm from './chat-form'
import ChatHeader from './chat-detail/chat-header'

function NewChat () {
  const [loading, setLoading] = useState(false)
  return (
        <div className={'pb-20'}>
            <ChatHeader/>
            <div className="content h-[calc(100vh_-_335px)] md:h-[calc(100vh_-_240px)]" ></div>
            <ChatForm goToDetail loading={loading} setLoading={setLoading}/>
        </div>
  )
}

export default NewChat
