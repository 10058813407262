import React from 'react'
import { Breadcrumb } from 'antd'
import { IoIosArrowForward } from 'react-icons/io'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { capitalize } from '../utils'
import { FiHome } from 'react-icons/fi'

const PageCrumbs = ({ homePath }) => {
  const location = useLocation()
  const pathSnippets = location.pathname.split('/').filter((i) => i)

  const items = [
    {
      title: <Link to={ '/' }>
                <FiHome style={ { color: 'var(--Gray-500)', marginTop: 5, fontSize: 16 } }/>
            </Link>
    }
  ]

  // eslint-disable-next-line array-callback-return
  pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
    isNaN(parseInt(pathSnippets[index])) &&
        items.push({
          title: <Link to={ url }>
                {
                    capitalize(decodeURIComponent(pathSnippets[index]).replace('-', ' '))
                }
            </Link>
        })
  })

  return (
        <Breadcrumb items={ items } className={ 'flex' } separator={ <IoIosArrowForward/> }/>
  )
}

PageCrumbs.defaultProps = {
  homePath: '/'
}

PageCrumbs.propTypes = {
  homePath: PropTypes.string
}

export default PageCrumbs
