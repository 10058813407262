import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
// import CircularCompetencies from './competencies/circular-competencies'
import { useLocation } from 'react-router'
import { connect, useSelector } from 'react-redux'
import { handleGetUserQuizResults, handleGetUserSkills } from '../../../actions/user/common/Action'
import DosAndDonts from './dos-and-donts'
import Emotions from './emotions'
import QuizResult from '../../../components/public-components/nous-quiz/quiz-result'
import { isEmptyObject } from '../../../utils'
import EffectivenessPersonality from './effectiveness-personality'

function SkillsOverview ({ getUserSkills, getUserQuizResults }) {
  const [loading, setLoading] = useState(false)
  const { pathname } = useLocation()
  const commonData = useSelector((state) => state.commonReducer)
  const quizResults = useSelector((state) => state.commonReducer.quizResults)
  const userId = useSelector(state => state.loginReducer?.authUser?.id)
  useEffect(() => {
    setLoading(true)
    getUserSkills(pathname === '/dashboard/overview/self' ? 'self' : 'contact')
      .then(() => {
        setLoading(false)
        if (pathname === '/dashboard/overview/self') getUserQuizResults(userId)
      })
  }, [pathname])
  return (
        <div className={ 'bg-white-base rounded-lg p-2 md:p-[13px]' }>
            <EffectivenessPersonality />
            {
              !isEmptyObject(commonData?.skillsByBodyParts) &&
              <div>
                <Emotions/>
                <Spin spinning={ loading }>
                  {
                    commonData?.personalities?.length !== 0 &&
                    <div className='mt-10'>
                      <DosAndDonts />
                    </div>
                  }
                  {/* User Quiz Results */ }
                  {
                    pathname === '/dashboard/overview/self' && quizResults.map((result, i) => <div key={ i }><QuizResult extraClasses={ 'w-full border my-5' }bulk={ true } result={ result }/></div>)
                  }
                </Spin>
              </div>
            }
        </div>
  )
}

SkillsOverview.propTypes = {
  skills: PropTypes.array.isRequired,
  getUserQuizResults: PropTypes.func.isRequired,
  getUserSkills: PropTypes.func.isRequired
}
const mapStateToProps = (state) => {
  return {
    skills: state.commonReducer.allSkillNoPagination ?? []
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUserQuizResults: (userId) => dispatch(handleGetUserQuizResults(userId)),
  getUserSkills: (skillType) => dispatch(handleGetUserSkills(skillType))
})

export default connect(mapStateToProps, mapDispatchToProps)(SkillsOverview)
