import React, { useEffect } from 'react'
import { Button, Form, InputNumber } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import TlaModalFormWrapper from '../../../components/tla-modal-form-wrapper'
import CloseModal from '../../../components/close-modal'
import TlaSelect from '../../../components/TlaSelect'
import { handleGetAllQuizForms } from '../../../actions/admin/quiz-forms/Action'
import {
  handleAddQuizFormQuestion,
  handleUpdateQuizFormQuestion
} from '../../../actions/admin/quiz-form-questions/Action'
import { handleGetAllQuizQuestions } from '../../../actions/admin/quiz-questions/Action'

function QuizFormQuestionsForm ({ addQuestion, updateQuestion, quizForms, quizQuestions, getQuizForms, getQuizQuestions }) {
  const { state } = useLocation()
  const resourceId = state?.data?.id
  // const userId = useSelector(state => state.loginReducer?.authUser?.id)
  const formValues = state?.data
  useEffect(() => {
    getQuizForms(1, 1000)
    // getQuizQuestions(1, 1000)
  }, [])
  return (
      <TlaModalFormWrapper
          initialValues={ { ...formValues, sequence: Math.floor(Math.random() * 100) + 1 } }
          formTitle={ `${resourceId ? 'Update' : 'Create'} Question` }
          resourceId={ state?.data?.id ?? null }
          onSubmit={ state?.data?.id ? updateQuestion : addQuestion }>
          <p className={'font-medium'}>{ formValues?.title }</p>
          <TlaSelect
              customClass='my-2'
              name={ 'quizFormId' }
              placeholder={ 'Select Form' }
              options={ quizForms }
              optionKey={ 'titleQuizForm' } label={ 'Select Form *' } required/>
          {/* <TlaSelect
        customClass='my-2'
        name={'quizQuestionId'}
        placeholder={'Select Question'}
        options={quizQuestions}
        optionKey={'title'} label={'Select Question *'} required /> */ }
          <Form.Item
              name={ 'sequence' } className='my-2'
              label='Sequence Number *'
              rules={ [
                {
                  required: true,
                  message: 'Sequence Number Required'
                }
              ] }>
              <InputNumber type='number' className='w-full' size={ 'large' } placeholder="Sequence Number*"/>
          </Form.Item>

          <Form.Item
              hidden
              name={ 'quizQuestionId' } className='my-2'
              label='quizQuestionId'
              rules={ [
                {
                  required: true,
                  message: 'quizQuestionId Required'
                }
              ] }>
              <InputNumber type='number' className='w-full' size={ 'large' } placeholder="Sequence Number*"/>
          </Form.Item>
          <div className={ 'flex gap-2' }>
              <div className={ 'w-full' }>
                  <CloseModal/>
              </div>
              <Form.Item className={ 'w-full' }>
                  <Button className={ 'btn-primary w-full' } size={ 'large' } htmlType="submit">
                      { `${resourceId ? 'Update' : 'Create'}` } Question
                  </Button>
              </Form.Item>
          </div>
      </TlaModalFormWrapper>
  )
}

QuizFormQuestionsForm.propTypes = {
  addQuestion: PropTypes.func.isRequired,
  getQuizForms: PropTypes.func.isRequired,
  getQuizQuestions: PropTypes.func.isRequired,
  quizQuestions: PropTypes.array,
  quizForms: PropTypes.array,
  updateQuestion: PropTypes.func.isRequired
}

/**
 * @param state
 * @returns {{skills: ([]|*)}}
 */
const mapStateToProps = (state) => ({
  quizForms: state.adminQuizFormsReducer.quizForms.data,
  quizQuestions: state.adminQuizQuestionsReducer.quizQuestions.data
})

/**
 *
 * @param dispatch
 * @returns {{addQuestion: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  addQuestion: (data) => dispatch(handleAddQuizFormQuestion(data)),
  getQuizForms: (pageNumber, pageSize) => dispatch(handleGetAllQuizForms(pageNumber, pageSize)),
  getQuizQuestions: (pageNumber, pageSize) => dispatch(handleGetAllQuizQuestions(pageNumber, pageSize)),
  updateQuestion: (questionId, data) => dispatch(handleUpdateQuizFormQuestion(questionId, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(QuizFormQuestionsForm)
