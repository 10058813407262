import React, { useState } from 'react'
import { Button, Form, Input, Spin } from 'antd'
import { connect, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import CloseModal from '../../../components/close-modal'
import { handleUpdatePost } from '../../../actions/admin/posts/Action'
import { TlaModal } from '../../../components/pop-ups/tla-modal'
import { formatPostBody } from '../../../utils'
import { TlaError, TlaSuccess } from '../../../utils/messages'

function EditSlideForm ({ updatePost }) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const post = useSelector(state => state.adminPostsReducer.post)
  const [form] = Form.useForm()
  const { state } = useLocation()

  const onFinish = (values) => {
    const postBody = formatPostBody(post?.body)
    setLoading(true)
    const body = postBody.map((post) => {
      return post.slide_number === values.slide_number ? values : post
    })

    updatePost(post?.id, {
      title: post?.title,
      skillId: post?.skillId,
      link: post?.link ?? '',
      body: { ...body },
      totalSlide: postBody.length
    }).then(() => {
      setLoading(false)
      TlaSuccess('Slide Updated')
      navigate(-1)
    }).catch(() => {
      TlaError('Something went wrong')
    })
  }

  return (
        <TlaModal title={'Edit Slide'}>
            <Spin spinning={loading}>
                <Form layout={'vertical'}
                      onFinish={onFinish}
                      initialValues={{ ...state?.data }}
                      requiredMark={false}
                      form={form}>
                    <div className={'w-full'}>
                        <Form.Item
                            hidden
                            label={'Slide Number'}
                            name={'slide_number'}
                            rules={[
                              {
                                required: true,
                                message: 'Required'
                              }
                            ]}>
                            <Input size={'large'} placeholder="Slide Title"/>
                        </Form.Item>
                    </div>
                    <div className={'w-full'}>
                        <Form.Item
                            label={'Slide Title'}
                            name={'slide_title'}
                            rules={[
                              {
                                required: true,
                                message: 'Required'
                              }
                            ]}>
                            <Input size={'large'} placeholder="Slide Title"/>
                        </Form.Item>
                    </div>
                    <div className={'w-full'}>
                        <Form.Item
                            label={'Content'}
                            name={'content'}
                            rules={[
                              {
                                required: true,
                                message: 'Required'
                              }
                            ]}>
                            <Input.TextArea rows={5} size={'large'} placeholder="Slide Description"/>
                        </Form.Item>
                    </div>
                    <div className={'flex gap-2'}>
                        <div className={'w-full'}>
                            <CloseModal/>
                        </div>
                        <Form.Item className={'w-full'}>
                            <Button className={'btn-primary w-full'} size={'large'} htmlType="submit">
                                Edit Slide
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </Spin>
        </TlaModal>
  )
}

EditSlideForm.propTypes = {
  updatePost: PropTypes.func.isRequired
}

/**
 *
 * @param dispatch
 * @returns {{addPost: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  updatePost: (postId, data) => dispatch(handleUpdatePost(postId, data))
})

export default connect(null, mapDispatchToProps)(EditSlideForm)
