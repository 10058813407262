import React from 'react'
import { Radio } from 'antd'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

function RatingQuestion ({ question, lowScoreHelp, highScoreHelp, setValue, value, self, receiver, receiverInfo }) {
  const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const { state } = useLocation()

  const getQuestionStatement = () => {
    const receiverUser = receiver ?? receiverInfo?.name
    const statement = self ? question?.questionTitleSelf : question?.questionTitleOthers?.replace('{person}', state?.mainUser?.userInformation?.name ?? receiverUser)

    return statement?.replace('{Person}', state?.mainUser?.userInformation?.name ?? receiverUser)

    // console.log(statement?.replace('{Person}', state?.mainUser?.userInformation?.name ?? receiverUser))
    // return statement
  }
  return (
        <div>
            <p className={'text-base mb-2.5'}>
              {getQuestionStatement()}
            </p>
            <Radio.Group value={value} onChange={(e) => setValue({
              id: question?.id,
              type: question?.type,
              skillId: question?.skillId,
              newQuestionScore: e.target.value
            })} className={'rating-wrapper'} options={options} optionType="button" buttonStyle="solid"/>
            <div className={'flex justify-between text-gray-500 text-xs mt-[15px]'}>
                <span>{lowScoreHelp ?? 'Strongly Disagree'}</span>
                <span>{highScoreHelp ?? 'Strongly Agree'}</span>
            </div>
        </div>
  )
}

RatingQuestion.defaultProps = {
  lowScoreHelp: null,
  highScoreHelp: null,
  receiver: null
}

RatingQuestion.propTypes = {
  question: PropTypes.object,
  self: PropTypes.bool,
  lowScoreHelp: PropTypes.string,
  value: PropTypes.number,
  highScoreHelp: PropTypes.string,
  setValue: PropTypes.func,
  receiver: PropTypes.string,
  receiverInfo: PropTypes.object
}
export default RatingQuestion
