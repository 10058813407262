import React, { useEffect, useState } from 'react'
import { Spin, Switch, Table } from 'antd'
import TlaProgress from '../../../components/tla-progress'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { handleGetUserSkills, handleUpdateUserSkill } from '../../../actions/user/common/Action'
import { useLocation } from 'react-router-dom'
import TlaIcon from '../../../components/tla-icon'
import { TlaError, TlaSuccess } from '../../../utils/messages'

function AllSkills ({ getUserSkills, skills, isLoading, updateUserSkill }) {
  const [loading, setLoading] = useState(true)
  const { pathname } = useLocation()
  useEffect(() => {
    console.log(skills)
    setLoading(true)
    isLoading(true)
    getUserSkills('self')
      .then(() => {
        setLoading(false)
        isLoading(false)
      })
  }, [pathname])
  const onChange = (checked, skillId) => {
    updateUserSkill(skillId, {
      publicProfile: checked
    }).then(() => {
      TlaSuccess(`Public Visibility set to ${checked}`)
    }).catch(() => {
      TlaError('Something went wrong')
    })
  }

  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <TlaIcon name={'arrow-left'}/>
    }

    if (type === 'next') {
      return <TlaIcon name={'arrow-right'}/>
    }

    return originalElement
  }

  return (
        <div className={'py-6 shadow-2xl rounded-lg'}>
            <div className={'flex justify-between items-center flex-wrap mb-8'}>
                <h4 className={'pl-6 text-lg font-medium leading-7 text-gray-900'}>
                    All Skills
                </h4>
            </div>
            <Spin spinning={loading}>
                {
                    skills.length > 0
                      ? <Table scroll={{ x: 50 }}
                                 className={'all-skills-table'}
                                 pagination={{
                                   itemRender
                                 }}
                                 dataSource={skills} rowKey={'id'}>
                            <Table.Column title={'Skill'} render={(text, record) => record.skillTitle}/>
                            <Table.Column
                                title={<><span className={'hidden md:block'}>Absolute Score</span><span
                                    className={'block md:hidden'}>Absolute</span></>}
                                render={(text, record) => (
                                    <TlaProgress totalValue={10} partialValue={record.absoluteScore}/>
                                )}/>
                            <Table.Column responsive={['md']}
                                title={<><span className={'hidden md:block'}>Normalized Score</span><span
                                    className={'block md:hidden'}>Normalized</span></>}
                                render={(text, record) => (
                                    <TlaProgress totalValue={10} partialValue={record.normalizedScore}/>
                                )}/>
                                <Table.Column title={'Public Visibility'}
                                              render={(text, record) => (
                                                  <Switch className={'bg-gray-100'}
                                                          defaultChecked={record.publicProfile}
                                                          onChange={(checked) => onChange(checked, record.id)}/>
                                              )}/>
                        </Table>
                      : <div className={'text-center'}>
                            <p>
                                You need to have received at least 3 feedbacks from your contacts to ensure anonymity
                            </p>
                        </div>
                }
            </Spin>
        </div>
  )
}

AllSkills.propTypes = {
  skills: PropTypes.array.isRequired,
  isLoading: PropTypes.func.isRequired,
  getUserSkills: PropTypes.func.isRequired,
  updateUserSkill: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    skills: state.commonReducer.allSkillNoPagination ?? []
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUserSkills: (skillType) => dispatch(handleGetUserSkills(skillType)),
  updateUserSkill: (skillId, data) => dispatch(handleUpdateUserSkill(skillId, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AllSkills)
