import React from 'react'
import { Button } from 'antd'
import { FiPlus } from 'react-icons/fi'
import PropTypes from 'prop-types'
import TlaAddNew from '../../../components/pop-ups/tla-add-new'

const PageTitle = ({ title, addButton }) => {
  return (
      <div className={'flex items-center justify-between mb-5'}>
          <p className={'text-base font-medium text-gray-900'}>{ title }</p>
          {
              addButton &&
              <TlaAddNew link={'/organization/form'}>
                  <Button className={'btn btn-primary'} size={'large'} icon={<FiPlus/>}>
                      Add {title}
                  </Button>
              </TlaAddNew>
          }
      </div>
  )
}
PageTitle.defaultProps = {
  addButton: true
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  addButton: PropTypes.bool
}

export default PageTitle
