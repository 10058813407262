import React, { useEffect, useState } from 'react'
import { Button, Form, Input, message, Radio, Spin, Upload } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import CloseModal from '../../../components/close-modal.js'
import { handleAddCourse, handleUpdateCourse } from '../../../actions/admin/courses/Action.js'
import TlaSelect from '../../../components/TlaSelect.js'
import { handleGetAllCourseCategories } from '../../../actions/admin/course-categories/Action.js'
import TlaModalFormWrapper from '../../../components/tla-modal-form-wrapper.js'
import { BiUpload } from 'react-icons/bi'
import WisiwigEditor from '../../../components/wisiwig-editor'

function CoursesForm ({ addCourse, updateCourse, categories, getCategories }) {
  const { state } = useLocation()
  const resourceId = state?.data?.id
  const [paywall, setPaywall] = useState(state?.data?.behindPaywall ?? true)
  const [published, setPublished] = useState(state?.data?.published ?? false)
  // eslint-disable-next-line no-unused-vars
  // const [category, setCategory] = useState(state?.data?.category?.id ?? 0)
  const [loading, setLoading] = useState(true)
  const [content, setContent] = useState(state?.data?.description ?? '')
  const [selectedFile, setSelectedFile] = useState(null)
  const formValues = {
    ...state?.data,
    // categoryId: category,
    behindPaywall: paywall,
    published
  }
  useEffect(() => {
    getCategories().then(() => setLoading(false))
  }, [])
  const uploadProps = {
    beforeUpload: (file) => {
      setLoading(true)
      const isImage = file.type === 'image/jpeg' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/svg+xml' || file.type === 'image/webp'
      if (!isImage) {
        message.error(`${file.name} is not an image file`)
        setLoading(false)
        return Upload.LIST_IGNORE
      }
      const reader = new FileReader()
      reader.onload = (e) => {
        const base64String = e.target.result
        setSelectedFile(base64String)
        setLoading(false)
      }
      reader.readAsDataURL(file)
      return false
    },
    maxCount: 1,
    accept: 'image/*',
    className: 'py-2 px-4 rounded-lg bg-slate-100 border-gray-200 border w-full block my-3 '
  }
  const appendFileBeforeSubmit = (values) => {
    values = {
      ...values,
      ...(selectedFile ? { featureImageUrl: selectedFile } : {}),
      behindPaywall: paywall,
      published,
      description: content
    }
    return values
  }
  return (
    <Spin spinning={loading}>

      <TlaModalFormWrapper
          width={'50%'}
          initialValues={formValues}
          formTitle={`${resourceId ? 'Update' : 'Create'} Course`}
          resourceId={state?.data?.id ?? null}
          beforeSubmit={appendFileBeforeSubmit}
          onSubmit={state?.data?.id ? updateCourse : addCourse}>
                    <Form.Item
                        name={'title'}
                        rules={[
                          {
                            required: true,
                            message: 'Required'
                          }
                        ]}
                        label={'Course Title *'}>
                        <Input size={'large'} placeholder={'Enter Course Title'}/>
                    </Form.Item>
                    <TlaSelect
                        name={'categoryId'}
                        customClass={'mb-3'}
                        placeholder={'Select Course Category'}
                        options={categories}
                        defaultValue={state?.data?.categoryId}
                        optionKey={'title'} label={'Select Category *'} required/>
                    <Form.Item
                        name={'description'}
                        rules={[
                          {
                            required: true,
                            message: 'Required'
                          }
                        ]}
                        label={'Course Description *'}>
                        <WisiwigEditor value={content} setValue={setContent}/>
                    </Form.Item>
                    <Form.Item label="" name="behindPaywall" className='mb-4 mt-16'
                              rules={[
                                {
                                  required: true,
                                  message: 'Required'
                                }
                              ]}>
                        <div className={'flex flex-wrap gap-x-3'}>
                            <p>Behind Paywall</p>
                            <Radio.Group value={paywall} onChange={(e) => setPaywall(e.target.value)}>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </div>
                    </Form.Item>
                    <Form.Item label="" name="published" className='my-4'
                              rules={[
                                {
                                  required: true,
                                  message: 'Required'
                                }
                              ]}>
                        <div className={'flex flex-wrap gap-x-3'}>
                            <p>Published</p>
                            <Radio.Group value={published} onChange={(e) => setPublished(e.target.value)}>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </div>
                    </Form.Item>
                    <Form.Item
                        name={'file'}
                        rules={[
                          {
                            required: !state?.data?.id,
                            message: 'Required'
                          }
                        ]}
                        label={state?.data?.id ? 'Feature Image' : 'Feature Image *'}>
                        <Upload {...uploadProps}>
                          <div className="flex gap-2 w-full align-center">
                            <BiUpload size={30}/>
                            <span className="py-1">Upload</span>
                          </div>
                        </Upload>
                    </Form.Item>
                    <div className={'flex gap-2'}>
                        <div className={'w-full'}>
                            <CloseModal/>
                        </div>
                        <Form.Item className={'w-full'}>
                            <Button className={'btn-primary w-full'} size={'large'} htmlType="submit">
                                {`${resourceId ? 'Edit' : 'Add'}`} Course
                            </Button>
                        </Form.Item>
                    </div>
      </TlaModalFormWrapper>
    </Spin>
  )
}

CoursesForm.propTypes = {
  addCourse: PropTypes.func.isRequired,
  updateCourse: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  categories: PropTypes.array.isRequired
}

/**
 * @param state
 * @returns {{categories: *}}
 */
const mapStateToProps = (state) => ({
  categories: state.adminCourseCategoriesReducer.courseCategories?.data,
  userId: state.loginReducer.authUser.id
})

/**
 *
 * @param dispatch
 * @returns {{addCourse: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  addCourse: (data) => dispatch(handleAddCourse(data)),
  updateCourse: (courseId, data) => dispatch(handleUpdateCourse(courseId, data)),
  getCategories: (pageNumber, pageSize) => dispatch(handleGetAllCourseCategories(pageNumber, pageSize))
})

export default connect(mapStateToProps, mapDispatchToProps)(CoursesForm)
