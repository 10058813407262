import React, { useState } from 'react'
import { Collapse, theme } from 'antd'
import { MdCheck, MdOutlineClose } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { Title } from './common'

function Dos () {
  const [active, setActive] = useState(1)
  const personalities = useSelector((state) => state.commonReducer?.personalities?.[0])

  const { token } = theme.useToken()

  const getItems = (panelStyle) => [
    {
      key: '1',
      label: <Title title={'AT WORK'} color={'text-violet-700'} value={1}
                          active={active} setActive={setActive}
                          headerTitle={personalities?.workPersonality?.personalityTitle}
                          headerText={personalities?.workPersonality?.personalityDescription}/>,
      children:
                <div>
                    <h3 className={'text-2xl text-gray-900 font-semibold -mt-7 mb-3'}>
                        {personalities?.workPersonality?.personalityTitle}
                    </h3>
                    <div className={'text-violet-700 font-normal text-base'}
                         dangerouslySetInnerHTML={{ __html: personalities?.workPersonality?.personalityDescription }}/>

                    <div className={'bg-[#FBFAFF] p-4 mt-6'}>
                        <div>
                            <div className={'flex items-center gap-x-2'}>
                                <div className={'bg-success-500 rounded-full text-sm p-1'}>
                                    <MdCheck className={'text-white-base'}/>
                                </div>
                                <h3 className={'text-gray-800 font-semibold text-2xl'}>DO’s</h3>
                            </div>
                            <div className={'text-gray-800 text-base mt-6'}>
                                <p className={'mb-2'} dangerouslySetInnerHTML={{ __html: personalities?.workPersonality?.dos }}></p>
                            </div>
                        </div>
                        <div className={'text-gray-800 text-base mt-6'}>
                            <div className={'flex items-center gap-x-2'}>
                                <div className={'bg-error-100 rounded-full text-sm p-1'}>
                                    <MdOutlineClose className={'text-error-600'}/>
                                </div>
                                <h3 className={'text-gray-800 font-semibold text-2xl'}>DONT’s</h3>
                            </div>
                            <div className={'mt-6'}>
                                <p className={'mb-2'}
                                   dangerouslySetInnerHTML={{ __html: personalities?.workPersonality?.donts }}></p>
                            </div>
                        </div>
                    </div>
                    <div className={`${active !== 1 ? 'hidden' : ''} text-end mt-5 text-gray-500`}>
                      <span className={'cursor-pointer underline text-sm'}
                            onClick={() => {
                              setActive(2)
                            }}>
                          Show&nbsp;Less
                      </span>
                    </div>
                </div>,
      style: {
        ...panelStyle,
        background: '#F5F3FF'
      },
      showArrow: false
    }
  ]

  const panelStyle = {
    marginBottom: 40,
    borderRadius: token.borderRadiusLG,
    border: 'none'
  }

  return (
        <div>
            <Collapse
                collapsible={'icon'}
                ghost accordion activeKey={active}
                bordered={false}
                defaultActiveKey={[active]}
                items={getItems(panelStyle)}
            />
        </div>
  )
}

export default Dos
