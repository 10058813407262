import React, { useEffect, useState } from 'react'
import { Form, Spin } from 'antd'
import { useNavigate } from 'react-router-dom'
import { TlaModal } from './pop-ups/tla-modal'
import PropTypes from 'prop-types'
import { TlaError, TlaSuccess } from '../utils/messages'

function TlaModalFormWrapper (props) {
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { onSubmit, initialValues, formTitle, children, width, resourceId, beforeSubmit, successMessage } = props
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 100)
  }, [])
  const submit = (values) => {
    setLoading(true)
    const data = beforeSubmit ? beforeSubmit(values) : values;
    (resourceId ? onSubmit(resourceId, data) : onSubmit(data)).then(() => {
      setLoading(false)
      TlaSuccess(successMessage)
      form.resetFields()
      navigate(-1)
    }).catch((error) => {
      console.log(error)
      setLoading(false)
      let errorMessages = ''
      if (error.response?.data?.errors !== undefined) {
        // error.response?.data?.errors.forEach((error, i) => { errorMessages += `${i + 1}. ${error.message} \n` })
        errorMessages = (
          <div>
            {error.response?.data?.errors.map((error, i) => <p key={i}> {i + 1} {error.message} </p>)}
          </div>
        )
      }
      TlaError(error.response?.data?.message ?? errorMessages)
    })
  }

  return (
        <TlaModal title={formTitle} width={width}>
            <Spin spinning={loading}>
                <Form
                    requiredMark={false}
                    form={form}
                    onFinish={(values) => {
                      submit(values)
                    }}
                    layout="vertical"
                    name="formName"
                    initialValues={initialValues}>
                    {children}
                </Form>
            </Spin>
        </TlaModal>
  )
}

TlaModalFormWrapper.defaultProps = {
  file: null,
  beforeSubmit: null,
  resourceId: null,
  successMessage: null,
  width: 520
}

TlaModalFormWrapper.propTypes = {
  initialValues: PropTypes.object,
  submitValues: PropTypes.object,
  formTitle: PropTypes.string,
  onSubmit: PropTypes.func,
  file: PropTypes.any,
  width: PropTypes.any,
  children: PropTypes.any,
  resourceId: PropTypes.number,
  successMessage: PropTypes.string,
  beforeSubmit: PropTypes.any
}

export default TlaModalFormWrapper
