import React from 'react'
import ChatList from './chat-list'
import { Outlet, useLocation } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import NousgptButtonSet from '../../components/public-components/nousgpt/nousgpt-button-set'

const BodyStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .content {
    padding: 20px;
    overflow-y: scroll;h-[calc(100vh_-_400px)]
    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: rgba(166, 166, 176, 0.11) #ffffff;
  }

  .chat-list {
    /*overflow-y: auto;*/
    height: calc(100vh - 150px);
    scrollbar-width: thin;
    scrollbar-color: rgba(166, 166, 176, 0.11) #ffffff;
  }
`
function NousGpt () {
  const { pathname } = useLocation()

  return (
      <React.Fragment>
          <BodyStyle/>
          {/* <div className={'bg-white-base w-full md:w-[1200px] mx-auto no-flex md:flex' +
              ' rounded-lg pb-3 -mt-[18px] md:mt-[18px]'}>
              <div className={pathname === '/nous-gpt' ? 'block' : 'hidden md:block'}>
                  <ChatList/>
              </div>
              <div className={`w-full pt-5 md:pt-0 ${pathname === '/nous-gpt' ? 'hidden md:block' : 'block'}`}>
                  <Outlet/>
              </div>
          </div> */}
          <div className={'bg-white-base w-full mx-auto' +
              ' rounded-lg -mt-[18px] md:mt-[10px]'}>
              <div className={`px-8 py-3 flex-col md:flex-row md:items-center items-start gap-6 border-b border-gray-200 ${pathname !== '/nous-gpt' ? 'hidden md:flex' : 'flex'}`}>
                <h3 className='text-gray-900 text-lg font-medium'>Select AI Expert</h3>
                <NousgptButtonSet />
              </div>
              <div className="flex">
                <div className={pathname === '/nous-gpt' ? 'block' : 'hidden md:block'}>
                    <ChatList/>
                </div>
                <div className={`w-full pt-5 md:pt-0 ${pathname === '/nous-gpt' ? 'hidden md:block' : 'block'}`}>
                    <Outlet/>
                </div>
              </div>
          </div>
      </React.Fragment>
  )
}

export default NousGpt
