import React from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { Button, Form, Input, Spin } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { FiSend } from 'react-icons/fi'
import { handleAddChat } from '../../actions/user/chat/Action'
import { hashCode } from '../../utils'
import { TlaSuccess } from '../../utils/messages'

function ChatForm ({ submitChat, setLoading, loading, goToDetail }) {
  const location = useLocation()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const expert = useSelector(state => state?.chatsReducer?.gptExpert)

  const submit = (values) => {
    setLoading(true)
    const data = {
      chatId: location?.state?.chatId ?? 0,
      expert,
      userquery: {
        role: 'user',
        content: values.message
      }
    }

    submitChat(data).then((res) => {
      setLoading(false)
      form.resetFields()
      if (goToDetail) {
        navigate(`/nous-gpt/chat/${hashCode(res.data.data.chat.chatId + 'amos')}/detail`, {
          state: {
            chatId: res.data.data.chat.chatId
          }
        })
      }
    })
      .catch((error) => {
        TlaSuccess(error)
        setLoading(false)
      })
  }

  return (
        <Spin spinning={loading}>
            <div className={'w-11/12 mx-auto'}>
                <Form form={form} onFinish={submit}>
                    <Form.Item noStyle rules={[{ required: true }]} name={'message'}>
                        <Input
                            placeholder={`${expert === 'soft_skills' ? 'e.g. How can I improve my communication skills?' : 'e.g. I am struggling to communicate with my partner.'}`}
                            className={'h-[64px] border'}
                            size={'large'}
                            suffix={
                                <Button htmlType={'submit'} className={'btn-primary-50'} icon={<FiSend size={20}/>}/>
                            }/>
                    </Form.Item>
                </Form>
            </div>
        </Spin>
  )
}

ChatForm.defaultProps = {
  goToDetail: false
}

ChatForm.propTypes = {
  submitChat: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  goToDetail: PropTypes.bool
}

const mapDispatchToProps = (dispatch) => ({
  submitChat: (data) => dispatch(handleAddChat(data))
})

export default connect(null, mapDispatchToProps)(ChatForm)
