import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { FiBookOpen, FiCheck } from 'react-icons/fi'
import { QuestionIcon } from '../../../utils/svgs'

function ModuleContents ({ moduleContents, module, course }) {
  console.log(module)
  return (
        <div>
            <ul className='list-none pl-3'>
                {
                    moduleContents.map(content => (
                        <li key={ content.id }
                            className={ `my-2 flex items-center gap-2 text-gray-600 ${course.enrolled ? 'underline' : ''}` }>
                            {
                                content.progress === 'COMPLETED'
                                  ? <FiCheck
                                        className='bg-green-600 text-white-base inline-block w-4 h-4 rounded-full'/>
                                  : content.contentType === 'LESSON'
                                    ? <FiBookOpen className='text-gray-400 inline-block w-4 h-4'/>
                                    : <QuestionIcon className='inline-block w-4 h-4' fill={ '#98a2b3' }/>
                            }
                            {
                                course.enrolled
                                  ? <Link state={ {
                                    categoryName: course.categoryName,
                                    moduleContents: module.moduleContents
                                  } }
                                            to={ `/courses/${course.id}/module/${module.moduleId}/lessons/${content.contentId}` }>{ content.contentType === 'LESSON' ? content?.lesson?.title : content?.question?.questionTitleSelf }</Link>
                                  : content.contentType === 'LESSON' ? content?.lesson?.title : content?.question?.questionTitleSelf
                            }
                        </li>
                    ))
                }
            </ul>
        </div>
  )
}

ModuleContents.propTypes = {
  moduleContents: PropTypes.array.isRequired,
  module: PropTypes.any.isRequired,
  course: PropTypes.object.isRequired
}

export default ModuleContents
