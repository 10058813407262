import api from '../../../utils/api'
import { addSkill, allSkills, deleteSkill, importSkill, updateSkill } from './ActionCreator'

/**
 * Store a newly created resource in storage.
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAddSkill = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/skill', data).then((res) => {
      dispatch(addSkill(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const handleImportSkills = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api('multipart/form-data').post('/auth/seed', data).then((res) => {
      dispatch(importSkill(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAllSkills = (pageNumber = 1, pageSize = 15) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/skill/all?&page=${pageNumber}&pageSize=${pageSize}`).then((res) => {
      dispatch(allSkills(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
export const handleGetAllSkillsNoPagination = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get('/skill/all').then((res) => {
      dispatch(allSkills(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Update the specified resource in storage.
 * @param skillId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleUpdateSkill = (skillId, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().put(`/skill/${skillId}`, data, {
      // headers: { 'Content-type': 'multipart/form-data' }
    }).then((res) => {
      dispatch(updateSkill(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Remove the specified resource from storage.
 * @returns {function(*): Promise<unknown>}
 * @param skillId
 */
export const handleDeleteSkill = (skillId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().delete(`/skill/${skillId}`).then((res) => {
      dispatch(deleteSkill(skillId))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
