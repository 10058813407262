import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function addSkill (payload) {
  return {
    type: Types.NEW_SKILL,
    payload
  }
}
export function importSkill (payload) {
  return {
    type: Types.IMPORT_SKILL,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function allSkills (payload) {
  return {
    type: Types.ALL_SKILLS,
    payload
  }
}

/**
 *
 * @param skill
 * @returns {{skill, type: string}}
 */
export function deleteSkill (skill) {
  return {
    type: Types.DELETE_SKILL,
    skill
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function updateSkill (payload) {
  return {
    type: Types.UPDATE_SKILL,
    payload
  }
}
