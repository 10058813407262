import React, { useState } from 'react'
import { Button, Form, Input } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import CloseModal from '../../../components/close-modal'
import { handleUpdateQuestion } from '../../../actions/admin/questions/Action'
import TlaSelect from '../../../components/TlaSelect'
import { handleGetAllSkills } from '../../../actions/admin/skills/Action'
import { TlaModal } from '../../../components/pop-ups/tla-modal'
import { TlaError, TlaSuccess } from '../../../utils/messages'

function QuestionsForm ({ updateQuestion, skills, getSkills }) {
  const [loading, setLoading] = useState(false)
  const { state } = useLocation()
  const [form] = Form.useForm()

  const navigate = useNavigate()
  const onFinish = (values) => {
    setLoading(true)
    values.type = 'RATING'
    updateQuestion(state?.data?.id, values).then(() => {
      setLoading(false)
      TlaSuccess('Question updated successfully')
      navigate(-1)
    }).catch((err) => {
      TlaError(err.response?.data?.message)
    })
  }
  return (
        <TlaModal title={'Edit Question'} loading={loading}>
            <Form onFinish={onFinish} layout={'vertical'} requiredMark={false} initialValues={{ ...state?.data }} form={form}>
                <TlaSelect
                    onFocus={skills.length <= 0
                      ? (getSkills(1, 500).then(() => {}))
                      : null}
                    required name={'skillId'} label={'Select Skill *'} options={skills} optionKey={'title'}/>
                <Form.Item
                    name={'questionTitleOthers'}
                    rules={[
                      {
                        required: true,
                        message: 'Required'
                      }
                    ]}
                    label={'Add Question Title (Others)*'}>
                    <Input.TextArea size={'large'} rows={2} placeholder={'Enter a description...'}/>
                </Form.Item>
                <Form.Item
                    name={'questionTitleSelf'}
                    rules={[
                      {
                        required: true,
                        message: 'Required'
                      }
                    ]}
                    label={'Add Question Title (Self)*'}>
                    <Input.TextArea size={'large'} rows={2} placeholder={'Enter a description...'}/>
                </Form.Item>
                <div className={'flex justify-between gap-3'}>
                    <Form.Item
                        name={'lowestScoreHelp'}
                        label={'Lowest Score help text'}>
                        <Input size={'large'} placeholder={'Eg Strongly Disagree'}/>
                    </Form.Item>
                    <Form.Item
                        name={'highestScoreHelp'}
                        label={'Highest Score help text'}>
                        <Input size={'large'} placeholder={'Eg Strongly Agree'}/>
                    </Form.Item>
                </div>
                <div className={'flex gap-2'}>
                    <div className={'w-full'}>
                        <CloseModal/>
                    </div>
                    <Form.Item className={'w-full'}>
                        <Button className={'btn-primary w-full'} size={'large'} htmlType="submit">
                            Update Question
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </TlaModal>
  )
}

QuestionsForm.propTypes = {
  skills: PropTypes.array.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  getSkills: PropTypes.func.isRequired
}

/**
 * @param state
 * @returns {{skills: ([]|*)}}
 */
const mapStateToProps = (state) => ({
  skills: state.adminSkillsReducer.skills.data
})

/**
 *
 * @param dispatch
 * @returns {{addQuestion: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  updateQuestion: (questionId, data) => dispatch(handleUpdateQuestion(questionId, data)),
  getSkills: (pageNumber, pageSize) => dispatch(handleGetAllSkills(pageNumber, pageSize))

})

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsForm)
