import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Spin } from 'antd'
import PropTypes from 'prop-types'
import { TlaError, TlaSuccess } from '../../../utils/messages'
import OptionItemCourses from './option-item-courses'
import { handleQuestionSubmission } from '../../../actions/user/common/Action'

function MultiSelectQuestion ({ question, submitQuestion, questionSubmitted, courseModuleDetails }) {
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [selected, setSelected] = useState(question.questionAnswer ? question.questionAnswer.answer : [])
  const correctAns = question.questionOptions?.reduce((acc, option, i) => {
    if (option.correct_ans === true) {
      acc.push(i)
    }
    return acc
  }, [])
  const checkAns = (index) => {
    const isCorrect = correctAns.includes(index)
    const isChecked = selected.includes(index)
    if (submitted || question.completed === true) {
      if (isCorrect && isChecked) return 'border-green-500 text-green-500 font-semibold'
      else if (!isCorrect && isChecked) return 'border-red-500 text-red-500 font-semibold'
      else if (isCorrect && !isChecked) return 'border-blue-500 text-blue-500 font-semibold'
      else return 'border-gray-300 text-gray-300'
    }
    if (isChecked) return 'border-blue-500 text-blue-500'
    else return 'border-gray-300 text-black-900'
  }
  const submit = () => {
    setLoading(true)
    setSubmitting(false)
    setSubmitted(true)
    submitQuestion({ ...courseModuleDetails, questionAnswer: { answer: selected } }).then(() => {
      setLoading(false)
      TlaSuccess('Question Submitted')
    }).catch((err) => {
      console.error('API Error:', err)
      TlaError('Something went wrong in question submission')
    })
  }
  const options = question.questionOptions !== undefined ? question.questionOptions : []
  useEffect(() => {
    if (!questionSubmitted) {
      setSubmitted(false)
    }
    setSelected(question.questionAnswer ? question.questionAnswer.answer : [])
  }, [question.id])
  useEffect(() => {
    if (questionSubmitted) {
      setSubmitting(true)
    } else {
      setSubmitted(false)
    }
  }, [questionSubmitted])
  useEffect(() => {
    if (submitting) {
      submit()
    }
  }, [submitting])
  return (
        <div className={'w-full rounded-10 bg-white-base p-5 md:p-[30px]'}>
            <div className={'mb-5'}>
                <p className={'text-black-900 font-medium text-lg'}>
                    {question.questionTitle ?? question.questionTitleSelf}
                </p>
                <p className='font-semibold leading-6 my-2'>
                  Please select all possible options
                </p>
            </div>
            <Spin spinning={loading}>
                <div className={`flex flex-col items-start justify-start gap-4 ${question.completed ? 'pointer-events-none' : ''}`}>
                    {
                      options.map(({ score, text, explanation }, index) => {
                        const find = selected.indexOf(index) !== -1
                        return (
                          <OptionItemCourses
                            onClick={() => {
                              selected.indexOf(index) === -1 ? setSelected([...selected, index]) : setSelected(selected.filter(item => item !== index))
                            }}
                            key={index} text={text}
                            explanation={explanation}
                            type={question.type}
                            colors={checkAns(index)}
                            selected={find}
                          />
                        )
                      })
                    }
                </div>
            </Spin>
        </div>
  )
}

MultiSelectQuestion.propTypes = {
  question: PropTypes.object,
  courseModuleDetails: PropTypes.object,
  isMulti: PropTypes.bool,
  questionSubmitted: PropTypes.bool,
  submitQuestion: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => ({
  submitQuestion: (data) => dispatch(handleQuestionSubmission(data))
})

export default connect(null, mapDispatchToProps)(MultiSelectQuestion)
