import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router'
import TlaTableWrapper from '../../../components/table/tla-table-wrapper'
import { Spin, Table } from 'antd'
import PropTypes from 'prop-types'
import { handleDeletePost, handleGetAllPosts } from '../../../actions/admin/posts/Action'
import { connect, useDispatch } from 'react-redux'
import TlaConfirm from '../../../components/tla-confirm'
import { TlaSuccess } from '../../../utils/messages'
import { useNavigate } from 'react-router-dom'
import TlaEdit from '../../../components/tla-edit'
import { FiEdit2 } from 'react-icons/fi'

const Posts = ({ getPosts, posts, deletePost }) => {
  const { setPageInfo } = useOutletContext()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const goToDetails = (record) => {
    dispatch({ type: 'SINGLE_POST', payload: record })
    navigate(`/posts/${record?.title.slice(1, 50)}...`)
  }
  const { data, meta } = posts
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setPageInfo({
      title: 'Posts',
      buttonText: 'Add Posts',
      buttonLink: 'Add Posts',
      subTitle: '',
      helpingText: '',
      showSearch: true
    })
    getPosts().then(() => setLoading(false))
  }, [])

  return (
        <div className={'pb-4'}>
            <Spin spinning={loading}>
                <TlaTableWrapper
                   meta={meta}
                   numberColumn={true} data={data} showHeader={false}
                   callbackFunction={getPosts}>
                    <Table.Column title={'Post Title'} render={((record) => (
                        <span className={'cursor-pointer hover:underline'} onClick={() => goToDetails(record)}>
                            {record.title }
                        </span>
                    ))}/>
                    <Table.Column title={'Actions'} render={((record) => (
                        <div className={'flex items-center'}>
                            { false && <TlaConfirm title={'Delete Post'}
                                        fullText={'Do you really want to delete this query?'}
                                        callBack={() => {
                                          deletePost(record.id).then(() => TlaSuccess('Deleted'))
                                        }}/> }
                            <TlaEdit data={record} icon={<FiEdit2 className={'icon'}/>} link={'form'}/>
                        </div>
                    ))}/>
                </TlaTableWrapper>
            </Spin>
        </div>
  )
}

Posts.propTypes = {
  getPosts: PropTypes.func.isRequired,
  deletePost: PropTypes.func.isRequired,
  posts: PropTypes.object.isRequired
}

/**
 * @param state
 * @returns {{posts: *}}
 */
const mapStateToProps = (state) => ({
  posts: state.adminPostsReducer.posts
})

/**
 *
 * @param dispatch
 * @returns {{getPosts: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  getPosts: (pageNumber) => dispatch(handleGetAllPosts(pageNumber)),
  deletePost: (postId) => dispatch(handleDeletePost(postId))
})

export default connect(mapStateToProps, mapDispatchToProps)(Posts)
