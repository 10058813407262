import React, { useState } from 'react'
import { Button, Form, Input } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import CloseModal from '../../../components/close-modal.js'
import { handleAddLesson, handleUpdateLesson } from '../../../actions/admin/lessons/Action.js'
import TlaSelect from '../../../components/TlaSelect.js'
import { handleGetAllSkills } from '../../../actions/admin/skills/Action.js'
import TlaModalFormWrapper from '../../../components/tla-modal-form-wrapper.js'
import 'react-quill/dist/quill.snow.css'
import WisiwigEditor from '../../../components/wisiwig-editor'

function LessonsForm ({ addLesson, updateLesson, skills, getSkills }) {
  const { state } = useLocation()
  const [content, setContent] = useState(state?.data?.lesson ?? '')
  const resourceId = state?.data?.id
  const formValues = {
    ...state?.data
  }
  const formatBeforeSubmit = (values) => {
    return {
      ...values,
      lesson: content
    }
  }
  return (
        <TlaModalFormWrapper
            width={ '50%' }
            initialValues={ formValues }
            formTitle={ `${resourceId ? 'Update' : 'Create'} Lesson` }
            resourceId={ state?.data?.id ?? null }
            beforeSubmit={ formatBeforeSubmit }
            onSubmit={ state?.data?.id ? updateLesson : addLesson }>
            <Form.Item
                name={ 'title' }
                rules={ [
                  {
                    required: true,
                    message: 'Required'
                  }
                ] }
                label={ 'Lesson Title *' }>
                <Input size={ 'large' } placeholder={ 'Enter Lesson title' }/>
            </Form.Item>
            <Form.Item
                name={ 'hint' }
                label={ 'Lesson Hint ' }>
                <Input size={ 'large' } placeholder={ 'SD' }/>
            </Form.Item>
            <TlaSelect
                customClass='mb-4 mt-[-10px]'
                onFocus={ skills.length <= 10
                  ? (getSkills(1, 500).then(() => {
                    }))
                  : null }
                name={ 'skillId' }
                placeholder={ 'Select Skill' }
                options={ skills }
                optionKey={ 'title' } label={ 'Select Skill *' } required/>
            <div>
                <Form.Item
                    name={ 'lesson' }
                    rules={ [
                      {
                        required: false,
                        message: 'Required'
                      }
                    ] }
                    label={ 'Lesson' }>
                    <WisiwigEditor value={content} setValue={setContent}/>
                </Form.Item>
            </div>
            <div className={ 'flex gap-2 mt-20' }>
                <div className={ 'w-full' }>
                    <CloseModal/>
                </div>
                <Form.Item className={ 'w-full' }>
                    <Button className={ 'btn-primary w-full' } size={ 'large' } htmlType="submit">
                        { `${resourceId ? 'Update' : 'Create'}` } Lesson
                    </Button>
                </Form.Item>
            </div>
        </TlaModalFormWrapper>
  )
}

LessonsForm.propTypes = {
  addLesson: PropTypes.func.isRequired,
  updateLesson: PropTypes.func.isRequired,
  getSkills: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  skills: PropTypes.array.isRequired
}

/**
 * @param state
 * @returns {{skills: *}}
 */
const mapStateToProps = (state) => ({
  skills: state.adminSkillsReducer.skills?.data,
  userId: state.loginReducer.authUser.id

})

/**
 *
 * @param dispatch
 * @returns {{addLesson: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  addLesson: (data) => dispatch(handleAddLesson(data)),
  updateLesson: (lessonId, data) => dispatch(handleUpdateLesson(lessonId, data)),
  getSkills: (pageNumber, pageSize) => dispatch(handleGetAllSkills(pageNumber, pageSize))
})

export default connect(mapStateToProps, mapDispatchToProps)(LessonsForm)
