import { Types } from '../../actions/admin/courses/Types'

const initialState = {
  courses: {
    data: [],
    meta: {}
  },
  course: {}
}

export default function adminCoursesReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_COURSE:
      return {
        ...state,
        courses: {
          ...state.courses,
          data: [
            action.payload.data.course,
            ...state.courses.data
          ]
        }
      }
    case Types.ALL_COURSES:
      return {
        ...state,
        courses: {
          ...state.courses,
          data: action.payload.data.courses,
          meta: {
            total: action.payload.data.count,
            current_page: Number(action.payload.data.page),
            page: action.payload.data.page,
            pageSize: action.payload.data.pageSize
          }
        }
      }

    case Types.UPDATE_COURSE:
      return {
        ...state,
        courses: {
          ...state.courses,
          data: state.courses.data.map((course) => {
            return course.id === action.payload.data.course.id ? action.payload.data.course : course
          })
        }
        // course: action.payload.data.upda
      }
    case Types.DELETE_COURSE:
      return {
        ...state,
        courses: {
          ...state.courses,
          data: state.courses.data.filter((courses) => courses.id !== action.course)
        }
      }

    default:
      return state
  }
}
