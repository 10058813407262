import React, { useState } from 'react'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import { FiUsers } from 'react-icons/fi'

function getItem (label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type
  }
}
const items = [
  getItem(<Link to={'users'}><div className={'flex items-center gap-2'}><FiUsers/> Employees</div></Link>, 'users')
]

// submenu keys of first level
const rootSubmenuKeys = ['sub1']
const OrgMenus = () => {
  const [openKeys, setOpenKeys] = useState(['sub1'])
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }
  return (
        <Menu
            mode="inline"
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            items={items}
        />
  )
}
export default OrgMenus
