import React, { useState } from 'react'
import { FiCopy } from 'react-icons/fi'
import { Tooltip } from 'antd'
import { useSelector } from 'react-redux'

const ShareFeedback = () => {
  const invitationCode = useSelector(state => state.loginReducer?.authUser?.invitationCode)
  const [text, setText] = useState('Copy')
  return (
        <Tooltip title={text}
                 onOpenChange={() => setText('Copy')}>
            <p onClick={() => {
              navigator.clipboard
                .writeText(`Hello, Can you please provide your valuable feedback to me anonymously? I greatly appreciate your time. \n${process.env.REACT_APP_FE_URL}/register/${invitationCode}/invitation`)
                .then(() => setText('Copied'))
            }} className={'flex items-center w-fit mx-auto gap-x-1' +
                ' text-primary-700 underline bg-primary-50' +
                ' rounded-full px-2 text-sm cursor-pointer'}>
                <FiCopy/> Share Feedback Link
            </p>
        </Tooltip>
  )
}
export default ShareFeedback
