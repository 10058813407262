import React from 'react'
import PropTypes from 'prop-types'
import TlaImage from '../../../components/tla-image'

function FeedbackTitle ({ name, jobTitle, photo }) {
  return (
        <div className={'flex items-center gap-2'}>
            <TlaImage photo={photo}/>
            {/* <Image alt={'Avatar'} src={DefaultImage} preview={false} className={'border rounded-full p-2 !h-10 !w-10'}/> */}
            <div>
                <h4 className={'text-sm text-gray-600 font-medium'}>{name}</h4>
                <p className={'text-xs text-gray-400'}>{jobTitle}</p>
            </div>
        </div>
  )
}

FeedbackTitle.defaultProps = {
  name: '',
  jobTitle: '',
  photo: 'default.png'
}

FeedbackTitle.propTypes = {
  name: PropTypes.string,
  jobTitle: PropTypes.string,
  photo: PropTypes.string
}

export default FeedbackTitle
