import React, { useState } from 'react'
import { Button, Form, Input } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import CloseModal from '../../../components/close-modal'
import { handleUpdateQuestion } from '../../../actions/admin/questions/Action'
import TlaSelect from '../../../components/TlaSelect'
import { handleGetAllSkills } from '../../../actions/admin/skills/Action'
import { FiPlus, FiTrash } from 'react-icons/fi'
import { TlaModal } from '../../../components/pop-ups/tla-modal'
import { formatBodyOptions } from '../../../utils'
import { TlaError, TlaSuccess } from '../../../utils/messages'

function EditScenarioQuestion ({ updateQuestion, skills, getSkills }) {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { state } = useLocation()
  const [form] = Form.useForm()
  const resourceId = state?.data?.id

  const formValues = {
    ...state?.data,
    questionBodyOthers: formatBodyOptions(state?.data?.questionBodyOthers ?? state?.data?.questionBodySelf)?.options
  }
  const onFinish = (values) => {
    setLoading(true)
    values.type = 'SCENARIO'

    values.questionBodyOthers = Object.assign({}, values.questionBodyOthers)
    values.questionBodySelf = ''
    updateQuestion(state?.data?.id, values).then(() => {
      setLoading(false)
      TlaSuccess('Question updated successfully')
      navigate(-1)
    }).catch((err) => {
      TlaError(err.response?.data?.message)
    })
  }

  return (
        <TlaModal loading={loading} title={'Edit Question'}>
            <Form onFinish={onFinish} layout={'vertical'} requiredMark={false} initialValues={formValues} form={form}>
                <TlaSelect
                    onFocus={skills.length <= 0 ? (getSkills(1, 500).then(() => {})) : null}
                    required name={'skillId'} label={'Select Skill *'} options={skills} optionKey={'title'}/>
                <Form.Item
                    name={'questionTitleOthers'}
                    rules={[
                      {
                        required: true,
                        message: 'Required'
                      }
                    ]}
                    label={'Add Question Title (Others)*'}>
                    <Input.TextArea size={'large'} rows={2} placeholder={'Enter a description...'}/>
                </Form.Item>
                <Form.Item
                    name={'questionTitleSelf'}
                    label={'Add Question Title (Self)*'}>
                    <Input.TextArea size={'large'} rows={2} placeholder={'Enter a description...'}/>
                </Form.Item>
                <Form.List name="questionBodyOthers">
                    {(fields, { add, remove }) => {
                      let total = 0
                      return (
                            <>
                                <div className={'flex justify-between items-center mb-2'}>
                                    <h3 className={'text-sm font-medium'}>Options *</h3>
                                    <Button type="text"
                                            className={'btn btn-light text-sm text-gray-500 hover:!bg-transparent'}
                                            onClick={() => {
                                              add()
                                              total = total + 1
                                            }} icon={<FiPlus size={20}/>}>
                                        Add
                                    </Button>
                                </div>
                                {fields.map(({ key, name, ...restField }) => (
                                    <div key={key} className={'flex gap-2'}>
                                        <div className={'w-full'}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'text']}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Required'
                                                  }
                                                ]}>
                                                <Input size={'large'} placeholder="Option Title"/>
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'explanation']}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Required'
                                                  }
                                                ]}>
                                                <Input size={'large'} placeholder="Explanation"/>
                                            </Form.Item>
                                        </div>
                                        <Button icon={<FiTrash/>} className={'btn items-center flex justify-center'}
                                                danger size={'large'}
                                                onClick={() => remove(name)}/>
                                    </div>
                                ))}
                            </>
                      )
                    }}
                </Form.List>
                <div className={'flex gap-2'}>
                    <div className={'w-full'}>
                        <CloseModal/>
                    </div>
                    <Form.Item className={'w-full'}>
                        <Button className={'btn-primary w-full'} size={'large'} htmlType="submit">
                            {`${resourceId ? 'Update' : 'Create'}`} Question
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </TlaModal>
  )
}

EditScenarioQuestion.propTypes = {
  skills: PropTypes.array.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  getSkills: PropTypes.func.isRequired
}

/**
 * @param state
 * @returns {{skills: ([]|*)}}
 */
const mapStateToProps = (state) => ({
  skills: state.adminSkillsReducer.skills.data
})

/**
 *
 * @param dispatch
 * @returns {{addQuestion: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  updateQuestion: (questionId, data) => dispatch(handleUpdateQuestion(questionId, data)),
  getSkills: (pageNumber, pageSize) => dispatch(handleGetAllSkills(pageNumber, pageSize))

})

export default connect(mapStateToProps, mapDispatchToProps)(EditScenarioQuestion)
