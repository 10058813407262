import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

export default function Title ({
  title,
  subText,
  focusText = null
}) {
  const { state } = useLocation()
  console.log(state?.email)
  focusText = focusText ?? state?.email
  return (
        <div className={'text-center w-[360px]'}>
            <h1 className={'text-gray-900 text-[30px] font-bold'}>{title}</h1>
            <p className={'text-center text-gray-500'}>
                {subText}
                {
                    focusText &&
                    <>
                        <br/>
                        <span className={'font-semibold'}>{focusText}</span>
                    </>
                }

            </p>
        </div>
  )
}

Title.defaultProps = {
  title: 'Check your email',
  subText: 'We sent a verification link to',
  focusText: null
}

Title.propTypes = {
  title: PropTypes.string,
  subText: PropTypes.string,
  focusText: PropTypes.string
}
