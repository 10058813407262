import React from 'react'
import PropTypes from 'prop-types'

// eslint-disable-next-line react/prop-types
const OptionItem = ({ text, explanation, selected, ...props }) => {
  return (
        <React.Fragment>
            <div
                {...props}
                className={`w-full md:w-fit ${selected ? 'scenario-btn-selected' : 'scenario-btn'}`} >
                {text}
            </div>
            {
                selected &&
                <div className={'bg-primary-25 p-2.5 rounded-10 w-full'}>
                    <h6 className={'text-primary-700 font-semibold text-sm mb-[10px]'}>Explanation:</h6>
                    <p className={'text-primary-700 text-sm m-0'}>
                        {explanation}
                    </p>
                </div>
            }

        </React.Fragment>
  )
}

OptionItem.defaultProps = {
  selected: false
}

OptionItem.propTypes = {
  selected: PropTypes.bool
}

export default OptionItem
