import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function addPost (payload) {
  return {
    type: Types.NEW_POST,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function allPosts (payload) {
  return {
    type: Types.ALL_POSTS,
    payload
  }
}

/**
 *
 * @param post
 * @returns {{post, type: string}}
 */
export function deletePost (post) {
  return {
    type: Types.DELETE_POST,
    post
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function updatePost (payload) {
  return {
    type: Types.UPDATE_POST,
    payload
  }
}
