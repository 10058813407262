import React from 'react'
import { Image, Space } from 'antd'

// eslint-disable-next-line react/prop-types
const DashItem = ({ icon, title, subTitle }) => (
    <Space className={'dash-item bg-white'}>
        <Image preview={false} src={icon} width={35} height={35}/>
        <Space className={'dash-item-inner'} direction={'vertical'}>
            <h3 className={'text-sm-semibold supporting-text'}>{title}</h3>
            <h4 className={'text-sm text-gray-500'}>{subTitle}</h4>
        </Space>
    </Space>
)
export default DashItem
