import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Radio } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import CloseModal from '../../../components/close-modal'
import { handleAddContentPost, handleUpdateContentPost } from '../../../actions/admin/content-posts/Action.js'
import TlaSelect from '../../../components/TlaSelect'
import { handleGetAllSkills } from '../../../actions/admin/skills/Action'
import TlaModalFormWrapper from '../../../components/tla-modal-form-wrapper'

function ContentPostsForm ({ addContentPost, updateContentPost, skills, userId, getSkills }) {
  const { state } = useLocation()
  const resourceId = state?.data?.id
  const [useIFrame, setUseIFrame] = useState(state?.data?.use_iframe)
  useEffect(() => {
    getSkills(1, 500)
  }, [])
  const formValues = {
    ...state?.data,
    body: state?.data?.body
      ? Object.values(state?.data?.body)
      : [
          {
            slide_title: '',
            content: ''
          }
        ]
  }
  const formatBeforeSubmit = (values) => {
    values = {
      ...values,
      use_iframe: values.use_iframe === 'true' || values.use_iframe === true
    }
    return values
  }

  return (
      <TlaModalFormWrapper
          initialValues={formValues}
          formTitle={`${resourceId ? 'Update' : 'Create'} Content Post`}
          resourceId={state?.data?.id ?? null}
          beforeSubmit={formatBeforeSubmit}
          onSubmit={state?.data?.id ? updateContentPost : addContentPost}>
            <Form.Item label="" name="use_iframe"
                       rules={[
                         {
                           required: true,
                           message: 'Required'
                         }
                       ]}>
                <div className={'flex flex-wrap gap-x-3'}>
                    <p>Using IFrame</p>
                    <Radio.Group value={useIFrame} onChange={(e) => setUseIFrame(e.target.value)}>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </div>
            </Form.Item>
                    <Form.Item
                        name={'title'}
                        rules={[
                          {
                            required: true,
                            message: 'Required'
                          }
                        ]}
                        label={'Add Content Post Title'}>
                        <Input.TextArea rows={2} size={'large'} placeholder={'Enter Content Post title'}/>
                    </Form.Item>
                    <TlaSelect
                        name={'skills'}
                        mode={'multiple'}
                        options={skills}
                        optionKey={'title'} label={'Select Skill *'} required/>
                    <Form.Item
                        name={'link'}
                        rules={[
                          {
                            type: 'url',
                            message: 'Enter a valid url'
                          }
                        ]}
                        label={'Add Content Post source link'}>
                        <Input size={'large'} placeholder={'Enter Content Post source link'}/>
                    </Form.Item>
                    <Form.Item
                        name={'brief'}
                        rules={[
                          {
                            required: true,
                            message: 'Required'
                          }
                        ]}
                        label={'Add Content Post Brief *'}>
                        <Input.TextArea rows={5} size={'large'} placeholder={'Enter Content Post brief'}/>
                    </Form.Item>
                    <div className={'flex gap-2'}>
                        <div className={'w-full'}>
                            <CloseModal/>
                        </div>
                        <Form.Item className={'w-full'}>
                            <Button className={'btn-primary w-full'} size={'large'} htmlType="submit">
                                {`${resourceId ? 'Edit' : 'Create'}`} Content Post
                            </Button>
                        </Form.Item>
                    </div>
      </TlaModalFormWrapper>
  )
}

ContentPostsForm.propTypes = {
  addContentPost: PropTypes.func.isRequired,
  updateContentPost: PropTypes.func.isRequired,
  getSkills: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  skills: PropTypes.array.isRequired
}

/**
 * @param state
 * @returns {{skills: *}}
 */
const mapStateToProps = (state) => ({
  skills: state.adminSkillsReducer.skills?.data,
  userId: state.loginReducer.authUser.id
})

/**
 *
 * @param dispatch
 * @returns {{addContentPost: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  addContentPost: (data) => dispatch(handleAddContentPost(data)),
  updateContentPost: (contentPostId, data) => dispatch(handleUpdateContentPost(contentPostId, data)),
  getSkills: (pageNumber, pageSize) => dispatch(handleGetAllSkills(pageNumber, pageSize))
})

export default connect(mapStateToProps, mapDispatchToProps)(ContentPostsForm)
