import { Types } from '../../actions/user/chat/Types'

const initialState = {
  chatList: [],
  chatId: 0,
  gptExpert: 'soft_skills',
  chat: []
}

export default function chatsReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_CHAT: {
      // eslint-disable-next-line no-case-declarations
      const index = state.chatList.findIndex(object => object.chatId === action.payload.data.chat.chatId)
      return {
        ...state,
        chat: {
          chat: action.payload.data.messages,
          expert: state.gptExpert
        },
        chatList: index === -1 ? [{ ...action.payload.data.chat, expert: state.gptExpert }, ...state.chatList] : state.chatList
      }
    }
    case Types.GET_CHAT:{
      return {
        ...state,
        chatId: action.chatId,
        chat: action.payload.data
      }
    }
    case Types.DELETE_CHAT: {
      return {
        ...state,
        chatList: state.chatList.filter(chat => chat.chatId !== action.chatId)
      }
    }
    case Types.UPDATE_CHAT: {
      return {
        ...state,
        chatList: state.chatList.map(chat => chat.chatId === action.chatId ? { ...chat, chatTitle: action.payload } : chat)
      }
    }
    case Types.GET_CHAT_LIST: {
      return {
        ...state,
        chatList: action.payload.data.chatlist
      }
    }
    case Types.CHANGE_GPT_EXPERT:{
      return {
        ...state,
        gptExpert: action.payload
      }
    }
    default:
      return state
  }
}
