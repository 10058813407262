import React from 'react'
import SidebarForm from '../dashboard/layout/sidebar-form'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

function NousGames () {
  const { pathname } = useLocation()

  const navigate = useNavigate()

  const change = (path) => {
    navigate(`/nous-games/${path}`)
  }
  return (
    <div>
        <div className={'hidden md:hidden xl:hidden'}>
            <SidebarForm col={'row'}/>
        </div>
        <div className={'grid grid-cols-2 items-center cursor-pointer'}>
            <div onClick={() => change('play')} className={`${pathname === '/nous-games/play' ? 'toggle-game-button-active' : 'toggle-game-button'} rounded-tl-lg`}>
                Play New Game
            </div>
            <div onClick={() => change('played')} className={`${pathname === '/nous-games/played' ? 'toggle-game-button-active' : 'toggle-game-button'} rounded-tr-lg`}>
                History of Games Played
            </div>
        </div>
        <div className={'bg-white-base rounded-b-lg p-3'}>
            <Outlet/>
        </div>
    </div>
  )
}

export default NousGames
