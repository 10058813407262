import api from '../../../utils/api'
import { allOrgUsers, updateOrgUser } from './ActionCreator'

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAllOrgUsers = (orgId, pageNumber = 1, pageSize = 15) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/organization/${orgId}/org-users?page=${pageNumber}&pageSize=${pageSize}`).then((res) => {
      dispatch(allOrgUsers(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const handleUpdateOrgUser = (orgUserId, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().put(`/organization/org-user/${orgUserId}`, data).then((res) => {
      dispatch(updateOrgUser(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
