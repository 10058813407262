import React, { useState } from 'react'
import { Menu } from 'antd'
import { createGlobalStyle } from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { useLocation } from 'react-router'
import { GiHamburgerMenu } from 'react-icons/gi'

const GlobalStyles = createGlobalStyle`
  .ant-menu-inline {
    border: none !important;
    background-color: transparent !important;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #FFFFFF !important;
  }

  :where(.css-dev-only-do-not-override-1n7nwfa).ant-menu-light .ant-menu-item-selected {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    padding: 8px 12px !important;
    color: #53B1FD !important;
    width: fit-content !important;
    height: 36px !important;
    background: #EFF8FF !important;
    border-radius: 6px !important;
  }

  .ant-menu-title-content .nav-item {
    color: var(--Gray-700) !important;
    font-family: var(--Popins) !important;
    font-weight: 500;
  }

  .nav-icon {
    font-size: 20px !important;
    color: var(--Gray-500) !important;
  }

  .ant-menu-horizontal,
  :where(.css-dev-only-do-not-override-1n7nwfa).ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after {
    border: none !important;
    background-color: transparent !important;
  }

  .menu-bar {
    height: 2px;
    background-color: var(--Gray-900);
    width: 15px;
    margin-bottom: 3px;
  }

  :where(.css-dev-only-do-not-override-1n7nwfa).ant-menu-light.ant-menu-horizontal > .ant-menu-item:after {
    border: none !important;
  }

  :where(.css-dev-only-do-not-override-1n7nwfa).ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover {
    border: none !important;
    color: #53B1FD !important;
  }
`

const rootSubmenuKeys = []

// eslint-disable-next-line react/prop-types
function MenuHelper ({ permissions, role, menus, direction, icons, linkStyles, customClass }) {
  const location = useLocation()
  const { pathname } = useLocation()
  const [openKeys, setOpenKeys] = useState([])

  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1)
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  function getItem (label, icon, key, children) {
    return {
      key,
      icon,
      children,
      label
    }
  }

  const items = menus.map((nav) => {
    rootSubmenuKeys.push(nav.title)
    const isActive = (item = null) => {
      if (item) {
        return pathname === item.link ? 'text-blue-600 font-semibold' : ''
      }
      if (nav.children.length) {
        for (let i = 0; i < nav.children.length; i++) {
          const child = nav.children[i]
          if (pathname === child.link) {
            return 'text-blue-600 font-semibold'
          }
        }
      } else {
        return pathname === nav.link ? 'text-blue-600 font-semibold' : ''
      }
    }
    // const activeTab = pathname === nav.link ? '!text-blue-600 font-semibold' : ''
    return getItem(
            <Link
                className={`nav-item mr-3 ${isActive()}`}
                style={linkStyles} to={nav.link}>
                <span className={isActive()}>{nav.title}</span>
            </Link>,
            icons[nav.icon] ?? <span className={isActive()}>{icons[nav.icon]}</span>,
            nav.title,
            nav.children.length && nav.children.map((child) => (
              getItem(
                        <Link className={`nav-item ${isActive(child)}`}
                              style={linkStyles}
                              state={{ background: child.modal && location }}
                              to={child.link}>
                          <span className={isActive(child)}>{child.title}</span>
                        </Link>, icons[child.icon] ?? '', child.title)
            )
            )
    )
  })

  return (
        <div className={'w-full'}>
            <GlobalStyles/>
            <Menu className={customClass} overflowedIndicator={<GiHamburgerMenu size={20}/>}
                  defaultSelectedKeys={['home']} openKeys={openKeys} onOpenChange={onOpenChange}
                  mode={direction}
                  theme="light"
                  items={items}
            />
        </div>
  )
}

MenuHelper.defaultProps = {
  direction: 'horizontal',
  icons: {},
  role: '',
  customClass: null
}

MenuHelper.propTypes = {
  permissions: PropTypes.array.isRequired,
  menus: PropTypes.array.isRequired,
  direction: PropTypes.string,
  role: PropTypes.string.isRequired,
  icons: PropTypes.object,
  customClass: PropTypes.string
}

const mapStateToProps = (state) => {
  return {
    permissions: [],
    role: state.loginReducer.authUser.user_type
  }
}

export default connect(mapStateToProps)(MenuHelper)
