import React from 'react'
import { Route, Routes } from 'react-router-dom'
import CvDifference from '../cv-difference'
import SkillsForm from '../../pages/admin/skills/skills-form'
import QuestionsForm from '../../pages/admin/questions/questions-form'
import SkillRelationshipForm from '../../pages/admin/skill-relationships/skill-relationship-form'
import PostsForm from '../../pages/admin/posts/posts-form'
import EditSlideForm from '../../pages/admin/posts/edit-slide-form'
import EditRatingQuestion from '../../pages/admin/questions/edit-rating-question'
import EditScenarioQuestion from '../../pages/admin/questions/edit-scenario-question'
import SkillsImportForm from '../../pages/admin/skills/skills-import-form'
import ContentPostsForm from '../../pages/admin/content-posts/content-posts-form'
import QuestionsImportForm from '../../pages/admin/questions/questions-import-form'
import ContentPostsImportForm from '../../pages/admin/content-posts/content-posts-import-form'
import ModulesForm from '../../pages/admin/modules/modules-form'
import LessonQuestionsForm from '../../pages/admin/lesson-questions/lesson-questions-form'
import LessonsForm from '../../pages/admin/lessons/lessons-form'
import ModuleLessonsForm from '../../pages/admin/module-lessons/module-lessons-form'
import CoursesForm from '../../pages/admin/courses/courses-form'
import CourseModulesForm from '../../pages/admin/course-modules/course-modules-form'
import CourseCategoriesForm from '../../pages/admin/course-categories/course-categories-form'
import PersonalitiesForm from '../../pages/admin/personalities/personalities-form'
import OrgForm from '../../pages/organization/org-form'
import QuizQuestionsForm from '../../pages/admin/quiz-questions/quiz-questions-form'
import QuizForm from '../../pages/admin/quiz-forms/quiz-form'
import QuizFormQuestionsForm from '../../pages/admin/quiz-form-questions/quiz-form-questions-form'

export const AdminModalRoute = () => {
  return (
        <Routes>
            <Route path={'/skills/form'} element={<SkillsForm/>}/>
            <Route path={'/skills/import'} element={<SkillsImportForm/>}/>
            <Route path={'/posts/form'} element={<PostsForm/>}/>
            <Route path={'/content-posts/form'} element={<ContentPostsForm/>}/>
            <Route path={'/content-posts/import'} element={<ContentPostsImportForm/>}/>
            <Route path={'/modules/form'} element={<ModulesForm/>}/>
            <Route path={'/module-lessons/form'} element={<ModuleLessonsForm/>}/>
            <Route path={'/lessons/form'} element={<LessonsForm/>}/>
            <Route path={'/lesson-questions/form'} element={<LessonQuestionsForm/>}/>
            <Route path={'/courses/form'} element={<CoursesForm/>}/>
            <Route path={'/course-modules/form'} element={<CourseModulesForm/>}/>
            <Route path={'/course-categories/form'} element={<CourseCategoriesForm/>}/>
            <Route path={'/personalities/form'} element={<PersonalitiesForm/>}/>
            <Route path={'/quiz-questions/form'} element={<QuizQuestionsForm/>}/>
            <Route path={'/quiz-forms/form'} element={<QuizForm/>}/>
            <Route path={'/quiz-form-questions/form'} element={<QuizFormQuestionsForm/>}/>

            <Route path={'/posts/:title/edit-slide'} element={<EditSlideForm/>}/>
            <Route path={'/skill-relationships/form'} element={<SkillRelationshipForm/>}/>
            <Route path={'/questions/form'} element={<QuestionsForm/>}/>
            <Route path={'/questions/:title/edit-rating'} element={<EditRatingQuestion/>}/>
            <Route path={'/questions/:title/edit-scenario'} element={<EditScenarioQuestion/>}/>
            <Route path={'/questions/import'} element={<QuestionsImportForm/>}/>
            <Route path={'/job-applications/:title/difference'} element={<CvDifference/>}/>
            <Route path={'/organizations/form'} element={<OrgForm/>}/>
        </Routes>
  )
}
