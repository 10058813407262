import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router'
import TlaTableWrapper from '../../../components/table/tla-table-wrapper'
import { Spin, Table } from 'antd'
import PropTypes from 'prop-types'
import { handleDeleteCourseModule, handleGetAllCourseModules } from '../../../actions/admin/course-modules/Action'
import { connect } from 'react-redux'
import TlaConfirm from '../../../components/tla-confirm'
import { TlaSuccess } from '../../../utils/messages'
import TlaEdit from '../../../components/tla-edit'
import { FiEdit2 } from 'react-icons/fi'
import { handleGetAllCourses } from '../../../actions/admin/courses/Action'
import TlaSelect from '../../../components/TlaSelect'

const CourseModules = ({ getCourseModules, courseModules, deleteCourseModule, courses, getCourses }) => {
  const { setPageInfo } = useOutletContext()
  const { data, meta } = courseModules
  const [loading, setLoading] = useState(true)
  const [course, setCourse] = useState(0)
  useEffect(() => {
    setPageInfo({
      title: 'Course Module',
      buttonText: 'Add Course Module Link',
      buttonLink: 'Add Course Module Link',
      showImportBtn: false,
      subTitle: '',
      helpingText: '',
      showSearch: false
    })
    getCourses(1, 1000)
    getCourseModules().then(() => setLoading(false))
  }, [])
  return (
        <div className={'pb-4'}>
            <Spin spinning={loading}>
                <div className="w-1/4 ml-auto pr-6 mb-5">
                  <TlaSelect
                    defaultValue='Select Course'
                    onChange={(value) => {
                      setLoading(true)
                      getCourseModules(1, 15, { course: value }).then(() => {
                        setLoading(false)
                        setCourse(value)
                      })
                    }}
                    required name={'course'} options={courses} optionKey={'title'}/>
                </div>
                <TlaTableWrapper
                   meta={meta} filters={{ course }}
                   numberColumn={true} data={data} showHeader={false}
                   callbackFunction={getCourseModules}>
                    <Table.Column title={'Course Title'} render={((record) => record.course?.title ?? 'No Course')}/>
                    <Table.Column title={'Module Title'} render={((record) => record.module?.title ?? 'No Moudle')}/>
                    <Table.Column title={'Sequence'} render={((record) => record.sequence ?? 'N/A')}/>
                    <Table.Column title={'Actions'} render={((record) => (
                        <div className={'flex items-center'}>
                            <TlaConfirm title={'Delete CourseModule'}
                                        fullText={'Do you really want to delete this Course Module?'}
                                        callBack={() => {
                                          deleteCourseModule(record.id).then(() => TlaSuccess('Deleted'))
                                        }}/>
                            <TlaEdit data={record} icon={<FiEdit2 className={'icon'}/>} link={'form'}/>
                        </div>
                    ))}/>
                </TlaTableWrapper>
            </Spin>
        </div>
  )
}

CourseModules.propTypes = {
  getCourseModules: PropTypes.func.isRequired,
  deleteCourseModule: PropTypes.func.isRequired,
  getCourses: PropTypes.func.isRequired,
  courses: PropTypes.array.isRequired,
  courseModules: PropTypes.object.isRequired
}

/**
 * @param state
 * @returns {{courseModules: *}}
 */
const mapStateToProps = (state) => ({
  courseModules: state.adminCourseModulesReducer.courseModules ?? {},
  courses: state.adminCoursesReducer.courses.data ?? []
})

/**
 *
 * @param dispatch
 * @returns {{getCourseModules: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  getCourseModules: (pageNumber, pageSize, filters) => dispatch(handleGetAllCourseModules(pageNumber, pageSize, filters)),
  getCourses: (pageNumber, pageSize) => dispatch(handleGetAllCourses(pageNumber, pageSize)),
  deleteCourseModule: (courseModuleId) => dispatch(handleDeleteCourseModule(courseModuleId))
})

export default connect(mapStateToProps, mapDispatchToProps)(CourseModules)
