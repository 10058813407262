import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function addModule (payload) {
  return {
    type: Types.NEW_MODULE,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function allModules (payload) {
  return {
    type: Types.ALL_MODULES,
    payload
  }
}

/**
 *
 * @param post
 * @returns {{post, type: string}}
 */
export function deleteModule (module) {
  return {
    type: Types.DELETE_MODULE,
    module
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function updateModule (payload) {
  return {
    type: Types.UPDATE_MODULE,
    payload
  }
}
