import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import ProfileBg from '../../../../assets/images/dashboard/profile-bg.svg'
import { Divider, Image } from 'antd'
import DashItems from './dash-items'
import PropTypes from 'prop-types'
import DefaultImage from '../../../../assets/images/default.png'
import { useSelector } from 'react-redux'
import { formatDate } from '../../../../utils'
import ShareFeedback from '../../../../components/share-feedback'

const GlobalStyles = createGlobalStyle`

  .profile-image {
    border: 4px solid #FFFFFF;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 200px;
  }
  .profile-name {
    color: var(--Gray-900);
    margin-top: 20px;
  }
  .profile-job-title {
    margin-top: -28px;
  }
  .dash-item{
    margin-bottom: 12px;
    text-align: left;
    display: flex;
    align-items: center;
    align-content: center;
    height: 50px;
  }
  .supporting-text {
    padding-top: 8px;
    margin-bottom: -8px;
  }
  
  .joined-info {
    font-family: var(--Popins);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--Gray-500);
    margin-top: 50px;
  }
  
  .side-bg {
    background: white !important;
    border-radius: 10px !important;
  }
`
const ProfileContainer = styled.div`
  background: url(${ProfileBg}) center center no-repeat;
  border-radius: 10px 10px 0 0;
  height: 120px;
`
const AvatarContainer = styled.div`
  margin-top: -32px;
  justify-content: center;
  display: block;
  text-align: center;
  align-items: center;
  align-content: center;
  margin-bottom: 35px;
`

const ProfileFooter = styled.div`
  justify-content: center;
  display: block;
  text-align: center;
  background: #FFF;
  align-items: center;
  align-content: center;
  padding: 10px 0 50px; 
  border-radius: 10px;
`

function Profile ({ userInfo }) {
  const avatar = DefaultImage
  const profile = useSelector(state => state.loginReducer?.authUserInfo?.userInformation)
  return (
        <div className={'side-bg'}>
            <GlobalStyles/>
            <ProfileContainer/>
            <AvatarContainer>
                <Image alt={userInfo?.userInformation?.name}
                       src={profile?.photo !== 'default.png' ? profile?.photo : avatar}
                       preview={false}
                       className={'profile-image !h-24 !w-24'}/>
                <h3 className={'text-md-medium profile-name'}>{userInfo?.userInformation?.name ?? ''}</h3> <br/>
                <h4 className={'text-sm-normal profile-job-title text-gray-500 text-sm mb-3.5'}>{userInfo?.userInformation?.jobTitle ?? ''}</h4>
                <ShareFeedback/>
            </AvatarContainer>
            <div className={'ml-5'}>
                <DashItems/>
            </div>
            <ProfileFooter>
                <Divider/>
                <h4 className={'joined-info'}>Joined: { formatDate(userInfo.createdAt ?? '2023-01-01', 'YYYY-MM-DD')}</h4>
            </ProfileFooter>
        </div>
  )
}

Profile.propTypes = {
  userInfo: PropTypes.object.isRequired
}

export default Profile
