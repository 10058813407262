import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function addQuizFormQuestion (payload) {
  return {
    type: Types.NEW_QUIZ_FORM_QUESTION,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function allQuizFormQuestions (payload) {
  return {
    type: Types.ALL_QUIZ_FORM_QUESTIONS,
    payload
  }
}

/**
 *
 * @param post
 * @returns {{post, type: string}}
 */
export function deleteQuizFormQuestion (quizFormQuestionId) {
  return {
    type: Types.DELETE_QUIZ_FORM_QUESTION,
    quizFormQuestionId
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function updateQuizFormQuestion (payload) {
  return {
    type: Types.UPDATE_QUIZ_FORM_QUESTION,
    payload
  }
}
