import { Types } from '../../actions/admin/course-modules/Types'

const initialState = {
  courseModules: {
    data: [],
    meta: {}
  },
  courseModule: {}
}

export default function adminCoursesReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_COURSE_MODULE:
      return {
        ...state,
        courseModules: {
          ...state.courseModules,
          data: [
            action.payload.data.courseModule,
            ...state.courseModules.data
          ]
        }
      }
    case Types.ALL_COURSE_MODULES:
      return {
        ...state,
        courseModules: {
          ...state.courseModules,
          data: action.payload.data.courseModules,
          meta: {
            total: action.payload.data.count,
            current_page: Number(action.payload.data.page),
            page: action.payload.data.page,
            pageSize: action.payload.data.pageSize
          }
        }
      }

    case Types.UPDATE_COURSE_MODULE:{
      return {
        ...state,
        courseModules: {
          ...state.courseModules,
          data: state.courseModules.data.map((courseModule) => {
            return courseModule.id === action.payload.data.courseModule.id ? action.payload.data.courseModule : courseModule
          })
        }
        // courseModule: action.payload.data.upda
      }
    }
    case Types.DELETE_COURSE_MODULE:
      return {
        ...state,
        courseModules: {
          ...state.courseModules,
          data: state.courseModules.data.filter((courseModule) => courseModule.id !== action.courseModule)
        }
      }

    default:
      return state
  }
}
