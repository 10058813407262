import { Types } from '../actions/job-applications/Types'

const initialState = {
  jobApplications: {
    data: [],
    meta: {}
  }
}

/**
 *
 * @param state
 * @param action
 *
 * @returns {{authenticated: boolean, authToken: null, authUser: {}}|{authenticated: boolean, authToken: null, authUser: {}}|{authenticated: boolean, authToken, authUser: *}}
 */
export default function jobApplicationReducer (state = initialState, action) {
  switch (action.type) {
    case Types.GET_JOB_APPLICATIONS: {
      return {
        ...state,
        jobApplications: {
          data: action.payload.data.jobs,
          meta: {
            total: action.payload.data.count,
            from: action.payload.data.page,
            current_page: parseInt(action.payload.data.page),
            to: action.payload.data.pageSize
          }
        }
      }
    }
    default:
      return state
  }
}
