import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { handleGetUserGames } from '../../actions/user/common/Action'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spin } from 'antd'
import SingleNousGame from './single-nous-game'

function PlayNewGame ({ getGames, games }) {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const { data, meta } = games
  const loadMoreData = () => {
    setLoading(true)
    getGames().then(() => {
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }
  useEffect(() => {
    loadMoreData()
  }, [])

  return (
    <div>
         <InfiniteScroll
            dataLength={data?.length}
            next={loadMoreData}
            hasMore={data?.length < meta.total}
            scrollableTarget="scrollableDiv">
            <Spin spinning={loading}>
              {
                data.map((quiz, i) => (
                  <div key={i} className='w-full'>
                      <SingleNousGame quiz={quiz}/>
                  </div>
                ))
              }
            </Spin>
        </InfiniteScroll>
    </div>
  )
}

PlayNewGame.propTypes = {
  games: PropTypes.object,
  getGames: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  games: state.commonReducer.games
})

const mapDispatchToProps = (dispatch) => ({
  getGames: () => dispatch(handleGetUserGames())
})

export default connect(mapStateToProps, mapDispatchToProps)(PlayNewGame)
