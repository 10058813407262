import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { handleGetUserPlayedGames } from '../../actions/user/common/Action'
import { useNavigate } from 'react-router-dom'
import { Spin } from 'antd'
import GameStats from './play-game/game-stats'

function PlayedGames ({ getPlayedGames, userPlayedGames, userId }) {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const loadMoreData = () => {
    setLoading(true)
    getPlayedGames(userId).then(() => {
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }
  useEffect(() => {
    loadMoreData()
  }, [])

  const navigate = useNavigate()
  const goToDetails = (game) => {
    dispatch({ type: 'SET_SINGLE_PLAYED_GAME', payload: game })
    navigate(`/played-game/${game?.uid}/result`)
  }
  if (userPlayedGames?.length === 0) {
    return <div className={'text-center'}> <h3>No Game Played</h3></div>
  }
  return (
        <Spin spinning={loading}>
            {
                userPlayedGames?.map((game) => (
                        <div key={ game.id } onClick={ () => goToDetails(game) }
                             className={ 'bg-gray-25 py-4 mb-3 rounded-xl w-full border-[#F4F4F4] border-2 cursor-pointer' }>
                            <div className="px-4">
                                <h3 className="font-semibold text-lg text-gray-900 mb-2">{ game?.playedContent?.title ?? '-' }</h3>
                              <GameStats stats={game?.finalStats?.final} statsInfo={game?.finalStats?.titles} />
                            </div>
                        </div>
                )
                )
            }
        </Spin>
  )
}

PlayedGames.propTypes = {
  userPlayedGames: PropTypes.array,
  userId: PropTypes.number,
  getPlayedGames: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  userPlayedGames: state.commonReducer.userPlayedGames,
  userId: state.loginReducer.authUser.id
})

const mapDispatchToProps = (dispatch) => ({
  getPlayedGames: (userId) => dispatch(handleGetUserPlayedGames(userId))
})

export default connect(mapStateToProps, mapDispatchToProps)(PlayedGames)
