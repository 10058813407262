import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Progress, Spin } from 'antd'
import PropTypes from 'prop-types'
import { handleGetSubmitFeedback } from '../../../actions/user/feedbacks/Action'
import { TlaError, TlaSuccess } from '../../../utils/messages'
import OptionItem from './senario-options'
import LearningAndDevHeader from '../learning-and-dev-header'
import FeedbackHeader from '../feedback-header'
import { formatBodyOptions } from '../../../utils'

function ScenarioQuestion ({ data, submitFeedback }) {
  const [selected, setSelected] = useState(null)
  const [loading, setLoading] = useState(false)
  const [answers, setAnswers] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [timer, setTimer] = useState(0)

  const formValues = {
    giver: {
      id: data?.userGiver.id,
      email: data?.userGiver.email
    },
    receiver: {
      id: data?.userReceiver.id,
      email: data?.userReceiver.email
    },
    questions: answers
  }

  const submit = () => {
    if (submitting === true) {
      setTimeout(() => {
        setTimer(timer + 20)
        if (timer >= 100) {
          setSubmitting(false)
          setTimer(0)

          setLoading(true)
          submitFeedback(formValues, data.id).then(() => {
            setLoading(false)
            TlaSuccess('Feedback Submitted')
          }).catch(() => {
            setTimer(0)
            TlaError('Something went wrong')
          })
        }
      }, 1000)
    } else {
      setTimer(0)
    }
  }
  useEffect(() => {
    submit()
  }, [timer, submitting])

  const isSelf = () => data.userGiverId === data.userReceiverId

  /*  const getOptions = (questions) => {
    if (typeof questions !== 'object') {
      return JSON.parse(questions)
    }

    return questions
  } */

  let options
  if (isSelf()) {
    options = formatBodyOptions(data?.question?.questionBodySelf)?.options ?? []
  } else {
    options = formatBodyOptions(data?.question?.questionBodyOthers)?.options ?? []
  }

  if (options.length === 0) {
    options = formatBodyOptions(data?.question?.questionBodySelf)?.options ?? []
  }
  // const random = randomize(options)
  return (
        <div className={'w-full min-h-[445px] rounded-10 bg-white-base p-5 md:p-[30px]'}>
            {
                (data.userGiverId === data.userReceiverId)
                  ? <LearningAndDevHeader questionId={data?.question?.id}/>
                  : <FeedbackHeader
                        questionId={data?.question?.id}
                        header1={false}
                        data={data?.userReceiver?.userInformation}/>
            }
            <div className={'mb-5'}>
                <p className={'text-base text-black-900'}>
                    {isSelf() ? data?.question?.questionTitleSelf : data?.question?.questionTitleOthers}
                </p>
            </div>
            <Spin spinning={loading}>
                <div className={'flex flex-col items-start justify-start gap-4'}>
                    {
                      options.map(({ score, text, explanation }, index) => (
                            <OptionItem
                                style={{
                                  cursor: selected ? 'default' : 'pointer',
                                  color: (selected && selected !== index) ? '#D0D5DD' : (selected === index ? '#175CD3' : 'black')
                                }}
                                onClick={() => {
                                  if (selected === null) {
                                    setAnswers([{
                                      id: data?.question?.id,
                                      type: data?.question?.type,
                                      skillId: data?.question?.skillId,
                                      newQuestionScore: score
                                    }])
                                    setSelected(index)
                                    setSubmitting(true)
                                  }
                                }}
                                key={index} text={text}
                                explanation={explanation}
                                selected={selected === index}
                            />
                      ))

                    }
                </div>
            </Spin>
            {
                submitting &&
                <div className={'flex items-center justify-center gap-x-3 flex-wrap'}>
                    <p>Submitting</p>
                    <div className={'w-1/2'}>
                        <Progress size={'small'} className={'pt-2'} percent={timer} showInfo={false}/>
                    </div>
                    <Button size={'small'} type={'text'} danger onClick={() => {
                      setSubmitting(false)
                      setTimer(0)
                      setAnswers([])
                      setSelected(null)
                    }} className={'btn'}>Cancel</Button>
                </div>
            }
        </div>
  )
}

ScenarioQuestion.propTypes = {
  data: PropTypes.object,
  submitFeedback: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => ({
  submitFeedback: (data, feedId) => dispatch(handleGetSubmitFeedback(data, feedId))
})

export default connect(null, mapDispatchToProps)(ScenarioQuestion)
