import dayjs from 'dayjs'

/**
 *
 * @param word
 * @returns {*}
 */
export const capitalize = (word) => {
  return word.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
}

export const isObject = (item) => {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null)
}

export const formatQuizFormOptions = (items, send = false) => {
  if (send) {
    return items.map(option => {
      return {
        title: option.title,
        description: option.description,
        tier_range: [Number(option.range_start), Number(option.range_end)]
      }
    })
  }
  return items.map(option => {
    return {
      title: option.title,
      description: option.description,
      range_start: option.tier_range[0],
      range_end: option.tier_range[1]
    }
  })
}

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}
/**
 *
 * @param partialValue
 * @param totalValue
 * @returns {number|string}
 */
export const getPercentage = (partialValue = 0, totalValue = 0) => {
  const percentage = ((10 * partialValue) / totalValue)
  return percentage.toString() === 'NaN' ? 0 : percentage.toFixed(2)
}

export const formatDate = (date = dayjs(), format = 'MMM DD, YYYY') => {
  if (date) {
    return dayjs(date).format(format)
  }
  return null
}

export const dateDifference = (date) => {
  if (date) {
    const today = dayjs()
    return today.diff(dayjs(date), 'month')
  }

  return null
}
export const hashCode = (str) => {
  let hash = 0
  let i
  let chr
  if (str.length === 0) return hash
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i)
    hash = ((hash << 5) - hash) + chr
    hash |= 0
  }
  return hash
}

export const graphColors = ['#1570EF', '#53B1FD', '#40a800', '#E91E63', '#FF9800']

export function getCookie (cname) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return null
}

export const randomize = (array) => {
  return array.sort(() => Math.random() - 0.5)
}

export const formatPostBody = (body) => {
  return typeof body === 'object' ? Object.values(body) : Object.values(JSON.parse(body))
}

export const formatBodyOptions = (body) => {
  try {
    if (body === null || body === 'null') {
      return []
    }

    if (typeof body === 'string') {
      return JSON.parse(body)
    }

    return typeof body === 'object' ? Object.values(body) : JSON.parse(body)
  } catch (err) {
    console.log(body, typeof body)
  }
}

export const addOrReplace = (arr, element) => {
  const i = arr.findIndex(item => item.id === element.id)
  if (i > -1) arr[i] = element
  else arr.push(element)

  return [...arr]
}

export function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export const getInitials = (name) => {
  if (name === '' || name === null) {
    return 'N/A'
  }
  // eslint-disable-next-line prefer-regex-literals
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')

  const initials = [...name.matchAll(rgx)] || []
  return ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase()
}

export const getErrors = (errors = []) => {
  if (errors && errors.length > 0) {
    return errors.map(error => error?.message).toString()
  }
}

export const RTEconfig = {
  apiKey: 'bcz0c9xjnhb0jowfvwwotyytptzhf6suuba6pv8jch9y8vii',
  init: {
    height: 300,
    menubar: false, // Enables the menu bar
    plugins: 'advlist autolink lists charmap print preview hr anchor pagebreak link image table',
    toolbar: 'styles fontFamily bold italic alignleft aligncenter alignright alignjustify bullist numlist outdent indent link fullpage forecolor backcolor'
  }
}

export const contactTypes = [
  {
    value: 'Student',
    label: 'Student'
  },
  {
    value: 'Early Career',
    label: 'Early Career (0-3 yrs)'
  },
  {
    value: 'Mid Senior',
    label: 'Mid Senior (4-7 yrs)'
  },
  {
    value: 'Senior',
    label: 'Senior (8+)'
  },
  {
    value: 'Executive',
    label: 'CXOs (Executive)'
  },
  {
    value: 'Family/Friends',
    label: 'Family/Friends'
  }
]

export const sentenceCaseString = (str) => {
  return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()
}

export function generateRandomPhoneNumberWithCountryCode () {
  // Define a list of common country codes. This can be expanded as needed.
  const countryCodes = ['+1', '+44', '+61', '+91', '+81']

  // Randomly select a country code from the list.
  const countryCode = countryCodes[Math.floor(Math.random() * countryCodes.length)]

  // Generate a random area code (100-999), avoiding numbers starting with 0 or 1.
  const areaCode = Math.floor(Math.random() * 900) + 100

  // Generate the first three digits of the main number (100-999), avoiding sequences starting with 0 or 1.
  const firstPart = Math.floor(Math.random() * 900) + 100

  // Generate the last four digits of the main number (0000-9999).
  const secondPart = Math.floor(Math.random() * 10000).toString().padStart(4, '0')

  // Format and return the phone number with the country code.
  return `${countryCode} (${areaCode}) ${firstPart}-${secondPart}`
}

export function getRandomHexColor (index) {
  const colors = ['#87CEEB', '#4A6D8C', '#5F7A61', '#228B22', '#800080', '#FFA500', '#008080', '#800020']

  return colors[index]
}

export function generateRandomPhoneNumber () {
  let phoneNumber = ''
  for (let i = 0; i < 10; i++) {
    phoneNumber += Math.floor(Math.random() * 10)
  }
  return phoneNumber
}
