import React from 'react'
import { useSelector } from 'react-redux'
import { Card } from 'antd'
import TlaEdit from '../../../components/tla-edit'
import { formatPostBody } from '../../../utils'

const SinglePost = () => {
  const post = useSelector(state => state.adminPostsReducer.post)

  return (
        <div className={'pb-4'}>
            <div className={'mb-5'}>
                <h3 className={'text-xl'}>{post?.title}</h3>
                <h3>Total Slide: {post?.totalSlide}</h3>
            </div>
            {
                formatPostBody(post?.body).map((slide, index) => (
                    <Card key={index} size={'small'}
                          className={'mb-5 shadow-sm'}
                          title={slide?.slide_title}
                          actions={[
                              <TlaEdit key={'edit'} link={'edit-slide'} data={slide} text={'Edit'}/>
                          ]}
                          extra={[
                              <p key={'number'}
                                 className={'bg-primary-700 w-[25px] h-[25px] text-center pt-0.5 text-white-base' +
                                     ' rounded-full'}>
                                  {slide?.slide_number}
                              </p>]}>
                        <div key={index}>
                            <h1>{slide?.content}</h1>
                        </div>
                    </Card>
                ))
            }
        </div>
  )
}

export default SinglePost
