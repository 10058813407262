import api from '../../../utils/api'
import { addPost, allPosts, deletePost, updatePost } from './ActionCreator'

/**
 * Store a newly created resource in storage.
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAddPost = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/educational-post', data).then((res) => {
      dispatch(addPost(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAllPosts = (pageNumber = 1) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/educational-post/all?page=${pageNumber}&pageSize=15`).then((res) => {
      dispatch(allPosts(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Update the specified resource in storage.
 * @param postId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleUpdatePost = (postId, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().put(`/educational-post/${postId}`, data, {
    }).then((res) => {
      dispatch(updatePost(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Remove the specified resource from storage.
 * @returns {function(*): Promise<unknown>}
 * @param postId
 */
export const handleDeletePost = (postId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().delete(`/educational-post/${postId}`).then((res) => {
      dispatch(deletePost(postId))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
